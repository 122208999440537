<div class="progress-bar-grid">
    <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
</div>
<main fxLayout="row wrap">
    <strong  fxFlex="100%">This information is displayed on the Resource Board across all clinics. This is only updated by Administrators</strong>
    <form  *ngIf="this.roleCaption.get(this.role) === 'Admin'" [formGroup]="helpfulInfoFormGroup" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <section fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
            <strong fxFlex="100%"><b>Information and Updates</b></strong>
            <ul fxLayout="row wrap" fxFlex="100%" formArrayName="information">
                <li  *ngFor="let item of informationArray.controls; let i = index" [formGroupName]="i" fxLayoutGap="16px" fxFlex="750px" fxLayout="row wrap"fxLayoutAlign="start center">
                    <mat-form-field fxFlex="200px" >
                        <mat-label>Title</mat-label>
                        <input formControlName="title" maxlength="60" matInput />
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Title is required</mat-error>
                      </mat-form-field>
                      <input [hidden]="true" (change)="onFileSelect($event, i)" type="file" accept="all/*" #fileSelect >

                        <button *ngIf="!getFileName(i)" fxFlex="160px" mat-stroked-button (click)="fileSelect.click();" [disabled]="doesUrlExist(i)">Choose Files</button>
                        <a *ngIf="getFileData(i)" (click)="downloadFile(getFileData(i),getFileType(i),getFileName(i) )"><p  >{{(getFileName(i).length > 10) ? (getFileName(i) | slice:0:10)+'..':(getFileName(i))}}</p></a>
                
                            <button  *ngIf="getFileName(i)" (click)="removeFile(i)" matSuffix 
                            type="button" color="warn" mat-icon-button>
                           <mat-icon>close</mat-icon>
                            </button>                
                      <strong>Or</strong>
                      <mat-form-field fxFlex="260px" >
                        <mat-label>URL</mat-label>
                        <input formControlName="url"  matInput  maxlength="255"   [readonly]="isFileUploaded(i)"/>
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Url must be correctly formatted</mat-error>
                      </mat-form-field>
                      <button fxFlex *ngIf="showDeleteInformation()" (click)="removeInformation(i)" mat-icon-button ><mat-icon color="warn">delete</mat-icon></button>
                      <mat-form-field fxFlex="636px" >
                        <mat-label>Description</mat-label>
                        <textarea maxlength="255" cdkTextareaAutosize cdkAutosizeMinRows="1" matInput formControlName="description"></textarea>
                      </mat-form-field>                    
                    </li>
            </ul>      
            <a (click)="addNewInformation()" ><strong><b>Add new</b></strong></a>

        </section>
        <section  fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start center">
            <strong fxFlex="100%"><b>Frequently Asked Questions</b></strong>
            <ul fxLayout="row wrap" fxFlex="100%" formArrayName="questions">
                <li *ngFor="let item of questionsArray.controls; let y = index" [formGroupName]="y" fxLayout="row wrap"  fxFlex="750px" fxLayoutGap="16px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="636px" >
                        <mat-label>Question</mat-label>
                        <input formControlName="question" maxlength="255" matInput />
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Question is required</mat-error>
                      </mat-form-field>
                      <button fxFlex *ngIf="showDeleteQuestion()" mat-icon-button (click)="removeQuestion(y)"><mat-icon color="warn">delete</mat-icon></button>
                      <mat-form-field fxFlex="636px" >
                        <mat-label>Answer</mat-label>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" formControlName="answer" maxlength="255" matInput ></textarea>
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Answer is required</mat-error>
                      </mat-form-field>
                    </li>
                </ul>        
            <a (click)="addNewQuestion()" ><strong><b>Add new</b></strong></a>
        </section>
        <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="cancel()"  mat-button color="primary"
            >Cancel</button>
          <button (click)="updateResource()" [disabled]="isWaiting" mat-raised-button color="primary"
           >Save</button>
    
        </footer>
    </form>
 
    <section    *ngIf="this.roleCaption.get(this.role) !== 'Admin'" fxLayout="row wrap" fxFlex="100%"> 

            <h2  fxFlex="100%">Information</h2>

        <ul  fxFlex="100%"  fxLayout="column"  fxLayoutAlign="space-between start" class="border-bottom margin-bottom-24" fxLayoutGap="32px">
            <li *ngFor="let info of dto?.informationDTO" fxLayout="column" class="margin-bottom-24" fxFlex="100%" >
                <strong *ngIf="!info?.url && !info?.fileData"><b>{{info?.title}}</b></strong>
                <strong *ngIf="info?.url"><a (click)="goToLink(info?.url)">{{info?.title}}</a></strong>
                <strong *ngIf="info?.fileData"><a (click)="downloadFile(info?.fileData, info?.fileType, info?.fileName)">{{info?.title}}</a></strong>
                <strong>{{info?.description}}</strong>


            </li>
        
        </ul>
        <div fxFlex="30%" fxLayout="row wrap" fxLayoutAlign="start start">
                <h2 fxFlex="100%">Frequently Asked Questions</h2>
                <!-- <mat-form-field fxFlex="100%" appearance="outline"  >
                    <mat-label>Search FAQ's</mat-label>
                    <input matInput [(ngModel)]="searchedTerm" />
                    <mat-icon matSuffix>search</mat-icon>
             </mat-form-field>    -->
        </div>
        <ul  fxFlex="100%"  fxLayout="row wrap"  fxLayoutAlign="space-between start">
            <li *ngFor="let question of dto?.faqDTO" fxLayout="column" class="margin-bottom-24" fxFlex="100%" >
                <strong><b  [innerHTML]="question?.question | highlight: searchedTerm"></b></strong>
                <strong [innerHTML]="question?.answer | highlight: searchedTerm"></strong>
            </li>
        </ul>

    </section>

</main>
