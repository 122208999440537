import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDTO, UserControllerService, UpdateUserDTO } from '@helplinesos-api-client';
import { UserDialogService } from '../user-dialog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppValidators } from '../../app-validators';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { ErrorService } from '../../shared/error.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { Location } from '@angular/common';
import { CancelDialogComponent } from '../../shared/cancel-dialog/cancel-dialog.component';
import { CancelDialogService } from '../../shared/cancel-dialog.service';

export interface InformationData {
  userDto: UserDTO;
}
@Component({
  selector: 'app-user-profile-editor',
  templateUrl: './user-profile-editor.component.html',
  styleUrls: ['./user-profile-editor.component.scss']
})
export class UserProfileEditorComponent extends ComponentBase implements OnInit, OnDestroy {
  isPasswordSectionShown = false;
  userDto: UserDTO;
  formGroup: FormGroup;
  isPasswordControlFocused = false;
  role: any;
  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };


  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],

  ]);

  timezone = '';
  timezoneText = 'Timezone';

  constructor(
    private cancelDialogService: CancelDialogService,
    private location: Location,
    private snackBarService: CustomSnackbarService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private userDialogService: UserDialogService,
    private service: UserControllerService
  ) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;

    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.formGroup = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)]],
      lastName: ['', [Validators.required, Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      oldPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'
          ),
        ],
      ],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'
          ),
        ],
      ],
      confirmPassword: ['', [Validators.required, AppValidators.isMatching('newPassword')],
      ]
    });

    this.service.getUserUsingGET().subscribe(response => {
      this.userDto = response;
      this.timezone = this.userDto.userTimeZone;
      if (this.timezone === null || this.timezone === '') {
        this.timezone = 'America/New_York';
      }
      this.formGroup.controls.firstName.setValue(this.userDto?.firstName);
      this.formGroup.controls.lastName.setValue(this.userDto?.lastName);
      this.formGroup.controls.phoneNumber.setValue(this.userDto?.phoneNumber);
      this.formGroup.controls.email.setValue(this.userDto?.email);
      this.isWaiting = false;
    });

    this.formGroup.controls.newPassword.valueChanges.subscribe(() => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });

    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));
  }

  ngOnDestroy() {
    this.errorService.clearErrorMessage();
  }

  getPhoneNumber(phonNumber: string) {
    return phonNumber ? '(' +
      phonNumber.slice(0, 3) + ') ' +
      phonNumber.slice(3, 6) + '-' +
      phonNumber.slice(6) : 'Phone number not set';
  }



  openEmailEditor() {
    this.userDialogService.openEmailEditor(this.userDto);
  }

  onPasswordControlFocus() {
    this.isPasswordControlFocused = true;
  }

  onPasswordControlFocusOut() {
    this.isPasswordControlFocused = false;
  }

  timezoneChange(element: any) {
    this.timezone = element.nameValue;
  }

  updateProfileIncludingEmail() {
    this.userDialogService.openPasswordConfirmationDialog(this.userDto);
  }

  updateProfile() {

    let hasPasswordErrors = false;
    if (this.formGroup.controls.newPassword.value === '' &&
      this.formGroup.controls.oldPassword.value === '' &&
      this.formGroup.controls.confirmPassword.value === ''
    ) {
      this.formGroup.controls.newPassword.clearValidators();
      this.formGroup.controls.newPassword.setErrors(null);
      this.formGroup.controls.oldPassword.clearValidators();
      this.formGroup.controls.oldPassword.setErrors(null);
      this.formGroup.controls.confirmPassword.clearValidators();
      this.formGroup.controls.confirmPassword.setErrors(null);
      hasPasswordErrors = true;
    }

    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {

      this.isWaiting = true;
      const updateUserDto: UpdateUserDTO =
      {
        email: this.formGroup.controls.email.value,
        enableCallVolumePopupNotification: this.userDto?.enableCallVolumePopupNotification,
        enableCallVolumeSoundNotification: this.userDto?.enableCallVolumeSoundNotification,
        enableDeActivateClinicPopupNotification: this.userDto?.enableDeActivateClinicPopupNotification,
        enableDeActivateClinicSoundNotification: this.userDto?.enableDeActivateClinicSoundNotification,
        enableDoNotDisturb: this.userDto?.enableDoNotDisturb,
        enableEnglishCallPopupNotification: this.userDto?.enableEnglishCallPopupNotification,
        enableEnglishCallSoundNotification: this.userDto?.enableEnglishCallSoundNotification,
        enableErOutcomePopupNotification: this.userDto?.enableErOutcomePopupNotification,
        enableErOutcomeSoundNotification: this.userDto?.enableErOutcomeSoundNotification,
        enableFlaggedCallPopupNotification: this.userDto?.enableFlaggedCallPopupNotification,
        enableFlaggedCallSoundNotification: this.userDto?.enableFlaggedCallSoundNotification,
        enableFollowUpCallPopupNotification: this.userDto?.enableFollowUpCallPopupNotification,
        enableFollowUpCallSoundNotification: this.userDto?.enableFollowUpCallSoundNotification,
        enableIncompleteCallPopupNotification: this.userDto?.enableIncompleteCallPopupNotification,
        enableIncompleteCallSoundNotification: this.userDto?.enableIncompleteCallSoundNotification,
        enableIncompleteClinicPopupNotification: this.userDto?.enableIncompleteClinicPopupNotification,
        enableIncompleteClinicSoundNotification: this.userDto?.enableIncompleteClinicSoundNotification,
        enableNewCallPopupNotification: this.userDto?.enableNewCallPopupNotification,
        enableNewCallSoundNotification: this.userDto?.enableNewCallSoundNotification,
        enableNewClinicPopupNotification: this.userDto?.enableNewClinicPopupNotification,
        enableNewClinicSoundNotification: this.userDto?.enableNewClinicSoundNotification,
        enableResourceBoardPopupNotification: this.userDto?.enableResourceBoardPopupNotification,
        enableResourceBoardSoundNotification: this.userDto?.enableResourceBoardSoundNotification,
        enableSpanishCallPopupNotification: this.userDto?.enableSpanishCallPopupNotification,
        enableSpanishCallSoundNotification: this.userDto?.enableSpanishCallSoundNotification,
        enableUpdatedClinicPopupNotification: this.userDto?.enableUpdatedClinicPopupNotification,
        enableUpdatedClinicSoundNotification: this.userDto?.enableUpdatedClinicSoundNotification,
        firstName: this.formGroup.controls.firstName.value,
        lastName: this.formGroup.controls.lastName.value,
        newPassword: this.formGroup.controls.newPassword.value,
        currentPassword: this.formGroup.controls.oldPassword.value,
        phoneNumber: this.formGroup.controls.phoneNumber.value.replace(/[&\/\\()-]/g, ''),
        userTimeZone: this.timezone,
        isoCountryCode: 'US'
      };
      // if (this.userDto.email !== this.formGroup.controls.email.value){
      //   this.userDialogService.openPasswordConfirmationDialog(this.userDto);
      // }
      // else{
      this.service.updateUserUsingPUT(updateUserDto, 'response').subscribe(response => {
        if (this.userDto.email !== this.formGroup.controls.email.value){
        this.userDto = response.body;
        localStorage.setItem('user', JSON.stringify(response.body));
        localStorage.setItem('token', response.headers.get('authorization'));
        }
        this.errorMessage = '';
        this.snackBarService.openToastNotification('User Profile Updated');
        this.isWaiting = false;
      });
      //   }
    }
    if (hasPasswordErrors) {
      this.refreshForm();
    }
  }


  resetPasswordSection() {
    this.isPasswordSectionShown = false;
    this.formGroup.controls.oldPassword.setValue('');
    this.formGroup.controls.newPassword.setValue('');
    this.formGroup.controls.confirmPassword.setValue('');
  }



  refreshForm() {
    this.formGroup = this.formBuilder.group({
      firstName: [this.formGroup.controls.firstName.value, [Validators.required, Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)]],
      lastName: [this.formGroup.controls.lastName.value, [Validators.required, Validators.maxLength(50),
      Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)]],
      phoneNumber: [this.formGroup.controls.phoneNumber.value.replace(/[&\/\\()-]/g, ''), [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      email: [this.formGroup.controls.email.value, [Validators.required, Validators.email, Validators.maxLength(50)]],
      oldPassword: [
        this.formGroup.controls.oldPassword.value,
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'
          ),
        ],
      ],
      newPassword: [
        this.formGroup.controls.newPassword.value,
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'
          ),
        ],
      ],
      confirmPassword: [this.formGroup.controls.confirmPassword.value, [Validators.required, AppValidators.isMatching('newPassword')],
      ]
    });
    this.formGroup.controls.newPassword.valueChanges.subscribe(() => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  cancel() {
    if (this.formGroup.dirty) {
      this.cancelDialogService.openDialog();
    }
    else {
      this.location.back();
    }
  }
}
