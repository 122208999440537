<nav>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</nav>

<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="100%" fxFlex.xs="90%" fxFlex.gt-xs="80%" fxFlex.gt-sm="60%">
    <header>
      <h2>
        Forgot your Password?
      </h2>
      <h3>
        Don't worry, just enter your email address and we will send you
        instructions on reseting your password
      </h3>
    </header>

    <form fxLayout="column" [formGroup]="forgotPassForm">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="" />
        <mat-error *ngIf="forgotPassForm.controls['email'].invalid && isValidationShown">Please enter a valid email address</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="send()">Send</button>
    </form>

    <footer> 
      <h4>
        <strong>
          Did you remember your password?
          <a [mat-dialog-close]="true">Return to login</a>
        </strong>
      </h4>
    </footer>
  </div>
</div>
