import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription, Observer } from 'rxjs';
import { DirtyComponent } from '../shared/models/dirty-component';
import { debug } from 'console';
import { MatDialog } from '@angular/material/dialog';
import { LeaveScribeDialogComponent } from './scribe/leave-scribe-dialog/leave-scribe-dialog.component';
import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { ScribeComponent } from './scribe/scribe.component';

@Injectable({
  providedIn: 'root'
})
export class UnassignGuard implements CanDeactivate<ScribeComponent> {
  allowLeavingScribe = false;

  constructor(
    private dialog: MatDialog,
  ) {

  }

  canDeactivate(
    component: ScribeComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const x = nextState.url;
    if (x === '/login' || component.isRegularLeaving) {
      return true;
    }
    // const isDirty = component.checkIsDirtyState();
    // if (isDirty) {
    return Observable.create((observer: Observer<boolean>) => {
      const dialogRef = this.dialog.open(LeaveScribeDialogComponent, {
        maxWidth: '560px',
        minWidth: '560px',
        backdropClass: 'dialog-backdrop'
      });
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
        if (result){
          component.sendCallBackToPending();
        }
        return result;
      });
    });
    }
    // else {
    //   if (component.isNewCall === 'true'){
    //     component.revertCallToNewCall();
    //     return true;
    //   }
    //   else{
    //     return true;
    //   }
    // }
 // }

}
