<mat-sidenav-container autosize>
  <mat-sidenav mode="side" opened disableClose="true">
    <aside fxLayout="column" [ngClass]="isExtended ? 'wide-side-nav' : 'shrink-side-nav'" fxLayoutAlign="start stretch">
      <header fxLayout="row wrap" fxLayoutAlign="start start">
        <div fxFlex="100%">
          <img (click) = "homeRedirect()"  src="../assets/images/HelplineLogo_02_FinalColor.svg" />
        </div>
        <div fxFlex="100%">
          <h2  (click) = "homeRedirect()" >{{isExtended ? 'Helpline SOS' : ''}} </h2>
        </div>
        <div  fxFlex="100%">
          <button *ngIf="(this.roleCaption.get(this.role) === 'Volunteer' || this.roleCaption.get(this.role) === 'Captain') && isExtended" (click)="collapseSideNav()" mat-icon-button mat-stroked-button><mat-icon>keyboard_arrow_left</mat-icon></button>
        </div>
        <div fxFlex="100%">
          <button *ngIf="(this.roleCaption.get(this.role) === 'Volunteer' || this.roleCaption.get(this.role) === 'Captain') && !isExtended" (click)="collapseSideNav()" mat-icon-button mat-stroked-button><mat-icon>keyboard_arrow_right</mat-icon></button>
        </div>

  
      </header>
      <mat-nav-list  *ngIf="this.roleCaption.get(this.role) === 'Admin'" fxFlex fxLayoutAlign="start stretch" fxLayout="column" >
        <mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon matListIcon>dashboard</mat-icon>
          <a *ngIf="isExtended" class="clinics" mat-list-item  [routerLink]="'/clinics'"><b>Clinics</b></a><!-- class="mat-subheading-1" -->
        </mat-list-item>
        <mat-list-item class="secondList" *ngIf="clinicId" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <a *ngIf="isExtended" class="clinic-information" mat-list-item [routerLink]="clinicInformationLink">Clinic Information </a>
        </mat-list-item>
        <mat-list-item class="secondList" *ngIf="isActive()" routerLinkActive="active">
          <a *ngIf="isExtended" class="call-queue-admin" mat-list-item  [routerLink]="callQueueLink">Call Queue</a><!-- class="mat-subheading-1" -->
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" >
          <mat-icon matListIcon>folder_open</mat-icon>
          <a *ngIf="isExtended" class="resource-board-admin" mat-list-item [routerLink]="'/resource-dashboard'">Resource Board</a>
        </mat-list-item>
      
      </mat-nav-list> 

      <mat-nav-list  fxFlex *ngIf="this.role === 'ROLE_VOLUNTEER'" fxLayoutAlign="start stretch" fxLayout="column" >

        <mat-list-item routerLinkActive="active" [routerLink]="['/call-queue', clinicId]">
          <mat-icon matListIcon>dashboard</mat-icon>
          <a *ngIf="isExtended" mat-list-item  class="call-queue-volunteer" >Call Queue</a><!-- class="mat-subheading-1" -->
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/team', clinicId]">
          <mat-icon matListIcon>group</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="team-volunteer"  >Team</a>
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/clinics', clinicId, 'information']">
          <mat-icon matListIcon>local_hospital</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="clinic-information-captain">Clinic Information</a>
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/resource-dashboard', clinicId]">
          <mat-icon matListIcon>folder_open</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="resource-board-volunteer" >Resource Board</a>
        </mat-list-item>
        <div  fxLayout="column" fxLayoutAlign="end" *ngIf="isExtended"><h4 >Team</h4></div>
      </mat-nav-list>

      <mat-nav-list  fxFlex *ngIf="this.roleCaption.get(this.role) === 'Captain'" fxLayoutAlign="start stretch" fxLayout="column" >

        <mat-list-item routerLinkActive="active" [routerLink]="['/call-queue', clinicId]" >
          <mat-icon  matListIcon>dashboard</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="call-queue-captain" >Call Queue</a><!-- class="mat-subheading-1" -->
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/team', clinicId]">
          <mat-icon matListIcon>group</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="team-captain" >Team</a>
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/clinics', clinicId, 'information']">
          <mat-icon matListIcon>local_hospital</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="clinic-information-captain">Clinic Information</a>
        </mat-list-item>
        <mat-list-item  routerLinkActive="active" [routerLink]="['/resource-dashboard', clinicId]">
          <mat-icon matListIcon>folder_open</mat-icon>
          <a *ngIf="isExtended" mat-list-item class="resource-board-captain">Resource Board</a>
        </mat-list-item>
        <div  fxLayout="column" fxLayoutAlign="end" *ngIf="isExtended"><h4 >Team</h4></div>
      </mat-nav-list>


      <mat-nav-list
        fxFlex = "50%"
        fxLayout="column"
        fxLayoutAlign="space-between start"
      >
        <ul  *ngIf="isExtended && this.role !== 'ROLE_ADMIN'" fxFlex fxLayout="column" fxLayoutGap="16px" >
          <li *ngFor="let member of members" fxLayout="row" >
            <mat-icon *ngIf="member?.onlineStatus !== 'PENDING'" [ngClass]='getStatusClass(member?.onlineStatus)'>{{member?.onlineStatus === 'INCALL'? 'call' : 'fiber_manual_record'}}</mat-icon>
            <strong >{{(member?.firstName?.length > 10) ? ((member?.firstName + ' ' + member?.lastName[0] + '.') | slice:0:12)+'..':(member?.firstName + ' ' + member?.lastName[0] + '.')}}</strong>
          </li>
        </ul>
        <div *ngIf="!isExtended">

        </div>
        <div *ngIf="this.role === 'ROLE_ADMIN'" >

        </div>
     
      <mat-list-item routerLinkActive="active" (click)="open()">
        <mat-icon matListIcon>rate_review</mat-icon>
        <a class="report-issue" *ngIf="isExtended" mat-list-item >Report Issue</a>
      </mat-list-item>
        <!-- <mat-list-item routerLinkActive="active">
          <mat-icon matListIcon>settings</mat-icon>
          <a *ngIf="isExtended" mat-list-item [routerLink]="'/settings'">Settings</a>
        </mat-list-item> -->
        
      </mat-nav-list>

    </aside>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

