import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';
import { UserDTO } from '@helplinesos-api-client';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SaveResourcesBoardComponent } from './save-resources-board/save-resources-board.component';
import { LeaveResourceDialogComponent } from '../leave-resource-dialog/leave-resource-dialog.component';

@Component({
  selector: 'app-resource-dashboard',
  templateUrl: './resource-dashboard.component.html',
  styleUrls: ['./resource-dashboard.component.scss']
})
export class ResourceDashboardComponent extends ComponentBase implements OnInit {
  @Input() public clinicIdForInformationView: number;
  clinicId: number;
  isDirtyForm: boolean;
  currentTab = '';
  role: any;
  tabs: any[];
  public destroyed = new Subject<any>();
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService) {
    super();
  }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    switch (this.role) {
      case UserDTO.RoleEnum.CAPTAIN:
      case UserDTO.RoleEnum.COORDINATOR: {
        this.tabs = [
          { value: 'scribeActions', display: 'Scribe Actions' },
          { value: 'helpfulInformation', display: 'Helpful Information' },
          { value: 'bulletin', display: 'Reminders and Updates' },
          { value: 'helplineSOSResource', display: 'HelplineSOS Resource' },
          { value: 'clinicFAQs', display: 'Clinic FAQs' }
        ];
        this.currentTab = 'scribeActions';
        break;
      }
      case UserDTO.RoleEnum.ADMIN: {
        if (this.clinicIdForInformationView) {
          this.tabs =
            [
              { value: 'helpfulInformation', display: 'Helpful Information' },
              { value: 'bulletin', display: 'Reminders and Updates' },
              { value: 'helplineSOSResource', display: 'HelplineSOS Resource' },
              { value: 'clinicFAQs', display: 'Clinic FAQs' }
            ];
          this.currentTab = 'helpfulInformation';
        }
        else {
          this.tabs =
            [
              { value: 'helplineSOSResource', display: 'HelplineSOS Resource' },
            ];
          this.currentTab = 'helplineSOSResource';
        }
        break;
      }
      case UserDTO.RoleEnum.VOLUNTEER: {
        this.tabs =
          [
            { value: 'helpfulInformation', display: 'Helpful Information' },
            { value: 'bulletin', display: 'Reminders and Updates' },
            { value: 'helplineSOSResource', display: 'HelplineSOS Resource' },
            { value: 'clinicFAQs', display: 'Clinic FAQs' }
          ];
        this.currentTab = 'helpfulInformation';
        break;
      }
      default:
        break;
    }

    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== '') {
        this.isWaiting = false;
      }
    }));
    this.isNotificationView();
  }

  isNotificationView() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      const tab = this.route.snapshot.queryParamMap.get('tab');
      if (tab){
        this.currentTab = tab;
      }
    });
    const tab1 = this.route.snapshot.queryParamMap.get('tab');
    if (tab1){
      this.currentTab = tab1;
    }
  }

  onFormGroupChangeEvent(e: any) {
    this.isDirtyForm = e.dirty;
  }

  changeTab(tabValue: string, e: any, group: any) {

    if (this.isDirtyForm && this.currentTab !== tabValue){
      const dialogRef = this.dialog.open(LeaveResourceDialogComponent, {
        maxWidth: '560px',
        minWidth: '560px',
        backdropClass: 'dialog-backdrop',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res){
          this.currentTab = tabValue;
          this.errorMessage = '';
          this.isDirtyForm = false;
        }
        else{
          group.value = this.currentTab;
          return;
        }
      });
    }
    else{
      this.currentTab = tabValue;
      this.errorMessage = '';
   }
  }

  stopPropagation(e: any){
    e.stopPropagation();
  }
}
