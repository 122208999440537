import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CallControllerService } from '@helplinesos-api-client';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { TimerData } from '../scribe.component';

@Component({
  selector: 'app-unassign-dialog',
  templateUrl: './unassign-dialog.component.html',
  styleUrls: ['./unassign-dialog.component.scss']
})
export class UnassignDialogComponent implements OnInit {
  callId: number;
  clinicId: number;
  constructor(
    private router: Router,
    private callControllerService: CallControllerService,
    @Optional() private dialogRef: MatDialogRef<UnassignDialogComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<UnassignDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: TimerData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: TimerData) { }

  ngOnInit(): void {
    if (this.dialogData){
      this.callId = this.dialogData.callId;
      this.clinicId = this.dialogData.clinicId;
    }
    else{
      this.callId = this.bottomSheetData.callId;
      this.clinicId = this.bottomSheetData.clinicId;
    }
  }

  unassignCall(){
    // this.callControllerService.unAssignCallUsingPOST(this.callId).subscribe(response => {
    //   this.router.navigate(['call-queue', this.clinicId]);
    //   this.closeDialog();
    // });
    this.closeDialog(true);
  }

  closeDialog(state: boolean){
    if (this.dialogRef){
      this.dialogRef.close(state);
    }
    else{
      this.bottomSheetRef.dismiss(state);
    }
  }
}
