

<main *ngIf="haveHistory" fxFlex="100%" fxLayout="row wrap">
    <mat-progress-bar class="progress-bar-grid" *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    
    <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
        <div></div>
        <strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong>
        <mat-icon (click)="closeDialog()">close</mat-icon>
    </nav>
    <header fxLayout="row wrap" fxFlex="100%">   
        <h2 fxFlex="100%">Call History</h2>
        <nav fxFlex="100%" fxLayout="row" fxLayoutGap="16px">
            <a *ngFor="let item of callBacksDto" [ngClass]="item.id === callBackForDisplay.id ?'active':''" (click)="changeCallBackForDisplay(item.id)">
                                                 <strong><p>{{item?.createdDate | date:'M.dd.yy'}}</p>
                                                         <p ngClass='timePosition'>{{item?.createdDate | date:'h:mma'}}</p>
                                                </strong>
            </a>
        </nav>
    </header>
    <section fxLayout="row wrap">
        <nav fxFlex="100%" fxLayout="row" fxLayoutGap="16px">
                <strong><b>Volunteer: </b>{{callBackForDisplay?.createdBy}}</strong>
                <strong><b>Duration: </b>{{callBackForDisplay?.callBackDuration}}</strong>
                <strong><b>Sent to: </b>{{roleCaption.get(callBackForDisplay?.status)}}</strong>
        </nav>
        <div fxFlex="25%" fxLayout="column" fxLayoutGap="8px" >
            <strong><b>Verified Information</b></strong>
            <strong><span>Age: </span><span>{{callDto?.age}}</span></strong>
            <strong><span>Type: </span><span [ngClass]='isTypeWarn(callDto?.callType)'>{{callDto?.callType}}</span></strong>
            <strong><span>Symptoms: </span><span [ngClass]='isWarn(callDto?.hasSymptoms)'>{{boolDisplayFormat(callDto?.hasSymptoms)}}</span></strong>
            <strong><span>Underlying Disease: </span><span [ngClass]='isWarn(callDto?.hasUnderlyingDisease)'>{{boolDisplayFormat(callDto?.hasUnderlyingDisease)}}</span></strong>
            <strong><span>Contact: </span><span [ngClass]='isWarn(callDto?.hadContactWithInfected)'>{{boolDisplayFormat(callDto?.hadContactWithInfected)}}</span></strong>
            <strong><span>Clinic Patient: </span><span>{{boolDisplayFormat(callDto?.patient)}}</span></strong>
            <strong><span>Zip Code: </span><span>{{callDto?.zip}}</span></strong>
            <strong><span>Insurance: </span><span>{{boolDisplayFormat(callDto?.hasInsurance)}} </span></strong>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutGap="8px" >
            <strong><b>Comment</b></strong>
            <strong>{{this.callBackForDisplay?.callBackNotes}}</strong>
        </div>
        <div fxFlex="40%">
            <p *ngIf="answeredQuestions?.length <= 0 ">There is no answered questions for display</p>
            <ul fxFlex fxLayout="column" fxLayoutGap="16px">
                <li *ngFor="let item of answeredQuestions">
                    <strong ><b>{{item?.title}}</b></strong><br />
                    <strong>{{ getQuestionValue(item)}}</strong></li>
            </ul >
        </div>
    </section>
</main>

<main *ngIf="!haveHistory" fxFlex="100%" fxLayout="row wrap">
    <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="end center">
        <mat-icon (click)="closeDialog()">close</mat-icon>
    </nav>
    <h2 fxFlex="100%" fxLayout="row" fxLayoutAlign="center center">There is no history for the current call</h2>
</main>