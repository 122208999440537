<nav>
  <mat-icon [mat-dialog-close]="true">close</mat-icon>
</nav>

<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="100%" fxFlex.xs="90%" fxFlex.gt-xs="80%" fxFlex.gt-sm="60%">

    <header>
      <h2>
        Terms and Conditions
      </h2>

    </header>

    <mat-dialog-content class="mat-typography">
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        ultrices sagittis lorem at consectetur. Curabitur at vulputate libero.
        Proin nisl arcu, varius id tempus in, aliquam quis augue. Nam varius
        accumsan nisi, eget facilisis mauris posuere non. Vivamus tincidunt
        tristique ultrices. Vivamus non turpis leo. Duis vel sollicitudin nisi.
        Sed viverra eget elit ac laoreet. Sed sem felis, dictum et felis sit
        amet, vehicula porta quam. Suspendisse quam magna, consequat dapibus
        massa id, ultricies interdum velit.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        ultrices sagittis lorem at consectetur. Curabitur at vulputate libero.
        Proin nisl arcu, varius id tempus in, aliquam quis augue. Nam varius
        accumsan nisi, eget facilisis mauris posuere non. Vivamus tincidunt
        tristique ultrices. Vivamus non turpis leo. Duis vel sollicitudin nisi.
        Sed viverra eget elit ac laoreet. Sed sem felis, dictum et felis sit
        amet, vehicula porta quam. Suspendisse quam magna, consequat dapibus
        massa id, ultricies interdum velit.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        ultrices sagittis lorem at consectetur. Curabitur at vulputate libero.
        Proin nisl arcu, varius id tempus in, aliquam quis augue. Nam varius
        accumsan nisi, eget facilisis mauris posuere non. Vivamus tincidunt
        tristique ultrices. Vivamus non turpis leo. Duis vel sollicitudin nisi.
        Sed viverra eget elit ac laoreet. Sed sem felis, dictum et felis sit
        amet, vehicula porta quam. Suspendisse quam magna, consequat dapibus
        massa id, ultricies interdum velit.
      </p> -->
      <p>Not Available yet </p>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="iAgree()">I Agree</button>
    </mat-dialog-actions>
    
  </div>
</div>
