<main fxLayout="row wrap"  fxLayoutAlign="start start">
    <header fxFlex="100%" fxLayoutAlign="space-between start">
        <div></div>
        <h2>Follow Up</h2><mat-icon>close</mat-icon>
    </header>
    <form [formGroup]="formGroup" fxFlex="100%" fxLayout="row">
        <mat-form-field  fxFlex="100%">
            <mat-label>Notes</mat-label>
            <textarea formControlName="notes" matInput tabIndex="-1"></textarea>
          </mat-form-field>
    </form>
    <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
        <button  (click)="skip()" mat-button color="primary"
        >Skip</button>
      <button (click)="save()" mat-raised-button color="primary"
       >Save</button>
    </footer>
</main>