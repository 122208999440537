import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CancelDialogComponent } from './shared/cancel-dialog/cancel-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { ApiModule, Configuration } from '@helplinesos-api-client';
import {ServerHttpInterceptor } from './shared/server-http.interceptor';
import { CustomSnackbarService } from './shared/custom-snackbar.service';
import {  ErrorService } from './shared/error.service';
import { Router } from '@angular/router';
import { CustomToastNotificationComponent } from './shared/custom-toast-notification/custom-toast-notification.component';
import {MatDividerModule} from '@angular/material/divider'
import { MatBadgeModule } from '@angular/material/badge';
import { CustomSnackbarComponent } from './shared/custom-snackbar/custom-snackbar.component';
import { environment } from './../environments/environment';
import { WebSocketAPIService } from './shared/web-socket-api.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    AppComponent,
    CustomSnackbarComponent,
    CustomToastNotificationComponent,
  ],
  imports: [
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatPasswordStrengthModule,
    MatDividerModule,
    MatBadgeModule,
    ApiModule.forRoot(() => new Configuration({ basePath: environment.apiUrl })),
    MatMenuModule,
  ],

  // Default MatFormField appearance to 'fill' as that is the new recommended approach and the
  // `legacy` and `standard` appearances are scheduled for deprecation in version 10.
  providers: [
   CustomSnackbarService,
   MatSnackBar,
   ErrorService,
   WebSocketAPIService,
    { provide: HTTP_INTERCEPTORS,
       useClass: ServerHttpInterceptor,
        multi: true,
        deps: [Router, ErrorService]
     },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
      { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
