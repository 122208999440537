import { Observable, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CallQueueNotificationModel } from './models/CallQueueNotificationModel';
import { NotificationControllerService } from '@helplinesos-api-client';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    // tslint:disable-next-line: max-line-length
    private static notification: BehaviorSubject<CallQueueNotificationModel> = new BehaviorSubject<CallQueueNotificationModel>(new CallQueueNotificationModel());
    public static isRead = false;
    public static notificationsCount = 0;
    constructor(
        private notificationService: NotificationControllerService,
    ) { }

    public pushCallQueueNotification(notification: CallQueueNotificationModel){
      NotificationService.notification.next(notification);
    }

    public fetchCallQueueNotification(): Observable<CallQueueNotificationModel> {
        return NotificationService.notification;
     }


    public  clearNotification(id: number) {
        NotificationService.notificationsCount = NotificationService.notificationsCount - 1;
        this.notificationService.clearNotificationUsingPOST(id, 'response').subscribe(
          (response) => {
            if (response.status === 200) {
            }
          }
        );
      }

    public clear(){
      NotificationService.notification = new BehaviorSubject<CallQueueNotificationModel>(new CallQueueNotificationModel());
    }
 }