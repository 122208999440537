<button mat-icon-button aria-label="Notifications" class="notification">
    <!-- <mat-icon matBadge="1" matBadgePosition="after" matBadgeColor="warn" >notifications</mat-icon> use for later sprint -->
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="setAsRead()">
        <mat-icon *ngIf="newNotifications.length === 0 || isRead " class="gray-bell">notifications</mat-icon>
        <mat-icon *ngIf="!(newNotifications.length === 0) && !isRead" matBadge={{newNotifications.length}} matBadgePosition="after" class="yellow-bell" matBadgeColor="warn" >notifications</mat-icon>
    </button>
    <mat-menu #menu="matMenu" fxLayout="row">
        <main fxLayout="row wrap" fxLayoutAlign="space-between start">
            <header fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%" >
                <strong ><b>Notification</b></strong>
                <strong><b><a (click)="clearAllNotifications($event)">Clear All</a></b></strong>
            </header>
            <section fxFlex="100%">
                <p><b>New</b></p>
                <ul>
                    <li *ngFor="let item of newNotifications" [ngClass]="getIconColor(item?.notificationType) === 'warn' ? 'border-warn': 'border-primary'"  fxLayout="row wrap">
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px"  fxFlex="100%">
                            <mat-icon color="{{getIconColor(item?.notificationType)}}">{{getNotificationType(item?.notificationType)}}</mat-icon>
                            <div fxFlex fxLayout="column" fxLayoutGap="8px">
                                <strong><b>{{item?.message}}</b></strong>
                                <a (click)="actions(item)">{{actionButtonName(item)}}</a>
                                <p>{{item?.duration}}</p>
                            </div>
                            <div fxLayoutAlign="start center">
                                <button  mat-icon-button (click)="clearNotification(item?.id, $event)"><mat-icon>close</mat-icon></button>
                            </div>
                            
                        </div>

                    </li>
                </ul>
            </section>
            <section *ngIf="areOlderNotificationsShown" fxFlex="100%">
                <p><b>Older</b></p>

                <ul>
                    <li *ngFor="let item of oldNotifications" [ngClass]="getIconColor(item?.notificationType) === 'warn' ? 'border-warn': 'border-primary'"  fxLayout="row wrap">
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px"  fxFlex="100%">
                            <mat-icon color="{{getIconColor(item?.notificationType)}}">{{getNotificationType(item?.notificationType)}}</mat-icon>
                            <div fxFlex fxLayout="column" fxLayoutGap="8px">
                                <strong><b>{{item?.message}}</b></strong>
                                <a (click)="actions(item)">Show alert</a>
                                <p>{{item?.duration}}</p>
                            </div>
                            <div fxLayoutAlign="start center">
                                <button  mat-icon-button (click)="clearNotification(item?.id, $event)"><mat-icon>close</mat-icon></button>
                            </div>
                            
                        </div>

                    </li>
                    <div *ngIf="oldNotifications.length === 0">
                        <strong>There are no old notifications</strong>
                    </div>
                </ul>
            </section>
            <footer fxFlex="100%" fxLayoutAlign="center start">
                <strong><b><a (click)="showOlderNotifications($event)">{{areOlderNotificationsShown ? 'Hide older notifications' : 'Show older notifications'}}</a></b></strong>
            </footer>
        </main>
    </mat-menu>
</button>
