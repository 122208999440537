
   <button mat-icon-button [matMenuTriggerFor]="menu" mat-raised-button matBadge="1" matBadgePosition="after" class="profile">
       <b>{{ this.userInitials }}</b>
   </button>
     <mat-menu #menu="matMenu">
       <button (click)="goToProfile()" mat-menu-item><mat-icon>person</mat-icon>Profile</button>
       <mat-divider></mat-divider>
       <button mat-menu-item><mat-icon>policy</mat-icon>Policies</button>
       <mat-divider></mat-divider>
       <button mat-menu-item><mat-icon>help_outline</mat-icon>Help</button>
       <mat-divider></mat-divider>
       <button mat-menu-item (click) = "logout()"><mat-icon>logout</mat-icon>Log Off</button>
     </mat-menu>
