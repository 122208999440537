import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ScribeActionsComponent } from './scribe-actions/scribe-actions.component';
import { MatDialog } from '@angular/material/dialog';
import { SaveResourcesBoardComponent } from './save-resources-board/save-resources-board.component';
import { CancelDialogComponent } from '../../shared/cancel-dialog/cancel-dialog.component';
import { ResourceDashboardComponent } from './resource-dashboard.component';
import { LeaveScribeDialogComponent } from '../../call-queue/scribe/leave-scribe-dialog/leave-scribe-dialog.component';
import { LeaveResourceDialogComponent } from '../leave-resource-dialog/leave-resource-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ResourceDashboardGuard implements CanDeactivate<ResourceDashboardComponent> {
  constructor(
    private dialog: MatDialog,
  ) {

  }
  canDeactivate(
    component: ResourceDashboardComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!component.isDirtyForm || nextState.url === '/login') {
      return true;
    }
    return Observable.create((observer: Observer<boolean>) => {
      const dialogRef = this.dialog.open(LeaveResourceDialogComponent, {
        maxWidth: '560px',
        minWidth: '560px',
        backdropClass: 'dialog-backdrop'
      });
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });

  }

}
