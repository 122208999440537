/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BulletinDTO } from '../model/bulletinDTO';
import { ClinicDTO } from '../model/clinicDTO';
import { CreateClinicDTO } from '../model/createClinicDTO';
import { FaqDTO } from '../model/faqDTO';
import { HelpfulInformationDTO } from '../model/helpfulInformationDTO';
import { HelplineResourceDTO } from '../model/helplineResourceDTO';
import { PageClinicDTO } from '../model/pageClinicDTO';
import { ScribeActionDTO } from '../model/scribeActionDTO';
import { UpdateClinicDTO } from '../model/updateClinicDTO';
import { UpdateFaqsDTO } from '../model/updateFaqsDTO';
import { UpdateHelpfulInformationDTO } from '../model/updateHelpfulInformationDTO';
import { UpdateHelplineResourceDTO } from '../model/updateHelplineResourceDTO';
import { UpdateScribeActionsDTO } from '../model/updateScribeActionsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClinicControllerService {

    protected basePath = 'https://api-dev.helplinesos.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate A Clinic
     * Activate&#39;s A Clinic(s)
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateClinicUsingPUT(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateClinicUsingPUT(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateClinicUsingPUT(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateClinicUsingPUT(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling activateClinicUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/clinics/${encodeURIComponent(String(clinicId))}/activate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create A Clinic
     * Create&#39;s A Clinic. PhoneNumber Types [HOTLINE, OPERATOR, FRONTDESK]
     * @param createClinicDTO createClinicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClinicUsingPOST(createClinicDTO: CreateClinicDTO, observe?: 'body', reportProgress?: boolean): Observable<ClinicDTO>;
    public createClinicUsingPOST(createClinicDTO: CreateClinicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClinicDTO>>;
    public createClinicUsingPOST(createClinicDTO: CreateClinicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClinicDTO>>;
    public createClinicUsingPOST(createClinicDTO: CreateClinicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createClinicDTO === null || createClinicDTO === undefined) {
            throw new Error('Required parameter createClinicDTO was null or undefined when calling createClinicUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ClinicDTO>(`${this.basePath}/api/clinics/`,
            createClinicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * DeActivate A Clinic
     * DeActivate&#39;s A Clinic(s)
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deActivateClinicUsingPUT(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deActivateClinicUsingPUT(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deActivateClinicUsingPUT(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deActivateClinicUsingPUT(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling deActivateClinicUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/clinics/${encodeURIComponent(String(clinicId))}/deactivate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Bulletin
     * Get&#39;s Bulletin
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBulletinUsingGET(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<BulletinDTO>;
    public getBulletinUsingGET(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BulletinDTO>>;
    public getBulletinUsingGET(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BulletinDTO>>;
    public getBulletinUsingGET(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getBulletinUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BulletinDTO>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/bulletin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get A Clinic
     * Return&#39;s A Clinic(s)
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClinicUsingGET(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<ClinicDTO>;
    public getClinicUsingGET(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClinicDTO>>;
    public getClinicUsingGET(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClinicDTO>>;
    public getClinicUsingGET(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getClinicUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClinicDTO>(`${this.basePath}/api/clinics/${encodeURIComponent(String(clinicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Clinic(s)
     * Returns All Clinic(s)
     * @param filter filter
     * @param page page
     * @param pageSize pageSize
     * @param sortBy sortBy
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClinicsUsingGET(filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<PageClinicDTO>;
    public getClinicsUsingGET(filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageClinicDTO>>;
    public getClinicsUsingGET(filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageClinicDTO>>;
    public getClinicsUsingGET(filter: string, page: string, pageSize: string, sortBy: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getClinicsUsingGET.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getClinicsUsingGET.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getClinicsUsingGET.');
        }

        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling getClinicsUsingGET.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getClinicsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageClinicDTO>(`${this.basePath}/api/clinics/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Clinic FAQ(s)
     * Get&#39;s Clinic FAQ(s)
     * @param clinicId clinicId
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFaqsUsingGET(clinicId: number, filter: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FaqDTO>>;
    public getFaqsUsingGET(clinicId: number, filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FaqDTO>>>;
    public getFaqsUsingGET(clinicId: number, filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FaqDTO>>>;
    public getFaqsUsingGET(clinicId: number, filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getFaqsUsingGET.');
        }

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getFaqsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FaqDTO>>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/faqs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Helpful Information
     * Get&#39;s Helpful Information
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHelpfulInformationUsingGET(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<HelpfulInformationDTO>;
    public getHelpfulInformationUsingGET(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HelpfulInformationDTO>>;
    public getHelpfulInformationUsingGET(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HelpfulInformationDTO>>;
    public getHelpfulInformationUsingGET(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getHelpfulInformationUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<HelpfulInformationDTO>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/helpfulinformation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get HelplineSOS Resources
     * Get&#39;s HelplineSOS Resources
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHelplineResourceUsingGET(observe?: 'body', reportProgress?: boolean): Observable<HelplineResourceDTO>;
    public getHelplineResourceUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HelplineResourceDTO>>;
    public getHelplineResourceUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HelplineResourceDTO>>;
    public getHelplineResourceUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<HelplineResourceDTO>(`${this.basePath}/api/clinics/resources/helplineresources`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Scribe Action
     * Get&#39;s Scribe Action
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScribeActionUsingGET(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ScribeActionDTO>>;
    public getScribeActionUsingGET(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScribeActionDTO>>>;
    public getScribeActionUsingGET(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScribeActionDTO>>>;
    public getScribeActionUsingGET(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getScribeActionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ScribeActionDTO>>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/scribeactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Clinic Scribe Action(s) to Default Values
     * Set Clinic&#39;s Scribe Action(s) to Default Values
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDefaultScribeActionsUsingPOST(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ScribeActionDTO>>;
    public setDefaultScribeActionsUsingPOST(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScribeActionDTO>>>;
    public setDefaultScribeActionsUsingPOST(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScribeActionDTO>>>;
    public setDefaultScribeActionsUsingPOST(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling setDefaultScribeActionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ScribeActionDTO>>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/scribeactions/default`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update Clinic Bulletin
     * Create&#39;s/Update&#39;s Clinic&#39;s Bulletin
     * @param bulletinDTO bulletinDTO
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBulletinUsingPUT(bulletinDTO: BulletinDTO, clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<BulletinDTO>;
    public updateBulletinUsingPUT(bulletinDTO: BulletinDTO, clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BulletinDTO>>;
    public updateBulletinUsingPUT(bulletinDTO: BulletinDTO, clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BulletinDTO>>;
    public updateBulletinUsingPUT(bulletinDTO: BulletinDTO, clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bulletinDTO === null || bulletinDTO === undefined) {
            throw new Error('Required parameter bulletinDTO was null or undefined when calling updateBulletinUsingPUT.');
        }

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling updateBulletinUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BulletinDTO>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/bulletin`,
            bulletinDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update A Clinic
     * Update&#39;s A Clinic
     * @param updateClinicDTO updateClinicDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateClinicUsingPUT(updateClinicDTO: UpdateClinicDTO, observe?: 'body', reportProgress?: boolean): Observable<ClinicDTO>;
    public updateClinicUsingPUT(updateClinicDTO: UpdateClinicDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClinicDTO>>;
    public updateClinicUsingPUT(updateClinicDTO: UpdateClinicDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClinicDTO>>;
    public updateClinicUsingPUT(updateClinicDTO: UpdateClinicDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateClinicDTO === null || updateClinicDTO === undefined) {
            throw new Error('Required parameter updateClinicDTO was null or undefined when calling updateClinicUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ClinicDTO>(`${this.basePath}/api/clinics/`,
            updateClinicDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update Clinic FAQ(s)
     * Create&#39;s/Update&#39;s Clinic&#39;s FAQ(s)
     * @param clinicId clinicId
     * @param updateFaqsDTO updateFaqsDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFaqsUsingPUT(clinicId: number, updateFaqsDTO: UpdateFaqsDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<FaqDTO>>;
    public updateFaqsUsingPUT(clinicId: number, updateFaqsDTO: UpdateFaqsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FaqDTO>>>;
    public updateFaqsUsingPUT(clinicId: number, updateFaqsDTO: UpdateFaqsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FaqDTO>>>;
    public updateFaqsUsingPUT(clinicId: number, updateFaqsDTO: UpdateFaqsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling updateFaqsUsingPUT.');
        }

        if (updateFaqsDTO === null || updateFaqsDTO === undefined) {
            throw new Error('Required parameter updateFaqsDTO was null or undefined when calling updateFaqsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<FaqDTO>>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/faqs`,
            updateFaqsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update Helpful Information
     * Create&#39;s/Update&#39;s Helpful Information
     * @param clinicId clinicId
     * @param updateHelpfulInformationDTO updateHelpfulInformationDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHelpfulInformationUsingPUT(clinicId: number, updateHelpfulInformationDTO: UpdateHelpfulInformationDTO, observe?: 'body', reportProgress?: boolean): Observable<HelpfulInformationDTO>;
    public updateHelpfulInformationUsingPUT(clinicId: number, updateHelpfulInformationDTO: UpdateHelpfulInformationDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HelpfulInformationDTO>>;
    public updateHelpfulInformationUsingPUT(clinicId: number, updateHelpfulInformationDTO: UpdateHelpfulInformationDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HelpfulInformationDTO>>;
    public updateHelpfulInformationUsingPUT(clinicId: number, updateHelpfulInformationDTO: UpdateHelpfulInformationDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling updateHelpfulInformationUsingPUT.');
        }

        if (updateHelpfulInformationDTO === null || updateHelpfulInformationDTO === undefined) {
            throw new Error('Required parameter updateHelpfulInformationDTO was null or undefined when calling updateHelpfulInformationUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HelpfulInformationDTO>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/helpfulinformation`,
            updateHelpfulInformationDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update HelplineSOS Resources
     * Create&#39;s/Update&#39;s HelplineSOS Resources
     * @param updateHelplineResourceDTO updateHelplineResourceDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateHelplineResourceUsingPUT(updateHelplineResourceDTO: UpdateHelplineResourceDTO, observe?: 'body', reportProgress?: boolean): Observable<HelplineResourceDTO>;
    public updateHelplineResourceUsingPUT(updateHelplineResourceDTO: UpdateHelplineResourceDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HelplineResourceDTO>>;
    public updateHelplineResourceUsingPUT(updateHelplineResourceDTO: UpdateHelplineResourceDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HelplineResourceDTO>>;
    public updateHelplineResourceUsingPUT(updateHelplineResourceDTO: UpdateHelplineResourceDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateHelplineResourceDTO === null || updateHelplineResourceDTO === undefined) {
            throw new Error('Required parameter updateHelplineResourceDTO was null or undefined when calling updateHelplineResourceUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HelplineResourceDTO>(`${this.basePath}/api/clinics/resources/helplineresources`,
            updateHelplineResourceDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create/Update Clinic Scribe Action(s)
     * Create&#39;s/Update&#39;s Clinic&#39;s Scribe Action(s)
     * @param clinicId clinicId
     * @param updateScribeActionsDTO updateScribeActionsDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateScribeActionsUsingPUT(clinicId: number, updateScribeActionsDTO: UpdateScribeActionsDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<ScribeActionDTO>>;
    public updateScribeActionsUsingPUT(clinicId: number, updateScribeActionsDTO: UpdateScribeActionsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScribeActionDTO>>>;
    public updateScribeActionsUsingPUT(clinicId: number, updateScribeActionsDTO: UpdateScribeActionsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScribeActionDTO>>>;
    public updateScribeActionsUsingPUT(clinicId: number, updateScribeActionsDTO: UpdateScribeActionsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling updateScribeActionsUsingPUT.');
        }

        if (updateScribeActionsDTO === null || updateScribeActionsDTO === undefined) {
            throw new Error('Required parameter updateScribeActionsDTO was null or undefined when calling updateScribeActionsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<ScribeActionDTO>>(`${this.basePath}/api/clinics/resources/${encodeURIComponent(String(clinicId))}/scribeactions`,
            updateScribeActionsDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
