<main  fxLayout="row wrap"  fxLayoutAlign="center start">
    <!-- <a (click)="goToCallBack()" >asd</a> -->
    <a (click)="goToCallBack()" ><strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong></a>
        <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="end center">

            <mat-icon (click)="closeDialog()">close</mat-icon>
        </nav>
        <header fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="60%" fxLayout="column">
                    <h2><b>Caller #{{this.callDto.id}}</b></h2>
                    <p><b>{{this.callDto.language}} Speaking</b></p>
                </div>
                <div fxFlex="40%" class="timestamp" fxLayoutGap="10px" fxLayout="row" fxLayout="start center">
                    <p fxFlex="30%">Timestamp</p>
                    <p fxFlex>{{this.callDto?.createdDate | date:'MM/dd/yy h:mm a'}}</p>
                </div>
        </header>
        <section fxFlex="100%" *ngIf="isFlagged" class="flag-notes" fxLayoutGap="10px" fxLayout="row" fxLayout="start center">
            <mat-icon class="big-icon" fxFlex="10%" color="warn">arrow_upward</mat-icon>
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                <strong><b>{{callFlagDto?.createdBy}}</b></strong>
                <p>{{callFlagDto?.createdDate}}</p>
                <strong fxFlex="100%">{{callFlagDto?.notes}}</strong>
            </div>

        </section>
        <section fxLayout="row" fxFlex>
            <section fxFlex="33%" class="grid-end">
                <p>Type<span
                        [ngClass]='isTypeWarn(this.callDto?.callType)'>{{this.roleCaption.get(this.callDto.callType)}}</span>
                </p>
                <p>Symptoms<span
                        [ngClass]='isWarn(this.callDto?.hasSymptoms)'>{{this.callDto.hasSymptoms ? 'Yes':'No'}}</span>
                </p>
                <p>Underlying Disease<span
                        [ngClass]='isWarn(this.callDto?.hasUnderlyingDisease)'>{{this.callDto.hasUnderlyingDisease ? 'Yes':'No'}}</span>
                </p>
                <p>Exposure<span
                        [ngClass]='isWarn(this.callDto?.hadContactWithInfected)'>{{this.callDto.hadContactWithInfected ? 'Yes':'No'}}</span>
                </p>
            </section>
            <section fxFlex="33%" class="grid-end">
                <p>Age<span>{{this.callDto?.age}}</span></p>
                <p>Travel<span>{{this.callDto?.hasTraveledToInfectedArea ? 'Yes':'No'}}</span></p>
                <p>Contact<span>{{this.callDto?.hadContactWithInfected ? 'Yes':'No'}}</span></p>
                <p>Clinic Patient<span>{{this.callDto?.patient?'Yes':'No'}}</span></p>
            </section>
            <section *ngIf="roleCaption.get(this.callDto?.status) !== 'Follow up'" fxFlex="33%">
                <p>Status<span
                        [ngClass]='getStatusClass(this.callDto?.status)'>{{this.roleCaption.get(this.callDto?.status)}}</span>
                </p>
                <p *ngIf="!isNewCall() && !isInProgress()">Outcome<span>{{this.callDto?.callOutcome!=null?this.callDto?.callOutcome:'N/A'}}</span></p>
                <!-- <p *ngIf="!isNewCall() && !isInProgress()">Attempts<span>{{this.data.callDto.noOfAttempts}} / 3</span></p> -->
                <p>Zip Code<span>{{this.callDto?.zip}}</span></p>
                <p *ngIf="!isNewCall()">Volunteer<span>{{this.callDto?.volunteer}}</span></p>
            </section>
            <section *ngIf="roleCaption.get(this.callDto?.status) === 'Follow up'" fxFlex="33%">
                <p *ngFor="let item of answeredQuestions">
                    {{item?.title}}:
                    {{item?.values}}
                </p>
            </section>
        </section>
        <footer fxFlex="100%" *ngIf="this.mode=='Edit'" fxLayout="column" fxLayoutAlign="center center" >
            
                <h2 ><a>Would you like to be assigned to this {{this.followUpText}} call?</a></h2>
                <p >Assigning yourself to this call means you agree to make the call back</p>
                <div  fxLayoutGap="32px" class="buttons">
                    <button mat-button (click)="closeDialog()" color="primary" tabIndex="-1" mat-button>Cancel</button>
                    <button mat-raised-button color="primary" (click)="assignCall()">Yes, assign</button>
                </div>
           
        </footer>

</main>