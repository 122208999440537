import { Component, OnInit } from '@angular/core';
import { SecurityControllerService, MemberControllerService } from '@helplinesos-api-client';
import { Router } from '@angular/router';
import { ErrorService } from '../error.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userInitials: string;

  constructor(
    private router: Router,
    private securityControllerService: SecurityControllerService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {

    const user = JSON.parse(localStorage.getItem('user'));
    this.userInitials = user?.firstName?.slice(0, 1) + ' ' +  user?.lastName?.slice(0, 1);
  }

  logout(){
    this.securityControllerService.logoutUsingPOST().subscribe((response) =>
    {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.errorService.clearErrorMessage();
      this.router.navigate(['/login']);
    }, err =>
    {

    });
  }

  goToProfile(){
    this.router.navigate(['user-profile-editor']);
  }

}
