import { Component, OnInit, VERSION, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ClinicControllerService, UserDTO, HelpfulInformationDTO } from '@helplinesos-api-client';
import { ActivatedRoute } from '@angular/router';
import { UpdateHelpfulInformationDTO } from 'projects/helplinesos-api-client/src/model/updateHelpfulInformationDTO';
import { UpdateExternalLinkDTO } from 'projects/helplinesos-api-client/src/model/updateExternalLinkDTO';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { saveAs } from 'file-saver';
import { ComponentBase } from '../../../shared/models/componentBase';

@Component({
  selector: 'app-helpful-information',
  templateUrl: './helpful-information.component.html',
  styleUrls: ['./helpful-information.component.scss']
})
export class HelpfulInformationComponent extends ComponentBase implements OnInit {
  helpfulInformationDto: HelpfulInformationDTO = {
    phoneDirectory: [],
    externalLinks: []
  };
  role: any;
  @Input() private clinicId: number;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  version = VERSION;
  base64File: string = null;
  filename: string = null;
  externalLinks: UpdateExternalLinkDTO[];
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain']
  ]);
  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  helpfulInfoFormGroup: FormGroup;

  get contactsArray() {
    return this.helpfulInfoFormGroup.get('contacts') as FormArray;
  }

  get linksArray() {
    return this.helpfulInfoFormGroup.get('links') as FormArray;
  }
  constructor(
    private snackBarService: CustomSnackbarService,
    private route: ActivatedRoute,
    private clinicControllerService: ClinicControllerService,
    private formBuilder: FormBuilder
  ) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.helpfulInfoFormGroup = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
      links: this.formBuilder.array([])
    });

    const contact = this.formBuilder.group({
      label: ['', Validators.pattern(/^[A-Za-z|0-9]/)],
      phoneNumber: ['', Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)],
    });

    this.contactsArray.push(contact);

    const link = this.formBuilder.group({
      label: ['', Validators.pattern(/^[A-Za-z|0-9]/)],
      file: [''],
      filename: [''],
      filetype: [''],
      url: ['',  Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')],
    });

    this.linksArray.push(link);

    this.getHelpfulInformation();
    this.onChanges();
  }

  onChanges(){
    this.helpfulInfoFormGroup.valueChanges.subscribe(res => {
      this.onFormGroupChange.emit(this.helpfulInfoFormGroup);
    });
  }

  addNewContact() {
    const contact = this.formBuilder.group({
      label: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      phoneNumber: ['', Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]
    });
    this.contactsArray.push(contact);
  }

  addNewLink() {
    const link = this.formBuilder.group({
      label: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      file: [''],
      filename: [''],
      filetype: [''],
      url: ['',  Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]
    });
    this.linksArray.push(link);
  }

  removeContact(i: number) {
    this.contactsArray.removeAt(i);
  }

  removeLink(i: number) {
    this.linksArray.removeAt(i);
  }

  showDeletePhone(){
    if (this.contactsArray.length > 1)
    {
      return true;
    }
    else{
      return false;
    }
  }

  showDeleteLink(){
    if (this.linksArray.length > 1)
    {
      return true;
    }
    else{
      return false;
    }
  }
  onFileSelect(e: any, i: number): void {
    try {
      const file = e.target.files[0];
      if (file.size === 0){
        this.snackBarService.openErrorNotification('This file has no content. Please upload file with content');
        return;
      }
      const fReader = new FileReader();
      const fileType = file.type;
      fReader.readAsDataURL(file);
      const blob = file as Blob;
      fReader.onloadend = (event: any) => {
        const filename = file.name;
        const base64File = event.target.result;
        const blob1 = new Blob([new Uint8Array(event.target.result)], { type: file.type });
        (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('file').setValue(base64File);
        (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('filename').setValue(filename);
        (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('fileType').setValue(fileType);
      };
    } catch (error) {
      this.filename = null;
      this.base64File = null;
      console.log('no file was selected...');
    }
  }

  getIndex(i: number) {
    return String(i);
  }
  getFileName(i: number) {
    return (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('filename').value;
  }

  getFileData(i: number) {
    const x =  (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('file').value;
    return x;
  }

  getFileType(i: number) {
    const x = (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('fileType').value;
    return x;
  }

  removeFile(i: number) {
    (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('filename').setValue('');
    (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('file').setValue('');
    (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('fileType').setValue('');
  }

  doesUrlExist(i: number){
    const url = (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('url').value;
    if (url){
      return true;
    }
    else{
      return false;
    }
  }

  isFileUploaded(i: number){
    const file = (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('file').value;
    if (file){
      return true;
    }
    else{
      return false;
    }
  }

  submitClick() {
    if (!this.helpfulInfoFormGroup.valid) {
      return;
    }
    this.isWaiting = true;
    const dto: UpdateHelpfulInformationDTO = {
      phoneDirectory: [],
      externalLinks: []
    };
    this.helpfulInfoFormGroup.controls.contacts.value.forEach((element: any) => {
      dto.phoneDirectory.push({
        label: element.label,
        phoneNumber: element.phoneNumber.replace(/[&\/\\()-]/g, '')
      });
    });
    this.helpfulInfoFormGroup.controls.links.value.forEach((element: any) => {
      dto.externalLinks.push({
        label: element.label,
        fileData: element.file,
        fileName: element.filename.split('.')[0],
        url: element.url
      });
    });
    // const x = this.dto;
    // const y = this.dto;
    this.clinicControllerService.updateHelpfulInformationUsingPUT(this.clinicId, dto).subscribe(response => {
      this.snackBarService.openToastNotification('Resource board updated');
      this.ngOnInit();
      this.isWaiting = false;
    }, err => {
      this.isWaiting = false;
    });
  }

  getHelpfulInformation() {
    this.clinicControllerService.getHelpfulInformationUsingGET(this.clinicId).subscribe(response => {
      this.helpfulInformationDto = response;
      if (response?.phoneDirectory.length > 0) {
        this.removeContact(0);
        response?.phoneDirectory.forEach(x => {
          const c = this.formBuilder.group({
            label: [x?.label,(x?.label ? [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)] : Validators.pattern(/^[A-Za-z|0-9]/))],
            phoneNumber: [x?.phoneNumber, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]
          });
          this.contactsArray.push(c);
        });
      }
      if (response?.externalLinks.length > 0) {
        this.removeLink(0);
        response?.externalLinks.forEach(x => {
          const c = this.formBuilder.group({
            label: [x?.label,(x?.label ? [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)] : Validators.pattern(/^[A-Za-z|0-9]/))],
            file: [x?.fileData],
            fileType: [x?.fileType],
            filename: [x?.fileName],
            url: [x?.url,  Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]
          });
          this.linksArray.push(c);
        });
      }
      this.isWaiting = false;
    },
      err => {
        this.isWaiting = false;
      });
  }

  public base64ToBlob(b64Data: string, contentType: string, sliceSize: 512) {
    b64Data = b64Data.replace(/\s/g, '');
    const x = b64Data.split(',');
    const byteCharacters = atob(x[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(b64encodedString: string, fileType: string, fileName: string) {
    // if (b64encodedString) {
    //   const blob = this.base64ToBlob(b64encodedString, fileType, 512);
    //   saveAs(blob, fileName);
    // }
    this.openBase64InNewTab(b64encodedString, fileType);
  }

  openBase64InNewTab(data: any, mimeType: any) {
    data = data.replace(/\s/g, '');
    const x = data.split(',');
    const byteCharacters = atob(x[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: mimeType + ';base64' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  getPhoneNumber(label: string, phonNumber: string) {
    return phonNumber ? label + ': (' +
      phonNumber.slice(0, 3) + ') ' +
      phonNumber.slice(3, 6) + '-' +
      phonNumber.slice(6) : 'Phone number not set';
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
  cancel() {
    this.ngOnInit();
  }

}
