/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateUserRoleDTO { 
    emailUser?: boolean;
    notes?: string;
    role?: UpdateUserRoleDTO.RoleEnum;
}
export namespace UpdateUserRoleDTO {
    export type RoleEnum = 'ROLE_ADMIN' | 'ROLE_CAPTAIN' | 'ROLE_VOLUNTEER' | 'ROLE_COORDINATOR';
    export const RoleEnum = {
        ADMIN: 'ROLE_ADMIN' as RoleEnum,
        CAPTAIN: 'ROLE_CAPTAIN' as RoleEnum,
        VOLUNTEER: 'ROLE_VOLUNTEER' as RoleEnum,
        COORDINATOR: 'ROLE_COORDINATOR' as RoleEnum
    };
}
