<main fxLayout="column" fxLayoutAlign="start stretch">
<header fxFlex="100%" fxLayout="row " fxLayoutAlign="start center" fxLayoutGap="16px">
  <button mat-icon-button (click)="cancel()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h1>{{ clinicId ? 'Edit Clinic' : 'Add New Clinic' }}</h1>
</header>

  <div><mat-progress-bar mode="indeterminate" *ngIf="isWaiting"></mat-progress-bar></div>

  <div class="error" *ngIf="errorMessage"><strong>{{errorMessage}}</strong></div>

  <mat-horizontal-stepper linear *ngIf="!clinicId">
    <mat-step [completed]="false">
      <ng-template matStepLabel>Fill out clinic information</ng-template>
    </mat-step>
    <mat-step optional>
      <ng-template matStepLabel>Invite team members</ng-template>
    </mat-step>
  </mat-horizontal-stepper>

  <section class="mat-elevation-z1">
  
    <form #form id="formGroup" [formGroup]="formGroup">

      <section>
        <h2>Clinic Information</h2>
        <div fxLayout="row wrap">
          <mat-form-field fxFlex="1 0 312px">
            <mat-label>Clinic Name</mat-label>
            <input matInput formControlName="name" maxlength="80" placeholder="Clinic Name" />
            <mat-error *ngIf="formGroup.controls['name'].hasError('required') && isValidationShown">Clinic Name is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls['name'].hasError('pattern') && isValidationShown">Clinic name must start with a letter or a number
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="1 0 242px">
            <mat-label>Phone Number</mat-label>
            <!--<span matPrefix>+1 &nbsp;</span>-->
            <input matInput [textMask]="mask" formControlName="phone" [readonly]="isCaptainRole()"  placeholder="(555)555-5555" />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-error *ngIf="formGroup.controls['phone'].hasError('required') && isValidationShown">Hotline Phone Number is
              required
            </mat-error>
            <mat-error *ngIf="formGroup.controls['phone'].hasError('pattern') && isValidationShown">Hotline Phone Number must have 10 Characters
            </mat-error>
           
          </mat-form-field>

          <mat-form-field fxFlex="1 0 376px">
            <mat-label>Clinic Address</mat-label>
            <input matInput formControlName="address" maxlength="100" placeholder="Clinic Address" />
            <mat-error *ngIf="formGroup.controls['address'].hasError('required') && isValidationShown">Clinic Address is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls['address'].hasError('pattern') && isValidationShown">Clinic Address must start with a letter or a number
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 176px">
            <mat-label>Apt/Suite - optional</mat-label>
            <input matInput formControlName="apt" maxlength="10" placeholder="Apt/Suite" />
            <mat-icon matSuffix></mat-icon>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 190px">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" maxlength="50" placeholder="City" />
            <mat-error *ngIf="formGroup.controls['city'].hasError('required') && isValidationShown">City is required</mat-error>
            <mat-error *ngIf="formGroup.controls['city'].hasError('pattern') && isValidationShown">City must start with a letter
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 162px">
            <mat-label>State</mat-label>
            <mat-select disableOptionCentering panelClass="position-of-content" formControlName="state" #singleSelect>
              <mat-option>
                <ngx-mat-select-search [formControl]="stateFilter" placeholderLabel="Search"
                  noEntriesFoundLabel="No states found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.controls['state'].invalid && isValidationShown">State is required
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 0 176px">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="zip" maxlength="5"  placeholder="Zip Code" />
            <mat-error *ngIf="formGroup.controls.zip.errors?.required && isValidationShown">Zip Code is required
            </mat-error>
            <mat-error *ngIf="formGroup.controls.zip.errors?.pattern && isValidationShown">Zip Code does not support letters
            </mat-error>
            <mat-error *ngIf="!formGroup.controls.zip.errors?.minLength && isValidationShown">Zip Code must have 5 digits
            </mat-error>
          </mat-form-field>
        </div>
        <h2>Clinic Hours - This information will affect notifications settings</h2>
        <div fxLayout="row wrap">
          <mat-list formArrayName="hours">
            <mat-list-item *ngFor="let item of hoursForms.controls; let y = index" [formGroupName]="y">
              <mat-form-field fxFlex="1 0 110px">
                <mat-label>Days</mat-label>
                <mat-select disableOptionCentering panelClass="position-of-content" multiple formControlName="days">
                  <mat-option *ngFor="let item of days" [value]="item.value">{{item.display}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.valid && isValidationShown">Days are required
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="1 0 210px">
                <mat-label>Opening Time</mat-label>
                <mat-select disableOptionCentering panelClass="position-of-content" formControlName="openingTime">
                  <mat-option *ngFor="let item of hours" [value]="item">{{item}}</mat-option>
                </mat-select>
                <mat-error *ngIf="!formGroup.valid && isValidationShown">Opening time is required
                </mat-error>
              </mat-form-field>
              
              <mat-form-field fxFlex="1 0 210px">
                <mat-label>Closing Time</mat-label>
                <mat-select disableOptionCentering panelClass="position-of-content" formControlName="closingTime">
                  <mat-option *ngFor="let item of hours" [value]="item">{{item}}</mat-option> 
                </mat-select>
                <mat-error *ngIf="!formGroup.valid && isValidationShown">Closing time is required
                </mat-error>
              </mat-form-field>
              <div fxFlex="100%">
                <button *ngIf="hasMultipleHours" mat-icon-button (click)="removeHours(y)" color="primary"><mat-icon>delete</mat-icon></button>
              </div>
            </mat-list-item>
         
            <div>
              <h4><a (click)="addHours()">Add Hours</a></h4>
            </div>
          </mat-list>
        </div>
        <div fxLayout="row wrap">
          <mat-form-field fxFlex="1 1 100%">
            <mat-label >Additional information about clinic hours</mat-label>
            <fate-material  [buttons]="['bold','italic','underline','strike','ordered','unordered','clean']"  [formControl]="formGroup.controls['hoursNotes']"></fate-material>
          </mat-form-field>
          </div>


        <h2>Main Clinic Contacts - optional</h2>
        <div fxLayout="column stretch">
          <mat-list formArrayName="contacts">
            <mat-list-item *ngFor="let item of contactsForms.controls; let i = index" [formGroupName]="i">
                <mat-form-field fxFlex="1 0 277px">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName" maxlength="50" />
                  <mat-error  *ngIf="contactsForms.get(getIndex(i)).get('firstName').hasError('pattern') && isValidationShown">First Name must start with character
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="1 0 277px">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName" maxlength="50" />
                  <mat-error  *ngIf="contactsForms.get(getIndex(i)).get('lastName').hasError('pattern') && isValidationShown">Last Name must start with character
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="1 0 277px">
                  <mat-label>Title</mat-label>
                  <input matInput formControlName="title" maxlength="50" />
                </mat-form-field>
                <mat-form-field fxFlex="1 0 277px">
                  <mat-label>Phone Number</mat-label>
                  <input  [textMask]="mask" matInput formControlName="phoneNumber"  />
                  <mat-error  *ngIf="contactsForms.get(getIndex(i)).get('phoneNumber').hasError('pattern') && isValidationShown">Hotline Phone Number must have 10 Characters
                  </mat-error>
                  
                </mat-form-field>
                
                <div fxFlex="100%">
                  <button *ngIf="hasMultipleContacts" mat-icon-button (click)="removeMember(i)" color="primary"><mat-icon>delete</mat-icon></button>
                </div>
            </mat-list-item>
          </mat-list>
          <div>
            <h4><a (click)="addNewMember()">Add Another Clinic Contact</a></h4>
          </div>
        </div>
       

        <h2>Additional Information - optional</h2>
        <div fxLayout="row wrap">
          <mat-form-field fxFlex="1 1 100%">

            <mat-label>Clinic Notes - optional</mat-label>
            <fate-material  [buttons]="['bold','italic','underline','strike','ordered','unordered','clean']"  [formControl]="formGroup.controls['notes']"></fate-material>
          </mat-form-field>

          <mat-form-field fxFlex="1 1 100%">
            <mat-label>Testing Requirements for your State - optional</mat-label>
            <fate-material  [buttons]="['bold','italic','underline','strike','ordered','unordered','clean']"  [formControl]="formGroup.controls['testingRequirements']"></fate-material>
            <!-- <input matInput formControlName="testingRequirements"  /> -->
          </mat-form-field>
        </div>
        <nav fxLayoutAlign="end end">
          <button mat-button color="primary" (click)="cancel()" class="cancel">
            Cancel
          </button>
          <button type="submit" form="formGroup" mat-raised-button [disabled]="isWaiting" color="primary" (click)="save()" class="save">
            Save
          </button>
        </nav>

      </section>
    
    </form>

  </section>

  <!-- <nav class="mat-elevation-z1">
    <button mat-button color="primary" (click)="cancel()" class="cancel">
      Cancel
    </button>
    <button type="submit" form="formGroup" mat-raised-button [disabled]="isWaiting" color="primary" (click)="save()" class="save">
      Save
    </button>
  </nav> -->

</main>