<main fxLayout="row wrap">
    <header fxFlex="100%">
        <h2>Report Issue</h2>
    </header>
    <strong fxFlex="100%">
        We value your feedback and want to create the best experience possible
    </strong>
    <!-- <strong fxFlex="100%"><b>How would you rate your experience?</b></strong>
    <div fxFlex="100%" fxLayout="center center">
        <div >
            <button tabIndex="-1" mat-icon-button color="primary" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
            (click)="onClick(i+1)">
            <mat-icon>
                {{showIcon(i)}}
            </mat-icon>
            <p *ngIf="i === 0">Very bad</p>
            <p *ngIf="i === 4">Excellent</p>
        </button>
      
        </div>
        
    </div> -->
    <!-- <strong fxFlex="100%"><b>Would you like to leave us a comment?</b></strong> -->
    <form [formGroup]="formGroup" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="start start">
        <mat-form-field fxFlex="100%" >
            <mat-label>What did you expect and what happened instead?</mat-label>
            <textarea tabIndex="-1" maxlength="255" formControlName="description" matInput ></textarea>
            <mat-error *ngIf="formGroup.controls.description.errors?.required">This area is required</mat-error>
        </mat-form-field>
        <!-- <mat-form-field fxFlex="100%" >
            <mat-label>What did we do right?</mat-label>
            <textarea formControlName="whatWeDidRight" matInput ></textarea>
            <mat-error *ngIf="formGroup.controls.whatWeDidRight.errors?.required">This area is required</mat-error>
        </mat-form-field> -->


        <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
            <button fxFlex tabIndex="-1" (click)="cancel()" mat-button color="primary"
            >Cancel</button>
            <button fxFlex tabIndex="-1" (click)="submit()" mat-raised-button color="primary"
            >Submit</button>
        </footer>
    </form>
</main>