/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CallDTO { 
    age?: number;
    assignedOn?: string;
    attempts?: number;
    callDuration?: string;
    callOutcome?: string;
    callType?: CallDTO.CallTypeEnum;
    callerId?: string;
    clinicHotlineNumber?: string;
    clinicId?: number;
    createdBy?: string;
    createdDate?: Date;
    flagged?: boolean;
    followUpVolunteer?: string;
    hadContactWithInfected?: boolean;
    hasInsurance?: boolean;
    hasSymptoms?: boolean;
    hasTraveledToInfectedArea?: boolean;
    hasUnderlyingDisease?: boolean;
    id?: number;
    language?: CallDTO.LanguageEnum;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    patient?: boolean;
    phoneNumber?: string;
    previousCalls?: number;
    receivedTest?: boolean;
    referredForTest?: boolean;
    seekingTest?: boolean;
    status?: CallDTO.StatusEnum;
    surveyFeedBack?: boolean;
    symptoms?: string;
    volunteer?: string;
    zip?: string;
    zonedCreatedDate?: Date;
    zonedLastModifiedDate?: Date;
}
export namespace CallDTO {
    export type CallTypeEnum = 'COMPLEX' | 'SIMPLE' | 'TESTING' | 'INFORMATION';
    export const CallTypeEnum = {
        COMPLEX: 'COMPLEX' as CallTypeEnum,
        SIMPLE: 'SIMPLE' as CallTypeEnum,
        TESTING: 'TESTING' as CallTypeEnum,
        INFORMATION: 'INFORMATION' as CallTypeEnum
    };
    export type LanguageEnum = 'ENGLISH' | 'SPANISH';
    export const LanguageEnum = {
        ENGLISH: 'ENGLISH' as LanguageEnum,
        SPANISH: 'SPANISH' as LanguageEnum
    };
    export type StatusEnum = 'NEWCALL' | 'INPROGRESS' | 'INCOMPLETE' | 'FOLLOWUP' | 'COMPLETE';
    export const StatusEnum = {
        NEWCALL: 'NEWCALL' as StatusEnum,
        INPROGRESS: 'INPROGRESS' as StatusEnum,
        INCOMPLETE: 'INCOMPLETE' as StatusEnum,
        FOLLOWUP: 'FOLLOWUP' as StatusEnum,
        COMPLETE: 'COMPLETE' as StatusEnum
    };
}
