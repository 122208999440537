/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface HoursOfOperationDTO { 
    closingTime?: string;
    dayOfWeek?: HoursOfOperationDTO.DayOfWeekEnum;
    openingTime?: string;
}
export namespace HoursOfOperationDTO {
    export type DayOfWeekEnum = 'ALL' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY' | 'WEEKDAYS' | 'WEEKENDS';
    export const DayOfWeekEnum = {
        ALL: 'ALL' as DayOfWeekEnum,
        MONDAY: 'MONDAY' as DayOfWeekEnum,
        TUESDAY: 'TUESDAY' as DayOfWeekEnum,
        WEDNESDAY: 'WEDNESDAY' as DayOfWeekEnum,
        THURSDAY: 'THURSDAY' as DayOfWeekEnum,
        FRIDAY: 'FRIDAY' as DayOfWeekEnum,
        SATURDAY: 'SATURDAY' as DayOfWeekEnum,
        SUNDAY: 'SUNDAY' as DayOfWeekEnum,
        WEEKDAYS: 'WEEKDAYS' as DayOfWeekEnum,
        WEEKENDS: 'WEEKENDS' as DayOfWeekEnum
    };
}
