export * from './callBackController.service';
import { CallBackControllerService } from './callBackController.service';
export * from './callController.service';
import { CallControllerService } from './callController.service';
export * from './clinicController.service';
import { ClinicControllerService } from './clinicController.service';
export * from './healthController.service';
import { HealthControllerService } from './healthController.service';
export * from './hotlineController.service';
import { HotlineControllerService } from './hotlineController.service';
export * from './memberController.service';
import { MemberControllerService } from './memberController.service';
export * from './notificationController.service';
import { NotificationControllerService } from './notificationController.service';
export * from './securityController.service';
import { SecurityControllerService } from './securityController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './websocketController.service';
import { WebsocketControllerService } from './websocketController.service';
export const APIS = [CallBackControllerService, CallControllerService, ClinicControllerService, HealthControllerService, HotlineControllerService, MemberControllerService, NotificationControllerService, SecurityControllerService, UserControllerService, WebsocketControllerService];
