<main fxLayout="row wrap" fxLayoutAlign="center start">
    <nav fxFlex=100% fxLayout="column" fxLayoutAlign="center center">
        <mat-icon  color="primary">help_outline</mat-icon>

            <h2>Important Reminder!</h2>
    </nav>
    <section fxFlex=100% fxFlex=100% fxLayout="column" fxLayoutAlign="start start">
        <strong>You will still be assigned to this call and your work will be saved if you navigate away without unassign yourself.</strong>
        <mat-checkbox ><strong>Don't show me this message again</strong></mat-checkbox>
    </section>

    <footer  fxFlex=100% fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
        <button  (click)="cancel()" mat-button color="primary" tabIndex="-1"
           mat-button>Cancel
        </button>
        <button  mat-raised-button color="primary" tabIndex="-1" (click)="leaveScribe()">Ok
        </button>
      </footer>

</main>
