
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  public static message: string;

  constructor(){
  }

  get Message() {
    return CustomSnackbarComponent.message;
  }

  ngOnInit(): void {
  }

}
