import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NoteData } from '../call-flag.component';
import { CallControllerService } from '@helplinesos-api-client';

@Component({
  selector: 'app-delete-comment',
  templateUrl: './delete-comment.component.html',
  styleUrls: ['./delete-comment.component.scss']
})
export class DeleteCommentComponent implements OnInit {

  noteId: number;
  callId: number;

  constructor(
      private dialogRef: MatDialogRef<DeleteCommentComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData: NoteData,
      private callControllerService: CallControllerService,
  ) { }

  ngOnInit(): void {
    this.noteId = this.dialogData.noteId;
    this.callId = this.dialogData.callId;

  }

  deleteNote(){
        this.callControllerService.deleteCallFlagNoteUsingDELETE(this.callId, this.noteId).subscribe(response => {
        this.dialogRef.close();
    });
  }
}
