
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';

import { Injectable } from '@angular/core';
import { MatSnackBarRef, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { CustomToastNotificationComponent } from './custom-toast-notification/custom-toast-notification.component';
import { SuccessToastNotificationComponent } from './success-toast-notification/success-toast-notification.component';
import { ErrorToastNotificationComponent } from './error-toast-notification/error-toast-notification.component';

@Injectable()
export class CustomSnackbarService {

  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;

  constructor(private snackBar: MatSnackBar) { }

  // open(message: string) {
  //   CustomSnackbarComponent.message = message;

  //   this.snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
  //     duration: 3000,
  //     panelClass: 'dialog-panel',
  //     verticalPosition: 'bottom',
  //     horizontalPosition: 'center',
  //   });

  //   this.snackBarRef.afterOpened().subscribe(this.align);
  // }

  // // TODO: find a way to make this common to all snacks
  // private align = () => {
  //   let elements = document.getElementsByClassName('cdk-global-overlay-wrapper');
  //   for (let i = 0; i < elements.length; i++) {
  //     if (elements[i].getElementsByClassName('dialog-panel')) {
  //       elements[i].setAttribute(
  //         'style',
  //         'justify-content: center;align-items: center;'
  //       );
  //       elements[i].classList.add('aligned');
  //     }
  //   }
  // }

  openToastNotification(message: string){


      CustomToastNotificationComponent.message = message;

      this.snackBarRef = this.snackBar.openFromComponent(CustomToastNotificationComponent, {
        duration: 5000,
        verticalPosition: 'top'
      });
      return this.snackBarRef;
  }

  openSuccessNotification(message: string){
    SuccessToastNotificationComponent.message = message;
    this.snackBarRef = this.snackBar.openFromComponent(SuccessToastNotificationComponent, {
      duration: 5000,
      verticalPosition: 'top'
    });
    return this.snackBarRef;
}

openErrorNotification(message: string){
  ErrorToastNotificationComponent.message = message;
  this.snackBarRef = this.snackBar.openFromComponent(ErrorToastNotificationComponent, {
    duration: 5000,
    verticalPosition: 'top'
  });
  return this.snackBarRef;
}
}
