import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationControllerService } from '@helplinesos-api-client';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  formGroup: FormGroup;
  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };
  constructor(
    private snackBarService: CustomSnackbarService,
    private formBuilder: FormBuilder,
    private router: Router,
    private service: NotificationControllerService
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      fullName: [''],
      email: ['', Validators.email],
      phoneNumber: [''],
      message: ['']
    });
  }

  redirectToLogin(){
    this.router.navigate(['login']);
  }

  submit(){
    const dto = {
      email:  this.formGroup.controls.email.value,
      fullName: this.formGroup.controls.fullName.value,
      message: this.formGroup.controls.message.value,
      phoneNumber: this.formGroup.controls.phoneNumber.value.replace(/[&\/\\()-]/g, ''),
    };
    this.service.contactUsUsingPOST(dto).subscribe(response => {
      this.snackBarService.openToastNotification('Message sent');
      this.formGroup = this.formBuilder.group({
        fullName: [''],
        email: ['', Validators.email],
        phoneNumber: [''],
        message: ['']
      });
    });
  }
}
