<nav>
    <mat-icon [mat-dialog-close]="true">close</mat-icon>
  </nav>
  
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="100%" fxFlex.xs="90%" fxFlex.gt-xs="80%" fxFlex.gt-sm="80%">
  
      <header>
        <h2>
          If you navigate from this page, your changes will not be saved.
        </h2>
      </header>
      
      <mat-dialog-actions >
        <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="true">Cancel</button>
        <button  mat-raised-button color="primary" (click)="onOkButton()">Leave Page</button>
      </mat-dialog-actions>
  
    </div> 
  </div> 
