import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-toast-notification',
  templateUrl: './error-toast-notification.component.html',
  styleUrls: ['./error-toast-notification.component.scss']
})
export class ErrorToastNotificationComponent implements OnInit {

  public static message: string;
  constructor(private snackBarRef: MatSnackBarRef<any>) { }

  get Message() {
    return ErrorToastNotificationComponent.message + '.';
  }
  ngOnInit(): void {
  }

  closeToastNotification(){
    this.snackBarRef.dismissWithAction();
  }
}
