import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { InformationData } from '../user-profile-editor.component';
import { UserDTO } from '@helplinesos-api-client';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {
  userFormGroup: FormGroup;
  userDto: UserDTO;
  constructor(
    private formBuilder: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<EditEmailComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<EditEmailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: InformationData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: InformationData,
  ) { }

  ngOnInit(): void {
    if (this.dialogData){
      this.userDto = this.dialogData.userDto;
      }
    else
    {
      this.userDto = this.bottomSheetData.userDto;
    }

    this.userFormGroup = this.formBuilder.group({
      email: [''],
    });
  }

  closeDialog(){
    if (this.dialogRef){
      this.dialogRef.close();
    }
    else{
      this.bottomSheetRef.dismiss();
    }
  }
}
