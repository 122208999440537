/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreateCallDTO { 
    age?: number;
    hadContactWithInfected?: boolean;
    hasInsurance?: boolean;
    hasSymptoms?: boolean;
    hasTraveledToInfectedArea?: boolean;
    hasUnderlyingDisease?: boolean;
    language?: CreateCallDTO.LanguageEnum;
    patient?: boolean;
    phoneNumber?: string;
    receivedTest?: boolean;
    referredForTest?: boolean;
    seekingTest?: boolean;
    surveyFeedBack?: boolean;
    zip?: string;
}
export namespace CreateCallDTO {
    export type LanguageEnum = 'ENGLISH' | 'SPANISH';
    export const LanguageEnum = {
        ENGLISH: 'ENGLISH' as LanguageEnum,
        SPANISH: 'SPANISH' as LanguageEnum
    };
}
