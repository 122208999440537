import { Component, OnInit, Inject } from '@angular/core';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClinicControllerService } from '@helplinesos-api-client';

export interface DialogData {
  clinicId: number;
}

@Component({
  selector: 'app-deactivate-dialog',
  templateUrl: './deactivate-dialog.component.html',
  styleUrls: ['./deactivate-dialog.component.scss']
})
export class DeactivateDialogComponent implements OnInit {


  constructor(
    private snackBarService: CustomSnackbarService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clinicService: ClinicControllerService,
    public dialogRef: MatDialogRef<any>){ }

  ngOnInit(): void {
  }
  deactivateClinic(){
    this.clinicService.deActivateClinicUsingPUT(this.data.clinicId).subscribe((response) => {
      this.router.navigate(['/clinics']);
      this.dialogRef.close();
      const snackBar = this.snackBarService.openToastNotification('Clinic Deactivated');
    }, err => {
      this.dialogRef.close(err.error.message);
    });
  }
}
