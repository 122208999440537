<main fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <header  >
      <mat-icon color="warn">warning</mat-icon>
    </header>
    <strong>If you navigate from this page your data will not be saved</strong>
    <footer   fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center start">
      <button tabIndex="-1" (click)="cancel()" fxFlex  mat-button color="primary"
      >Cancel</button>
    <button fxFlex (click)="confirm()" mat-raised-button color="primary"
     >Confirm</button>

  </footer>
</main>