/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CallDTO } from '../model/callDTO';
import { CallFlagNoteDTO } from '../model/callFlagNoteDTO';
import { CreateCallDTO } from '../model/createCallDTO';
import { CreateCallFlagNoteDTO } from '../model/createCallFlagNoteDTO';
import { PageCallDTO } from '../model/pageCallDTO';
import { Resource } from '../model/resource';
import { UpdateCallDTO } from '../model/updateCallDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CallControllerService {

    protected basePath = 'https://api-dev.helplinesos.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Flag a Call
     * Flag&#39;s a Call
     * @param callId callId
     * @param createCallFlagNoteDTO createCallFlagNoteDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCallFlagNoteUsingPOST(callId: number, createCallFlagNoteDTO: CreateCallFlagNoteDTO, observe?: 'body', reportProgress?: boolean): Observable<CallFlagNoteDTO>;
    public createCallFlagNoteUsingPOST(callId: number, createCallFlagNoteDTO: CreateCallFlagNoteDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallFlagNoteDTO>>;
    public createCallFlagNoteUsingPOST(callId: number, createCallFlagNoteDTO: CreateCallFlagNoteDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallFlagNoteDTO>>;
    public createCallFlagNoteUsingPOST(callId: number, createCallFlagNoteDTO: CreateCallFlagNoteDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling createCallFlagNoteUsingPOST.');
        }

        if (createCallFlagNoteDTO === null || createCallFlagNoteDTO === undefined) {
            throw new Error('Required parameter createCallFlagNoteDTO was null or undefined when calling createCallFlagNoteUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CallFlagNoteDTO>(`${this.basePath}/api/calls/flag/${encodeURIComponent(String(callId))}`,
            createCallFlagNoteDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Test HotlineCall to a Clinic
     * Create&#39;s a Test HotlineCall to a Clinic
     * @param clinicId clinicId
     * @param createCallDTO createCallDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCallUsingPOST(clinicId: number, createCallDTO: CreateCallDTO, observe?: 'body', reportProgress?: boolean): Observable<CallDTO>;
    public createCallUsingPOST(clinicId: number, createCallDTO: CreateCallDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallDTO>>;
    public createCallUsingPOST(clinicId: number, createCallDTO: CreateCallDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallDTO>>;
    public createCallUsingPOST(clinicId: number, createCallDTO: CreateCallDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling createCallUsingPOST.');
        }

        if (createCallDTO === null || createCallDTO === undefined) {
            throw new Error('Required parameter createCallDTO was null or undefined when calling createCallUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CallDTO>(`${this.basePath}/api/calls/${encodeURIComponent(String(clinicId))}`,
            createCallDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Flaged Call Note
     * Delete&#39;s a Call Note
     * @param callId callId
     * @param callNoteId callNoteId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCallFlagNoteUsingDELETE(callId: number, callNoteId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CallFlagNoteDTO>>;
    public deleteCallFlagNoteUsingDELETE(callId: number, callNoteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CallFlagNoteDTO>>>;
    public deleteCallFlagNoteUsingDELETE(callId: number, callNoteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CallFlagNoteDTO>>>;
    public deleteCallFlagNoteUsingDELETE(callId: number, callNoteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling deleteCallFlagNoteUsingDELETE.');
        }

        if (callNoteId === null || callNoteId === undefined) {
            throw new Error('Required parameter callNoteId was null or undefined when calling deleteCallFlagNoteUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Array<CallFlagNoteDTO>>(`${this.basePath}/api/calls/flag/${encodeURIComponent(String(callId))}/${encodeURIComponent(String(callNoteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download Clinic Calls
     * Download&#39;s Clinic Calls
     * @param clinicId clinicId
     * @param extension extension
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadCallsAsResourceUsingGET(clinicId: number, extension: 'CSV' | 'XLSX', observe?: 'body', reportProgress?: boolean): Observable<Resource>;
    public downloadCallsAsResourceUsingGET(clinicId: number, extension: 'CSV' | 'XLSX', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
    public downloadCallsAsResourceUsingGET(clinicId: number, extension: 'CSV' | 'XLSX', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
    public downloadCallsAsResourceUsingGET(clinicId: number, extension: 'CSV' | 'XLSX', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling downloadCallsAsResourceUsingGET.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling downloadCallsAsResourceUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/calls/download/${encodeURIComponent(String(clinicId))}/${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob'
            }
        );
    }

    /**
     * Get Flaged Call Note(s)
     * Return&#39;s All Notes(s) of a Flaged Call
     * @param callId callId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallFlagNotesUsingGET(callId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CallFlagNoteDTO>>;
    public getCallFlagNotesUsingGET(callId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CallFlagNoteDTO>>>;
    public getCallFlagNotesUsingGET(callId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CallFlagNoteDTO>>>;
    public getCallFlagNotesUsingGET(callId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling getCallFlagNotesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CallFlagNoteDTO>>(`${this.basePath}/api/calls/flag/${encodeURIComponent(String(callId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get HotlineCall By Id
     * Returns A HotlineCall By Id
     * @param callId callId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallUsingGET(callId: number, observe?: 'body', reportProgress?: boolean): Observable<CallDTO>;
    public getCallUsingGET(callId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallDTO>>;
    public getCallUsingGET(callId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallDTO>>;
    public getCallUsingGET(callId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling getCallUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CallDTO>(`${this.basePath}/api/calls/${encodeURIComponent(String(callId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Clinic&#39;s Calls
     * Return&#39;s All Clinic&#39;s Calls
     * @param clinicId clinicId
     * @param filter filter
     * @param language language
     * @param page page
     * @param pageSize pageSize
     * @param sortBy sortBy
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallsUsingGET(clinicId: number, filter: string, language: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<PageCallDTO>;
    public getCallsUsingGET(clinicId: number, filter: string, language: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageCallDTO>>;
    public getCallsUsingGET(clinicId: number, filter: string, language: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageCallDTO>>;
    public getCallsUsingGET(clinicId: number, filter: string, language: string, page: string, pageSize: string, sortBy: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getCallsUsingGET.');
        }

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getCallsUsingGET.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getCallsUsingGET.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getCallsUsingGET.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getCallsUsingGET.');
        }

        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling getCallsUsingGET.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getCallsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageCallDTO>(`${this.basePath}/api/calls/${encodeURIComponent(String(clinicId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * UnAssign Call
     * UnAssign&#39;s A Call
     * @param callBackId callBackId
     * @param callId callId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unAssignCallUsingPOST(callBackId: number, callId: number, observe?: 'body', reportProgress?: boolean): Observable<CallDTO>;
    public unAssignCallUsingPOST(callBackId: number, callId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallDTO>>;
    public unAssignCallUsingPOST(callBackId: number, callId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallDTO>>;
    public unAssignCallUsingPOST(callBackId: number, callId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callBackId === null || callBackId === undefined) {
            throw new Error('Required parameter callBackId was null or undefined when calling unAssignCallUsingPOST.');
        }

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling unAssignCallUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CallDTO>(`${this.basePath}/api/calls/unAssign/${encodeURIComponent(String(callId))}/${encodeURIComponent(String(callBackId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Call
     * Update&#39;s Call
     * @param callId callId
     * @param updateCallDTO updateCallDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCallUsingPUT(callId: number, updateCallDTO: UpdateCallDTO, observe?: 'body', reportProgress?: boolean): Observable<CallDTO>;
    public updateCallUsingPUT(callId: number, updateCallDTO: UpdateCallDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CallDTO>>;
    public updateCallUsingPUT(callId: number, updateCallDTO: UpdateCallDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CallDTO>>;
    public updateCallUsingPUT(callId: number, updateCallDTO: UpdateCallDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling updateCallUsingPUT.');
        }

        if (updateCallDTO === null || updateCallDTO === undefined) {
            throw new Error('Required parameter updateCallDTO was null or undefined when calling updateCallUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CallDTO>(`${this.basePath}/api/calls/${encodeURIComponent(String(callId))}`,
            updateCallDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
