import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  isDialog: boolean;
  closeForm: boolean;
}

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})


export class CancelDialogComponent implements OnInit {


  constructor(
            private location: Location,
            public dialogRef: MatDialogRef<any>,
              ) { }

  ngOnInit(): void {

  }
  onOkButton(){

        this.location.back();
  }
}
