import { Injectable } from '@angular/core';
import {  CallDTO, PageCallDTO, CallControllerService } from '@helplinesos-api-client';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


export class CallQueueTableRow {
  callId: number;
  callerId: string;
  timeStamp: Date;
  type: string;
  symptoms: string;
  chronicDisease: string;
  volunteer: string;
  exposure: string;
  status: string;
  age: number;
  travel: string;
  phoneNumber: string;
  outcome: any;
  zip: string;
  previousCalls: number;
  flagged: boolean;
  lastEdited: Date;
  duration: string;
  language: string;
  insurance: string;
}

export class CallQueueTableQuery {
  search: string;
  language: string;
  status: string;
}

//    !request.sort.order ? '' : `${request.sort.property}.${request.sort.order}`,
@Injectable({
  providedIn: 'root'
})
export class CallQueueService {

  constructor(private service: CallControllerService) { }

  get(
    clinicId: number,
    request: PageRequest<CallQueueTableRow>,
    query: CallQueueTableQuery
  ): Observable<Page<CallQueueTableRow>> {
    return this.service.getCallsUsingGET(
      clinicId,
      '',
      query.language,
      `${request.page}`,
      `${request.size}`,
      (!request.sort.order ||
      (request.sort.property === 'status' && request.sort.order === 'desc')) ? '' : `${request.sort.property}.${request.sort.order}`,
      query.status
    ).pipe(
      map((response: PageCallDTO) => {
          const content = response?.content?.map((call: CallDTO) => {
            return {
              callId: call?.id,
              callerId: call?.callerId,
              timeStamp: call?.createdDate,
              type: call?.callType,
              symptoms: call.hasSymptoms ? 'Yes' : 'No',
              chronicDisease: call.hasUnderlyingDisease ? 'Yes' : 'No',
              volunteer: call.volunteer,
              exposure: call.hadContactWithInfected ? 'Yes' : 'No',
              status: call?.status,
              age: call?.age,
              travel: call?.hasTraveledToInfectedArea ? 'Yes' : 'No',
              phoneNumber: call?.phoneNumber ?
              '(' +
              call.phoneNumber.slice(0, 3) + ') ' +
              call.phoneNumber.slice(4, 7) + '-' +
              call.phoneNumber.slice(8) : 'Phone number not set',
              outcome: call?.callOutcome,
              zip: call?.zip,
              previousCalls: call?.previousCalls,
              flagged: call?.flagged,
              lastEdited: call?.lastModifiedDate,
              duration: call?.callDuration,
              language: call?.language,
              insurance: call?.hasInsurance ? 'Yes' : 'No'

            } as CallQueueTableRow;
          });
          return {
            totalElements: response?.totalElements ?? 0, // TODO: map from response
            size: response?.size ?? 0,
            number: (response?.number ?? 0) + 1, // IMPORTANT: UI uses 1-based, API uses 0-based
            content,
          } as Page<CallQueueTableRow>;
        })
      ); // .pipe(delay(1000));
    }


}
