<main fxLayout="row wrap" fxLayoutAlign="start start">
    <header fxFlex="100%" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center" fxLayoutGap="16px">
            <mat-icon color="primary">group</mat-icon>
            <strong><b>{{Message}}</b></strong>
        </div>
        <button (click)="closeNotification()"mat-icon-button><mat-icon>close</mat-icon></button>
    </header>

    <!-- <strong><b><a (click)="logout()">Logout</a></b></strong> -->
</main>

