/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ScribeActionDTO { 
    editable?: boolean;
    id?: number;
    question?: string;
    required?: boolean;
    sequence?: number;
    title?: string;
    type?: ScribeActionDTO.TypeEnum;
    values?: string;
}
export namespace ScribeActionDTO {
    export type TypeEnum = 'CHECK' | 'RADIO';
    export const TypeEnum = {
        CHECK: 'CHECK' as TypeEnum,
        RADIO: 'RADIO' as TypeEnum
    };
}
