import { Component, OnInit, Optional, Inject } from '@angular/core';
import { CallDTO, CallBackDTO, CallControllerService, CallBackControllerService, UpdateCallBackQuestionDTO, CallBackQuestionDTO, ScribeActionDTO } from '@helplinesos-api-client';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ScribeHistoryData } from '../scribe/scribe.component';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';

@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss']
})
export class CallHistoryComponent extends ComponentBase  implements OnInit {

  callDto: CallDTO;
  callBacksDto: Array<CallBackDTO>;
  callBackForDisplay: CallBackDTO;
  currentCallBackId: number;
  haveHistory = true;
  answeredQuestions: ScribeActionDTO[] = [];

  constructor(
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<CallHistoryComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<CallHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ScribeHistoryData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: ScribeHistoryData,
    private route: ActivatedRoute,
    private callControllerService: CallControllerService,
    private callBackControllerService: CallBackControllerService,
    private errorService: ErrorService,
    ) { super(); }


    roleCaption = new Map([
      [CallDTO.StatusEnum.NEWCALL, 'Pending'],
      [CallDTO.StatusEnum.INPROGRESS, 'Pending'],
      [CallDTO.StatusEnum.COMPLETE, 'Complete'],
      [CallDTO.StatusEnum.INCOMPLETE, 'Pending'],
      [CallDTO.StatusEnum.FOLLOWUP, 'Follow up'],
      ['NO', 'No'],
      ['CVS', 'CVS'],
      ['HOME_CLINIC', 'Home Clinic'],
      ['CORE', 'CORE']
    ]);


  ngOnInit(): void {

    this.isWaiting = true;
    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== ''){
      this.isWaiting = false;
      }
    }));

    if (this.dialogData){
      this.callDto = this.dialogData.callDto;
      this.currentCallBackId = this.dialogData.callBackId;
      this.callBacksDto = this.dialogData.callBacks;
    }
    else{
      this.callDto = this.bottomSheetData.callDto;
      this.currentCallBackId = this.bottomSheetData.callBackId;
      this.callBacksDto = this.bottomSheetData.callBacks;
    }
    const test = this.callDto;
    this.getAllCallBacks();
  }

  initAnsweredQuestions(){
    this.answeredQuestions = [];
    for (const question of this.callBackForDisplay.questions){

      if ( question === undefined || question === null){
        continue;
      }

      const action = this.callBackForDisplay.scribeActions.find(x => x.id === question.scibeActionId);

      if(action === undefined || action === null){
        continue;
      }
      this.answeredQuestions.push(
        {
          id: question?.scibeActionId,
          sequence: action?.sequence,
          type: action?.type,
          question: action?.question,
          required: action?.required,
          values: question?.answer,
          title: action.title
        }
      );
  }
  }

  getAllCallBacks(){
    // this.callBackControllerService.getCallBacksUsingGET(this.callDto.id, 'response').subscribe(
    //   (response) => {
        // this.callBacksDto = response.body;
        // const currentCallBack = this.callBacksDto?.find(x => x.id === this.currentCallBackId);
        // this.callBacksDto = this.callBacksDto?.filter(x => x.id !== this.currentCallBackId && x.createdDate <= currentCallBack.createdDate )
        //                     .sort(function(a, b){
        //                             return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
        //                           });
        // if ( this.callBacksDto.length === 0){
        //   this.haveHistory = false;
        // }
        this.callBackForDisplay = this.callBacksDto[0];
        this.initAnsweredQuestions();
        this.isWaiting = false;
    //   }
    // );
  }



  changeCallBackForDisplay(calbackId: number){
    this.callBackForDisplay = this.callBacksDto.find(x => x.id === calbackId);
    this.initAnsweredQuestions();
  }

  closeDialog(){
    if (this.dialogRef){
      this.dialogRef.close();
    }
    else{
      this.bottomSheetRef.dismiss();
    }
  }

  getQuestionValue(element: ScribeActionDTO){
    let value = '';
    switch (element?.type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
       value = this.getSingeQuestionValue(element.id);
       break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        value = this.getMultiSelectQuestionValue(element.id);
        break;
      }
      default:
        break;
    }
    return value;
  }

  getSingeQuestionValue(questionId: number){
    const question = this.answeredQuestions?.find(x => x.id === questionId);
    if (question === undefined || question === null){
      return '';
    }
    return question.values;
  }

  getMultiSelectQuestionValue(questionId: number){
    const question = this.answeredQuestions?.find(x => x.id === questionId);
    if (question === undefined || question === null){
      return '';
    }
    const arrayOfValues = question.values.split(',');
    return arrayOfValues.join(', ');
  }


  boolDisplayFormat(value: any){
    if (value === undefined || value === null ){
      return 'N/A';
    }
    return value === true ? 'Yes' : 'No';
  }
  
  isTypeWarn(callType: CallDTO.CallTypeEnum){
    let warn = '';
    if (callType === CallDTO.CallTypeEnum.COMPLEX || callType === CallDTO.CallTypeEnum.SIMPLE){
      warn = 'textDanger';
    }
    return warn;
  }

  isWarn(element: boolean){
    let warn = '';
    if (element){
      warn = 'textDanger';
    }
    return warn;
  }

}
