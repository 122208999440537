<main>
  <div fxLayout="row" fxLayoutAlign="start start">
    <header fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="space-between start">
    <header fxFlex>
      <nav  *ngIf="this.roleCaption.get(this.role) === 'Admin'">
        <button mat-icon-button (click)="goToDashboard()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </nav>
      <h1>{{ clinicDto?.name }}</h1>
      <h3 *ngIf="this.roleCaption.get(this.role) === 'Admin'">
        <a (click)="goToClinicQueue()" >Go to Clinic Call Queue</a>
      </h3>
    </header>
    <span>
      <app-notifications></app-notifications>
      <app-user-profile></app-user-profile>
    </span>
    </header>
    
    <!-- <aside *ngIf="!isWaiting && clinicDto"> //commented for now 
      <div>{{ clinicDto?.name }}, {{ clinicDto?.address?.city }}</div>
      <div>{{ getAddress() }}</div>
      <div>{{ clinicDto?.createdBy }}</div>
      <mat-form-field appearance="standard">
        <mat-label></mat-label>
        <mat-select class="mat-h4" [value]="getPhoneNumber()[0]">
          <mat-option *ngFor="let number of getPhoneNumber()" [value]="number">
            {{ number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </aside> -->
  </div>
  <div *ngIf="errorMessage" fxLayoutAlign="center center"><strong class="error">{{errorMessage}}</strong></div>
  <nav fxLayout="row wrap"  *ngIf="this.roleCaption.get(this.role) === 'Admin'">
    <mat-button-toggle-group [(value)]="openTab">
      <mat-button-toggle value="information" (click)="showInformation()"
        >Information</mat-button-toggle
      >
      <mat-button-toggle value="team" (click)="showTeam()"
        >Team</mat-button-toggle
      >
      <mat-button-toggle value="analytics" [disabled]="true"
        >Analytics</mat-button-toggle
      >
      <mat-button-toggle value="resources" (click)="showResourceBoard()"
        >Resource Board</mat-button-toggle
      >
    </mat-button-toggle-group>
    <div fxFlex></div>
    <button mat-button *ngIf="roleCaption.get(clinicDto?.status) !== 'Deactivated'" (click)="deactivateClinic()" color="warn">Deactivate Clinic</button>
    <button mat-button *ngIf="roleCaption.get(clinicDto?.status) === 'Deactivated'" (click)="activateClinic()" color="primary">Activate Clinic</button>

  </nav>
  <section fxLayout="column" class="mat-elevation-z1">
    <app-clinic-information-tab [dto]="this.clinicDto" *ngIf="isInformationShown"></app-clinic-information-tab>
    <app-members-tab [clinicId]="this.clinicId" *ngIf="isTeamShown"></app-members-tab>
    <app-resource-dashboard [clinicIdForInformationView]="clinicId" *ngIf="isResourceBoardShown"></app-resource-dashboard>
  </section>
</main>
