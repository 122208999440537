import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallQueueDashboardComponent } from './call-queue-dashboard/call-queue-dashboard.component';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PagingControlsComponent } from '../shared/paging-controls/paging-controls.component';
import { SharedModule } from '../shared/shared.module';
import { CallInformationComponent } from './call-information/call-information.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { DialogService } from './dialog.service';
import { MatSortModule } from '@angular/material/sort';
import { CallFlagComponent } from './call-flag/call-flag.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeleteCommentComponent } from './call-flag/delete-comment/delete-comment.component';
import { ScribeComponent } from './scribe/scribe.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { TimerDialogComponent } from './scribe/timer-dialog/timer-dialog.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { UnassignDialogComponent } from './scribe/unassign-dialog/unassign-dialog.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { ScribeCompleteComponent } from './scribe-complete/scribe-complete.component';
import { FollowUpDialogComponent } from './scribe/follow-up-dialog/follow-up-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CancelScribeDialogComponent } from './scribe-complete/cancel-scribe-dialog/cancel-scribe-dialog.component';
import { AuthGuard } from '../auth.guard';
import { UserDTO } from '@helplinesos-api-client';
import { UnassignGuard } from './unassign.guard';
import { LeaveScribeDialogComponent } from './scribe/leave-scribe-dialog/leave-scribe-dialog.component';


@NgModule({
  declarations: [
    CallQueueDashboardComponent,
    CallInformationComponent,
    CallFlagComponent,
    DeleteCommentComponent,
    ScribeComponent,
    TimerDialogComponent,
    UnassignDialogComponent,
    CallHistoryComponent,
    ScribeCompleteComponent,
    FollowUpDialogComponent,
    CancelScribeDialogComponent,
    LeaveScribeDialogComponent,
  ],
  imports: [
    MatRadioModule,
    MatSidenavModule,
    MatTooltipModule,
    MatListModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatListModule,
    MatCheckboxModule,
    MatMenuModule,
    SharedModule,
    CommonModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    CommonModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    NgxPaginationModule,
    MatPaginatorModule,
    MatBadgeModule,
    RouterModule.forChild([
      {
        path: 'call-queue/:clinicId', component: CallQueueDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          role: [UserDTO.RoleEnum.VOLUNTEER, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
        }
      },
      {
        path: 'clinics/:clinicId/call-queue', component: CallQueueDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          role: UserDTO.RoleEnum.ADMIN
        }
      },
      { path: 'call-queue/:clinicId/:callId', component: CallInformationComponent },
      {
        path: 'scribe/:clinicId/:callId/:callBackId', component: ScribeComponent,
        canActivate: [AuthGuard],
        data: {
          role: [UserDTO.RoleEnum.VOLUNTEER, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
        },
        canDeactivate: [UnassignGuard]
      },
      {
        path: 'scribe-complete/:clinicId/:callId', component: ScribeCompleteComponent,
        canActivate: [AuthGuard],
        data: {
          role: [UserDTO.RoleEnum.VOLUNTEER, UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
        }
      }
    ]
    ),
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: DialogService },
    { provide: BnNgIdleService },
    { provide: ScribeComponent }
  ]
})
export class CallQueueModule { }
