<main fxLayout="row wrap" fxLayoutAlign="start start">
    <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="start start">
                <button mat-icon-button (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                  </button>
                <h1>Scribe</h1>
            </div>
            <span>
                <app-notifications></app-notifications>
                <app-user-profile></app-user-profile>
             </span>
        </div>
    </header>
   
    <div fxFlex="100%" fxLayoutAlign="center start"><strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong></div>
    <mat-progress-bar class="progress-bar-grid" *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    <section fxFlex="100%" fxLayout="row" fxLayoutAlign="start space-between" fxLayoutGap="20px">   
        <mat-drawer-container fxLayout="column" fxLayoutGap="16px">

                <h2>Call History</h2>
                <a *ngFor="let item of callBacksDto" [ngClass]="item.id === callBackForDisplay.id ?'active':''" (click)="changeCallBackForDisplay(item.id)">
                    <strong><p>{{item?.createdDate | date:'M.dd.yy'}}</p>
                            <p ngClass='timePosition'>{{item?.createdDate | date:'h:mma'}}</p>
                   </strong>
               </a>

        </mat-drawer-container>

    <form [formGroup]="formGroup" fxFlex fxLayout="row wrap" fxLayoutAlign="start start">
        <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start center ">
            <section  fxFlex="310px" fxLayout="column" class="border-right">
                <div fxLayout="row"  fxLayoutAlign="start start" fxLayoutGap="16px">
                    <h2 ><b>Caller {{callDto?.callerId}}</b></h2>
                    <strong><b>{{this.callDto?.language}} speaker</b></strong>
                </div>
                <div>
                    <strong>Assigned Volunteer: <b>{{callBackForDisplay?.createdBy}} </b></strong>
                </div>
            </section>
            <section  fxLayout="column" fxLayoutAlign="start center" class="border-right">
                <strong><b>Call Duration </b></strong>
                <strong>{{callBackForDisplay?.callBackDuration}}</strong>
            </section>
            <section  fxLayout="column" class="border-right" fxLayoutAlign="start center">
                <strong><b>Date and Time</b></strong>
                <strong>
                    <p>{{callBackForDisplay?.createdDate | date:'M.dd.yy'}}</p>
                    <p ngClass='timePosition'>{{callBackForDisplay?.createdDate | date:'h:mma'}}</p>
                </strong>
            </section>
            <section  class="border-right" fxLayoutAlign="center center ">
                <mat-icon fxFlex="30px" color="primary">phone</mat-icon>
                <strong>           
                    {{getCallPhoneNumber(callDto?.phoneNumber)}}
                </strong>
            </section>
            <section fxLayoutAlign="center center">
                <strong>Sent To: </strong>
                <strong><b>{{roleCaption.get(callBackForDisplay?.status)}}</b></strong>
            </section>
        </header>
        <!-- <section *ngIf="this.roleCaption.get(this.role) === 'Volunteer'" fxLayout="row wrap" fxFlex="100%">
                <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
                    <h3 fxFlex="100%">Follow up Comment</h3>
                    <strong fxFlex="100%">{{callBackForDisplay?.followUpNotes}}</strong>
                </div>
                <div fxLayout="row wrap">
                    <div fxFlex="100%" fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                        <h3>Verification</h3> <strong>Please verify your information</strong>
                    </div>
                    <ul fxLayout="row wrap" fxFlex="100%" class="mat-table">
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Age</strong>
                             <span>{{this.callDto?.age}}</span>
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Type</strong>
                            <span [ngClass]='isTypeWarn(this.callDto?.callType)'>{{this.type}}</span> 
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Symptoms</strong>
                            <span [ngClass]='isWarn(callDto?.hasSymptoms)'>{{this.boolDisplayFormat(callDto?.hasSymptoms)}}</span> 
                        </li>
                        <li fxLayout="row wrap"  fxFlex="12%" >
                            <strong fxFlex="100%">Underlying Disease</strong>
                            <span [ngClass]='isWarn(callDto?.hasUnderlyingDisease)'>{{this.boolDisplayFormat(callDto?.hasUnderlyingDisease)}}</span>
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Contact</strong>
                            <span [ngClass]='isWarn(callDto?.hadContactWithInfected)'>{{this.boolDisplayFormat(callDto?.hadContactWithInfected)}}</span> 
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">ClinicPatient</strong>
                            <span>{{this.boolDisplayFormat(callDto?.patient)}}</span>
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Zipcode</strong>
                            <span>{{this.callDto?.zip}}</span>
                        </li>
                        <li fxLayout="row wrap" fxFlex="12%">
                            <strong fxFlex="100%">Insurance</strong>
                            <span>{{this.boolDisplayFormat(callDto?.hasInsurance)}}</span>
                        </li>
                    </ul>       
               
                    <div class="margin-bottom-16" fxLayout="row wrap" fxLayoutAlign="start start " fxLayoutGap="20px">
                        <h3>Comment </h3>
                        <strong fxFlex="100%">
                            {{this.callBackForDisplay?.callBackNotes}}
                        </strong>
                    </div>
            
                    <div fxFlex="100%"  fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                        <h3>Actions</h3>
                    </div>
                    <div fxFlex="33%"  fxLayout="column" fxLayoutAlign="start stretch">
                        <ul fxFlex fxLayout="column" fxLayoutGap="16px">
                            <li *ngFor="let item of answeredQuestions">
                                <strong ><b>{{item.question}}</b></strong><br />
                                <strong>{{ getAnsweredQuestionValue(item)}}</strong></li>
                        </ul >
                    </div>
                </div>              
        </section> -->

        <section   fxLayout="row wrap" fxFlex="100%">

                <!-- <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start " fxLayoutGap="20px">
                    <h3>Follow up Comment </h3>
                    <mat-form-field fxFlex="100%">
                        <textarea matInput type="text"  placeholder="Follow up Comment" [value]="this.callBackForDisplay?.followUpNotes" (focusout)='updateFollowUpNotes($event.target.value)'></textarea>
                      </mat-form-field>
                </div> -->

        <div fxFlex="100%"  fxLayout="row wrap" fxLayoutAlign="start start ">
            <div fxFlex="100%" fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                <h3>Verification</h3> <strong>Please verify your information</strong>
            </div>
            <ul fxLayout="row wrap" fxFlex="100%" class="mat-table">
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Age</strong>
                    <mat-form-field fxFlex="100%" fxFlex="80px">
                        <input  formControlName="age" matInput type="number" (focusout)='updateCall("Age",$event.target.value)' >
                        <mat-error *ngIf="formGroup.controls.age.errors?.required">Age is required</mat-error>
                        <mat-error *ngIf="formGroup.controls.age.errors?.max || formGroup.controls.age.errors?.min">Age must be from 0-120</mat-error>
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%" >Type</strong>
                    <span class="margin-top" [ngClass]='isTypeWarn(this.callDto?.callType)'>{{this.type}}</span> 
       
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Symptoms</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select  [ngClass]='isWarn(this.callDto?.hasSymptoms)' (selectionChange)='updateCall("Symptoms",$event.source.value)' [value]="callDto?.hasSymptoms" >
                            <mat-option  *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Underlying Disease</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select [ngClass]='isWarn(callDto?.hasUnderlyingDisease)' (selectionChange)='updateCall("UnderlyingDisease",$event.source.value)' [value]="callDto?.hasUnderlyingDisease" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Contact</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select  [ngClass]='isWarn(callDto?.hadContactWithInfected)' (selectionChange)='updateCall("Contact",$event.source.value)' [value]="callDto?.hadContactWithInfected" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">ClinicPatient</strong>
                    
                    <mat-form-field fxFlex="100px">
                        <mat-select (selectionChange)='updateCall("ClinicPatient",$event.source.value)' [value]="callDto?.patient" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Zipcode</strong>
                    
                    <mat-form-field fxFlex="120px">
                        <input formControlName="zipCode" matInput type="text" (focusout)='updateCall("Zipcode" , $event.target.value)' >
                        <mat-error *ngIf="formGroup.controls.zipCode.errors?.required">Zip Code is required</mat-error>
                        <mat-error *ngIf="formGroup.controls.zipCode.errors?.pattern">Only numbers are allowed</mat-error>
                        <mat-error *ngIf="!formGroup.controls.zipCode.errors?.minLength">Zip code must have 5 digits</mat-error>
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Insurance</strong>
                    
                    <mat-form-field fxFlex="100px">
                        <mat-select (selectionChange)='updateCall("Insurance",$event.source.value)' [value]="callDto?.hasInsurance" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field>
                </li>
            </ul>
      
        <nav fxFlex="100%" >
            <div fxLayout="row wrap" fxLayoutAlign="start start " fxLayoutGap="20px">
                <h3>Comment </h3><strong fxFlex> - Optional</strong>
                <mat-form-field fxFlex="100%">
                    <textarea matInput type="text" placeholder="Notes" [value]="this.callBackForDisplay?.callBackNotes" (focusout)='updateCallbackNotes($event.target.value)' ></textarea>
                  </mat-form-field>
            </div>
        </nav>
        <!-- [value]="this.callBackForDisplay.notes" (focusout)='updateCallbackNotes($event.target.value)' -->

        <nav fxFlex="100%" fxLayout="column" fxLayoutAlign="start start ">
            <div fxFlex="100%"  fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                <h3>Actions</h3>
            </div>
            <ul    class="questionary" fxLayout="column" >
                <li *ngFor="let item of getRequiredScribeActions(); let i = index"  fxLayout="column">
                    <h4 fxFlex="100%">{{item?.question}}<span class="error"> *</span></h4>
                    <ul  *ngIf="roleCaption.get(item?.type) === 'Check'" fxLayout="row wrap" fxLayoutAlign="start start"  >
                        <li fxFlex="200px"   role="listitem"  *ngFor="let value of item.values.split(',')" >
                            <mat-checkbox [value]="value" (change)='updateCallback(item, $event.source.value)'  [checked]="isSelected(item,value)">
                                {{value}}
                            </mat-checkbox>
                        </li>
                    </ul>
                    <mat-radio-group *ngIf="roleCaption.get(item?.type) === 'Radio'" fxLayout="column"  [value]="getSelectedSingleValue(item?.id)"  >
                        <mat-radio-button (change)='updateCallback(item, $event.source.value)'  *ngFor="let value of item?.values.split(',')" [value]="value" >
                            {{value}}
                          </mat-radio-button>
                    </mat-radio-group>
                </li>          
            </ul>
            <ul class="questionary"   fxLayout="column" >
                <li *ngFor="let item of getOptionalScribeActions(); let i = index"  fxLayout="column">
                    <h4 fxFlex="100%">{{item?.question}}<span class="primary"> -Optional</span></h4>
                    <ul *ngIf="roleCaption.get(item?.type) === 'Check'"  fxLayout="row wrap" fxLayoutAlign="start start"  >
                        <li  fxFlex="200px" role="listitem"  *ngFor="let value of item.values.split(',')" >
                            <mat-checkbox [value]="value" (change)='updateCallback(item, $event.source.value)' [checked]="isSelected(item,value)">
                                {{value}}
                            </mat-checkbox>
                        </li>
                    </ul>
                    <mat-radio-group *ngIf="roleCaption.get(item?.type) === 'Radio'" fxLayout="column"  [value]="getSelectedSingleValue(item?.id)">
                        <mat-radio-button (change)='updateCallback(item, $event.source.value)'  *ngFor="let value of item?.values.split(',')" [value]="value" >
                            {{value}}
                          </mat-radio-button>
                    </mat-radio-group>
                </li>
            </ul>
        </nav>

    </div>

        </section>
           
</form>
    </section>
    <footer class="mat-elevation-z1" fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center">
        <button (click)="cancel()"   mat-button color="primary"
        >Cancel</button>
      <button (click)="save()"  mat-raised-button color="primary"
       >Save</button>
    </footer>
</main>