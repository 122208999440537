import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-follow-up-dialog',
  templateUrl: './follow-up-dialog.component.html',
  styleUrls: ['./follow-up-dialog.component.scss']
})
export class FollowUpDialogComponent implements OnInit {

  formGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FollowUpDialogComponent>,
  ) {

   }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
       notes: [''],
     });
  }

  save(){
    const notes = this.formGroup.controls.notes.value;
    this.dialogRef.close(notes);
  }
  skip(){
    this.dialogRef.close();
  }
}
