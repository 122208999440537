<!-- https://github.com/michaelbromley/ngx-pagination/blob/master/src/template.ts -->
<!-- https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-controls.component.ts -->

    <nav>
        <button mat-button color="primary" [disabled]="isFirstPage()" (click)="setCurrent(1)">First</button>
        <button mat-button color="primary" [disabled]="isFirstPage()" (click)="previous()">&lt;</button>

        <!-- [ngStyle]="getCurrent() === page.value ? 'mat-flat-button' : 'mat-button'"   -->
        <button mat-button 
            *ngFor="let page of pages"
            [ngClass]="getCurrent() === page.value ? 'current' : null"
            [disabled]="getCurrent() === page.value"
            (click)="setCurrent(page.value)">
                {{ page.label }}</button>
        
        <button mat-button color="primary" [disabled]="isLastPage()" (click)="next()">&gt;</button>
        <button mat-button color="primary" [disabled]="isLastPage()" (click)="setCurrent(getLastPage())">Last</button>
    </nav>
 
        <!--

    <pagination-template  #p="paginationApi"
                         [id]="id"
                         [maxSize]="maxSize"
                         (pageChange)="pageChange.emit($event)"
                         (pageBoundsCorrection)="pageBoundsCorrection.emit($event)">
    <ul class="ngx-pagination" 
        role="navigation" 
        [attr.aria-label]="screenReaderPaginationLabel" 
        [class.responsive]="responsive"
        *ngIf="!(autoHide && p.pages.length <= 1)">
        <li class="pagination-previous" [class.disabled]="p.isFirstPage()" *ngIf="directionLinks"> 
            <a tabindex="0" *ngIf="1 < p.getCurrent()" (keyup.enter)="p.previous()" (click)="p.previous()" [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span *ngIf="p.isFirstPage()">
                {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li> 
        <li class="small-screen">
            {{ p.getCurrent() }} / {{ p.getLastPage() }}
        </li>
        <li [class.current]="p.getCurrent() === page.value" 
            [class.ellipsis]="page.label === '...'"
            *ngFor="let page of p.pages">
            <a tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
                <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
            </a>
            <ng-container *ngIf="p.getCurrent() === page.value">
                <span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
                <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span> 
            </ng-container>
        </li>
        <li class="pagination-next" [class.disabled]="p.isLastPage()" *ngIf="directionLinks">
            <a tabindex="0" *ngIf="!p.isLastPage()" (keyup.enter)="p.next()" (click)="p.next()" [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel">
                 {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
            <span *ngIf="p.isLastPage()">
                 {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
        </li>
    </ul>
    </pagination-template>

-->