import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxPaginationModule } from 'ngx-pagination';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { PagingControlsComponent } from './paging-controls/paging-controls.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationsComponent } from './notifications/notifications.component';
import { CustomSnackbarService } from './custom-snackbar.service';
import { SuccessToastNotificationComponent } from './success-toast-notification/success-toast-notification.component';
import { ErrorToastNotificationComponent } from './error-toast-notification/error-toast-notification.component';




@NgModule({
  declarations: [PagingControlsComponent, UserProfileComponent, NotificationsComponent, SuccessToastNotificationComponent, ErrorToastNotificationComponent],
  imports: [
    FlexLayoutModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatBadgeModule
  ],
  exports: [PagingControlsComponent, UserProfileComponent, NotificationsComponent],
  providers: [
    { provide: CustomSnackbarService }
  ]
})
export class SharedModule { }
