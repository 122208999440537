import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SecurityControllerService } from '@helplinesos-api-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-toast-notification',
  templateUrl: './success-toast-notification.component.html',
  styleUrls: ['./success-toast-notification.component.scss']
})
export class SuccessToastNotificationComponent implements OnInit {

  public static message: string;
  constructor(
    private router: Router,
    private snackBarRef: MatSnackBarRef<any>,
    private securityControllerService: SecurityControllerService) { }

  get Message() {
    return SuccessToastNotificationComponent.message + '.';
  }

  ngOnInit(): void {
  }


  closeNotification(){
    this.snackBarRef.dismiss();
  }

  logout(){
    this.securityControllerService.logoutUsingPOST().subscribe((response) =>
    {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
      this.snackBarRef.dismiss();
    });
  }
}
