import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ClinicControllerService, CreateClinicDTO, HoursOfOperationDTO, UserDTO } from '../../../../../helplinesos-api-client/src';
import { MatDialog } from '@angular/material/dialog';
import { ClinicDTO, UpdateClinicDTO } from '../../../../../helplinesos-api-client/src';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CancelDialogService } from '../../shared/cancel-dialog.service';
import { Location } from '@angular/common';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { HistoryRoutingService } from '../../shared/history-routing.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';
import { ContactDTO } from 'projects/helplinesos-api-client/src/model/contactDTO';
import { validateBasis } from '@angular/flex-layout';

export class State {
  name: string;
  abbreviation: string;
}

@Component({
  selector: 'app-clinic-editor',
  templateUrl: './clinic-editor.component.html',
  styleUrls: ['./clinic-editor.component.scss']
})

export class ClinicEditorComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {
  role: any;
  hasMultipleContacts = false;
  hasMultipleHours = false;
  formGroup: FormGroup;
  isValidationShown = false;
  clinicId: string;
  clinicDto: ClinicDTO;
  public filteredStates: ReplaySubject<State[]> = new ReplaySubject<State[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected onDestroy = new Subject<void>();
  public stateFilter: FormControl = new FormControl();


  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  hours = [
    '12:00 am',
    '12:15 am',
    '12:30 am',
    '12:45 am',
    '1:00 am',
    '1:15 am',
    '1:30 am',
    '1:45 am',
    '2:00 am',
    '2:15 am',
    '2:30 am',
    '2:45 am',
    '3:00 am',
    '3:15 am',
    '3:30 am',
    '3:45 am',
    '4:00 am',
    '4:15 am',
    '4:30 am',
    '4:45 am',
    '5:00 am',
    '5:15 am',
    '5:30 am',
    '5:45 am',
    '6:00 am',
    '6:15 am',
    '6:30 am',
    '6:45 am',
    '7:00 am',
    '7:15 am',
    '7:30 am',
    '7:45 am',
    '8:00 am',
    '8:15 am',
    '8:30 am',
    '8:45 am',
    '9:00 am',
    '9:15 am',
    '9:30 am',
    '9:45 am',
    '10:00 am',
    '10:15 am',
    '10:30 am',
    '10:45 am',
    '11:00 am',
    '11:15 am',
    '11:30 am',
    '11:45 am',
    '12:00 pm',
    '12:15 pm',
    '12:30 pm',
    '12:45 pm',
    '1:00 pm',
    '1:15 pm',
    '1:30 pm',
    '1:45 pm',
    '2:00 pm',
    '2:15 pm',
    '2:30 pm',
    '2:45 pm',
    '3:00 pm',
    '3:15 pm',
    '3:30 pm',
    '3:45 pm',
    '4:00 pm',
    '4:15 pm',
    '4:30 pm',
    '4:45 pm',
    '5:00 pm',
    '5:15 pm',
    '5:30 pm',
    '5:45 pm',
    '6:00 pm',
    '6:15 pm',
    '6:30 pm',
    '6:45 pm',
    '7:00 pm',
    '7:15 pm',
    '7:30 pm',
    '7:45 pm',
    '8:00 pm',
    '8:15 pm',
    '8:30 pm',
    '8:45 pm',
    '9:00 pm',
    '9:15 pm',
    '9:30 pm',
    '9:45 pm',
    '10:00 pm',
    '10:15 pm',
    '10:30 pm',
    '10:45 pm',
    '11:00 pm',
    '11:15 pm',
    '11:30 pm',
    '11:45 pm',
  ];

  days = [
    {display: 'Monday', value: HoursOfOperationDTO.DayOfWeekEnum.MONDAY},
    {display: 'Tuesday', value: HoursOfOperationDTO.DayOfWeekEnum.TUESDAY},
    {display: 'Wednesday', value: HoursOfOperationDTO.DayOfWeekEnum.WEDNESDAY},
    {display: 'Thursday', value: HoursOfOperationDTO.DayOfWeekEnum.THURSDAY},
    {display: 'Friday', value: HoursOfOperationDTO.DayOfWeekEnum.FRIDAY},
    {display: 'Saturday', value: HoursOfOperationDTO.DayOfWeekEnum.SATURDAY},
    {display: 'Sunday', value: HoursOfOperationDTO.DayOfWeekEnum.SUNDAY}
  ];

  states: any[] = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' }
  ];

  get contactsForms() {
    return this.formGroup.get('contacts') as FormArray;
  }

  get hoursForms() {
    return this.formGroup.get('hours') as FormArray;
  }

  constructor(private formBuilder: FormBuilder,
              private clinicService: ClinicControllerService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private cancelDialogService: CancelDialogService,
              private location: Location,
              private snackBarService: CustomSnackbarService,
              private historyRoutingService: HistoryRoutingService,
              private errorService: ErrorService) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.filteredStates.next(this.states.slice());
    this.stateFilter.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterStates();
      });
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/) ]],
      phone: ['', [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      address: ['',  [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/) ]],
      apt: [''],
      city: ['',  [Validators.required, Validators.pattern(/^[A-Za-z]/) ]],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(5)]],
      notes: [''],
      testingRequirements: [''],
      contacts: this.formBuilder.array([]),
      hours: this.formBuilder.array([]),
      hoursNotes: ['']
    });

    const contact = this.formBuilder.group({
      firstName: ['', Validators.pattern(/^[A-Za-z]/)],
      lastName: ['', Validators.pattern(/^[A-Za-z]/)],
      title: [''],
      phoneNumber: ['', Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)],
    });
    this.contactsForms.push(contact);

    const hours = this.formBuilder.group({
      openingTime: ['', Validators.required],
      closingTime: ['', Validators.required],
      days: ['', Validators.required]
    });
    this.hoursForms.push(hours);

    if (this.route.snapshot.paramMap.get('clinicId')) {
      this.clinicId = this.route.snapshot.paramMap.get('clinicId');
      this.clinicService.getClinicUsingGET(Number(this.clinicId)).subscribe((response) => {
        this.clinicDto = response;
        this.formGroup = this.formBuilder.group({
          name: [this.clinicDto?.name, [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
          phone: [this.clinicDto?.hotlineNumber, [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
          address: [this.clinicDto.address?.streetAddress,  [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/) ]],
          apt: [this.clinicDto.address?.apt],
          city: [this.clinicDto.address?.city,  [Validators.required, Validators.pattern(/^[A-Za-z]/) ]],
          state: [this.states.find(item => item.abbreviation === this.clinicDto.address?.state), Validators.required],
          zip: [this.clinicDto.address?.zipCode, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(5)]],
          notes: [this.clinicDto?.notes],
          testingRequirements: [this.clinicDto?.testingRequirements],
          contacts: this.formBuilder.array([]),
          hours: this.formBuilder.array([]),
          hoursNotes: [this.clinicDto?.hoursOfOperationNotes]
        });
        if (this.clinicDto.contacts.length === 0){
          const c = this.formBuilder.group({
            firstName: ['', Validators.pattern(/^[A-Za-z]/)],
            lastName: ['', Validators.pattern(/^[A-Za-z]/)],
            title: [''],
            phoneNumber: ['', Validators.pattern(/^(?:[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})$/im)],
          });
          this.contactsForms.push(c);
        }
        else{
          this.clinicDto.contacts.forEach(x => {
            const c = this.formBuilder.group({
              firstName: [x?.firstName, Validators.pattern(/^[A-Za-z]/)],
              lastName: [x?.lastName, Validators.pattern(/^[A-Za-z]/)],
              title: [x?.title],
              phoneNumber: [x?.phoneNumber, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)],
            });
            this.contactsForms.push(c);
          });
          if (this.clinicDto.contacts.length > 1){
            this.hasMultipleContacts = true;
          }
        }
        if (this.clinicDto.hoursOfOperation.length === 0){
          const h = this.formBuilder.group({
            openingTime: ['', Validators.required],
            closingTime: ['', Validators.required],
            days: ['', Validators.required]
          });
          this.hoursForms.push(h);
        }
        else{
          const onlyHours = this.clinicDto.hoursOfOperation.map(({closingTime, openingTime}) => ({closingTime, openingTime}));

          const uniqueHours = new Set();

          onlyHours.forEach(arr => uniqueHours.add(JSON.stringify(arr)));

          uniqueHours.forEach((x: any) => {
            const days: string[] = [];
            this.clinicDto.hoursOfOperation.forEach(y => {
              if (y.openingTime === JSON.parse(x).openingTime && y.closingTime === JSON.parse(x).closingTime){
                days.push(y.dayOfWeek);
              }
            });
            const h1 = this.formBuilder.group({
              openingTime: [JSON.parse(x).openingTime, Validators.required],
              closingTime: [JSON.parse(x).closingTime, Validators.required],
              days: [days, Validators.required]
            });
            this.hoursForms.push(h1);
          });
          if (this.hoursForms.length > 1){
            this.hasMultipleHours = true;
          }
          const h = this.formBuilder.group({
            openingTime: ['', Validators.required],
            closingTime: ['', Validators.required],
            days: ['', Validators.required]
          });


        }
      });
    }
    this.isWaiting = false;

    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.errorService.clearErrorMessage();
  }

  protected setInitialValue() {
    this.filteredStates
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: State, b: State) => a && b && a.name === b.name;
      });
  }

  protected filterStates() {
    if (!this.states) {
      return;
    }
    // get the search keyword
    let search = this.stateFilter.value;
    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStates.next(
      this.states.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  openDialog() {
    this.cancelDialogService.openDialog();
  }

  isCaptainRole(){
    if (this.role === UserDTO.ActiveRoleEnum.CAPTAIN || this.role === UserDTO.ActiveRoleEnum.COORDINATOR){
      return true;
    }
    return false;
  }
  cancel() {
    if (this.formGroup.dirty) {
      this.openDialog();
    }
    else {
      this.location.back();
      // this.router.navigate(['/clinics']);
    }

  }
  save() {
    this.isValidationShown = true;
    if (this.formGroup.valid) {
      this.isWaiting = true;
      if (this.clinicId){
          this.updateClinic();
      }
      else{
        this.saveClinic();
      }
    }
    else{
      this.snackBarService.openErrorNotification('Please fill in all the fields correctly');
    }
  }



  updateClinic() {
    const contactsDto: ContactDTO[] = [];
    this.formGroup.controls.contacts.value.forEach((element: any) => {
      contactsDto.push({
        firstName: element.firstName,
        lastName: element.lastName,
        title: element.title,
        phoneNumber: element.phoneNumber.replace(/[&\/\\()-]/g, '')
      });
    });

    const hours: HoursOfOperationDTO[] = [];
    this.formGroup.controls.hours.value.forEach((element: any) => {
    for ( let i = 0, len = element.days.length; i < len; i++){
      hours.push({
            dayOfWeek: element.days[i],
            openingTime: element.openingTime,
            closingTime: element.closingTime,
          });
       }
    });


    const updateClinicDto: UpdateClinicDTO = {
      id: Number(this.clinicId),
      hoursOfOperationNotes: this.formGroup.value.hoursNotes,
      hoursOfOperation: hours,
      contacts: contactsDto,
      name: this.formGroup.value.name,
      notes: this.formGroup.value.notes,
      testingRequirements: this.formGroup.value.testingRequirements,
      hotlineNumber: this.formGroup.value.phone.replace(/[&\/\\()-]/g, ''),
      address: {
        city: this.formGroup.value.city,
        country: 'USA',
        county: '',
        streetAddress: this.formGroup.value.address,
        state: this.formGroup.value.state.abbreviation,
        apt: this.formGroup.value.apt,
        zipCode: this.formGroup.value.zip
      },
      // phoneNumbers: [{
      //   countryCode: '+1',
      //   extension: '',
      //   phoneNumber: this.formGroup.value.phone.replace(/[&\/\\()-]/g, ''),
      //   type: 'HOTLINE'
      // }]
    };
    this.clinicService.updateClinicUsingPUT(updateClinicDto).subscribe(res => {
      this.router.navigate(['clinics/' + this.clinicId + '/information']);
      const message = 'Clinic Updated';
      this.snackBarService.openToastNotification(message);
      this.isWaiting = false;
    });
  }

  saveClinic() {
    const contactsDto: ContactDTO[] = [];
    this.formGroup.controls.contacts.value.forEach((element: any) => {
      contactsDto.push({
        firstName: element.firstName,
        lastName: element.lastName,
        title: element.title,
        phoneNumber: element.phoneNumber.replace(/[&\/\\()-]/g, '')
      });
    });

    const hours: HoursOfOperationDTO[] = [];
    this.formGroup.controls.hours.value.forEach((element: any) => {
    for ( let i = 0, len = element.days.length; i < len; i++){
      hours.push({
            dayOfWeek: element.days[i],
            openingTime: element.openingTime,
            closingTime: element.closingTime,
          });
       }
    });

    const c: CreateClinicDTO = {
      hoursOfOperationNotes: this.formGroup.value.hoursNotes,
      hoursOfOperation: hours,
      contacts: contactsDto,
      name: this.formGroup.value.name,
      notes: this.formGroup.value.notes,
      hotlineNumber: this.formGroup.value.phone.replace(/[&\/\\()-]/g, ''),
      testingRequirements: this.formGroup.value.testingRequirements,
      address: {
        city: this.formGroup.value.city,
        country: 'USA',
        county: '',
        streetAddress: this.formGroup.value.address,
        state: this.formGroup.value.state.abbreviation,
        apt: this.formGroup.value.apt,
        zipCode: this.formGroup.value.zip
      },
      // phoneNumbers: [{
      //   countryCode: '+1',
      //   phoneNumber: this.formGroup.value.phone.replace(/[&\/\\()-]/g, ''),
      //   type: 'HOTLINE'
      // }]
    };
    this.clinicService.createClinicUsingPOST(c).subscribe(response => {
      // this.router.navigate([`clinics/${response.id}/members?registration=true`], { replaceUrl: true });
      this.router.navigate(['clinics/' + response.id + '/members'], { replaceUrl: true });
      const message = 'Clinic Created';
      this.snackBarService.openToastNotification(message);
    });
  }

  addNewMember() {
    const c = this.formBuilder.group({
      firstName: ['', Validators.pattern(/^[A-Za-z]/)],
      lastName: ['', Validators.pattern(/^[A-Za-z]/)],
      title: [''],
      phoneNumber: ['', Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)],
    });
    this.contactsForms.push(c);
    this.hasMultipleContacts = true;
  }

  addHours(){
    const hours = this.formBuilder.group({
      openingTime: ['', Validators.required],
      closingTime: ['', Validators.required],
      days: ['', Validators.required]
    });
    this.hoursForms.push(hours);
    this.hasMultipleHours = true;
  }

  removeHours(i: number) {
    this.hoursForms.removeAt(i);

    if (this.hoursForms.controls.length === 1) {
      this.hasMultipleHours = false;
    }
  }

  removeMember(i: number) {
    this.contactsForms.removeAt(i);

    if (this.contactsForms.controls.length === 1) {
      this.hasMultipleContacts = false;
    }
  }

  getIndex(i: number) {
    return String(i);
  }

  // getOpeningHours(i: number){
  //   const closingHours = this.hoursForms.get(String(i)).value.closingTime;
  //   if ( !closingHours){
  //     return this.hours;
  //   }
  //   else {
  //     const index = this.hours.findIndex(x => x === closingHours);
  //     return this.hours.slice(0, index);
  //   }
  // }

  // getClosingHours(i: number){
  //   const openingHours = this.hoursForms.get(String(i)).value.openingTime;
  //   if ( !openingHours){
  //     return this.hours;
  //   }
  //   else {
  //     const index = this.hours.findIndex(x => x === openingHours);
  //     return this.hours.slice(index + 1);
  //   }
  // }
}
