import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-leave-resource-dialog',
  templateUrl: './leave-resource-dialog.component.html',
  styleUrls: ['./leave-resource-dialog.component.scss']
})
export class LeaveResourceDialogComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<LeaveResourceDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }

}
