import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextMaskModule } from 'angular2-text-mask';
import { CancelDialogService } from '../shared/cancel-dialog.service';
import {MatMenuModule, MatMenu} from '@angular/material/menu';
import { CustomSnackbarService } from '../shared/custom-snackbar.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppErrorStateMatcher } from '../app-matcher';
import { SharedModule } from '../shared/shared.module';
import { ResourceDashboardComponent } from './resource-dashboard/resource-dashboard.component';
import { ScribeActionsComponent } from './resource-dashboard/scribe-actions/scribe-actions.component';
import { HelpfulInformationComponent } from './resource-dashboard/helpful-information/helpful-information.component';
import { BulletinComponent } from './resource-dashboard/bulletin/bulletin.component';
import { HelplineResourceComponent } from './resource-dashboard/helpline-resource/helpline-resource.component';
import { ClinicFaqsComponent } from './resource-dashboard/clinic-faqs/clinic-faqs.component';
import { FateModule, FateMaterialModule  } from 'fate-editor';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { HighlightPipe } from './resource-dashboard/highlight.pipe';
import { UserDTO } from '@helplinesos-api-client';
import { AuthGuard } from '../auth.guard';
import { ResourceDashboardGuard } from './resource-dashboard/resource-dashboard.guard';
import { SaveResourcesBoardComponent } from './resource-dashboard/save-resources-board/save-resources-board.component';
import { LeaveResourceDialogComponent } from './leave-resource-dialog/leave-resource-dialog.component';


@NgModule({
  declarations: [ResourceDashboardComponent,
     ScribeActionsComponent, HelpfulInformationComponent,
     BulletinComponent, HelplineResourceComponent,
     ClinicFaqsComponent,
     SaveResourcesBoardComponent,
     HighlightPipe,
     LeaveResourceDialogComponent],
  imports: [
    MatIconModule,
    FateMaterialModule,
    FateModule,
    MatSlideToggleModule,
    CommonModule,
    SharedModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatButtonToggleModule,
    CommonModule,
    MatProgressBarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    DragDropModule,
    RouterModule.forChild([
      { path: 'resource-dashboard/:clinicId', component: ResourceDashboardComponent,
      canActivate: [AuthGuard],
      canDeactivate: [ResourceDashboardGuard],
      data: {
        role: [UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.VOLUNTEER,  UserDTO.RoleEnum.COORDINATOR]
      }
    },
      { path: 'resource-dashboard', component: ResourceDashboardComponent,
      canActivate: [AuthGuard],
      canDeactivate: [ResourceDashboardGuard],
      data: {
        role: UserDTO.RoleEnum.ADMIN
      },
     }
    ]
    )
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    { provide: CancelDialogService },
    { provide: CustomSnackbarService },
    { provide: ErrorStateMatcher, useClass: AppErrorStateMatcher },
  ],
  exports: [ResourceDashboardComponent,
    ScribeActionsComponent, HelpfulInformationComponent,
    BulletinComponent, HelplineResourceComponent,
    ClinicFaqsComponent,
    HighlightPipe],
})
export class ResourceBoardModule { }



