import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-scribe-dialog',
  templateUrl: './cancel-scribe-dialog.component.html',
  styleUrls: ['./cancel-scribe-dialog.component.scss']
})
export class CancelScribeDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CancelScribeDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  okayClick(){
    this.dialogRef.close('revert');
  }
}
