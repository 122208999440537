<div class="progress-bar-grid">
  <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
</div>
<main  fxLayout="row wrap">
    <h2 *ngIf="this.roleCaption.get(this.role) === 'Captain'" fxFlex="100%">This information will be displayed on the scribe page</h2>
    <form *ngIf="this.roleCaption.get(this.role) === 'Captain'"  [formGroup]="helpfulInfoFormGroup" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <section fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
            <strong fxFlex="100%"><b>Phone Directory</b></strong>
            <ul formArrayName="contacts">
                <li   *ngFor="let item of contactsArray.controls; let i = index" [formGroupName]="i" fxLayoutGap="16px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="260px" >
                        <mat-label>Label</mat-label>
                        <input maxlength="50" formControlName="label" matInput />
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Label is required</mat-error>
                      </mat-form-field>
                      <mat-form-field fxFlex="260px" >
                        <mat-label>Phone Number</mat-label>
                        <input matInput formControlName="phoneNumber" [textMask]="mask"/>
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Phone number must have 10 characters</mat-error>
                      </mat-form-field>
                      <button (click)="removeContact(i)" *ngIf="showDeletePhone()" mat-icon-button ><mat-icon color="warn">delete</mat-icon></button>
                    </li>
            </ul>   
            <div  fxFlex="100%"><a (click)="addNewContact()" ><strong><b>Add new Contact</b></strong></a></div>   
   

        </section>
        <section  fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start center">
            <strong fxFlex="100%"><b>External Links</b></strong>
            <ul formArrayName="links">
                <li *ngFor="let item of linksArray.controls; let y = index" [formGroupName]="y"  fxLayoutGap="16px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="260px" >
                        <mat-label>Label</mat-label>
                        <input  formControlName="label" matInput maxlength="50" />
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Label is required</mat-error>
                      </mat-form-field>
                      <input [hidden]="true" (change)="onFileSelect($event, y)" type="file" accept="all/*" #fileSelect >

                        <button *ngIf="!getFileName(y)" fxFlex="100%" mat-stroked-button (click)="fileSelect.click();" [disabled]="doesUrlExist(y)" >Choose Files</button>
                        <a (click)="downloadFile(getFileData(y),getFileType(y),getFileName(y) )"><p *ngIf="getFileName(y)" >{{getFileName(y)}}</p></a>
                
                            <button  *ngIf="getFileName(y)" (click)="removeFile(y)" matSuffix 
                            type="button" color="warn" mat-icon-button>
                           <mat-icon>close</mat-icon>
                            </button>                
                      <strong>Or</strong>
                      <mat-form-field fxFlex="260px" >
                        <mat-label>URL</mat-label>
                        <input formControlName="url" matInput maxlength="255" [readonly]="isFileUploaded(y)"/>
                        <mat-error *ngIf="!this.helpfulInfoFormGroup.valid">Url must be correctly formatted</mat-error>
                      </mat-form-field>
                      <button mat-icon-button *ngIf="showDeleteLink()" (click)="removeLink(y)"><mat-icon color="warn">delete</mat-icon></button>
                    </li>
                </ul>    
                <div  fxFlex="100%">
                  <a (click)="addNewLink()"><strong><b>Add new File/Link</b></strong></a>
                </div>    
 
        </section>
        <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button   mat-button color="primary"
            (click)="cancel()">Cancel</button>
          <button (click)="submitClick()" [disabled]="this.isWaiting"  mat-raised-button color="primary"
           >Save</button>
    
        </footer>
    </form>
    <section *ngIf="this.roleCaption.get(this.role) !== 'Captain'"  fxFlex="100%">
        <h2>HelplineSOS Resources</h2>
        <strong><b>Phone Directory</b></strong>
        <ul>
            <li *ngFor="let item of helpfulInformationDto?.phoneDirectory"><strong>{{getPhoneNumber(item?.label,item?.phoneNumber)}}</strong></li>
        </ul>
        <strong><b>External Links</b></strong>
        <ul>
           <li *ngFor="let item of helpfulInformationDto?.externalLinks">
             <div fxLayout="column" fxLayoutGap="8px">
              <strong *ngIf="item?.fileData"><a (click)="downloadFile(item.fileData, item.fileType, item.fileName)">{{item?.label}}</a></strong>
              <strong *ngIf="item?.url"><a (click)="goToLink(item?.url)">{{item?.label}}</a></strong>
             </div>

            </li> 
        </ul>
     </section>
</main>
