<div class="progress-bar-grid">
    <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
</div>
<main fxLayout="row wrap">
    <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <h2>These are Scribe action items.</h2>
        <a (click)="revertToDefault()">
            <strong>
                <b>
                    Revert to Default
                </b>
            </strong>
        </a>
    </header>
    <form 
    id="form"
    [formGroup]="questionsFormGroup">
    <ul cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="questions">
        <li cdkDrag *ngFor="let item of questionForms.controls; let i = index" [formGroupName]="i">
            <section fxLayout="row wrap" fxLayoutAlign="space-between start">
                <div fxFlex="80%"  fxLayout="row wrap" >
                    <button cdkDragHandle  mat-icon-button><mat-icon>drag_handle</mat-icon></button>
                    <div  fxLayout="row wrap" fxFlex="80%" >
                        <mat-form-field  fxFlex="30%">
                            <mat-label>Title-ie. Outcome</mat-label>
                            <input formControlName="title" maxlength="25" matInput [readonly]="!isEditable(i)"/>
                            <mat-error *ngIf="!questionsFormGroup.valid && isValidationShown">Title is required
                            </mat-error>
                          </mat-form-field>
                        
                        <mat-form-field  fxFlex="100%">
                            <mat-label>Question</mat-label>
                            <input maxlength="255" matInput formControlName="question" [readonly]="!isEditable(i)"/>
                            <mat-error *ngIf="!questionsFormGroup.valid && isValidationShown">Question is required
                            </mat-error>
                          </mat-form-field>
                          <div fxFlex="100%" formArrayName='options' fxLayout="row wrap" fxLayoutAlign="space-between start" >
                            <div fxFlex="{{getQuestionType(i).value === 'RADIO' ? 60 : 40}}" fxFlex.xs="60%" 
                             *ngFor="let subgroup of item.controls.options.controls; let idx = index;" [formGroupName]="idx" fxLayout="row">
                                <mat-icon>{{getQuestionType(i).value === 'RADIO' ? 'radio_button_unchecked' : 'crop_square'}}</mat-icon>
                                <mat-form-field fxFlex="80%">
                                    <mat-label>Option</mat-label>
                                    <input  maxlength="25" formControlName="option" matInput [readonly]="!isEditable(i)"/>
                                    <mat-error *ngIf="!questionsFormGroup.valid && isValidationShown">Option is required
                                    </mat-error>
                                  </mat-form-field>
                                  <button *ngIf="isEditable(i) && showDeleteForOptions(i)" (click)="removeOption(i, idx)"  mat-icon-button><mat-icon>close</mat-icon></button>
                              </div>
                          </div>
                         
                    </div>
                  
                </div>
                <div fxLayout="row" fxLayoutAlign="end start"> 
                    <a fxFlex="200px" mat-stroked-button [matMenuTriggerFor]="menu" *ngIf="isEditable(i)">{{getQuestionType(i).name}}<mat-icon>arrow_drop_down</mat-icon></a >
                        <mat-menu #menu="matMenu" >                            
                                    <mat-list >
                                        <mat-option *ngFor="let item of questionTypes" (click)="changeQuestionType(item, i)" tabindex="-1" [value]="item.value">{{item.name}}</mat-option>
                                    </mat-list>
                        </mat-menu>
                    <strong *ngIf="!isEditable(i)" class="error">This question is not editable</strong>
                </div>
                <span fxFlex="100%">
                    <a *ngIf="isEditable(i)"  (click)="addOption(i)"><strong><b>Add another</b></strong></a>
                </span>
                
                <nav  fxFlex="100%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                    <strong >Required</strong>

                        <mat-slide-toggle [disabled]="!isEditable(i)" formControlName="required">

                        </mat-slide-toggle>


                    
                    <button mat-icon-button [disabled]="!isEditable(i)" (click)="removeQuestion(i)"><mat-icon color="warn">delete</mat-icon></button>
                </nav>
            </section>
        </li>
    </ul>
       
    

    </form>
    <button (click)="addNewQuestion()" mat-stroked-button color="primary">Add New Question</button>
    <footer fxFlex="100%" class="mat-elevation-z1"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center center">
        <button  (click)="cancel()" mat-button color="primary"
        >Cancel</button>
      <button (click)="submitClick()" form="form" [disabled]="isWaiting"  mat-raised-button color="primary"
       >Save</button>
    </footer>
</main>