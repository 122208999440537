import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CallDTO, CallControllerService,
  ClinicControllerService, UpdateCallDTO,
  UpdateCallBackQuestionDTO, CallBackControllerService,
  CallBackDTO, CallFlagNoteDTO, CallBackQuestionDTO, ScribeActionDTO, CallBackNoteDTO, UserDTO, CallBackFollowUpNoteDTO, EndCallBackDTO, UpdateCallBackDTO
} from '@helplinesos-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../dialog.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription, TimeoutError } from 'rxjs';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ErrorService } from '../../shared/error.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-scribe-complete',
  templateUrl: './scribe-complete.component.html',
  styleUrls: ['./scribe-complete.component.scss']
})
export class ScribeCompleteComponent extends ComponentBase implements OnInit, OnDestroy {
  formGroup: FormGroup;
  role: any;
  isBulletinShown = false;
  callFlagDto: CallFlagNoteDTO;
  clinicId: number;
  isHelpfulInformationShown = true;
  callDto: CallDTO;
  questions: Map<number, UpdateCallBackQuestionDTO[]> = new Map();
  currentQuestionsState: UpdateCallBackQuestionDTO[] = [];
  currentCallbackState: CallBackDTO;
  currentCallState: CallDTO;
  isDirtyState = false;
  //notes: Map<number, any> = new Map();
  //followUpNotes: Map<number, any> = new Map();
  answeredQuestions: ScribeActionDTO[] = [];
  callBacksDto: Array<CallBackDTO>;
  callBackForDisplay: CallBackDTO;
  interval: any;
  type = 'N/A';
  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: CustomSnackbarService,
    private dialogService: DialogService,
    private callController: CallControllerService,
    private callBackController: CallBackControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private errorService: ErrorService,
  ) { super(); }

  scribeInfo: any[] = [
    {
      age: '',
      type: '',
      symptoms: '',
      underlyingDisease: '',
      contact: '',
      clinicPatient: '',
      zipcode: ''
    }
  ];

  displayedColumns: string[] = ['age', 'type', 'symptoms', 'underlyingDisease', 'contact', 'clinicPatient', 'zipcode'];

  roleCaption = new Map([
    [CallDTO.StatusEnum.NEWCALL, 'New Call'],
    [CallDTO.StatusEnum.INPROGRESS, 'In Progress'],
    [CallDTO.StatusEnum.COMPLETE, 'Complete'],
    [CallDTO.StatusEnum.INCOMPLETE, 'Incomplete'],
    [CallDTO.StatusEnum.FOLLOWUP, 'Follow up'],
    [CallDTO.CallTypeEnum.COMPLEX, 'Complex'],
    [CallDTO.CallTypeEnum.INFORMATION, 'Information'],
    [CallDTO.CallTypeEnum.SIMPLE, 'Simple'],
    [CallDTO.CallTypeEnum.TESTING, 'Testing'],
    [CallDTO.LanguageEnum.ENGLISH, 'English'],
    [CallDTO.LanguageEnum.SPANISH, 'Spanish'],
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
    [ScribeActionDTO.TypeEnum.RADIO, 'Radio'],
    [ScribeActionDTO.TypeEnum.CHECK, 'Check']
  ]);

  boolDropdown: any[] = [
    { value: true, display: 'Yes', state: true },
    { value: false, display: 'No', state: false },
  ];

  ngOnInit(): void {

    this.isWaiting = true;
    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== '') {
        this.isWaiting = false;
      }
    }));

    this.formGroup = this.formBuilder.group({
      age: ['', [Validators.required, Validators.min(0), Validators.max(120)]],
      zipCode: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });

    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));

    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    const callId = Number(this.route.snapshot.paramMap.get('callId'));
    this.callController.getCallUsingGET(callId).subscribe(response => {
      this.callDto = response;
      this.formGroup = this.formBuilder.group({
        age: [this.callDto?.age, [Validators.required, Validators.min(0), Validators.max(120)]],
        zipCode: [this.callDto?.zip, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(5)]]
      });
      this.type = this.roleCaption.get(this.callDto?.callType);
      this.getAllCallBacks();
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  initAnsweredQuestions() {
    this.answeredQuestions = [];
    for (const question of this.callBackForDisplay.questions) {
      if (question === undefined || question === null || question.answer === '') {
        continue;
      }
      const action = this.callBackForDisplay.scribeActions.find(x => x.id === question.scibeActionId);
      if (action === undefined || action === null) {
        continue;
      }
      this.answeredQuestions.push(
        {
          id: question?.scibeActionId,
          sequence: action?.sequence,
          type: action?.type,
          question: action?.question,
          required: action?.required,
          values: question?.answer
        }
      );
    }
  }

  initQuestions() {
    this.questions = new Map();
    for (const callback of this.callBacksDto) {
      this.questions.set(callback.id, []);
      for (const actions of callback.scribeActions) {
        this.questions.get(callback.id)?.push(
          {
            scibeActionId: actions.id,
            answer: ''
          }
        );
      }
      for (const question of callback.questions) {
        const questionForUpdate = this.getQuestion(callback.id, question.scibeActionId);
        if (questionForUpdate !== undefined && questionForUpdate != null) {
          questionForUpdate.answer = question.answer;
        }
      }

      // this.followUpNotes.set(callback.id , callback.followUpNotes);
      // this.notes.set(callback.id, callback.callBackNotes);
    }
  }

  checkIsDirtyState() {
    if (JSON.stringify(this.callDto).toLowerCase() !== JSON.stringify(this.currentCallState).toLowerCase()) {
      this.isDirtyState = true;
      return;
    }

    if (JSON.stringify(this.callBackForDisplay).toLowerCase() !== JSON.stringify(this.currentCallbackState).toLowerCase()) {
      this.isDirtyState = true;
      return;
    }

    const questions = this.questions.get(this.callBackForDisplay.id);

    if (JSON.stringify(questions).toLowerCase() !== JSON.stringify(this.currentQuestionsState).toLowerCase()) {
      this.isDirtyState = true;
      return;
    }
  }

  initCurrentState() {
    this.isDirtyState = false;
    this.currentCallState = Object.assign({}, this.callDto);
    this.currentCallbackState = Object.assign({}, this.callBackForDisplay);
    this.currentQuestionsState = [];

    for (const actions of this.callBackForDisplay.scribeActions) {
      this.currentQuestionsState.push(
        {
          scibeActionId: actions.id,
          answer: ''
        }
      );
    }
    for (const question of this.callBackForDisplay.questions) {
      const questionForUpdate = this.currentQuestionsState.find(x => x.scibeActionId === question.scibeActionId);
      if (questionForUpdate !== undefined && questionForUpdate != null) {
        questionForUpdate.answer = question.answer;
      }
    }
  }

  revertState() {
    const callBackQuestions = this.questions.get(this.callBackForDisplay.id);
    if (callBackQuestions !== undefined && callBackQuestions != null) {
      this.questions.set(this.callBackForDisplay.id, this.currentQuestionsState);
    }

    const callback = this.callBacksDto.find(x => x.id === this.callBackForDisplay.id);
    if (callback !== undefined && callback != null) {
      const index = this.callBacksDto.indexOf(callback);
      this.callBacksDto[index] = Object.assign({}, this.currentCallbackState);
    }
    this.callBackForDisplay = Object.assign({}, this.currentCallbackState);
    this.callDto = Object.assign({}, this.currentCallState);
  }

  getSelectedSingleValue(questionId: number) {
    const element = this.getQuestion(this.callBackForDisplay.id, questionId);
    return element.answer;
  }

  getAllCallBacks() {
    this.callBackController.getCallBacksUsingGET(this.callDto.id, 'response').subscribe(
      (response) => {
        this.callBacksDto = response.body;
        this.callBacksDto = this.callBacksDto?.sort(function (a, b) {
          return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
        });
        this.callBackForDisplay = this.callBacksDto[0];
        this.initCurrentState();
        this.initQuestions();
        this.initAnsweredQuestions();
        this.isWaiting = false;
      }
    );
  }
  // is dirty?
  changeCallBackForDisplay(calbackId: number) {
    this.checkIsDirtyState();
    if (this.isDirtyState) {
      const cancelRef = this.dialogService.openCancelDialog();
      cancelRef.afterClosed().subscribe((response) => {
        if (response === 'revert') {
          this.revertState();
          this.callBackForDisplay = this.callBacksDto.find(x => x.id === calbackId);
          this.initCurrentState();
          // this.initQuestions();
          this.initAnsweredQuestions();
        }
      });
    }
    else{
      this.revertState();
      this.callBackForDisplay = this.callBacksDto.find(x => x.id === calbackId);
      this.initCurrentState();
      // this.initQuestions();
      this.initAnsweredQuestions();
    }

  }

  cancel() {
    // this.revertState();
    this.checkIsDirtyState();
    if (this.isDirtyState) {
      const cancelRef = this.dialogService.openCancelDialog();
      cancelRef.afterClosed().subscribe((response) => {
        if (response === 'revert') {
          this.revertState();
        }
      });
    }

    
    // this.isWaiting = true;
    // this.interval = setInterval(() => {
    //   this.isWaiting = false;
    //   this.router.navigate(['call-queue/' + this.clinicId]);
    //    }, 1000);
  }
  goBack(){
    this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { status: CallDTO.StatusEnum.COMPLETE } });
  }

  getAnsweredQuestionValue(element: ScribeActionDTO) {
    let value = '';
    switch (element?.type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
        value = this.getAnsweredSingeQuestionValue(element.id);
        break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        value = this.getAnsweredMultiSelectQuestionValue(element.id);
        break;
      }
      default:
        break;
    }
    return value;
  }

  getAnsweredSingeQuestionValue(questionId: number) {
    const question = this.answeredQuestions?.find(x => x.id === questionId);
    if (question === undefined || question === null) {
      return '';
    }
    return question.values;
  }

  getAnsweredMultiSelectQuestionValue(questionId: number) {
    const question = this.answeredQuestions?.find(x => x.id === questionId);
    if (question === undefined || question === null) {
      return '';
    }
    const arrayOfValues = question.values.split(',');

    return arrayOfValues.join(', ');
  }

  showHelpfulInformation() {
    this.isHelpfulInformationShown = !this.isHelpfulInformationShown;
  }


  isTypeWarn(callType: CallDTO.CallTypeEnum) {
    let warn = '';
    if (callType === CallDTO.CallTypeEnum.COMPLEX || callType === CallDTO.CallTypeEnum.SIMPLE) {
      warn = 'textDanger';
    }
    return warn;
  }

  isWarn(element: boolean) {
    let warn = '';
    if (element) {
      warn = 'textDanger';
    }
    return warn;
  }

  isSelected(element: ScribeActionDTO, value: any) {
    let result = false;
    switch (element?.type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
        result = this.isSelectedSingleValue(element.id, value);
        break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        result = this.isSelectedMultipleValue(element.id, value);
        break;
      }
      default:
        break;
    }
    return result;
  }

  isSelectedSingleValue(questionId: number, value: any) {
    const element = this.getQuestion(this.callBackForDisplay.id, questionId);
    return element.answer === value;
  }

  isSelectedMultipleValue(questionId: number, value: any) {
    const element = this.getQuestion(this.callBackForDisplay.id, questionId);
    const arrayOfValues = element.answer.split(',') as string[];
    return arrayOfValues.find(x => x === value) !== undefined ? true : false;
  }

  boolDisplayFormat(value: any) {
    if (value === undefined || value === null) {
      return 'N/A';
    }
    return value === true ? 'Yes' : 'No';
  }


  updateCall(propertyName: string, newValue: any) {
    this.updateCallPropertyValue(propertyName, newValue);
    // const updateCall: UpdateCallDTO = this.getUpdateCallDto();
    // this.callController.updateCallUsingPUT(this.callDto.id, updateCall, 'response').subscribe(
    //   (response) => {
    //     this.callDto = response.body;
    //     this.type = this.roleCaption.get(this.callDto?.callType);
    //   }
    // );
  }

  updateCallback(element: ScribeActionDTO, newValue: any) {
    const questionForUpdate = this.getQuestion(this.callBackForDisplay.id, element.id);
    this.updateAnswer(questionForUpdate, element.type, newValue);
    // const updateCallBack = questionForUpdate as UpdateCallBackQuestionDTO;
    // // tslint:disable-next-line: max-line-length
    // this.callBackController.updateCallBackQuestionUsingPATCH(this.callBackForDisplay.id, this.callDto.id, updateCallBack, 'response').subscribe(
    //   (response) => {
    //     const test = response.body;
    //   }
    // );
  }

  updateCallbackNotes(notes: any) {

    // this.notes.set(this.callBackForDisplay.id, notes);
    if (notes === '') {
      notes = null;
    }
    this.callBackForDisplay.callBackNotes = notes;
    //this.callBacksDto.find(x => x.id === this.callBackForDisplay.id).callBackNotes = notes;
    // const noteDto = { notes } as CallBackNoteDTO;
    // this.callBackController.updateCallBackNotesUsingPATCH(this.callBackForDisplay.id, noteDto, this.callDto.id, 'response').subscribe(
    //   (response) => {
    //     const test = response.body;
    //   }
    // );
  }

  updateFollowUpNotes(notes: any) {
    if (notes === '') {
      notes = null;
    }
    // this.followUpNotes.set(this.callBackForDisplay.id, notes);
    //this.callBacksDto.find(x => x.id === this.callBackForDisplay.id).followUpNotes = notes;
    this.callBackForDisplay.followUpNotes = notes;
    // const noteDto = { followUpNotes: notes } as CallBackFollowUpNoteDTO;
    // this.callBackController.updateCallBackFollowUpNotesUsingPATCH
    // (noteDto, this.callBackForDisplay.id, this.callDto.id, 'response')
    //   .subscribe(
    //   (response) => {
    //   }
    // );
  }

  getReorderedScribeActions() {
    const scribeActions: ScribeActionDTO[] = [];
    const numberOfActions = this.callBackForDisplay?.scribeActions.length;
    if (numberOfActions % 2 === 0) {
      for (let i = 0; i < numberOfActions / 2; i++) {
        scribeActions.push(this.callBackForDisplay?.scribeActions[i]);
        scribeActions.push(this.callBackForDisplay?.scribeActions[i + (numberOfActions / 2)]);
      }
    }
    else {
      for (let i = 0; i < numberOfActions / 2; i++) {
        scribeActions.push(this.callBackForDisplay?.scribeActions[i]);
        scribeActions.push(this.callBackForDisplay?.scribeActions[i + ((numberOfActions + 1) / 2)]);
      }
    }
    return scribeActions;
  }

  getRequiredScribeActions(){
    const scribeActions: ScribeActionDTO[] = [];
    this.callBackForDisplay?.scribeActions.forEach(x => {
      if (x.required){
        scribeActions.push(x);
      }
    });
    return scribeActions;
  }

  getOptionalScribeActions(){
    const scribeActions: ScribeActionDTO[] = [];
    this.callBackForDisplay?.scribeActions.forEach(x => {
      if (!x.required){
        scribeActions.push(x);
      }
    });
    return scribeActions;
  }


  updateAnswer(element: any, type: ScribeActionDTO.TypeEnum, newValue: any) {
    if (element.answer === '') {
      element.answer = newValue;
      return;
    }
    switch (type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
        element.answer = newValue;
        break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        let arrayOfValues = element.answer.split(',') as string[];
        const currentValue = arrayOfValues.find(x => x === newValue);
        if (currentValue !== undefined && currentValue !== null) {
          arrayOfValues = arrayOfValues.filter(x => x !== currentValue);
        }
        else {
          arrayOfValues.push(newValue);
        }
        element.answer = arrayOfValues.join(',');
        break;
      }
      default:
        break;
    }
  }

  getQuestion(callbackId: number, questionId: number) {
    return this.questions.get(callbackId)?.find(x => x.scibeActionId === questionId);
  }


  getCallPhoneNumber(phoneNumber: string) {
    return phoneNumber ?
      '(' +
      phoneNumber.slice(0, 3) + ') ' +
      phoneNumber.slice(4, 7) +
      phoneNumber.slice(7) : 'Phone number not set';
  }

  // isPhoneNumberSet() {
  //   // const isSet = this.callDto?.clinicPhoneNumbers == null || this.callDto?.clinicPhoneNumbers.length === 0 ? false : true;
  //   // return isSet;
  //   return false;
  // }

  getPhoneNumber(element: any) {

    if (element === null) {
      return '(not set)';
    }
    const elementFormat =
      'Clinic ' +
      element.type +
      ' (' + element.phoneNumber.slice(0, 3) + ')' +
      element.phoneNumber.slice(3, 6) + '-' +
      element.phoneNumber.slice(6);

    return elementFormat;
  }

  getHotlineNumber() {
    return 'Clinic Hotline: (' + this.callDto?.clinicHotlineNumber.slice(0, 3) + ') ' +
      this.callDto?.clinicHotlineNumber.slice(3, 6) + '-' +
      this.callDto?.clinicHotlineNumber.slice(6);
  }

  openFlag() {
    this.callController.getCallFlagNotesUsingGET(this.callDto.id).subscribe(response => {
      this.callFlagDto = response[0];
      this.dialogService.openFlagCall(this.callFlagDto, this.callDto.id);
    });
  }


  updateCallPropertyValue(name: string, value: any) {
    switch (name) {
      case 'Age': {
        // tslint:disable-next-line: radix
        this.callDto.age = Number.parseInt(value);
        break;
      }
      case 'Symptoms': {
        this.callDto.hasSymptoms = value;
        break;
      }
      case 'UnderlyingDisease': {
        this.callDto.hasUnderlyingDisease = value;
        break;
      }
      case 'Contact': {
        this.callDto.hadContactWithInfected = value;
        break;
      }
      case 'ClinicPatient': {
        this.callDto.patient = value;
        break;
      }
      case 'Zipcode': {
        this.callDto.zip = value;
        break;
      }
      case 'Insurance': {
        this.callDto.hasInsurance = value;
        break;
      }
      default:
        break;
    }
  }

  getUpdateCallDto() {

    const updateCallDto: UpdateCallDTO = {
      age: this.callDto.age,
      hadContactWithInfected: this.callDto.hadContactWithInfected,
      hasSymptoms: this.callDto.hasSymptoms,
      hasTraveledToInfectedArea: this.callDto.hasTraveledToInfectedArea,
      hasUnderlyingDisease: this.callDto.hasUnderlyingDisease,
      language: this.callDto.language,
      patient: this.callDto.patient,
      phoneNumber: this.callDto.phoneNumber.replace(/[&\/\\()-]/g, ''),
      seekingTest: this.callDto.seekingTest,
      surveyFeedBack: this.callDto.surveyFeedBack,
      zip: this.callDto.zip,
      hasInsurance: this.callDto.hasInsurance,
      receivedTest: this.callDto.receivedTest,
      referredForTest: this.callDto.referredForTest
    };

    return updateCallDto;
  }

  unassignCall() {
    this.dialogService.openUnassignCall(this.callDto.id, this.clinicId);
  }

  save() {
    if (!this.formGroup.valid){
      this.snackBarService.openErrorNotification('In order to save, please fill all fields correctly');
      return;
    }
    this.isWaiting = true;

    const updateCall: UpdateCallDTO = this.getUpdateCallDto();
    this.callController.updateCallUsingPUT(this.callDto.id, updateCall, 'response').subscribe((response) => {

      const callbackForSave = this.callBackForDisplay;

      const updateCallback: UpdateCallBackDTO = {
        updateCallBackQuestionDTO: this.questions.get(callbackForSave.id).filter(x => x.answer !== '')
     };
      // tslint:disable-next-line: max-line-length
      this.callBackController.updateCallBackUsingPATCH(callbackForSave.id,this.callDto.id, updateCallback, 'response').subscribe((response) => {
        const noteDto = { notes: callbackForSave.callBackNotes } as CallBackNoteDTO;
        this.callBackController.updateCallBackNotesUsingPATCH(callbackForSave.id, noteDto, this.callDto.id, 'response').subscribe(
          (response1) => {
        this.snackBarService.openToastNotification('Scribe Updated');
        this.isDirtyState = false;
        this.callBackForDisplay.questions = this.questions.get(callbackForSave.id).filter(x => x.answer !== '');
        this.initCurrentState();
        this.isWaiting = false;
      }
      );
      });
      return true;
    });
  }

}


