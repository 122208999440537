import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable()
export class HistoryRoutingService {

    previousUrl = '';
    currentUrl = '';
    constructor(
        private router: Router
    ) {  this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
          this.previousUrl =  events[0].urlAfterRedirects;
          this.currentUrl = events[0].url;
        });
    }


    public getPreviousUrl(): string {
    const urlParts = this.previousUrl.split('/');
    if ( urlParts.length > 3){
        return urlParts[3];
    }
    else{
        return this.previousUrl.split('/').pop();
    }
    }

    public getCurrentUrl(): string {
        return this.currentUrl;
    }

}
