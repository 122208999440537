import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClinicControllerService, UserDTO } from '@helplinesos-api-client';
import { Router, ActivatedRoute } from '@angular/router';
import { BulletinDTO } from 'projects/helplinesos-api-client/src/model/bulletinDTO';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { ComponentBase } from '../../../shared/models/componentBase';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss']
})
export class BulletinComponent extends ComponentBase implements OnInit {
  role: any;
  formGroup: FormGroup;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  dto: BulletinDTO = {
    bulletin: ''
  };
  @Input() private clinicId: number;

  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
  ]);

  constructor(
    private snackBarService: CustomSnackbarService,
    private route: ActivatedRoute,
    private clinicControllerService: ClinicControllerService,
    private formBuilder: FormBuilder,

  ) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.formGroup = this.formBuilder.group({
      bulletin: ['', Validators.required]
    });
    this.loadData();
    this.onChanges();
  }

  onChanges(){
    this.formGroup.valueChanges.subscribe(res => {
      this.onFormGroupChange.emit(this.formGroup);
    });
  }
  updateBulletin() {
    if (!this.formGroup.valid){
      this.snackBarService.openErrorNotification('Reminders and updates field is required');
      return;
    }
    this.isWaiting = true;
    this.dto.bulletin = this.formGroup.controls.bulletin.value;
    this.dto.bulletin = this.dto.bulletin.split('<strong>').join('<b>');
    this.dto.bulletin = this.dto.bulletin.split('</strong>').join('</b>');
    this.dto.bulletin = this.dto.bulletin.replace(/(<(font[^>]+)>)/g, '');
    this.clinicControllerService.updateBulletinUsingPUT(this.dto, this.clinicId).subscribe(respone => {
      this.isWaiting = false;
      this.snackBarService.openToastNotification('Bulletin Updated');
    //  this.formGroup.reset();
      this.loadData();
    },
      err => {
        this.isWaiting = false;
      });
  }

  loadData() {
    this.clinicControllerService.getBulletinUsingGET(this.clinicId).subscribe(response => {
      this.dto = response;
      if (this.dto.bulletin) {
         // content = content.replace(/(<(font[^>]+)>)/g, '');
        this.dto.bulletin = this.dto.bulletin.replace(/(<(font[^>]+)>)/g, '');
        this.dto.bulletin = this.dto.bulletin.replace('<b>', '<strong>');
        this.dto.bulletin = this.dto.bulletin.replace('</b>', '</strong>');
        this.formGroup = this.formBuilder.group({
          bulletin: [this.dto?.bulletin, Validators.required]
        });
      }
      this.isWaiting = false;
    },
      err => {
        this.isWaiting = false;
      });
  }

  onPaste(e: any) {
    e.stopPropagation();
    e.preventDefault();
    const content = e.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, content);
  }

  cancel() {
    this.ngOnInit();
  }

}
