/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NotificationDTO { 
    counter?: number;
    createdBy?: string;
    createdDate?: Date;
    duration?: string;
    id?: number;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    message?: string;
    notificationType?: NotificationDTO.NotificationTypeEnum;
    resourceId?: number;
    systemNotificationType?: NotificationDTO.SystemNotificationTypeEnum;
}
export namespace NotificationDTO {
    export type NotificationTypeEnum = 'INFORMATION' | 'ALERT' | 'SUCCESS';
    export const NotificationTypeEnum = {
        INFORMATION: 'INFORMATION' as NotificationTypeEnum,
        ALERT: 'ALERT' as NotificationTypeEnum,
        SUCCESS: 'SUCCESS' as NotificationTypeEnum
    };
    export type SystemNotificationTypeEnum = 'NEW_CALL' | 'FOLLOWUP_CALL' | 'INCOMPLETE_CALL' | 'ENGLISH_CALL' | 'SPANISH_CALL' | 'FLAGGED_CALL' | 'ER_OUTCOME' | 'DEACTIVATED_CLINIC' | 'ACTIVATED_CLINIC' | 'NEW_CLINIC' | 'INCOMPLETE_CLINIC' | 'UPDATED_CLINIC' | 'CALL_VOLUME_SPIKE' | 'LONG_CALLBACK' | 'COMPLETE_CALL' | 'HOTLINE_NUMBER_CHANGE' | 'HELPFUL_INFORMATION_UPDATE' | 'BULLETIN_UPDATE' | 'SCRIBE_ACTION_UPDATE' | 'FAQ_UPDATE';
    export const SystemNotificationTypeEnum = {
        NEWCALL: 'NEW_CALL' as SystemNotificationTypeEnum,
        FOLLOWUPCALL: 'FOLLOWUP_CALL' as SystemNotificationTypeEnum,
        INCOMPLETECALL: 'INCOMPLETE_CALL' as SystemNotificationTypeEnum,
        ENGLISHCALL: 'ENGLISH_CALL' as SystemNotificationTypeEnum,
        SPANISHCALL: 'SPANISH_CALL' as SystemNotificationTypeEnum,
        FLAGGEDCALL: 'FLAGGED_CALL' as SystemNotificationTypeEnum,
        EROUTCOME: 'ER_OUTCOME' as SystemNotificationTypeEnum,
        DEACTIVATEDCLINIC: 'DEACTIVATED_CLINIC' as SystemNotificationTypeEnum,
        ACTIVATEDCLINIC: 'ACTIVATED_CLINIC' as SystemNotificationTypeEnum,
        NEWCLINIC: 'NEW_CLINIC' as SystemNotificationTypeEnum,
        INCOMPLETECLINIC: 'INCOMPLETE_CLINIC' as SystemNotificationTypeEnum,
        UPDATEDCLINIC: 'UPDATED_CLINIC' as SystemNotificationTypeEnum,
        CALLVOLUMESPIKE: 'CALL_VOLUME_SPIKE' as SystemNotificationTypeEnum,
        LONGCALLBACK: 'LONG_CALLBACK' as SystemNotificationTypeEnum,
        COMPLETECALL: 'COMPLETE_CALL' as SystemNotificationTypeEnum,
        HOTLINENUMBERCHANGE: 'HOTLINE_NUMBER_CHANGE' as SystemNotificationTypeEnum,
        HELPFULINFORMATIONUPDATE: 'HELPFUL_INFORMATION_UPDATE' as SystemNotificationTypeEnum,
        BULLETINUPDATE: 'BULLETIN_UPDATE' as SystemNotificationTypeEnum,
        SCRIBEACTIONUPDATE: 'SCRIBE_ACTION_UPDATE' as SystemNotificationTypeEnum,
        FAQUPDATE: 'FAQ_UPDATE' as SystemNotificationTypeEnum
    };
}
