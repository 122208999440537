<main fxLayout="column" fxLayoutAlign="start stretch">
  <mat-toolbar class="mat-elevation-z4">
    <button *ngIf="!registration" mat-icon-button (click)="cancel()">
      <mat-icon >arrow_back</mat-icon>
    </button>
    <h1>Team</h1>
  </mat-toolbar>
  <div><mat-progress-bar mode="indeterminate" *ngIf="isWaiting"></mat-progress-bar></div>

  <div class="error" *ngIf="errorMessage">
    <strong>{{ errorMessage }}</strong>
  </div>

  <mat-horizontal-stepper linear [selectedIndex]="1" *ngIf="registration">
    <mat-step [completed]="true" [editable]="false">
      <ng-template matStepLabel>Fill out clinic information</ng-template>
    </mat-step>
    <mat-step optional>
      <ng-template matStepLabel>Invite team members</ng-template>
    </mat-step>
  </mat-horizontal-stepper>

  <section class="mat-elevation-z1">
    <form 
      id="form"
      fxLayout="column stretch"
      [formGroup]="inviteFormGroup"
    >
      <header>
        <h2>{{ registration ? 'Would you like to start inviting team members to your team?' : 'Invite new members to your team.' }}</h2>
        <h4>They will receive an email inviting them to {{ dto?.name }} after you save.</h4>
      </header>

      <mat-list formArrayName="invitations">
        <mat-list-item *ngFor="let item of invitationForms.controls; let i = index" [formGroupName]="i">
            <!-- <mat-form-field fxFlex="1 0 312px">
              <mat-label>Name - optional</mat-label>
              <input matInput formControlName="name" maxlength="50" />
            </mat-form-field>
            <mat-form-field fxFlex="1 0 242px">
              <mat-label>Phone Number - optional</mat-label>
              <input [textMask]="mask" matInput formControlName="phoneNumber" maxlength="50"/>
            </mat-form-field> -->
            <mat-form-field fxFlex="1 0 312px">
              <mat-label>Email(i.e. john@example.com)</mat-label>
              <input matInput formControlName="email" maxlength="100" />
              <mat-error *ngIf="invitationForms.get(getIndex(i)).get('email').hasError('pattern')">Email is not valid
              </mat-error>
              <mat-error *ngIf="invitationForms.get(getIndex(i)).get('email').hasError('required')">Email is required
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="1 0 242px">
              <mat-label>Role</mat-label>
              <mat-select disableOptionCentering panelClass="position-of-content" formControlName="role">
                <mat-option *ngFor="let role of roles" [value]="role.value">
                  {{ role.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="invitationForms.get(getIndex(i)).get('role').hasError('required')">Role is required
              </mat-error>
            </mat-form-field>
            <div fxFlex="100%">
              <button *ngIf="hasMultipleInvites" mat-icon-button (click)="removeMember(i)" color="primary"><mat-icon>delete</mat-icon></button>
            </div>
        </mat-list-item>
      </mat-list>

      <div>
        <h4><a (click)="addNewMember()">Add another member</a></h4>
      </div>

      <mat-form-field>
        <mat-label>Notes-optional</mat-label>
        <textarea  matInput formControlName="notes" maxlength="2000"></textarea>
      </mat-form-field>
    </form>
  </section>

  <nav class="mat-elevation-z1">
    <button mat-button color="primary" (click)="cancel()">{{ registration ? 'Do this later' : 'Cancel' }}</button>
    <button form="form" type="submit" mat-raised-button [disabled]="isWaiting" color="primary" (click)="save()">Send Invite</button>
  </nav>
</main>
