<main fxLayout="row wrap" fxLayoutAlign="center start">
    <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="end start">
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </nav>
    <header fxLayout="column" fxLayoutAlign="center center">
        <h2 >
            Account Settings
        </h2>
        <strong>Your current email address is {{userDto?.email}}. If you wish to change it, do so below.</strong>
    </header>
  
  
  
    <form [formGroup]="userFormGroup" fxFlex="100%" id="infoForm" fxLayout="column" fxLayoutAlign="start center">
  
        <mat-form-field >
            <mat-label>Email Address (example@email.com)</mat-label>
            <input formControlName="email" matInput>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Confirm email address</mat-label>
            <input  matInput>
          </mat-form-field>

    </form>
  
    <footer fxFlex="60%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center start">
        <button fxFlex  mat-button color="primary"
        >Cancel</button>
      <button fxFlex form="infoForm" mat-raised-button color="primary"
       >Submit</button>

    </footer>
  </main>