<nav>
    <mat-icon [mat-dialog-close]="true">close</mat-icon>
  </nav>
  
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="100%" fxFlex.xs="90%" fxFlex.gt-xs="80%" fxFlex.gt-sm="80%">
  
      <header>
        <h2>
          Are you sure you want to deactivate this clinic?
        </h2>
      </header>
      
      <mat-dialog-actions >
        <button  mat-button tabIndex="-1" color="primary" [mat-dialog-close]="true">Cancel</button> <!--tabIndex we set to avoid auto selected button after opening the component -->
        <!-- <button  mat-raised-button color="primary" (click)="deactivateClinic()">Deactivate</button> -->
        <button mat-button (click)="deactivateClinic()" color="warn">Deactivate Clinic</button>
      </mat-dialog-actions>
  
    </div>
  </div> 
 