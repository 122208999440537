import { Component, OnInit } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  ActivatedRoute,
  RoutesRecognized
} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { UserDTO, MemberControllerService, TaggedTeamMemberDTO, PageUserDTO } from '@helplinesos-api-client';
import { MatDialog } from '@angular/material/dialog';
import { CancelDialogComponent } from '../shared/cancel-dialog/cancel-dialog.component';
import { SurveyFeedbackComponent } from './survey-feedback/survey-feedback.component';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  interval: any;
  members: UserDTO[];
  isExtended = true;
  role: any;
  isProgressBarShown = true;
  clinicId = '';
  clinicInformationLink: string;
  callQueueLink: string;
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
  ]);
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private memberControllerService: MemberControllerService) {
  }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    switch (this.role) {
      case UserDTO.RoleEnum.CAPTAIN:
      case UserDTO.RoleEnum.COORDINATOR: {
       // this.isExtended = false;
        this.clinicId = JSON.parse(localStorage.getItem('user'))?.clinicId;
        this.memberControllerService.getTeamMembersUsingGET(Number(this.clinicId), '', '0', '20', 'name', 'INCALL,ONLINE,IDLE,OFFLINE')
        .subscribe(res => {
          this.members = res?.content;
        });
        this.interval = setInterval(() => {
          this.memberControllerService.getTeamMembersUsingGET(Number(this.clinicId), '', '0', '20', 'name', 'INCALL,ONLINE,IDLE,OFFLINE')
          .subscribe(res => {
            this.members = res?.content;
          });
        }, 5000);
        break;
      }
      case UserDTO.RoleEnum.ADMIN: {
        this.router.events
          .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
          .subscribe((events: RoutesRecognized[]) => {
            if (events[1].url.split('/')[2] !== 'add') {
              this.clinicId = events[1].url.split('/')[2];
              this.clinicInformationLink = '/clinics/' + this.clinicId + '/information';
            }
            else {
              this.clinicId = '';
            }
          });
        break;
      }
      case UserDTO.RoleEnum.VOLUNTEER: {
      //  this.isExtended = false;
        this.clinicId = JSON.parse(localStorage.getItem('user'))?.clinicId;
        this.memberControllerService.getTeamMembersUsingGET(Number(this.clinicId), '', '0', '20', 'name', 'INCALL,ONLINE,IDLE,OFFLINE')
        .subscribe(res => {
          this.members = res?.content;
        });
        this.interval = setInterval(() => {
          this.memberControllerService.getTeamMembersUsingGET(Number(this.clinicId), '', '0', '20', 'name', 'INCALL,ONLINE,IDLE,OFFLINE')
          .subscribe(res => {
            this.members = res?.content;
          });
        }, 5000);
        break;
      }
      default:
        break;
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(){
    clearInterval(this.interval);
  }
  
  homeRedirect() {
    if (this.role === 'ROLE_ADMIN') {
      this.router.navigate(['/clinics']);
    }
    else {
      this.router.navigate(['/call-queue/' + this.clinicId]);
    }
  }


  getStatusClass(status: UserDTO.OnlineStatusEnum){
    let className = '';
    switch (status) {
     case UserDTO.OnlineStatusEnum.INCALL: {
       className = 'status-online';
       break;
     }
     case UserDTO.OnlineStatusEnum.ONLINE: {
       className = 'status-online';
       break;
     }
     case UserDTO.OnlineStatusEnum.IDLE: {
       className = 'status-idle';
       break;
     }
     case UserDTO.OnlineStatusEnum.DEACTIVATED: {
       className = 'status-deactivated';
       break;
     }
     case UserDTO.OnlineStatusEnum.OFFLINE: {
       className = 'status-offline';
       break;
     }
     case UserDTO.OnlineStatusEnum.PENDING: {
       className = 'status-pending';
       break;
     }
     default:
       break;
   }
    return className;
 }

  isActive(): boolean {
    if (this.router.url.split('/')[3] === 'call-queue') {
      return true;
    }
    else {
      return false;
    }

  }

  open() {
    const dialogRef = this.dialog.open(SurveyFeedbackComponent, {
      maxWidth: '500px',
      backdropClass: 'dialog-backdrop'
    });
    return dialogRef;
  }

  collapseSideNav() {
    this.isExtended = !this.isExtended;
  }
}
