/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MemberInviteDTO } from '../model/memberInviteDTO';
import { PageUserDTO } from '../model/pageUserDTO';
import { TaggedTeamMemberDTO } from '../model/taggedTeamMemberDTO';
import { UpdateUserRoleDTO } from '../model/updateUserRoleDTO';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MemberControllerService {

    protected basePath = 'https://api-dev.helplinesos.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * DeActivate a Team Member
     * DeActivate&#39;s a Team Member
     * @param clinicId clinicId
     * @param memberId memberId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivateUsingPOST(clinicId: number, memberId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deactivateUsingPOST(clinicId: number, memberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deactivateUsingPOST(clinicId: number, memberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deactivateUsingPOST(clinicId: number, memberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling deactivateUsingPOST.');
        }

        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling deactivateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/members/${encodeURIComponent(String(clinicId))}/deactivate/${encodeURIComponent(String(memberId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Team Member
     * Get Team Member
     * @param teamMemberId teamMemberId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeamMemberUsingGET(teamMemberId: number, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public getTeamMemberUsingGET(teamMemberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public getTeamMemberUsingGET(teamMemberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public getTeamMemberUsingGET(teamMemberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (teamMemberId === null || teamMemberId === undefined) {
            throw new Error('Required parameter teamMemberId was null or undefined when calling getTeamMemberUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDTO>(`${this.basePath}/api/members/${encodeURIComponent(String(teamMemberId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Member(s)
     * Returns All Member(s) of a Clinic
     * @param clinicId clinicId
     * @param filter filter
     * @param page page
     * @param pageSize pageSize
     * @param sortBy sortBy
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeamMembersUsingGET(clinicId: number, filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<PageUserDTO>;
    public getTeamMembersUsingGET(clinicId: number, filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserDTO>>;
    public getTeamMembersUsingGET(clinicId: number, filter: string, page: string, pageSize: string, sortBy: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserDTO>>;
    public getTeamMembersUsingGET(clinicId: number, filter: string, page: string, pageSize: string, sortBy: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getTeamMembersUsingGET.');
        }

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getTeamMembersUsingGET.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getTeamMembersUsingGET.');
        }

        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling getTeamMembersUsingGET.');
        }

        if (sortBy === null || sortBy === undefined) {
            throw new Error('Required parameter sortBy was null or undefined when calling getTeamMembersUsingGET.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getTeamMembersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', <any>sortBy);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageUserDTO>(`${this.basePath}/api/members/clinic/${encodeURIComponent(String(clinicId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Team Member Names (s) for a Clinic
     * Returns Team Member Names (s) for a Clinic
     * @param clinicId clinicId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeamMembersUsingGET1(clinicId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TaggedTeamMemberDTO>>;
    public getTeamMembersUsingGET1(clinicId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaggedTeamMemberDTO>>>;
    public getTeamMembersUsingGET1(clinicId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaggedTeamMemberDTO>>>;
    public getTeamMembersUsingGET1(clinicId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling getTeamMembersUsingGET1.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TaggedTeamMemberDTO>>(`${this.basePath}/api/members/names/${encodeURIComponent(String(clinicId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initiate Surge (CAPTAIN ONLY)
     * Initiate Surge (ADMIN ONLY)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateSurgeUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public initiateSurgeUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public initiateSurgeUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public initiateSurgeUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/members/surge/initiate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invite a Clinic Member
     * Invite a Clinic Member
     * @param clinicId clinicId
     * @param memberInviteDTO memberInviteDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteMemberUsingPOST(clinicId: number, memberInviteDTO: MemberInviteDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inviteMemberUsingPOST(clinicId: number, memberInviteDTO: MemberInviteDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inviteMemberUsingPOST(clinicId: number, memberInviteDTO: MemberInviteDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inviteMemberUsingPOST(clinicId: number, memberInviteDTO: MemberInviteDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling inviteMemberUsingPOST.');
        }

        if (memberInviteDTO === null || memberInviteDTO === undefined) {
            throw new Error('Required parameter memberInviteDTO was null or undefined when calling inviteMemberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/members/${encodeURIComponent(String(clinicId))}/invite`,
            memberInviteDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Terminate Surge (CAPTAIN ONLY)
     * Terminate Surge (ADMIN ONLY)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public iterminateSurgeUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public iterminateSurgeUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public iterminateSurgeUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public iterminateSurgeUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/members/surge/terminate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ReActivate a DeActivated Team Member
     * ReActivate&#39;s a DeActivated Team Member
     * @param clinicId clinicId
     * @param memberId memberId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reactivateUsingPOST(clinicId: number, memberId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reactivateUsingPOST(clinicId: number, memberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reactivateUsingPOST(clinicId: number, memberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reactivateUsingPOST(clinicId: number, memberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling reactivateUsingPOST.');
        }

        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling reactivateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/members/${encodeURIComponent(String(clinicId))}/reactivate/${encodeURIComponent(String(memberId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ReSend Invite of Clinic Team Member
     * ReSend&#39;s Invite
     * @param clinicId clinicId
     * @param memberEmail memberEmail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendInviteUsingPOST(clinicId: number, memberEmail: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resendInviteUsingPOST(clinicId: number, memberEmail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resendInviteUsingPOST(clinicId: number, memberEmail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resendInviteUsingPOST(clinicId: number, memberEmail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clinicId === null || clinicId === undefined) {
            throw new Error('Required parameter clinicId was null or undefined when calling resendInviteUsingPOST.');
        }

        if (memberEmail === null || memberEmail === undefined) {
            throw new Error('Required parameter memberEmail was null or undefined when calling resendInviteUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/members/${encodeURIComponent(String(clinicId))}/resend-invite/${encodeURIComponent(String(memberEmail))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Team Member Role
     * Update&#39;s Team Member&#39;s Role
     * @param memberId memberId
     * @param updateUserRoleDTO updateUserRoleDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMemberRoleUsingPUT(memberId: number, updateUserRoleDTO: UpdateUserRoleDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMemberRoleUsingPUT(memberId: number, updateUserRoleDTO: UpdateUserRoleDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMemberRoleUsingPUT(memberId: number, updateUserRoleDTO: UpdateUserRoleDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMemberRoleUsingPUT(memberId: number, updateUserRoleDTO: UpdateUserRoleDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling updateMemberRoleUsingPUT.');
        }

        if (updateUserRoleDTO === null || updateUserRoleDTO === undefined) {
            throw new Error('Required parameter updateUserRoleDTO was null or undefined when calling updateMemberRoleUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/members/${encodeURIComponent(String(memberId))}/role`,
            updateUserRoleDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
