
<main fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="32px">
    <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <h1>Profile</h1>
        <div class="error" *ngIf="errorMessage"><strong>{{errorMessage}}</strong></div>
        <div>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
        </div>

    </header>
    <div fxFlex="100%" class="progress-bar-grid">
        <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    </div>
    <form autocomplete="off" [formGroup]="formGroup" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="space-between start">
        <section fxLayout="column" fxFlex="30%" fxLayoutGap="8px">
            <h2>
                Personal Information
            </h2>
            <mat-form-field fxFlex>
                <mat-label>First Name</mat-label>
                <input class="profile-first-name" matInput formControlName="firstName" maxlength="50">
                <mat-error *ngIf="formGroup.controls.firstName.errors?.required">First name is required</mat-error>
                <mat-error *ngIf="formGroup.controls.firstName.errors?.maxlength">First name is too long</mat-error>
                <mat-error *ngIf="formGroup.controls.firstName.errors?.pattern">First name should consist only of letters</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <mat-label>Last Name</mat-label>
                <input class="profile-last-name" matInput formControlName="lastName" maxlength="50">
                <mat-error *ngIf="formGroup.controls.lastName.errors?.required">Last name is required</mat-error>
                <mat-error *ngIf="formGroup.controls.lastName.errors?.maxlength">Last name is too long</mat-error>
                <mat-error *ngIf="formGroup.controls.lastName.errors?.pattern">Last name should consist only of letters</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Phone Number</mat-label>
                <input class="profile-phone-number" matInput [textMask]="mask" formControlName="phoneNumber">
                <mat-error *ngIf="formGroup.controls.phoneNumber.errors?.pattern">Phone number should have 10 characters</mat-error>
                <mat-error *ngIf="formGroup.controls.phoneNumber.errors?.required">Phone number is required</mat-error>
              </mat-form-field>
            <h2>
                Account Settings
            </h2>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input class="profile-email" maxlength="100" matInput formControlName="email"  placeholder="">
                <mat-error *ngIf="formGroup.controls['email'].hasError('email')">Email form is not valid</mat-error>
                <mat-error *ngIf="formGroup.controls['email'].hasError('required')">Email is required</mat-error>
              </mat-form-field>

              <div class="password-section" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="start start" fxLayoutGap="16px">
                  <strong>Password</strong>                
                  <strong *ngIf="!isPasswordSectionShown"><a (click)="isPasswordSectionShown = !isPasswordSectionShown"><b>Change</b></a></strong>
                  <strong *ngIf="isPasswordSectionShown"><a (click)="resetPasswordSection()"><b>Cancel</b></a></strong>
                  <p *ngIf="!isPasswordSectionShown" fxFlex="100%"> ********</p>
              </div>
              <div *ngIf="isPasswordSectionShown"  fxLayout="column">
                <mat-form-field >
                    <mat-label>Current Password</mat-label>
                    <input autocomplete="new-password" class="profile-old-password" [type]="toggle.type" matInput [formControl]="formGroup.controls.oldPassword" maxlength="20" > 
                    <mat-error *ngIf="formGroup.controls.oldPassword.errors?.required">Password is required</mat-error>
                    <mat-error *ngIf="formGroup.controls.oldPassword.errors?.pattern">Password complexity criteria not met</mat-error>
                  </mat-form-field>
    
                  <mat-form-field >
                    <mat-label>New Password (Use at least 8 characters)</mat-label>
                    <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                    <input class="profile-new-password" [type]="toggle.type" matInput [formControl]="formGroup.controls.newPassword" maxlength="20"  (focus)="onPasswordControlFocus()"> 
                    <mat-error *ngIf="formGroup.controls.newPassword.errors?.required">Password is required</mat-error>
                    <mat-error *ngIf="formGroup.controls.newPassword.errors?.pattern">Password complexity criteria not met</mat-error>
                  </mat-form-field>
                
                  <mat-password-strength 
                    #passwordComponent 
                    [hidden]="(!isPasswordControlFocused)"
                    [password]="formGroup.value.newPassword">
                  </mat-password-strength>
                  <mat-password-strength-info 
                    [hidden]="(!isPasswordControlFocused)"
                    [passwordComponent]="passwordComponent"
                    lowerCaseCriteriaMsg="at least 1 lower case"
                    upperCaseCriteriaMsg="at least 1 upper case"
                    digitsCriteriaMsg="at least 1 digit"
                    specialCharsCriteriaMsg="at least 1 special character"
                    minCharsCriteriaMsg="at least 8 characters"
                  ></mat-password-strength-info>
      
                  <mat-form-field >
                    <mat-label>Confirm Password</mat-label>
                    <input class="profile-confirm-password" [type]="toggle.type" matInput [formControl]="formGroup.controls.confirmPassword" maxlength="20">
                    <mat-error *ngIf="!formGroup.controls.confirmPassword.errors?.isMatching">Password and confirmed password do not match</mat-error>
                    <!-- <mat-error *ngIf="formGroup.controls.confirmPassword.errors?.required">Confirm password field is required</mat-error> -->
                  </mat-form-field>
              </div>
            

              <ng-moment-timezone-picker class="profile-time-zone" *ngIf="timezone !== ''" (onselect)="timezoneChange($event)"
              [customPlaceholderText]="this.timezoneText" [(setZone)]='timezone'>
             </ng-moment-timezone-picker>

        </section>
        <section *ngIf="roleCaption.get(this.role) === 'Captain'" fxLayout="column" fxFlex="50%" fxLayoutGap="28px">
            <h2 fxLayoutAlign="space-between end">
               Notifications 
               <div fxLayoutGap="8px" >
                <strong><b>Do not disturb</b></strong>  <mat-slide-toggle 
                [checked]="userDto?.enableDoNotDisturb"
                (change)="userDto.enableDoNotDisturb = !userDto.enableDoNotDisturb"
                > </mat-slide-toggle>
               </div> 
            </h2>
          <!-- <strong><b>Which calls will you be taking?</b></strong>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>English</strong>
                <mat-checkbox 
                [checked]="userDto?.enableEnglishCallPopupNotification"
                (change)="userDto.enableEnglishCallPopupNotification = !userDto.enableEnglishCallPopupNotification"
                ></mat-checkbox>    
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>Spanish</strong>
                <mat-checkbox 
                [checked]="userDto?.enableSpanishCallPopupNotification"
                (change)="userDto.enableSpanishCallPopupNotification = !userDto.enableSpanishCallPopupNotification"
                ></mat-checkbox>   
            </div>
            <strong><b>Categories</b></strong>
            <div fxLayout="row" fxLayoutAlign="end start">
               
                <div fxLayoutGap="50px">
                <strong>Popup</strong>
                 <strong>Sound</strong> 
                </div>        
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Call:</strong>
                <div fxLayoutGap="75px">
                <mat-checkbox  
                [checked]="userDto?.enableNewCallPopupNotification"
                (change)="userDto.enableNewCallPopupNotification = !userDto.enableNewCallPopupNotification"></mat-checkbox>
                 <mat-checkbox  
                [checked]="userDto?.enableNewCallSoundNotification"
                (change)="userDto.enableNewCallSoundNotification = !userDto.enableNewCallSoundNotification"></mat-checkbox> 
                </div>        
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Follow Up:</strong>
                <div fxLayoutGap="75px">
                <mat-checkbox 
                [checked]="userDto?.enableFollowUpCallPopupNotification"
                (change)="userDto.enableFollowUpCallPopupNotification = !userDto.enableFollowUpCallPopupNotification"
                ></mat-checkbox>
                <mat-checkbox 
                [checked]="userDto?.enableFollowUpCallSoundNotification"
                (change)="userDto.enableFollowUpCallSoundNotification = !userDto.enableFollowUpCallSoundNotification"></mat-checkbox>
                </div>             
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Incomplete Call:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableIncompleteCallPopupNotification"
                    (change)="userDto.enableIncompleteCallPopupNotification = !userDto.enableIncompleteCallPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableIncompleteCallSoundNotification"
                    (change)="userDto.enableIncompleteCallSoundNotification = !userDto.enableIncompleteCallSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>Update in Resource Board:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableResourceBoardPopupNotification"
                    (change)="userDto.enableResourceBoardPopupNotification = !userDto.enableResourceBoardPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableResourceBoardSoundNotification"
                    (change)="userDto.enableResourceBoardSoundNotification = !userDto.enableResourceBoardSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>ER Outcome:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableErOutcomePopupNotification"
                    (change)="userDto.enableErOutcomePopupNotification = !userDto.enableErOutcomePopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableErOutcomeSoundNotification"
                    (change)="userDto.enableErOutcomeSoundNotification = !userDto.enableErOutcomeSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>English Speaking Calls:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableEnglishCallPopupNotification"
                    (change)="userDto.enableEnglishCallPopupNotification = !userDto.enableEnglishCallPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableEnglishCallSoundNotification"
                    (change)="userDto.enableEnglishCallSoundNotification = !userDto.enableEnglishCallSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>Spanish Speaking Calls:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableSpanishCallPopupNotification"
                    (change)="userDto.enableSpanishCallPopupNotification = !userDto.enableSpanishCallPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableSpanishCallSoundNotification"
                    (change)="userDto.enableSpanishCallSoundNotification = !userDto.enableSpanishCallSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
        --> </section>
        <section *ngIf="roleCaption.get(this.role) === 'Volunteer'" fxLayout="column" fxFlex="50%" fxLayoutGap="28px">
            <h2 fxLayoutAlign="space-between end">
               Notifications 
               <div fxLayoutGap="8px" >
                <strong><b>Do not disturb</b></strong> <mat-slide-toggle 
                [checked]="userDto?.enableDoNotDisturb"
                (change)="userDto.enableDoNotDisturb = !userDto.enableDoNotDisturb"
                > </mat-slide-toggle>
               </div> 
            </h2>
          <!--  <strong><b>Which calls will you be taking?</b></strong>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>English</strong>
                <mat-checkbox 
                [checked]="userDto?.enableEnglishCallPopupNotification"
                (change)="userDto.enableEnglishCallPopupNotification = !userDto.enableEnglishCallPopupNotification"
                ></mat-checkbox>    
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>Spanish</strong>
                <mat-checkbox 
                [checked]="userDto?.enableSpanishCallPopupNotification"
                (change)="userDto.enableSpanishCallPopupNotification = !userDto.enableSpanishCallPopupNotification"
                ></mat-checkbox>   
            </div>    
           
            <strong><b>Categories</b></strong>
            <div fxLayout="row" fxLayoutAlign="end start">
               
                <div fxLayoutGap="50px">
                <strong>Popup</strong>
                <strong>Sound</strong>
                </div>        
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Call:</strong>
                <div fxLayoutGap="75px">
                <mat-checkbox  
                [checked]="userDto?.enableNewCallPopupNotification"
                (change)="userDto.enableNewCallPopupNotification = !userDto.enableNewCallPopupNotification"></mat-checkbox>
                <mat-checkbox  
                [checked]="userDto?.enableNewCallSoundNotification"
                (change)="userDto.enableNewCallSoundNotification = !userDto.enableNewCallSoundNotification"></mat-checkbox>
                </div>        
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Follow Up:</strong>
                <div fxLayoutGap="75px">
                <mat-checkbox 
                [checked]="userDto?.enableFollowUpCallPopupNotification"
                (change)="userDto.enableFollowUpCallPopupNotification = !userDto.enableFollowUpCallPopupNotification"
                ></mat-checkbox>
                <mat-checkbox 
                [checked]="userDto?.enableFollowUpCallSoundNotification"
                (change)="userDto.enableFollowUpCallSoundNotification = !userDto.enableFollowUpCallSoundNotification"></mat-checkbox>
                </div>             
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>New Incomplete Call:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableIncompleteCallPopupNotification"
                    (change)="userDto.enableIncompleteCallPopupNotification = !userDto.enableIncompleteCallPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableIncompleteCallSoundNotification"
                    (change)="userDto.enableIncompleteCallSoundNotification = !userDto.enableIncompleteCallSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <strong>Update in Resource Board:</strong>
                <div fxLayoutGap="75px">
                    <mat-checkbox 
                    [checked]="userDto?.enableResourceBoardPopupNotification"
                    (change)="userDto.enableResourceBoardPopupNotification = !userDto.enableResourceBoardPopupNotification"
                    ></mat-checkbox>
                    <mat-checkbox 
                    [checked]="userDto?.enableResourceBoardSoundNotification"
                    (change)="userDto.enableResourceBoardSoundNotification = !userDto.enableResourceBoardSoundNotification"
                    ></mat-checkbox>
                </div>           
            </div>
        --> </section>
    <section *ngIf="roleCaption.get(this.role) === 'Admin'" fxLayout="column" fxFlex="50%" fxLayoutGap="28px">
        <h2 fxLayoutAlign="space-between end">
           Notifications 
           <div fxLayoutGap="8px" >
            <strong><b>Do not disturb</b></strong> <mat-slide-toggle 
            [checked]="userDto?.enableDoNotDisturb"
            (change)="userDto.enableDoNotDisturb = !userDto.enableDoNotDisturb"
            > </mat-slide-toggle>
           </div> 
        </h2>
      <!--  <strong><b>Categories</b></strong>
        <div fxLayout="row" fxLayoutAlign="end start">
           
            <div fxLayoutGap="50px">
            <strong>Popup</strong>
            <strong>Sound</strong>
            </div>        
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <strong>New Clinic Added:</strong>
            <div fxLayoutGap="75px">
            <mat-checkbox [checked]="userDto?.enableNewClinicPopupNotification"
            (change)="userDto.enableNewClinicPopupNotification = !userDto.enableNewClinicPopupNotification"></mat-checkbox>
            <mat-checkbox [checked]="userDto?.enableNewClinicSoundNotification"
            (change)="userDto.enableNewClinicSoundNotification = !userDto.enableNewClinicSoundNotification"></mat-checkbox>
            </div>        
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <strong>Deactivation/Activation of Clinic:</strong>
            <div fxLayoutGap="75px">
            <mat-checkbox [checked]="userDto?.enableDeActivateClinicPopupNotification"
            (change)="userDto.enableDeActivateClinicPopupNotification = !userDto.enableDeActivateClinicPopupNotification"></mat-checkbox>
            <mat-checkbox [checked]="userDto?.enableDeActivateClinicSoundNotification"
            (change)="userDto.enableDeActivateClinicSoundNotification = !userDto.enableDeActivateClinicSoundNotification"></mat-checkbox>
            </div>             
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <strong>Update to Clinic Information:</strong>
            <div fxLayoutGap="75px">
            <mat-checkbox [checked]="userDto?.enableUpdatedClinicPopupNotification"
            (change)="userDto.enableUpdatedClinicPopupNotification = !userDto.enableUpdatedClinicPopupNotification"></mat-checkbox>
            <mat-checkbox [checked]="userDto?.enableUpdatedClinicSoundNotification"
            (change)="userDto.enableUpdatedClinicSoundNotification = !userDto.enableUpdatedClinicSoundNotification"></mat-checkbox>
            </div>           
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <strong>Incomplete Clinic:</strong>
            <div fxLayoutGap="75px">
            <mat-checkbox [checked]="userDto?.enableIncompleteClinicPopupNotification"
            (change)="userDto.enableIncompleteClinicPopupNotification = !userDto.enableIncompleteClinicPopupNotification"></mat-checkbox>
            <mat-checkbox [checked]="userDto?.enableIncompleteClinicSoundNotification"
            (change)="userDto.enableIncompleteClinicSoundNotification = !userDto.enableIncompleteClinicSoundNotification"></mat-checkbox>
            </div>           
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <strong>Sustained change in call volume:</strong>
            <div fxLayoutGap="75px">
            <mat-checkbox [checked]="userDto?.enableCallVolumePopupNotification"
            (change)="userDto.enableCallVolumePopupNotification = !userDto.enableCallVolumePopupNotification"></mat-checkbox>
            <mat-checkbox [checked]="userDto?.enableCallVolumeSoundNotification"
            (change)="userDto.enableCallVolumeSoundNotification = !userDto.enableCallVolumeSoundNotification"></mat-checkbox>
            </div>           
        </div> -->
</section>
        <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end start">
            <button fxFlex="128px" (click)="cancel()" mat-button color="primary"
            >Cancel</button>
             <button 
             fxFlex="128px" 
             [disabled]="isWaiting"
             (click)="updateProfile()"
             mat-raised-button 
             color="primary"
           >Save</button>
    
        </footer>
    </form>




</main>