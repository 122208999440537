import { Component, OnInit } from '@angular/core';
import { SecurityControllerService, LoginDTO, UserDTO } from '@helplinesos-api-client';
import { MatDialog } from '@angular/material/dialog';
import {
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ErrorService } from '../../shared/error.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { MatSnackBarRef } from '@angular/material/snack-bar';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends ComponentBase implements OnInit {
  loginDto: LoginDTO;
  loginForm: FormGroup;
  isPasswordHidden = true;
  isRememberMeChecked = 'rememberMe';
  isValidationShown = false;
  snackBarRef: MatSnackBarRef<any>;
  constructor(
    private service: SecurityControllerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private errorService: ErrorService,
  )
  { super(); }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      rememberMe: ['']
    });
    if (localStorage.getItem('rememberMe')) {
      this.loginForm.controls[this.isRememberMeChecked].setValue(localStorage.getItem('rememberMe'));
    }

    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
          this.errorMessage = errorMessage;
          this.isWaiting = false;
        }));

  }

  openDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      maxWidth: '684px', // IMPORTANT: otherwise it uses 80uw
      backdropClass: 'dialog-backdrop'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.snackBarRef = result;
    });
  }



  login() {
    this.isValidationShown = true;
    if (this.loginForm.valid) {
      this.isWaiting = true;
      const loginDto: LoginDTO = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
        rememberMe: this.loginForm.value.rememberMe
      };

      this.service.loginUsingPOST(loginDto, 'response').subscribe(
        (response) => {
          localStorage.setItem('user', JSON.stringify(response.body)); // get using JSON.parse(localStorage.getItem('user'));
          localStorage.setItem('token', response.headers.get('authorization')); // get token from headers
          localStorage.setItem('rememberMe', String(loginDto.rememberMe));
          this.isWaiting = false;
          if (this.snackBarRef){
            this.snackBarRef.dismissWithAction();
          }
          switch (response.body.role) {
            case UserDTO.RoleEnum.CAPTAIN: {
              const clinicId = response.body.clinicId;
              this.router.navigate(['/call-queue/' + clinicId]);
              break;
            }
            case UserDTO.RoleEnum.COORDINATOR: {
              const clinicId = response.body.clinicId;
              this.router.navigate(['/call-queue/' + clinicId]);
              break;
            }
            case UserDTO.RoleEnum.ADMIN: {
              this.router.navigate(['/clinics']);
              break;
            }
            case UserDTO.RoleEnum.VOLUNTEER: {
              const clinicId = response.body.clinicId;
              this.router.navigate(['/call-queue/' + clinicId]);
              break;
            }
            default:
              break;
          }
        }
      );
    }
  }
}
