import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';

@Injectable()
export class CancelDialogService {

  constructor(private dialog: MatDialog) { }

  openDialog() {
    const dialogRef = this.dialog.open(CancelDialogComponent, {
      maxWidth: '684px',
      backdropClass: 'dialog-backdrop'
    });
    return dialogRef;
  }
}
