import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailSentNotificationComponent } from '../email-sent-notification/email-sent-notification.component';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SecurityControllerService } from '@helplinesos-api-client';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  isValidationShown = false;
  snackBarRef: MatSnackBarRef<any>;

  constructor(
    private service: SecurityControllerService,
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private customSnackbarService: CustomSnackbarService
  ) {}

  ngOnInit(): void {
    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  send() {
    // TODO: call API
    this.isValidationShown = true;
    if (this.forgotPassForm.valid) {
      this.service.resetPasswordUsingPUT(this.forgotPassForm.value.email).subscribe(response => {
        const message = 'Email sent to your email address, please check your inbox';
        const snackBar = this.customSnackbarService.openToastNotification(message);
        this.dialogRef.close(snackBar);
      },
      err => {
        this.customSnackbarService.openErrorNotification(err.error.message);
      });
    }
  }

  closeDialog(){
    this.dialogRef.close();
    // this.snackBarRef.dismissWithAction();
  }

}
