import { Injectable, ComponentFactoryResolver } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UserDTO } from '@helplinesos-api-client';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  constructor(private router: Router, private location: Location, private route: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserAuthorization(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserAuthorization(route: ActivatedRouteSnapshot, url: any): boolean {
    const userRole = JSON.parse(localStorage.getItem('user'))?.role;
    const clinicId = Number(route.params.clinicId);
    const membersClinicId = JSON.parse(localStorage.getItem('user'))?.clinicId;
    if (userRole ===  UserDTO.RoleEnum.CAPTAIN || userRole === UserDTO.RoleEnum.VOLUNTEER || userRole ===  UserDTO.RoleEnum.COORDINATOR){
      if ((route.data.role && route.data.role.indexOf(userRole) === -1) || this.checkClinicId(clinicId, membersClinicId)) {
        this.location.back();
        return false;
      }
      return true;
    }
    else{
    if (route.data.role && route.data.role.indexOf(userRole) === -1) {
      this.location.back();
      return false;
    }
    return true;
    }
  }
  checkClinicId(clinicId: any, membersClinicId: any) {
    if (clinicId) {
      if (membersClinicId === clinicId) {
        return false;
      }
      return true;
    }
    return true;
  }

}


