<main fxLayout="row wrap" fxLayoutAlign="center start">
  <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="end start">
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </nav>
  <header fxFlex="100%" fxLayout="column" fxLayoutAlign="start center">
    <mat-icon>arrow_upward</mat-icon>
    <h2>{{ isFlagged ? 'Escalated Call' : 'Escalate Call' }}</h2>
  </header>

  <mat-dialog-content *ngIf="isFlagged" class="mat-typography" fxLayout="row wrap" fxLayoutAlign="start start"
    fxLayoutGap="10px">
    <strong fxLayoutGap="5px"><b>{{callFlagDto?.firstName}}</b><b>{{callFlagDto?.lastName}}</b></strong>
    <p>{{callFlagDto?.createdDate | date:'MM/dd/yy h:mm a'}}</p>
    <strong fxFlex="100%">{{callFlagDto?.notes}}</strong>
  </mat-dialog-content>

  <form *ngIf="!isFlagged" fxFlex="100%" id="flagForm" fxLayout="column" fxLayoutAlign="start center"
    [formGroup]="callFlagFormGroup">
    <!-- <mat-form-field >
                <mat-label>Tag people...</mat-label>
                <mat-chip-list #chipList>
                  <mat-chip
                    *ngFor="let member of members"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(member)">
                    {{member.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    tabIndex="-1"
                    #membersInput
                    [formControl]="callFlagFormGroup.controls.members"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let teamMember of filteredTeamMembers | async" [value]="teamMember">
                    {{teamMember.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea matInput tabIndex="-1" [formControl]="callFlagFormGroup.controls.notes"></textarea>
      <mat-error *ngIf="callFlagFormGroup.controls['notes'].hasError('required')">Notes field is required</mat-error>
    </mat-form-field>
  </form>

  <footer fxFlex="80%" fxFlex="100%" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center start">
    <button *ngIf="(this.roleCaption.get(this.role) === 'Captain' || this.userId === callFlagDto?.createdById) && isFlagged" fxFlex="38%" mat-raised-button color="primary" tabIndex="-1"
      (click)="unflagCall()" mat-button><strong><b>De-escalate</b></strong></button>

    <button *ngIf="!isFlagged" fxFlex="38%" mat-button color="primary" tabIndex="-1" (click)="closeDialog()"
      mat-button>Cancel</button>
    <button *ngIf="!isFlagged" fxFlex="38%" form="flagForm" mat-raised-button color="primary"
      (click)="callFlagSubmit()">Submit</button>
  </footer>
</main>