import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserDTO, MemberControllerService } from '@helplinesos-api-client';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdateUserRoleDTO } from 'projects/helplinesos-api-client/src/model/updateUserRoleDTO';
import { CancelDialogService } from '../../shared/cancel-dialog.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';
import {Location} from '@angular/common';
import { HistoryRoutingService } from '../../shared/history-routing.service';

@Component({
  selector: 'app-role-editor',
  templateUrl: './role-editor.component.html',
  styleUrls: ['./role-editor.component.scss']
})
export class RoleEditorComponent extends ComponentBase implements OnInit,  OnDestroy {
  clinicId: number;
  userDto: UserDTO;
  memberId: number;
  isNotesFieldShown = false;
  formGroup: FormGroup;
  roles = [
    UserDTO.RoleEnum.CAPTAIN,
    UserDTO.RoleEnum.COORDINATOR,
    UserDTO.RoleEnum.VOLUNTEER,
  ];
  role: any;
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Coordinator'],
   ]);


  constructor(
    private historyRoutingService: HistoryRoutingService,
    private cancelDialogService: CancelDialogService,
    private snackBarService: CustomSnackbarService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: MemberControllerService,
    private errorService: ErrorService,
    private location: Location
  ) {
    super();
   }

  ngOnDestroy(): void {
      this.errorService.clearErrorMessage();
  }

  ngOnInit(): void {

    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));

    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.memberId = Number(this.route.snapshot.paramMap.get('userId'));

    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    if (this.role === UserDTO.RoleEnum.COORDINATOR){
      this.roles = [
        UserDTO.RoleEnum.COORDINATOR,
        UserDTO.RoleEnum.VOLUNTEER,
      ];
    }

    this.service.getTeamMemberUsingGET(this.memberId).subscribe(response => {
      this.userDto = response;
    });

    this.formGroup = this.formBuilder.group({
      role: [''],
      notes: ['']
    });

  }


  updateUserRole(){
    this.isWaiting = true;
    const user: UpdateUserRoleDTO =
    {
      notes: this.formGroup.controls.notes.value,
      role: this.formGroup.controls.role.value,
      emailUser: this.isNotesFieldShown
    };
    const x = this.memberId;
    this.service.updateMemberRoleUsingPUT(this.memberId, user).subscribe( response => {
      this.snackBarService.openToastNotification('Role Updated');
      this.location.back();
      this.isWaiting = false;
    });
  }

  cancel(){
      this.cancelDialogService.openDialog();
  }

  getPhoneNumber(phone: string){
    return phone ?
    '(' +
    phone.slice(0, 3) + ') ' +
    phone.slice(3, 6) + '-' +
    phone.slice(6) : 'Phone number not set';
  }

}
