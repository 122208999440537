<main fxLayout="row wrap" fxLayoutAlign="start start">
    <header fxFlex="100%" fxLayoutAlign="space-between start">
        <h1>
            Team
        </h1>
        <span>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
          </span>
    </header>
    <section fxFlex="100%" fxLayout="column" class="mat-elevation-z1">
        <app-members-tab [clinicId]="this.clinicId" ></app-members-tab>
      </section>
</main>
