<main fxLayout="row wrap" fxLayoutAlign="center start">
        <nav fxFlex=100% fxLayout="column" fxLayoutAlign="center center">
            <mat-icon  color="primary">timer</mat-icon>

                <h2>Are you still there?</h2>
                <h2>You will be automatically logged off in 2 minutes based on your clinic closing hours</h2>
        </nav>
        <section fxFlex=100% fxFlex=100% fxLayout="column" fxLayoutAlign="center center">
            <h1>0:{{timeOut}}</h1>
            <strong>Please click "Continue" to keep working or "Log Off" to end your session.</strong>
        </section>

        <footer  fxFlex=100% fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
            <button (click)="logOut()" mat-button color="warn" tabIndex="-1"
               mat-button>Log Out
            </button>
            <button (click)="continueToScribe()" mat-raised-button color="primary"
              >Continue
            </button>
          </footer>

</main>
