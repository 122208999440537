import { Injectable, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CallDTO, CallFlagNoteDTO, CallBackDTO } from '@helplinesos-api-client';
import { CallInformationComponent } from './call-information/call-information.component';
import { CallFlagComponent } from './call-flag/call-flag.component';
import { DeleteCommentComponent } from './call-flag/delete-comment/delete-comment.component';
import { TimerDialogComponent } from './scribe/timer-dialog/timer-dialog.component';
import { UnassignDialogComponent } from './scribe/unassign-dialog/unassign-dialog.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { FollowUpDialogComponent } from './scribe/follow-up-dialog/follow-up-dialog.component';
import { CancelScribeDialogComponent } from './scribe-complete/cancel-scribe-dialog/cancel-scribe-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  @HostListener('window:resize', ['$event'])
  public innerWidth: any;

  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet) {
    this.innerWidth = window.innerWidth;
  }

  getInnerWidth() {
    return this.innerWidth;
  }

  openCallInformation(call: CallDTO, callFlagDto: CallFlagNoteDTO, callBackDto?: CallBackDTO) {
    this.onResize(event);
    if (this.getInnerWidth() >= 1280) {
      this.dialog.open(CallInformationComponent, {
        data: { callDto: call, callFlagDto, callBackDto },
        maxWidth: '700px',
        minWidth: '700px',
        backdropClass: 'dialog-backdrop'
      });
    }
    else {
      this.bottomSheet.open(CallInformationComponent, {
        data: { callDto: call, callFlagDto }
      });
    }
  }

  openCallHistory(call: CallDTO, callBackId: number, callBacks: CallBackDTO[]) {
    this.onResize(event);
    if (this.getInnerWidth() >= 1280) {
      this.dialog.open(CallHistoryComponent, {
        data: { callDto: call, callBackId, callBacks },
        maxWidth: '935px',
        minWidth: '935px',
        backdropClass: 'dialog-backdrop'
      });
    }
    else {
      this.bottomSheet.open(CallHistoryComponent, {
        data: call
      });
    }
  }

  openFlagCall(CallFlagDto: CallFlagNoteDTO, CallId: number) {
    // this.onResize(event);
    // if (this.getInnerWidth() >= 1280) {
     const ref =  this.dialog.open(CallFlagComponent, {
        maxWidth: '560px',
        minWidth: '560px',
        backdropClass: 'dialog-backdrop',
        data: { callFlagDto: CallFlagDto, callId: CallId }
      });
     return ref;
    // }
    // else {
    //   const ref = this.bottomSheet.open(CallFlagComponent, {
    //     data: { callFlagDto: CallFlagDto, callId: CallId }
    //   });
    //   return ref;
    // }
  }

  // openTimer(callId: number, clinicId: number) {
  //   this.onResize(event);
  //   if (this.getInnerWidth() >= 1280) {
  //     this.dialog.open(TimerDialogComponent, {
  //       maxWidth: '560px',
  //       minWidth: '560px',
  //       backdropClass: 'dialog-backdrop',
  //       data: { callId, clinicId }
  //     });
  //   }
  //   else {
  //     this.bottomSheet.open(TimerDialogComponent, {
  //       data: { callId, clinicId }
  //     });
  //   }
  // }

  openUnassignCall(callId: number, clinicId: number) {
    // this.onResize(event);
    // if (this.getInnerWidth() >= 1280) {
      const dialogRef = this.dialog.open(UnassignDialogComponent, {
        maxWidth: '560px',
        minWidth: '560px',
        backdropClass: 'dialog-backdrop',
        data: { callId, clinicId }
      });
      return dialogRef;
    // }
    // else {
    //   const bottomSheetRef = this.bottomSheet.open(UnassignDialogComponent, {
    //     data: { callId, clinicId }
    //   });
    //   return bottomSheetRef;
    // }
  }

  openFollowUpDialog() {
    const dialogRef = this.dialog.open(FollowUpDialogComponent, {
      maxWidth: '560px',
      minWidth: '560px',
      backdropClass: 'dialog-backdrop'
    });
    return dialogRef;
  }

  openCancelDialog() {
    const dialogRef = this.dialog.open(CancelScribeDialogComponent, {
      maxWidth: '560px',
      minWidth: '560px',
      backdropClass: 'dialog-backdrop'
    });
    return dialogRef;
  }
}
