import { ClinicDTO, UserDTO } from '@helplinesos-api-client';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray
} from '@angular/forms';
import { MemberInviteDTO } from '../../../../../helplinesos-api-client/src/model/memberInviteDTO';
import { ClinicControllerService } from '../../../../../helplinesos-api-client/src';
import { Router, ActivatedRoute } from '@angular/router';
import { MemberControllerService } from '../../../../../helplinesos-api-client/src/api/memberController.service';
import { CancelDialogService } from '../../shared/cancel-dialog.service';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import {Location} from '@angular/common';
import { HistoryRoutingService } from '../../shared/history-routing.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';

@Component({
  selector: 'app-members-editor',
  templateUrl: './members-editor.component.html',
  styleUrls: ['./members-editor.component.scss'],
})
export class MembersEditorComponent extends ComponentBase implements OnInit {
  registration = true;
  mask = {
    guide: false,
    showMask: false,
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  };
  hasMultipleInvites = false;
  clinicId: number;
  dto: ClinicDTO;
  inviteFormGroup: FormGroup;
  role: any;
  get invitationForms() {
    return this.inviteFormGroup.get('invitations') as FormArray;
  }

  // TODO: API enum
  roles = [
    { value: UserDTO.RoleEnum.CAPTAIN, name: 'Captain' },
    { value: UserDTO.RoleEnum.COORDINATOR, name: 'Coordinator' },
    { value: UserDTO.RoleEnum.VOLUNTEER, name: 'Volunteer' },
  ];

  constructor(
    private errorService: ErrorService,
    private membersControllerService: MemberControllerService,
    private formBuilder: FormBuilder,
    private clinicService: ClinicControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private cancelDialogService: CancelDialogService,
    private snackBarService: CustomSnackbarService,
    private location: Location,
    private historyRoutingService: HistoryRoutingService
  ) {
    super();
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    if (this.historyRoutingService.getPreviousUrl() === 'information'){
      this.registration = false;
    }
   // this.registration = Boolean(this.route.snapshot.queryParams.registration);
  }

  ngOnInit(): void {
    this.errorService.clearErrorMessage();
    // if (this.registration){
    //   this.snackBarService.open('Clinic Saved');
    // }
    this.isWaiting = true;
    this.clinicService.getClinicUsingGET(Number(this.clinicId)).subscribe(
      (res) => {
        this.dto = res;
        this.isWaiting = false; // TODO in pipe
      }
    );

    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    if (this.role === UserDTO.RoleEnum.COORDINATOR){
      this.roles = [
        { value: UserDTO.RoleEnum.COORDINATOR, name: 'Coordinator' },
        { value: UserDTO.RoleEnum.VOLUNTEER, name: 'Volunteer' },
      ];
      this.registration = false;
    }
    this.inviteFormGroup = this.formBuilder.group({
      invitations: this.formBuilder.array([]),
      notes: ['']
    });
    const invitation = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      role: ['', Validators.required]
    });
    this.invitationForms.push(invitation);

    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));
  }

  addNewMember() {
    const invitation = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      role: ['', Validators.required]
    });
    this.invitationForms.push(invitation);
    this.hasMultipleInvites = true;
  }

  save() {
    if (!this.inviteFormGroup.valid) {
      return;
    }
    const notes = this.inviteFormGroup.controls.notes.value;
    const submitDto: MemberInviteDTO = {
      invitations: [],
    };

    // REVIEW: learn RxJS.map
    this.inviteFormGroup.controls.invitations.value.forEach(function (
      element: any
    ) {
      submitDto.invitations.push({ email: element.email, role: element.role, notes });
    });

    this.isWaiting = true;
    this.membersControllerService
      .inviteMemberUsingPOST(this.clinicId, submitDto)
      .subscribe(
        (response) => {
          this.router.navigate(['clinics/' + this.clinicId + '/information']);
          this.isWaiting = false;
          const snackBar = this.snackBarService.openToastNotification('Members Invited');
        }
      );
  }

  cancel() {
    if (!this.inviteFormGroup.dirty) {
      if (this.registration){
        this.router.navigate(['/clinics/' + this.clinicId + '/information']);
      }
      else{
        this.location.back();
      }

      return;
    }
    this.cancelDialogService.openDialog();
  }

  removeMember(i: number) {
    this.invitationForms.removeAt(i);

    if (this.invitationForms.controls.length === 1) {
      this.hasMultipleInvites = false;
    }
  }

  getIndex(i: number){
    return String(i);
  }
}
