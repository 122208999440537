<main fxLayout="row wrap" fxLayoutAlign="center start">
    <nav fxFlex="100%" fxLayout="row" fxLayoutAlign="end start">
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </nav>
    <header fxLayout="column"   fxLayoutAlign="center center">
        <h2>
            Personal Information
        </h2>
        <strong>You are tying to change email address. In the future you will log in with new email address. Please type your password to continue</strong>
    </header>
  
  
    <form [formGroup]="userFormGroup" fxFlex="100%" id="infoForm" fxLayout="column" fxLayoutAlign="start center">
  
        <mat-form-field >
            <mat-label>Password</mat-label>
            <input tabIndex="-1" type="password" formControlName="password" matInput>
          </mat-form-field>

    </form>
  
    <footer   fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="center start">
        <button tabIndex="-1" fxFlex  mat-button color="primary"
        >Cancel</button>
      <button fxFlex form="infoForm" mat-raised-button color="primary"
       >Confirm</button>

    </footer>
  </main>