<main fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="32px">
    <header *ngIf="!clinicIdForInformationView" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxLayout="row" fxLayoutAlign="start start">
            <h1>Resource Board</h1>
        </div>
        <div class="error" *ngIf="errorMessage"><strong>{{errorMessage}}</strong></div>
        <span>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
          </span>
    </header>
    <nav fxFlex="272px" >
        <mat-button-toggle-group #group="matButtonToggleGroup" [value]="currentTab" fxLayout="column"  fxFlex="100%" fxLayoutAlign="start start">
            <mat-button-toggle  (change)="changeTab(tab.value, $event, group)" *ngFor="let tab of tabs" [id]="tab.value" [value]="tab.value">{{tab.display}}</mat-button-toggle>
        </mat-button-toggle-group>

    </nav>
    <section  fxFlex>
        <app-scribe-actions [clinicId]="this.clinicId" (onFormGroupChange)="onFormGroupChangeEvent($event)" *ngIf="currentTab === 'scribeActions'"></app-scribe-actions>
        <app-helpful-information [clinicId]="this.clinicId" (onFormGroupChange)="onFormGroupChangeEvent($event)" *ngIf="currentTab === 'helpfulInformation'"></app-helpful-information>
        <app-bulletin  [clinicId]="this.clinicId" *ngIf="currentTab === 'bulletin'"></app-bulletin>
        <app-helpline-resource [clinicId]="this.clinicId" (onFormGroupChange)="onFormGroupChangeEvent($event)" *ngIf="currentTab === 'helplineSOSResource'"></app-helpline-resource>
        <app-clinic-faqs  [clinicId]="this.clinicId" (onFormGroupChange)="onFormGroupChangeEvent($event)" *ngIf="currentTab === 'clinicFAQs'"></app-clinic-faqs>
    </section>


</main>