import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { AnyARecord } from 'dns';
import { ComponentBase } from './models/componentBase';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';

export class WebSocketAPIService {
    webSocketEndPoint = environment.apiUrl + '/api/websocket';
    stompClient: any;
    isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    token: string;
    constructor(){
        this.token = localStorage.getItem('token');
    }


    _connect() {
        console.log('Initialize WebSocket Connection');
        const ws = new SockJS(this.webSocketEndPoint, {'x-auth-token': this.token});
        this.stompClient = Stomp.over(ws);
        // tslint:disable-next-line: variable-name
        const _this = this;
        const headers = {
            'client-id': 'my-client-id',
            'x-auth-token': _this.token,
        };
        // tslint:disable-next-line: only-arrow-functions
        _this.stompClient.connect(headers, function(frame: any) {
            _this.isConnected.next(true);
         }, this.errorCallBack);
    }


    _disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        console.log('Disconnected');
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error: any) {
        console.log('errorCallBack -> ' + error);
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

 /**
  * Send message to sever via web socket
  * @param {*} message
  */
    _send(message: any) {
        console.log('calling logout api via web socket');
        this.stompClient.send('/app/hello', {}, JSON.stringify(message));
    }
}
