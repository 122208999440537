import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-toast-notification',
  templateUrl: './custom-toast-notification.component.html',
  styleUrls: ['./custom-toast-notification.component.scss']
})
export class CustomToastNotificationComponent implements OnInit {

  public static message: string;
  constructor(private snackBarRef: MatSnackBarRef<any>){
  }

  get Message() {
    return CustomToastNotificationComponent.message + '.';
  }

  ngOnInit(): void {
  }
  closeToastNotification(){
    this.snackBarRef.dismissWithAction();
  }
}
