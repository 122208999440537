import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, RouterEvent } from '@angular/router';
import { CallControllerService, CallDTO, CallBackControllerService, CallFlagNoteDTO, CallBackQuestionDTO, CallBackDTO } from '@helplinesos-api-client';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { CallData } from '../call-queue-dashboard/call-queue-dashboard.component';
import { filter, tap, take } from 'rxjs/operators';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';

@Component({
  selector: 'app-call-queue-information',
  templateUrl: './call-information.component.html',
  styleUrls: ['./call-information.component.scss']
})
export class CallInformationComponent extends ComponentBase implements OnInit {

  roleCaption = new Map([
    [CallDTO.StatusEnum.NEWCALL, 'New Call'],
    [CallDTO.StatusEnum.INPROGRESS, 'In Progress'],
    [CallDTO.StatusEnum.COMPLETE, 'Complete'],
    [CallDTO.StatusEnum.INCOMPLETE, 'Incomplete'],
    [CallDTO.StatusEnum.FOLLOWUP, 'Follow up'],
    [CallDTO.CallTypeEnum.COMPLEX, 'Complex'],
    [CallDTO.CallTypeEnum.INFORMATION, 'Information'],
    [CallDTO.CallTypeEnum.SIMPLE, 'Simple'],
    [CallDTO.CallTypeEnum.TESTING, 'Testing']
  ]);
  callBackDto: CallBackDTO;
  answeredQuestions: any[];
  callBackQuestions: CallBackDTO;
  isFlagged = false;
  callFlagDto: CallFlagNoteDTO;
  clinicId = '';
  mode = 'Edit';
  followUpText = '';
  role: string;
  callDto: CallDTO;
  constructor(
    private errorService: ErrorService,
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<CallInformationComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<CallInformationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: CallData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: CallData,
    private route: ActivatedRoute,
    private service: CallControllerService,
    private callBackControllerService: CallBackControllerService) {
      super();
      router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        tap(() => this.closeDialog()),
        take(1),
      ).subscribe();
  }

  ngOnInit(): void {

    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== '') {
        this.isWaiting = false;
      }
    }));



    if (this.dialogData){
      this.callDto = this.dialogData.callDto;
      this.callFlagDto = this.dialogData.callFlagDto;
      this.callBackDto = this.dialogData?.callBackDto;
    }
    else{
      this.callDto = this.bottomSheetData.callDto;
      this.callFlagDto = this.bottomSheetData.callFlagDto;
      this.callBackDto = this.bottomSheetData?.callBackDto;
    }
    if (this.callFlagDto){
      this.isFlagged = true;
    }
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    if (this.role === 'ROLE_ADMIN' ||
     this.callDto.status === CallDTO.StatusEnum.COMPLETE ||
      this.callDto.status === CallDTO.StatusEnum.INPROGRESS)
        {
          this.mode = 'Read';
        }
    if ( this.callDto.status === CallDTO.StatusEnum.FOLLOWUP){
        this.followUpText = 'follow-up';
        this.loadQuestions();
      }

   }


   loadQuestions(){
    // if ( this.callDto.status === CallDTO.StatusEnum.FOLLOWUP){
   //   this.callBackControllerService.getCallBacksUsingGET(this.callDto?.id).subscribe( response => {
        if (this.callBackDto?.status === CallBackDTO.StatusEnum.INPROGRESS){
          this.mode = 'read';
        }
        this.callBackQuestions = this.callBackDto;
        this.answeredQuestions = [];
        let counter = 0;
        for (const question of this.callBackQuestions?.questions) {
          if (counter === 4){
            return;
          }
          if (question === undefined || question === null || question.answer === '') {
            continue;
          }
          const action = this.callBackQuestions?.scribeActions.find(x => x.id === question.scibeActionId);
          if (action === undefined || action === null) {
            continue;
          }
          this.answeredQuestions.push(
            {
              id: question?.scibeActionId,
              sequence: action?.sequence,
              type: action?.type,
              question: action?.question,
              required: action?.required,
              values: question?.answer,
              title: action?.title
            }
          );
          counter = counter + 1;
        }
   //   });
    // }

   }

   isNewCall() {
     return this.callDto.status === CallDTO.StatusEnum.NEWCALL ? true : false;
   }

   isInProgress() {
    return this.callDto.status === CallDTO.StatusEnum.INPROGRESS ? true : false;
  }

   isTypeWarn(callType: CallDTO.CallTypeEnum){
     let warn = '';
     if (callType === CallDTO.CallTypeEnum.COMPLEX || callType === CallDTO.CallTypeEnum.SIMPLE){
       warn = 'textDanger';
     }
     return warn;
   }

   isWarn(element: boolean){
    let warn = '';
    if (element){
      warn = 'textDanger';
    }
    return warn;
  }

  getStatusClass(status: CallDTO.StatusEnum){
    let statusClass = '';
    switch (status) {
      case CallDTO.StatusEnum.NEWCALL: {
        statusClass = 'new-call';
        break;
      }
      case CallDTO.StatusEnum.INPROGRESS: {
        statusClass = 'in-progress';
        break;
      }
      default:
        break;
    }
    return statusClass;
  }

  closeDialog(){
    if (this.dialogData){
      this.dialogRef.close();
    }
    else{
      this.bottomSheetRef.dismiss();
    }
  }

  assignCall(){
    this.clinicId = JSON.parse(localStorage.getItem('user'))?.clinicId;
    this.callBackControllerService.startCallBackUsingPOST(this.callDto.id).subscribe((response) => {
      let isNewCall = false;
      if ( this.callDto.status === CallDTO.StatusEnum.NEWCALL){
        isNewCall = true;
      }
      // tslint:disable-next-line: max-line-length
      this.router.navigate(['/scribe/' + this.clinicId + '/' + this.callDto.id + '/' + response.id],{ queryParams: { isNewCall }});
      this.closeDialog();
    });

  }

  goToCallBack(){
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
    const callInProgressId = Number(this.errorMessage.split(': #')[1]);
    if (!callInProgressId){
      return;
    };
    this.callBackControllerService.getCallBacksUsingGET(callInProgressId, 'response').subscribe(
      (response1) => {
        const callBacksDto = response1.body.filter(x => x.createdById === userId)
          .sort(function (a, b) {
            return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
          });
        if ( callBacksDto.length === 0){
          return;
        }
        const myCallback = callBacksDto[0];
        if ( myCallback?.status === CallDTO.StatusEnum.INPROGRESS ) {
          this.router.navigate(['/scribe/' + this.clinicId + '/' + callInProgressId + '/' + myCallback.id]);
        }
      }
    );
  }

}
