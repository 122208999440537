/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateReportIssueDTO } from '../model/createReportIssueDTO';
import { DefaultColumnsDTO } from '../model/defaultColumnsDTO';
import { ReportIssueDTO } from '../model/reportIssueDTO';
import { UpdateUserDTO } from '../model/updateUserDTO';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserControllerService {

    protected basePath = 'https://api-dev.helplinesos.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * User Reports Issue
     * User Reports an Issue
     * @param createReportIssueDTO createReportIssueDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIssueUsingPOST(createReportIssueDTO: CreateReportIssueDTO, observe?: 'body', reportProgress?: boolean): Observable<ReportIssueDTO>;
    public createIssueUsingPOST(createReportIssueDTO: CreateReportIssueDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReportIssueDTO>>;
    public createIssueUsingPOST(createReportIssueDTO: CreateReportIssueDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReportIssueDTO>>;
    public createIssueUsingPOST(createReportIssueDTO: CreateReportIssueDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createReportIssueDTO === null || createReportIssueDTO === undefined) {
            throw new Error('Required parameter createReportIssueDTO was null or undefined when calling createIssueUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ReportIssueDTO>(`${this.basePath}/api/user/reportissue`,
            createReportIssueDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User&#39;s Default Columns
     * Returns User&#39;s Default Columns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDefaultColumnsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<DefaultColumnsDTO>;
    public getDefaultColumnsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DefaultColumnsDTO>>;
    public getDefaultColumnsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DefaultColumnsDTO>>;
    public getDefaultColumnsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DefaultColumnsDTO>(`${this.basePath}/api/user/default/columns`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Authenticated User
     * Returns the currently authenticated User
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserUsingGET(observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public getUserUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public getUserUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public getUserUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserDTO>(`${this.basePath}/api/user/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User&#39;s Default Columns
     * Returns User&#39;s Default Columns
     * @param defaultColumns defaultColumns
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDefaultColumnsUsingPUT(defaultColumns: DefaultColumnsDTO, observe?: 'body', reportProgress?: boolean): Observable<DefaultColumnsDTO>;
    public setDefaultColumnsUsingPUT(defaultColumns: DefaultColumnsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DefaultColumnsDTO>>;
    public setDefaultColumnsUsingPUT(defaultColumns: DefaultColumnsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DefaultColumnsDTO>>;
    public setDefaultColumnsUsingPUT(defaultColumns: DefaultColumnsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (defaultColumns === null || defaultColumns === undefined) {
            throw new Error('Required parameter defaultColumns was null or undefined when calling setDefaultColumnsUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DefaultColumnsDTO>(`${this.basePath}/api/user/categories`,
            defaultColumns,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Authenticated User
     * Updates the currently authenticated User
     * @param updateUserDTO updateUserDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserUsingPUT(updateUserDTO: UpdateUserDTO, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public updateUserUsingPUT(updateUserDTO: UpdateUserDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public updateUserUsingPUT(updateUserDTO: UpdateUserDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public updateUserUsingPUT(updateUserDTO: UpdateUserDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateUserDTO === null || updateUserDTO === undefined) {
            throw new Error('Required parameter updateUserDTO was null or undefined when calling updateUserUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserDTO>(`${this.basePath}/api/user/`,
            updateUserDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
