<main fxLayout="row wrap" fxLayoutAlign="start start" (click)="this.isBulletinShown = false">
    <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="start start">
                <h1>Scribe</h1><button mat-button color="warn" (click)="unassignCall()">Unassign call</button>
            </div>
            <div [className]="isBulletinShown ? 'bulletin-expanded' : 'bulletin-shrink'" 
            fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div fxFlex="100%" *ngIf="!isBulletinShown" fxLayoutAlign="center center" fxLayoutGap="8px">       
                <mat-icon color="warn">warning</mat-icon>
                <strong  class="error">Reminders and Updates</strong>
            </div>     
            <button *ngIf="!isBulletinShown" (click)="showBulletin($event)" mat-icon-button>
                <mat-icon color="warn">arrow_circle_down</mat-icon></button>
            <section (click)="stopPropagation($event)" fxFlex="100%" fxLayout="row wrap" *ngIf="isBulletinShown" class="bulletin-content" fxLayoutAlign="center center">
                <header fxFlex="100%"   fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                    <mat-icon color="warn">warning</mat-icon>
                    <strong  class="error">Reminders and Updates</strong>
                </header>
                
                <div [innerHtml]="this.bulletinValue" fxFlex="100%">  </div>
                <button (click)="this.isBulletinShown = !this.isBulletinShown" mat-icon-button>
                    <mat-icon color="warn">arrow_circle_up</mat-icon></button>
            </section>
        </div>
        <span>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
         </span>
        </div>
        <p>You are assigned to this call</p>
    </header>
    
    <div fxFlex="100%" fxLayoutAlign="center start"><strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong></div>
    <mat-progress-bar class="progress-bar-grid" fxFlex="100%" *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    <section fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start space-between" fxLayoutGap="20px">
        <mat-drawer-container fxFlex="288px">
            <!-- <div fxFlex="100%" fxLayout="row" fxLayoutAlign="end center">
                <button mat-icon-button mat-stroked-button color="primary" (click)="showHelpfulInformation()">
                    <mat-icon>{{isHelpfulInformationShown ? 'skip_previous' : 'skip_next'}}</mat-icon>
                </button>
            </div> -->

            <div *ngIf="isHelpfulInformationShown">
                <h2>Helpful Information</h2>
                <h3>Phone Directory</h3>
                <ul>
                    <li *ngFor="let item of helpfulInformationDto?.phoneDirectory"><strong>{{getPhoneNumber(item?.label,item?.phoneNumber)}}</strong></li>
                </ul>
                <h3>External Links</h3>
                <ul>
                    <li *ngFor="let item of helpfulInformationDto?.externalLinks">
                      <div fxLayout="column" fxLayoutGap="8px">
                       <strong *ngIf="item?.fileData"><a (click)="downloadFile(item.fileData, item.fileType, item.fileName)">{{item?.label ? item?.label : item?.fileName}}</a></strong>
                       <strong *ngIf="item?.url"><a (click)="goToLink(item?.url)">{{item?.label ? item?.label : item?.url}}</a></strong>
                      </div>
         
                     </li> 
                 </ul>
            </div>
        </mat-drawer-container>
        <form fxFlex fxLayout="row wrap" [formGroup]="formGroup" fxLayoutAlign="start start">
            <nav fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between center ">
                <div fxFlex="50%" fxLayout="row wrap" fxLayoutAlign="space-between center ">

                    <h2 fxFlex="180px">
                        <mat-icon fxFlex="30px" color="primary">phone</mat-icon>
                        {{getCallPhoneNumber(callDto?.phoneNumber)}}
                    </h2>
                    <strong fxFlex="80px"><b>Caller {{callDto?.callerId}}</b></strong>
                    <strong fxFlex="120px"><b>{{this.roleCaption.get(callDto?.language)}} Speaker</b></strong>
                </div>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="end start " fxLayoutGap="20px">
                    <h4><a (click)="openFlag()" *ngIf="callDto?.flagged">Escalate Note</a></h4>
                    <h4><a (click)='openCallHistory()' [ngClass]="hasHistory ? 'has-history' : 'disable-history'">Call History</a></h4>
                </div>

            </nav>

        <nav fxFlex="100%"  fxLayout="row wrap" fxLayoutAlign="start start ">
            <div fxFlex="100%" fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                <h3>Verification</h3> <strong>Please verify your information</strong>
            </div>
            <ul fxLayout="row wrap" fxFlex="100%" class="mat-table">
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Age</strong>
                    <mat-form-field fxFlex="100%" fxFlex="80px">
                        <input  formControlName="age" matInput type="number" (focusout)='updateCall("Age",$event.target.value)' >
                        <mat-error *ngIf="formGroup.controls.age.errors?.required">Age is required</mat-error>
                        <mat-error *ngIf="formGroup.controls.age.errors?.max || formGroup.controls.age.errors?.min">Age must be from 0-120</mat-error>
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Type</strong>
                    <span [ngClass]='isTypeWarn(this.callDto?.callType)'>{{this.type}}</span> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Symptoms</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select [ngClass]='isWarn(callDto?.hasSymptoms)' (selectionChange)='updateCall("Symptoms",$event.source.value)' [value]="callDto?.hasSymptoms" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%" class="min-width-160">
                    <strong fxFlex="100%">Underlying Disease</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select [ngClass]='isWarn(callDto?.hasUnderlyingDisease)' (selectionChange)='updateCall("UnderlyingDisease",$event.source.value)' [value]="callDto?.hasUnderlyingDisease" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Contact</strong>
                    <mat-form-field fxFlex="100px">
                        <mat-select  [ngClass]='isWarn(callDto?.hadContactWithInfected)' (selectionChange)='updateCall("Contact",$event.source.value)' [value]="callDto?.hadContactWithInfected" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field> 
                </li>
                <li fxLayout="row wrap" fxFlex="12%" class="min-width-120">
                    <strong fxFlex="100%">Clinic Patient</strong>
                    
                    <mat-form-field fxFlex="100px">
                        <mat-select (selectionChange)='updateCall("ClinicPatient",$event.source.value)' [value]="callDto?.patient" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Zip Code</strong>
                    
                    <mat-form-field fxFlex="120px">
                        <input formControlName="zipCode" maxlength="5" matInput type="text" (focusout)='updateCall("Zipcode" , $event.target.value)' >
                        <mat-error *ngIf="formGroup.controls.zipCode.errors?.required">Field is required</mat-error>
                        <mat-error *ngIf="formGroup.controls.zipCode.errors?.pattern || !formGroup.controls.zipCode.errors?.minLength">5 digits required</mat-error>
                        <!-- <mat-error *ngIf="!formGroup.controls.zipCode.errors?.minLength">Zip code must have 5 digits</mat-error> -->
                      </mat-form-field>
                </li>
                <li fxLayout="row wrap" fxFlex="12%">
                    <strong fxFlex="100%">Insurance</strong>
                    
                    <mat-form-field fxFlex="100px">
                        <mat-select (selectionChange)='updateCall("Insurance",$event.source.value)' [value]="callDto?.hasInsurance" >
                            <mat-option   *ngFor="let item of boolDropdown" [value]="item.value"> {{item.display}}</mat-option>
                        </mat-select>
  
                      </mat-form-field>
                </li>
            </ul>

           
        </nav>
        <nav fxFlex="100%" >
            <div fxLayout="row wrap" fxLayoutAlign="start start " fxLayoutGap="20px">
                <h3>Comment </h3><strong fxFlex> - Optional</strong>
                <mat-form-field fxFlex="100%">
                <textarea [value]="this.callBackDto?.callBackNotes" (focusout)='updateCallbackNotes($event.target.value)' matInput type="text" placeholder="Notes" ></textarea>
                  </mat-form-field>
            </div>
        </nav>

        <nav fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start stretch ">
            <div fxFlex="100%"  fxLayout="row " fxLayoutAlign="start start" fxLayoutGap="20px">
                <h3>Actions</h3> 
            </div>
                <ul fxFlex="100%" class="questionary" fxLayout="column" >
                    <li  *ngFor="let scribeAction of getRequiredScribeActions(); let i = index"  fxLayout="column" >
                        <h4  [ngClass]="getValidation(scribeAction?.id)?.showValidation ?'textDanger':''">{{scribeAction?.question}}<span class="error"> *</span></h4>
                        <p *ngIf="getValidation(scribeAction?.id)?.showValidation" [ngClass]='"textDanger"' >{{getValidation(scribeAction?.id)?.message}}</p>
                        <ul class="checkboxes-margin" *ngIf="roleCaption.get(scribeAction?.type) === 'Check'" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start"  >
                            <li  role="listitem"  *ngFor="let item of scribeAction?.values.split(',')" >
                                <mat-checkbox fxFlex="200px" (click)="this.stopPropagation($event)" [value]="item" (change)='updateCallback(scribeAction, $event.source.value)' [checked]="isSelected(scribeAction,item)">
                                   {{item}} 
                                </mat-checkbox>
                            </li>
                            
                        </ul>
                        <mat-radio-group   *ngIf="roleCaption.get(scribeAction?.type) === 'Radio'" fxLayout="column"  [value]="getSelectedSingleValue(scribeAction?.id)"  >
                            <mat-radio-button (click)="this.stopPropagation($event)" (change)='updateCallback(scribeAction, $event.source.value)'  *ngFor="let item of scribeAction?.values.split(',')" [value]="item" >
                                {{item}}
                              </mat-radio-button>
                        </mat-radio-group>
                    </li>
                </ul>  
                <ul fxFlex="100%" class="questionary" fxLayout="column">
                    <li *ngFor="let scribeAction of getOptionalScribeActions(); let i = index"  fxLayout="column" >
                        <h4  [ngClass]="getValidation(scribeAction?.id)?.showValidation ?'textDanger':''">{{scribeAction?.question}}<span class="primary"> - Optional</span></h4>
                        <!-- <p *ngIf="getValidation(scribeAction?.id)?.showValidation" [ngClass]='"textDanger"' >{{getValidation(scribeAction?.id)?.message}}</p> -->
                        <ul  class="checkboxes-margin" *ngIf="roleCaption.get(scribeAction?.type) === 'Check'" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start"  >
                            <li  role="listitem" *ngFor="let item of scribeAction?.values.split(',')" >
                                <mat-checkbox (click)="this.stopPropagation($event)" fxFlex="200px" [value]="item" (change)='updateCallback(scribeAction, $event.source.value)' [checked]="isSelected(scribeAction,item)">
                                   <strong>{{item}}</strong> 
                                </mat-checkbox>
                            </li>
                            
                        </ul>
                        <mat-radio-group  fxFlex="100%" *ngIf="roleCaption.get(scribeAction?.type) === 'Radio'" fxLayout="column"  [value]="getSelectedSingleValue(scribeAction?.id)"  >
                            <mat-radio-button (click)="this.stopPropagation($event)" (change)='updateCallback(scribeAction, $event.source.value)'  *ngFor="let item of scribeAction?.values.split(',')" [value]="item" >
                                {{item}}
                              </mat-radio-button>
                        </mat-radio-group>
                    </li>
                </ul>  

               
        </nav>
        <nav fxFlex="100%" fxLayout="row " fxLayoutAlign="center center ">
            <button mat-raised-button color="primary" (click)="endCallBack()">Submit</button>
        </nav>
    </form>
    </section>

</main>