import { Component, OnInit, ViewChild, ElementRef, Optional, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// import { startWith, map } from 'rxjs/operators';
// import { MatChipInputEvent } from '@angular/material/chips';
// import { MemberService } from '../../clinics/member.service';
import { MemberControllerService, CallControllerService, UserDTO } from '@helplinesos-api-client';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FlagData } from '../call-queue-dashboard/call-queue-dashboard.component';
// import { TaggedTeamMemberDTO } from 'projects/helplinesos-api-client/src/model/taggedTeamMemberDTO';
import { CallFlagNoteDTO } from 'projects/helplinesos-api-client/src/model/callFlagNoteDTO';
// import { DialogService } from '../dialog.service';
// import { DeleteCommentComponent } from './delete-comment/delete-comment.component';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { filter, tap, take } from 'rxjs/operators';

export interface NoteData {
  noteId: number;
  callId: number;
}

@Component({
  selector: 'app-call-flag',
  templateUrl: './call-flag.component.html',
  styleUrls: ['./call-flag.component.scss']
})
export class CallFlagComponent implements OnInit {
  role: any;
  userId: number;
  isFlagged = false;
  callFlagDto: CallFlagNoteDTO;
  callFlagFormGroup: FormGroup;
  clinicId = 0;
  callId = 0;
  visible = true;
  selectable = true;
  removable = true;
  teamMembersFromControl = new FormControl();
  filteredTeamMembers: Observable<string[]>;
  members: any[] = [];
  teamMembers: any[] = [];
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
  ]);
  @ViewChild('membersInput') membersInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private snackBarService: CustomSnackbarService,
 //   private dialog: MatDialog,
    private formBuilder: FormBuilder,
  //  private membersService: MemberControllerService,
    private callControllerService: CallControllerService,
    @Optional() private dialogRef: MatDialogRef<CallFlagComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<CallFlagComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: FlagData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: FlagData,
    private router: Router) {
      router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        tap(() => this.closeDialog()),
        take(1),
      ).subscribe();
  }

  ngOnInit(): void {
      this.role = JSON.parse(localStorage.getItem('user'))?.role;
      this.userId = Number(JSON.parse(localStorage.getItem('user'))?.id);
      if (this.dialogData){
        this.callFlagDto = this.dialogData.callFlagDto;
        this.callId = this.dialogData.callId;
        if (this.callFlagDto){
          this.isFlagged = true;
        }
      }
      else{
        this.callFlagDto = this.bottomSheetData.callFlagDto;
        this.callId = this.bottomSheetData.callId;
        if (this.callFlagDto){
          this.isFlagged = true;
        }
      }


      this.callFlagFormGroup = this.formBuilder.group({
       // members: [''],
        notes: ['', Validators.required],
      });
  //     this.membersService.getTeamMembersUsingGET(this.clinicId).subscribe((response) => {
  //     this.teamMembers = response.map(x =>
  //        ({name:  x.firstName + ' ' + x.lastName, id: x.id}));
  //     this.filteredTeamMembers = this.callFlagFormGroup.controls.members.valueChanges.pipe(
  //       startWith(null),
  //       map((member: string | null) => member ? this._filter(member) : this.teamMembers.slice()));
  //  });
  }

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   // Add team member to chip set
  //   if ((value || '').trim()) {
  //     this.members.push(value.trim());
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }
  //   this.teamMembersFromControl.setValue(null);
  // }

  // remove(member: string): void {
  //   const index = this.members.indexOf(member);

  //   if (index >= 0) {
  //     this.members.splice(index, 1);
  //   }
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   this.members.push(event.option.value);
  //   this.membersInput.nativeElement.value = '';
  //   this.teamMembersFromControl.setValue(null);
  // }

  // private _filter(value: any): string[] {
  //   let filterValue = '';
  //   if (value.name){
  //     filterValue = value.name.toLowerCase();
  //   }
  //   else{
  //     filterValue = value.toLowerCase();
  //   }
  //   return this.teamMembers.filter(member => member.name.toLowerCase().indexOf(filterValue) === 0);
  // }

  closeDialog(){
    if (this.dialogData){
      this.dialogRef.close();
    }
    else{
      this.bottomSheetRef.dismiss();
    }
  }

  callFlagSubmit(){
    if (this.callFlagFormGroup.valid){
     // const taggedTeamMembers = this.members.map(x => x.id);
      const notes = this.callFlagFormGroup.controls.notes.value;
      const flagDto = {notes };
      this.callControllerService.createCallFlagNoteUsingPOST(this.callId, flagDto).subscribe(response => {
       // this.teamMembers = [];
        if (this.dialogData){
          this.dialogRef.close();
        }
        else{
          this.bottomSheetRef.dismiss();
        }
        this.snackBarService.openToastNotification('Call escalated');
      });
    }
  }

  // deleteNotes(noteId: number){
  //   this.dialog.open(DeleteCommentComponent, {
  //     data: {noteId, callId: this.callId}
  //   });

  //   this.dialogRef.afterClosed().subscribe(result => {
  //     this.ngOnInit();
  //   });
  // }

  // loadData(){
  //   this.callControllerService.getCallFlagNotesUsingGET(this.callId).subscribe(response => {
  //     this.callFlagDto = response[0];
  //   });
  // }

  unflagCall(){
    this.callControllerService.deleteCallFlagNoteUsingDELETE(this.callId, this.callFlagDto.id).subscribe(response => {
      this.snackBarService.openToastNotification('Call De-Escalated');
      this.closeDialog();
    });
  }

  // getDate(date: string){
  //   const dateTime = date.split(' ');
  //   const x = dateTime[0].split('.').join('/');
  //   const time = dateTime[1].split(':');
  //   return x + ' ' + time[0] + ':' + time[1];
  // }

}
