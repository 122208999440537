<div class="progress-bar-grid">
    <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
  </div>
<main fxLayout="row wrap">
    <strong fxFlex="100%">These are Frequently Asked Questions about your specific clinic</strong>
    <form *ngIf="this.roleCaption.get(this.role) === 'Captain'" [formGroup]="helpfulInfoFormGroup" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <section  fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start center">
            <ul fxFlex="80%" fxLayout="row wrap" formArrayName="questions">
                <li *ngFor="let item of questionsArray.controls; let y = index" [formGroupName]="y" fxLayout="row wrap"  fxFlex="750px" fxLayoutGap="16px" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="636px" >
                        <mat-label>Question</mat-label>
                        <input formControlName="question" matInput maxlength="255" />
                        <mat-error *ngIf="questionsArray.get(getIndex(y)).get('question').hasError('required')">Question is required</mat-error>
                        <mat-error *ngIf="questionsArray.get(getIndex(y)).get('question').hasError('pattern')">Question must have valid format</mat-error>
                      </mat-form-field>
                      <button  fxFlex *ngIf="showDeleteFaq()" mat-icon-button (click)="removeQuestion(y)"><mat-icon color="warn">delete</mat-icon></button>
                      <mat-form-field fxFlex="636px" class="element-up-8">
                        <mat-label>Answer</mat-label>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" formControlName="answer" matInput maxlength="255"></textarea>
                        <mat-error *ngIf="questionsArray.get(getIndex(y)).get('answer').hasError('required')">Answer is required</mat-error>
                        <mat-error *ngIf="questionsArray.get(getIndex(y)).get('answer').hasError('pattern')">Answer must have valid format</mat-error>
                      </mat-form-field>
                    </li>
                </ul> 
                <div fxFlex="100%">
                    <a  (click)="addNewQuestion()"><strong><b>Add new</b></strong></a>
                </div>       
        </section>
        <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="cancel()"   mat-button color="primary"
            >Cancel</button>
          <button (click)="updateFaqs()" [disabled]="isWaiting" mat-raised-button color="primary"
           >Save</button>
    
        </footer>
    </form>
    <section *ngIf="this.roleCaption.get(this.role) !== 'Captain'" fxLayout="row wrap" fxFlex="100%">
        <div fxFlex="30%" fxLayout="row wrap" fxLayoutAlign="start start">
                <p fxFlex="100%"><b>Frequently Asked Questions</b></p>
                <mat-form-field fxFlex="100%" appearance="outline"  >
                    <mat-label>Search FAQ's</mat-label>
                    <input matInput [(ngModel)]="searchedTerm" (input)="searchData()"/>
                    <mat-icon matSuffix>search</mat-icon>
             </mat-form-field>   
        </div>
        <ul  fxFlex="100%"  fxLayout="row wrap"  fxLayoutAlign="space-between start">
            <li *ngFor="let item of dto" fxLayout="column" class="margin-bottom-24" fxFlex="40%" fxLayoutGap="8px">
                <strong><b [innerHTML]="item?.question | highlight: searchedTerm"></b></strong>
                <strong [innerHTML]="item?.answer | highlight: searchedTerm"></strong>
            </li>
        </ul>

    </section>

</main>
