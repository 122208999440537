import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clinic-team',
  templateUrl: './clinic-team.component.html',
  styleUrls: ['./clinic-team.component.scss']
})
export class ClinicTeamComponent implements OnInit {
  clinicId: number;
  role = '';
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
  }

}
