import { DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { PaginationDataSource, PaginationEndpoint, Sort, Page } from 'ngx-pagination-data-source';
import { timer } from 'rxjs';


export class ExtendPaginationDataSource<T, Q = Partial<T>> extends PaginationDataSource<T> {
    currentPage = 1;
    interval: any;
    constructor(endpoint: PaginationEndpoint<T, Q>, initialSort: Sort<T>, initialQuery: Q, pageSize?: number){
        super(endpoint , initialSort , initialQuery , pageSize);

        clearInterval(this.interval);
        this.interval = setInterval(() => {
             super.fetch(this.currentPage - 1);
        }, 10000);

        this.page$.subscribe((page) => {
           this.currentPage = page.number;
        });
    }

     queryBy(query: Partial<Q>): void {
        super.queryBy(query);
      }

      sortBy(sort: Partial<Sort<T>>): void {
          super.sortBy(sort);
      }

      disconnect(): void {
        clearInterval(this.interval);
      }

}
