/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CallBackQuestionDTO } from './callBackQuestionDTO';
import { ScribeActionDTO } from './scribeActionDTO';


export interface CallBackDTO { 
    callBackDuration?: string;
    callBackNotes?: string;
    callId?: number;
    clinicId?: number;
    createdBy?: string;
    createdById?: number;
    createdDate?: Date;
    followUpNotes?: string;
    id?: number;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    phoneNumber?: string;
    questions?: Array<CallBackQuestionDTO>;
    scribeActions?: Array<ScribeActionDTO>;
    status?: CallBackDTO.StatusEnum;
}
export namespace CallBackDTO {
    export type StatusEnum = 'NEWCALL' | 'INPROGRESS' | 'INCOMPLETE' | 'FOLLOWUP' | 'COMPLETE';
    export const StatusEnum = {
        NEWCALL: 'NEWCALL' as StatusEnum,
        INPROGRESS: 'INPROGRESS' as StatusEnum,
        INCOMPLETE: 'INCOMPLETE' as StatusEnum,
        FOLLOWUP: 'FOLLOWUP' as StatusEnum,
        COMPLETE: 'COMPLETE' as StatusEnum
    };
}
