/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserDTO { 
    activated?: boolean;
    activeRole?: UserDTO.ActiveRoleEnum;
    clinicId?: number;
    createdBy?: string;
    createdDate?: Date;
    defaultColumns?: string;
    email?: string;
    enableCallVolumePopupNotification?: boolean;
    enableCallVolumeSoundNotification?: boolean;
    enableDeActivateClinicPopupNotification?: boolean;
    enableDeActivateClinicSoundNotification?: boolean;
    enableDoNotDisturb?: boolean;
    enableEnglishCallPopupNotification?: boolean;
    enableEnglishCallSoundNotification?: boolean;
    enableErOutcomePopupNotification?: boolean;
    enableErOutcomeSoundNotification?: boolean;
    enableFlaggedCallPopupNotification?: boolean;
    enableFlaggedCallSoundNotification?: boolean;
    enableFollowUpCallPopupNotification?: boolean;
    enableFollowUpCallSoundNotification?: boolean;
    enableIncompleteCallPopupNotification?: boolean;
    enableIncompleteCallSoundNotification?: boolean;
    enableIncompleteClinicPopupNotification?: boolean;
    enableIncompleteClinicSoundNotification?: boolean;
    enableNewCallPopupNotification?: boolean;
    enableNewCallSoundNotification?: boolean;
    enableNewClinicPopupNotification?: boolean;
    enableNewClinicSoundNotification?: boolean;
    enableResourceBoardPopupNotification?: boolean;
    enableResourceBoardSoundNotification?: boolean;
    enableSpanishCallPopupNotification?: boolean;
    enableSpanishCallSoundNotification?: boolean;
    enableUpdatedClinicPopupNotification?: boolean;
    enableUpdatedClinicSoundNotification?: boolean;
    firstName?: string;
    id?: number;
    isoCountryCode?: string;
    lastActiveDate?: string;
    lastLoginDate?: string;
    lastLogoutDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    lastName?: string;
    onlineStatus?: UserDTO.OnlineStatusEnum;
    phoneNumber?: string;
    role?: UserDTO.RoleEnum;
    userTimeZone?: string;
}
export namespace UserDTO {
    export type ActiveRoleEnum = 'ROLE_ADMIN' | 'ROLE_CAPTAIN' | 'ROLE_VOLUNTEER' | 'ROLE_COORDINATOR';
    export const ActiveRoleEnum = {
        ADMIN: 'ROLE_ADMIN' as ActiveRoleEnum,
        CAPTAIN: 'ROLE_CAPTAIN' as ActiveRoleEnum,
        VOLUNTEER: 'ROLE_VOLUNTEER' as ActiveRoleEnum,
        COORDINATOR: 'ROLE_COORDINATOR' as ActiveRoleEnum
    };
    export type OnlineStatusEnum = 'PENDING' | 'INCALL' | 'ONLINE' | 'IDLE' | 'OFFLINE' | 'DEACTIVATED';
    export const OnlineStatusEnum = {
        PENDING: 'PENDING' as OnlineStatusEnum,
        INCALL: 'INCALL' as OnlineStatusEnum,
        ONLINE: 'ONLINE' as OnlineStatusEnum,
        IDLE: 'IDLE' as OnlineStatusEnum,
        OFFLINE: 'OFFLINE' as OnlineStatusEnum,
        DEACTIVATED: 'DEACTIVATED' as OnlineStatusEnum
    };
    export type RoleEnum = 'ROLE_ADMIN' | 'ROLE_CAPTAIN' | 'ROLE_VOLUNTEER' | 'ROLE_COORDINATOR';
    export const RoleEnum = {
        ADMIN: 'ROLE_ADMIN' as RoleEnum,
        CAPTAIN: 'ROLE_CAPTAIN' as RoleEnum,
        VOLUNTEER: 'ROLE_VOLUNTEER' as RoleEnum,
        COORDINATOR: 'ROLE_COORDINATOR' as RoleEnum
    };
}
