import { Component, OnInit, OnDestroy, Optional, HostListener } from '@angular/core';
import {
  CallDTO, CallControllerService,
  ClinicControllerService, UpdateCallDTO,
  UpdateCallBackQuestionDTO, CallBackControllerService,
  CallBackDTO, CallFlagNoteDTO, CallBackQuestionDTO, ScribeActionDTO,
  CallBackNoteDTO,
  EndCallBackDTO,
  HelpfulInformationDTO,
  CallBackFollowUpNoteDTO,
  UpdateCallBackDTO
} from '@helplinesos-api-client';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DialogService } from '../dialog.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription, Observable, Observer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ErrorService } from '../../shared/error.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { DirtyComponent } from '../../shared/models/dirty-component';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UnassignDialogComponent } from './unassign-dialog/unassign-dialog.component';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CanDeactivate } from '@angular/router';
import { LeaveScribeDialogComponent } from './leave-scribe-dialog/leave-scribe-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { AnonymousSubject } from 'rxjs/internal/Subject';

export interface TimerData {
  callId: number;
  clinicId: number;
}

export interface ScribeHistoryData {
  callBackId: number;
  callDto: CallDTO;
  callBacks: CallBackDTO[];
}


@Component({
  selector: 'app-scribe',
  templateUrl: './scribe.component.html',
  styleUrls: ['./scribe.component.scss']
})
export class ScribeComponent extends ComponentBase implements OnInit, OnDestroy {
  previousCallStatus: any;
  callId: number;
  callBackId: number;
  callBacksForHistory: CallBackDTO[];
  hasHistory = false;
  isDirty = false;
  helpfulInformationDto: HelpfulInformationDTO;
  timeOutSubscription: Subscription;
  isBulletinShown = false;
  bulletinValue = '';
  callFlagDto: CallFlagNoteDTO;
  clinicId: number;
  isHelpfulInformationShown = true;
  callDto: CallDTO;
  questions: UpdateCallBackQuestionDTO[] = [];
  validations: any[] = [];
  callBackDto: CallBackDTO;
  currentQuestionsState: UpdateCallBackQuestionDTO[] = [];
  currentCallbackState: CallBackDTO;
  currentCallState: CallDTO;
  isNewCall: string;
  notes: any;
  isRegularLeaving = false;
  formGroup: FormGroup;

  type = 'N/A';
  constructor(
    private snackBarService: CustomSnackbarService,
    private formBuilder: FormBuilder,
    private callBackControllerService: CallBackControllerService,
    private dialog: MatDialog,
    private callControllerService: CallControllerService,
    @Optional() private dialogRef: MatDialogRef<UnassignDialogComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<UnassignDialogComponent>,
   // private bnIdle: BnNgIdleService,
    private dialogService: DialogService,
    private callController: CallControllerService,
    private callBackController: CallBackControllerService,
    private clinicController: ClinicControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
  ) { super(); }

  scribeInfo: any[] = [
    {
      age: '',
      type: '',
      symptoms: '',
      underlyingDisease: '',
      contact: '',
      clinicPatient: '',
      zipcode: ''
    }
  ];

  displayedColumns: string[] = ['age', 'type', 'symptoms', 'underlyingDisease', 'contact', 'clinicPatient', 'zipcode'];

  roleCaption = new Map([
    [CallDTO.StatusEnum.NEWCALL, 'New Call'],
    [CallDTO.StatusEnum.INPROGRESS, 'In Progress'],
    [CallDTO.StatusEnum.COMPLETE, 'Complete'],
    [CallDTO.StatusEnum.INCOMPLETE, 'Incomplete'],
    [CallDTO.StatusEnum.FOLLOWUP, 'Follow up'],
    [CallDTO.CallTypeEnum.COMPLEX, 'Complex'],
    [CallDTO.CallTypeEnum.INFORMATION, 'Information'],
    [CallDTO.CallTypeEnum.SIMPLE, 'Simple'],
    [CallDTO.CallTypeEnum.TESTING, 'Testing'],
    [CallDTO.LanguageEnum.ENGLISH, 'English'],
    [CallDTO.LanguageEnum.SPANISH, 'Spanish'],
    [ScribeActionDTO.TypeEnum.RADIO, 'Radio'],
    [ScribeActionDTO.TypeEnum.CHECK, 'Check']
  ]);

  boolDropdown: any[] = [
    { value: true, display: 'Yes', state: true },
    { value: false, display: 'No', state: false },
  ];

  ngOnInit(): void {

    this.isWaiting = true;

    this.formGroup = this.formBuilder.group({
      age: ['', [Validators.required, Validators.min(0), Validators.max(120)]],
      zipCode: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
    this.isNewCall = this.route.snapshot.queryParamMap.get('isNewCall');

    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== '') {
        this.isWaiting = false;
      }
    }));

    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));

    // if (this.bnIdle.expired$.observers.length === 0) {
    //   this.timeOutSubscription = this.bnIdle.startWatching(60 * 30).subscribe((isTimedOut: boolean) => {
    //     if (isTimedOut) {
    //       console.log('session expired');
    //       this.dialogService.openTimer(this.callDto.id, this.clinicId);
    //     }
    //   });
    // }
    // else {
    //   this.bnIdle.resetTimer();
    // }

    this.callId = Number(this.route.snapshot.paramMap.get('callId'));
    this.callBackId = Number(this.route.snapshot.paramMap.get('callBackId'));

    this.clinicController.getHelpfulInformationUsingGET(this.clinicId).subscribe(response => {
      this.helpfulInformationDto = response;
    });

    this.callController.getCallUsingGET(this.callId).subscribe(response => {
      this.callDto = response;
      this.previousCallStatus = this.callDto?.status;
      this.type = this.roleCaption.get(this.callDto?.callType);
      this.formGroup = this.formBuilder.group({
        age: [this.callDto?.age, [Validators.required, Validators.min(0), Validators.max(120)]],
        zipCode: [this.callDto?.zip, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(5)]]
      });
      this.getCallback(this.callBackId);
    });
    this.getBulletin();
    this.getCallHistory();
  }

  ngOnDestroy() {

    // this.bnIdle.stopTimer();
    //  this.timeOutSubscription.unsubscribe();

  }

  public onPageUnload($event: BeforeUnloadEvent) {
    // if (this.hasUnsavedData()) {
    $event.returnValue = true;
    // }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  // canDeactivate(
  //   component: ScribeComponent,
  //   currentRoute: ActivatedRouteSnapshot,
  //   currentState: RouterStateSnapshot,
  //   nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   const x = nextState.url;
  //   if (x === '/login' || component.isRegularLeaving) {
  //     return true;
  //   }
  //   const isDirty = component.checkIsDirtyState();
  //   if (isDirty) {
  //   return Observable.create((observer: Observer<boolean>) => {
  //     const dialogRef = this.dialog.open(LeaveScribeDialogComponent, {
  //       maxWidth: '560px',
  //       minWidth: '560px',
  //       backdropClass: 'dialog-backdrop'
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       observer.next(result);
  //       observer.complete();
  //       if (result){
  //         component.sendCallBackToPending();
  //       }
  //       return result;
  //     });
  //   });
  //   }
  //   else {
  //     if (component.isNewCall === 'true'){
  //       component.revertCallToNewCall();
  //       return true;
  //     }
  //     else{
  //       return true;
  //     }
  //   }
  // }

  showHelpfulInformation() {
    this.isHelpfulInformationShown = !this.isHelpfulInformationShown;
  }


  isTypeWarn(callType: CallDTO.CallTypeEnum) {
    let warn = '';
    if (callType === CallDTO.CallTypeEnum.COMPLEX || callType === CallDTO.CallTypeEnum.SIMPLE) {
      warn = 'textDanger';
    }
    return warn;
  }

  isWarn(element: boolean) {
    let warn = '';
    if (element) {
      warn = 'textDanger';
    }
    return warn;
  }

  updateCall(propertyName: string, newValue: any) {
    this.updateCallPropertyValue(propertyName, newValue);
    // const updateCall: UpdateCallDTO = this.getUpdateCallDto();
    // this.callController.updateCallUsingPUT(this.callDto.id, updateCall, 'response').subscribe(
    //   (response) => {
    //     this.callDto = response.body;
    //     this.type = this.roleCaption.get(this.callDto?.callType);
    //   }
    // );
  }

  updateCallback(element: ScribeActionDTO, newValue: any) {
    const questionForUpdate = this.getQuestion(element.id);
    this.updateAnswer(questionForUpdate, element.type, newValue);
    // const updateCallBack = questionForUpdate as UpdateCallBackQuestionDTO;
    // this.callBackController.updateCallBackQuestionUsingPATCH(this.callBackDto.id, this.callDto.id, updateCallBack, 'response').subscribe(
    //   (response) => {
    //     const test = response.body;
    //   }
    //);
  }

  updateCallbackNotes(notes: any) {
    this.notes = notes;
    // const noteDto = { notes } as CallBackNoteDTO;
    // this.callBackController.updateCallBackNotesUsingPATCH(this.callBackDto.id, noteDto, this.callDto.id, 'response').subscribe(
    //   (response) => {
    //     const test = response.body;
    //   }
    // );
  }

  updateAnswer(element: any, type: ScribeActionDTO.TypeEnum, newValue: any) {
    if (element.answer === '') {
      element.answer = newValue;
      return;
    }
    switch (type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
        element.answer = newValue;
        break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        let arrayOfValues = element.answer.split(',') as string[];
        const currentValue = arrayOfValues.find(x => x === newValue);
        if (currentValue !== undefined && currentValue !== null) {
          arrayOfValues = arrayOfValues.filter(x => x !== currentValue);
        }
        else {
          arrayOfValues.push(newValue);
        }
        element.answer = arrayOfValues.join(',');
        break;
      }
      default:
        break;
    }
  }

  getBulletin() {
    this.clinicController.getBulletinUsingGET(this.clinicId, 'response').subscribe(
      (response) => {
        this.bulletinValue = response?.body?.bulletin;
      }
    );
  }


  getCallback(callBackId: number) {
    this.callBackController.getCallBackUsingGET(callBackId, this.callDto.id, 'response').subscribe(
      (response) => {
        this.callBackDto = response.body;
        this.getScribeActions();
      }
    );
  }

  initCurrentState() {
    this.currentCallState = Object.assign({}, this.callDto);
    this.currentCallbackState = Object.assign({}, this.callBackDto);
    this.currentQuestionsState = [];

    for (const actions of this.callBackDto.scribeActions) {
      this.currentQuestionsState.push(
        {
          scibeActionId: actions.id,
          answer: ''
        }
      );
    }
    for (const question of this.callBackDto.questions) {
      const questionForUpdate = this.currentQuestionsState.find(x => x.scibeActionId === question.scibeActionId);
      if (questionForUpdate !== undefined && questionForUpdate != null) {
        questionForUpdate.answer = question.answer;
      }
    }
  }

  checkIsDirtyState() {
    if (JSON.stringify(this.callDto).toLowerCase() !== JSON.stringify(this.currentCallState).toLowerCase()) {
      return true;
    }

    if (JSON.stringify(this.callBackDto).toLowerCase() !== JSON.stringify(this.currentCallbackState).toLowerCase()) {
      return true;
    }

    if (JSON.stringify(this.questions).toLowerCase() !== JSON.stringify(this.currentQuestionsState).toLowerCase()) {
      return true;
    }

    return false;
  }

  getScribeActions() {
    // this.clinicController.getScribeActionUsingGET(this.clinicId, 'response').subscribe(
    //   (response) => {
    //     this.callBackDto.scribeActions = response?.body;
    for (const actions of this.callBackDto.scribeActions) {
      this.questions.push(
        {
          scibeActionId: actions.id,
          answer: ''
        });

      this.validations.push(
        {
          scribeActionId: actions.id,
          message: 'This action is required',
          isRequired: actions.required,
          showValidation: false
        }
      );
    }
    for (const question of this.callBackDto.questions) {
      const questionForUpdate = this.getQuestion(question.scibeActionId);
      if (questionForUpdate !== undefined && questionForUpdate != null) {
        questionForUpdate.answer = question.answer;
      }
    }

    this.initCurrentState();
    this.isWaiting = false;
    //   }
    // );
  }

  getReorderedScribeActions() {
    const scribeActions: ScribeActionDTO[] = [];
    const numberOfActions = this.callBackDto?.scribeActions.length;
    if (numberOfActions % 2 === 0) {
      for (let i = 0; i < numberOfActions / 2; i++) {
        scribeActions.push(this.callBackDto?.scribeActions[i]);
        scribeActions.push(this.callBackDto?.scribeActions[i + (numberOfActions / 2)]);
      }
    }
    else {
      for (let i = 0; i < numberOfActions / 2; i++) {
        scribeActions.push(this.callBackDto?.scribeActions[i]);
        scribeActions.push(this.callBackDto?.scribeActions[i + ((numberOfActions + 1) / 2)]);
      }
    }
    return scribeActions;
  }

  getRequiredScribeActions() {
    const scribeActions: ScribeActionDTO[] = [];
    this.callBackDto?.scribeActions.forEach(x => {
      if (x.required) {
        scribeActions.push(x);
      }
    });
    return scribeActions;
  }

  getOptionalScribeActions() {
    const scribeActions: ScribeActionDTO[] = [];
    this.callBackDto?.scribeActions.forEach(x => {
      if (!x.required) {
        scribeActions.push(x);
      }
    });
    return scribeActions;
  }


  getQuestion(questionId: number) {
    return this.questions.find(x => x.scibeActionId === questionId);
  }

  isSelected(element: ScribeActionDTO, value: any) {
    let result = false;
    switch (element?.type) {
      case ScribeActionDTO.TypeEnum.RADIO: {
        result = this.isSelectedSingleValue(element.id, value);
        break;
      }
      case ScribeActionDTO.TypeEnum.CHECK: {
        result = this.isSelectedMultipleValue(element.id, value);
        break;
      }
      default:
        break;
    }
    return result;
  }

  isSelectedSingleValue(questionId: number, value: any) {
    const element = this.getQuestion(questionId);
    return element?.answer === value;
  }

  getSelectedSingleValue(questionId: number) {
    const element = this.getQuestion(questionId);
    return element?.answer;
  }

  isSelectedMultipleValue(questionId: number, value: any) {
    const element = this.getQuestion(questionId);
    const arrayOfValues = element?.answer.split(',') as string[];
    return arrayOfValues?.find(x => x === value) !== undefined ? true : false;
  }

  getQuestionValue(questionId: number) {
    return this.getQuestion(questionId)?.answer;
  }

  getValidation(id: number) {
    return this.validations?.find(x => x.scribeActionId === id);
  }


  // followUpSubmit(notes: string){
  //   this.isFollowUpSet = true;
  //   this.followUpNotes = notes;
  //   this.endCallBack();
  // }

  // followUpSkip(){
  //   this.isFollowUpSet = true;
  //   this.followUpNotes = '';
  //   this.endCallBack();
  // }

  sendCallBackToPending() {

    for (const question of this.questions) {
      const action = this.callBackDto.scribeActions?.find(x => x.id === question.scibeActionId);
      if (action !== undefined && action !== null) {
        if (action.question.includes('Send call to')) {
          question.answer = '';
        }
      }
    }
    const saveQuestions: UpdateCallBackQuestionDTO[] = [];
    for (const question of this.questions) {
      if (question.answer !== '') {
        saveQuestions.push(question);
      }
    }
    const endCallBack: EndCallBackDTO = {
      updateCallBackQuestionDTO: saveQuestions
    };

    const updateCallBackDto: UpdateCallBackDTO = {
      updateCallBackQuestionDTO: saveQuestions
    };

    const updateCall: UpdateCallDTO = this.getUpdateCallDto();
    this.callController.updateCallUsingPUT(this.callDto.id, updateCall, 'response').subscribe(
      (response) => {
        this.isDirty = false;
        this.callDto = response.body;
        this.type = this.roleCaption.get(this.callDto?.callType);
        //  this.callBackController.endCallBackUsingPUT(this.callBackDto.id, this.callDto.id, endCallBack).subscribe(response => {
        this.callBackController.updateCallBackUsingPATCH(this.callBackDto.id, this.callDto.id, updateCallBackDto).subscribe(response => {
          const noteDto = { notes: this.notes } as CallBackNoteDTO;
          this.callBackController.updateCallBackNotesUsingPATCH(this.callBackDto.id, noteDto, this.callDto.id, 'response').subscribe(
            (response1) => {
              this.isWaiting = false;
              // this.router.navigate(['call-queue/' + this.clinicId]);
            }
          );
        });

        //   });
      }
    );
  }

  revertCallToNewCall() {
    this.callController.unAssignCallUsingPOST(this.callBackDto.id, this.callDto.id, 'response').subscribe(
      (response) => {
        this.router.navigate(['call-queue/' + this.clinicId]);
      }
    );
  }

  endCallBack() {
    if (!this.formGroup.valid) {
      this.snackBarService.openErrorNotification('All required fields must be correctly filled');
      return;
    }
    this.isRegularLeaving = true;
    // this.sendCallBackToPending();
    // return;
    this.isWaiting = true;
    let isValid = true;
    // let showFollowUpPopup = false;
    for (const question of this.questions) {

      // const action = this.callBackDto.scribeActions?.find(x => x.id === question.scibeActionId);
      // if (action !== undefined && action !== null) {
      //    if ( action.title.toUpperCase().includes('FOLLOW') || action.question.toUpperCase().includes('FOLLOW') ){
      //      if (question.answer.toUpperCase().includes('YES')){
      //        showFollowUpPopup = true;
      //      }
      //    }
      // }

      const validation = this.validations?.find(x => x.scribeActionId === question.scibeActionId);
      if (validation === undefined || validation === null) {
        continue;
      }
      if (validation.isRequired && question.answer === '') {
        validation.showValidation = true;
        isValid = false;
      }
      else {
        validation.showValidation = false;
      }

    }

    if (!isValid) {
      this.snackBarService.openErrorNotification('All required fields must be filled');
      this.isRegularLeaving = false;
      this.isWaiting = false;
      return;
    }

    // if (showFollowUpPopup && !this.isFollowUpSet){
    //   const followUpRef = this.dialogService.openFollowUpDialog();
    //   followUpRef.afterClosed().subscribe((response) => {
    //     if (response) {
    //       this.followUpSubmit(response);
    //     }
    //     else{
    //       this.followUpSkip();
    //     }
    //   });
    //   return;
    // }



    const saveQuestions: UpdateCallBackQuestionDTO[] = [];
    for (const question of this.questions) {
      if (question.answer !== '') {
        saveQuestions.push(question);
      }
    }
    const endCallBack: EndCallBackDTO = {
      updateCallBackQuestionDTO: saveQuestions
    };

    const updateCall: UpdateCallDTO = this.getUpdateCallDto();
    this.callController.updateCallUsingPUT(this.callDto.id, updateCall, 'response').subscribe(
      (response) => {
        this.isDirty = false;
        this.callDto = response.body;
        this.type = this.roleCaption.get(this.callDto?.callType);
        this.callBackController.endCallBackUsingPUT(this.callBackDto.id, this.callDto.id, endCallBack).subscribe(response => {

          const noteDto = { notes: this.notes } as CallBackNoteDTO;
          this.callBackController.updateCallBackNotesUsingPATCH(this.callBackDto.id, noteDto, this.callDto.id, 'response').subscribe(
            (response1) => {

              // if ( this.isFollowUpSet && this.followUpNotes !== ''){
              //   const followUpNoteDto = { followUpNotes: this.followUpNotes } as CallBackFollowUpNoteDTO;
              //   // tslint:disable-next-line: max-line-length
              //   this.callBackController.updateCallBackFollowUpNotesUsingPATCH(followUpNoteDto,
              // this.callBackDto.id, this.callDto.id, 'response').subscribe(
              //     (response2) => {
              //       this.isWaiting = false;

              //       this.router.navigate(['call-queue/' + this.clinicId]);
              //     }
              //   );
              // }
              // else{
              this.isWaiting = false;
              if (this.previousCallStatus === CallDTO.StatusEnum.NEWCALL ||
                this.previousCallStatus === CallDTO.StatusEnum.INPROGRESS ||
                this.previousCallStatus === CallDTO.StatusEnum.INCOMPLETE){
            this.previousCallStatus =
              CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE;
          }
              this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { status: this.previousCallStatus } });
          // }
        },
          err => {
            this.isRegularLeaving = false;
          }
        );
      }, err => {
        this.isRegularLeaving = false;
      });
  }
    );
}

getCallPhoneNumber(phoneNumber: string) {
  return phoneNumber ?
    '(' +
    phoneNumber.slice(0, 3) + ') ' +
    phoneNumber.slice(4, 7) +
    phoneNumber.slice(7) : 'Phone number not set';
}

// isPhoneNumberSet() {
//   // const isSet = this.callDto?.clinicPhoneNumbers == null || this.callDto?.clinicPhoneNumbers.length === 0 ? false : true;
//   // return isSet;
//   return false;
// }

// getPhoneNumber(element: PhoneNumberDTO) {

//   if (element === null) {
//     return '(not set)';
//   }
//   const elementFormat =
//     'Clinic ' +
//     element.type +
//     ' (' + element.phoneNumber.slice(0, 3) + ')' +
//     element.phoneNumber.slice(3, 6) + '-' +
//     element.phoneNumber.slice(6);

//   return elementFormat;
// }

getHotlineNumber() {
  return 'Clinic Hotline: (' + this.callDto?.clinicHotlineNumber.slice(0, 3) + ') ' +
    this.callDto?.clinicHotlineNumber.slice(3, 6) + '-' +
    this.callDto?.clinicHotlineNumber.slice(6);
}

openFlag() {
  this.callController.getCallFlagNotesUsingGET(this.callDto.id).subscribe(response => {
    this.callFlagDto = response[0];
    this.dialogService.openFlagCall(this.callFlagDto, this.callDto.id);
  });
}


updateCallPropertyValue(name: string, value: any) {
  switch (name) {
    case 'Age': {
      // tslint:disable-next-line: radix
      this.callDto.age = Number.parseInt(value);
      break;
    }
    case 'Symptoms': {
      this.callDto.hasSymptoms = value;
      break;
    }
    case 'UnderlyingDisease': {
      this.callDto.hasUnderlyingDisease = value;
      break;
    }
    case 'Contact': {
      this.callDto.hadContactWithInfected = value;
      break;
    }
    case 'ClinicPatient': {
      this.callDto.patient = value;
      break;
    }
    case 'Zipcode': {
      this.callDto.zip = value;
      break;
    }
    case 'Insurance': {
      this.callDto.hasInsurance = value;
      break;
    }
    default:
      break;
  }
}

getUpdateCallDto() {

  const updateCallDto: UpdateCallDTO = {
    age: this.formGroup.controls.age.value,
    hadContactWithInfected: this.callDto.hadContactWithInfected,
    hasSymptoms: this.callDto.hasSymptoms,
    hasTraveledToInfectedArea: this.callDto.hasTraveledToInfectedArea,
    hasUnderlyingDisease: this.callDto.hasUnderlyingDisease,
    language: this.callDto.language,
    patient: this.callDto.patient,
    phoneNumber: this.callDto.phoneNumber.replace(/[&\/\\()-]/g, ''),
    seekingTest: this.callDto.seekingTest,
    surveyFeedBack: this.callDto.surveyFeedBack,
    zip: this.formGroup.controls.zipCode.value,
    hasInsurance: this.callDto.hasInsurance,
    receivedTest: this.callDto.receivedTest,
    referredForTest: this.callDto.referredForTest
  };

  return updateCallDto;
}

unassignCall() {
  const ref = this.dialogService.openUnassignCall(this.callDto.id, this.clinicId);
  ref.afterClosed().subscribe(result => {
    if (result) {
      this.callControllerService.unAssignCallUsingPOST(this.callBackDto.id, this.callDto.id).subscribe(response => {
        this.isRegularLeaving = true;
        if (this.previousCallStatus === CallDTO.StatusEnum.NEWCALL ||
          this.previousCallStatus === CallDTO.StatusEnum.INPROGRESS ||
          this.previousCallStatus === CallDTO.StatusEnum.INCOMPLETE){
      this.previousCallStatus =
        CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE;
    }
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { status: this.previousCallStatus } });
        this.isDirty = false;
      });
    }
  });
}
// if ( this.callBacksDto.length === 0){
//   this.haveHistory = false;
// }

openCallHistory() {
  // this.callBackControllerService.getCallBacksUsingGET(this.callDto.id, 'response').subscribe(
  //   (response) => {
  //     let callBacks = response.body;
  //     const currentCallBack = callBacks?.find(x => x.id === this.callBackDto?.id);
  //     callBacks = callBacks?.filter(x => x.id !== this.callBackDto?.id && x.createdDate <= currentCallBack.createdDate )
  //                         .sort(function(a, b){
  //                                 return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
  //                               });
  //     if ( callBacks.length === 0){
  //       return;
  //     }
  //     else{
  this.dialogService.openCallHistory(this.callDto, this.callBackDto.id, this.callBacksForHistory);
  //     }
  // });
}

getCallHistory() {
  this.callBackControllerService.getCallBacksUsingGET(this.callId, 'response').subscribe(
    (response) => {
      this.callBacksForHistory = response.body;
      const currentCallBack = this.callBacksForHistory?.find(x => x.id === this.callBackId);
      this.callBacksForHistory = this.callBacksForHistory?.
        filter(x => x.id !== this.callBackId && x.createdDate <= currentCallBack.createdDate)
        .sort(function (a, b) {
          return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
        });
      if (this.callBacksForHistory.length > 0) {
        this.hasHistory = true;
      }
    });
}


getPhoneNumber(label: string, phonNumber: string) {
  return phonNumber ? label + ': (' +
    phonNumber.slice(0, 3) + ') ' +
    phonNumber.slice(3, 6) + '-' +
    phonNumber.slice(6) : label + ': (Not set)';
}

showBulletin(e: any){
  this.stopPropagation(e);
  this.isBulletinShown = true;
}

stopPropagation(e: any) {
  e.stopPropagation();
}

downloadFile(b64encodedString: string, fileType: string, fileName: string) {
  // if (b64encodedString) {
  //   const blob = this.base64ToBlob(b64encodedString, fileType, 512);
  //   saveAs(blob, fileName);
  // }
  this.openBase64InNewTab(b64encodedString, fileType);
}

openBase64InNewTab(data: any, mimeType: any) {
  data = data.replace(/\s/g, '');
  const x = data.split(',');
  const byteCharacters = atob(x[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: mimeType + ';base64' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}


  public base64ToBlob(b64Data: string, contentType: string, sliceSize: 512) {
  b64Data = b64Data.replace(/\s/g, '');
  const x = b64Data.split(',');
  const byteCharacters = atob(x[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

}

