import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserControllerService, ReportIssueDTO } from '@helplinesos-api-client';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';

@Component({
  selector: 'app-survey-feedback',
  templateUrl: './survey-feedback.component.html',
  styleUrls: ['./survey-feedback.component.scss']
})
export class SurveyFeedbackComponent implements OnInit {
  ratingArr: any[] = [];
  rating = 3;
  starCount = 5;
  formGroup: FormGroup;
  constructor(
    private customSnackBarService: CustomSnackbarService,
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private userControllerService: UserControllerService
  ) { }

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

    this.formGroup = this.formBuilder.group({
      description: ['', [Validators.required]]
    });
  }

  onClick(rating: number) {
    this.rating = rating;
  }

  submit(){
    if (this.formGroup.valid){
      const reportIssueDto: ReportIssueDTO =
      {
        description: this.formGroup.controls.description.value
      };
      this.userControllerService.createIssueUsingPOST(reportIssueDto).subscribe(response => {
        this.customSnackBarService.openToastNotification('Issue report sent');
        this.dialogRef.close();
      });
    }
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  cancel(){
    this.dialogRef.close();
  }

}
