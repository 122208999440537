import { Injectable, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UserDTO } from '@helplinesos-api-client';
import { EditEmailComponent } from './user-profile-editor/edit-email/edit-email.component';
import { ConfirmEmailChangesComponent } from './user-profile-editor/confirm-email-changes/confirm-email-changes.component';

@Injectable({
  providedIn: 'root'
})
export class UserDialogService {


  public innerWidth: any;

  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet
  ) { }

  getInnerWidth() {
    return this.innerWidth;
  }

  openPasswordConfirmationDialog(userDto: UserDTO) {
    this.onResize(event);
    if (this.getInnerWidth() >= 1280) {
      this.dialog.open(ConfirmEmailChangesComponent, {
        maxWidth: '576px',
        minWidth: '576px',
        backdropClass: 'dialog-backdrop',
        data: {userDto}
      });
    }
    else {
      this.bottomSheet.open(ConfirmEmailChangesComponent, {
      });
    }
  }

  openEmailEditor(userDto: UserDTO) {
    this.onResize(event);
    if (this.getInnerWidth() >= 1280) {
      this.dialog.open(EditEmailComponent, {
        maxWidth: '576px',
        minWidth: '576px',
        backdropClass: 'dialog-backdrop',
        data: {userDto}
      });
    }
    else {
      this.bottomSheet.open(EditEmailComponent, {
      });
    }
  }
}
