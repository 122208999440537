import { Component, OnInit, Input } from '@angular/core';
import { ClinicDTO, UserDTO, HoursOfOperationDTO } from '@helplinesos-api-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clinic-information-tab',
  templateUrl: './clinic-information-tab.component.html',
  styleUrls: ['./clinic-information-tab.component.scss']
})
export class ClinicInformationTabComponent implements OnInit {

  @Input() dto: ClinicDTO;
  role: any;
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain']
  ]);
  constructor(private router: Router) { }

  ngOnInit(): void {

    this.role = JSON.parse(localStorage.getItem('user'))?.role;
  }

  // getPhoneNumber() {
  //   // return this.clinicDto?.phoneNumbers ?? '(not set)';
  //   const numbers: string[] = [];
  //   this.dto?.phoneNumbers.forEach(item => {
  //     numbers.push(
  //       item.type +
  //       ' (' + item.phoneNumber.slice(0, 3) + ')' +
  //       item.phoneNumber.slice(3, 6) + '-' +
  //       item.phoneNumber.slice(6));
  //   });
  //   return numbers ?? '(not set)';
  // }

  // isPhoneNumberSet() {
  //   // return this.clinicDto?.phoneNumbers ?? '(not set)';
  //   // const isSet = this.dto?.phoneNumbers == null || this.dto?.phoneNumbers.length === 0 ? false : true;
  //   // return isSet;
  //   return false;
  // }

  getPhoneNumber(element: any) {

    if (element === null) {
      return '(not set)';
    }
    const elementFormat =
      element.type +
      ' (' + element.phoneNumber.slice(0, 3) + ') ' +
      element.phoneNumber.slice(3, 6) + '-' +
      element.phoneNumber.slice(6);

    return elementFormat;
  }

  getHotlineNumber() {
    return 'HOTLINE (' + this.dto?.hotlineNumber.slice(0, 3) + ') ' +
      this.dto?.hotlineNumber.slice(3, 6) + '-' +
      this.dto?.hotlineNumber.slice(6);
  }

  getContactNumber(phone: string) {
    return phone ? '(' + phone.slice(0, 3) + ')' +
      phone.slice(3, 6) + '-' +
      phone.slice(6) : '(none)';
  }

  getAddress() {
    if (this.dto?.address) {
      return [
        this.dto.address.streetAddress,
        this.dto.address.city,
        this.dto.address.zipCode
      ].join(', ');
    }

    return null;
  }


  getHoursOfOperations() {
    const hoursArray: string[] = [];


    function s(a: any, b: any) {
      return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b); // basic sort function that compares the indexes of the two days
    }

    const daysOfWeek =
    [HoursOfOperationDTO.DayOfWeekEnum.MONDAY,
      HoursOfOperationDTO.DayOfWeekEnum.TUESDAY,
      HoursOfOperationDTO.DayOfWeekEnum.WEDNESDAY,
      HoursOfOperationDTO.DayOfWeekEnum.THURSDAY,
      HoursOfOperationDTO.DayOfWeekEnum.FRIDAY,
      HoursOfOperationDTO.DayOfWeekEnum.SATURDAY,
      HoursOfOperationDTO.DayOfWeekEnum.SUNDAY,
    ];

    const onlyHours = this.dto?.hoursOfOperation.map(({ closingTime, openingTime }) => ({ closingTime, openingTime }));

    const uniqueHours = new Set();

    onlyHours?.forEach(arr => uniqueHours.add(JSON.stringify(arr)));

    uniqueHours?.forEach((x: any) => {
      const days: string[] = [];
      this.dto?.hoursOfOperation.forEach(y => {
        if (y.openingTime === JSON.parse(x).openingTime && y.closingTime === JSON.parse(x).closingTime) {
          days.push(y.dayOfWeek);
        }
      });
      days.sort(s);
      const h1 =
        'Days: ' + days.join(', ') + ', Clinic opens at ' + JSON.parse(x).openingTime + ', and closes at ' + JSON.parse(x).closingTime;
      // openingTime: [JSON.parse(x).openingTime, Validators.required],
      // closingTime: [JSON.parse(x).closingTime, Validators.required],
      // days: [days, Validators.required]

      hoursArray.push(h1);
    });
    if (hoursArray.length === 0) {
      hoursArray.push('Hours of operations not set');
    }
    return hoursArray;
  }

  goToEditClinic() {
    this.router.navigate(['clinics/' + this.dto.id + '/edit']);
  }
}
