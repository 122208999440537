<script src="https://cdn.jsdelivr.net/npm/sockjs-client@1/dist/sockjs.min.js">
    
</script>
<main>

    <header fxLayout="row wrap" fxLayoutAlign="space-between start">
        <h1 fxFlex>Clinic Dashboard</h1>
        <span>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
         </span>
    </header>
    
    <nav><button mat-raised-button color="primary" (click)="addClinic()">Add New Clinic</button></nav>

    <section>

        <header [className]="isClinicChecked?'hide':'show'"  fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="24px">
            <mat-form-field fxFlex="30%" appearance="outline"  >
                <mat-label>Search</mat-label>
                <input #in matInput (input)="dataSource.queryBy({ search: in.value, status: se.value })" />
                <!-- TODO: https://medium.com/better-programming/expressionchangedafterithasbeencheckederror-in-angular-what-why-and-how-to-fix-it-c6bdc0b22787 -->
                <mat-icon matSuffix>search</mat-icon>
                <!-- TODO: switch to search_off if input not blank -->
            </mat-form-field>   
            <mat-form-field fxFlex="13%" appearance="outline" >
                <mat-label>Status</mat-label>
                <mat-select #se [(value)]="selected" disableOptionCentering panelClass="position-of-content" multiple (selectionChange)="dataSource.queryBy({ search: in.value, status: se.value})" >
                    <mat-option *ngFor="let item of activeAndIncomplete" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <a fxFlex="200px" mat-stroked-button [matMenuTriggerFor]="menu">{{selected}}<mat-icon>arrow_drop_down</mat-icon></a >
                <mat-menu #menu="matMenu" >
                            <mat-list >

                                <mat-option (click)="stopPropagation($event)" *ngFor="let item of activeAndIncomplete" [value]="item.value">
                                     <mat-checkbox (click)="changeStatus($event,item.value)"></mat-checkbox>
                                     {{item.name}}
                                    </mat-option>
                            </mat-list>                
                </mat-menu> -->
            <app-paging-controls [style.visibility]="isOnePage ? 'hidden': 'visible'" fxFlex (pageChange)="pageChange($event)"></app-paging-controls>
        </header>

        <header *ngIf="isClinicChecked" fxLayout="column" class='selection-toolbar'>
            <mat-toolbar  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="60px">
                <h4 fxFlex>{{clinicsSelected}} Items Selected</h4>
                <h4>Email Reports</h4>
                <h4>Download Reports</h4>
                <h4><a (click)="deactivateListOfClinics()">Deactivate</a></h4>
               
              </mat-toolbar>
             <div><mat-icon>arrow_drop_down</mat-icon></div> 
        </header>
        <div class="progress-bar-grid">
            <mat-progress-bar *ngIf="dataSource.loading$ | async" mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- TODO: multi sort: https://github.com/angular/components/issues/7226#issuecomment-561117656 -->
        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="select">
                <mat-header-cell fxFlex="72px" *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell class="cursor-default" fxFlex="72px" *matCellDef="let element">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="toggle(element)" [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name" sticky>
                <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header>Clinic Name</mat-header-cell>
                <mat-cell fxFlex="25%" (click)="goToClinic(element.id)" *matCellDef="let element">{{ element.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="address">
                <mat-header-cell fxFlex="35%" *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
                <mat-cell fxFlex="35%" (click)="goToClinic(element.id)" *matCellDef="let element">{{ element.address }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
                <mat-header-cell fxFlex="20%" *matHeaderCellDef>Phone Number</mat-header-cell>
                <mat-cell fxFlex="20%" (click)="goToClinic(element.id)" *matCellDef="let element">{{ element.phone }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell fxFlex="15%" *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell fxFlex="15%" (click)="goToClinic(element.id)" *matCellDef="let element">{{ element.status }}</mat-cell>
            </ng-container>

            <mat-header-row class="mat-subheading-1" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row class="mat-body-2" *matRowDef="let element; columns: displayedColumns" [ngClass]="{ 'selected': selection.isSelected(element)}"></mat-row>
        </mat-table>
        <h4 fxFlexAlign="center" *ngIf="paginator.getTotalItems() === 0">There are no clinics to display</h4>
        <app-paging-controls [style.visibility]="isOnePage ? 'hidden': 'visible'" fxLayoutAlign="end start"  (pageChange)="pageChange($event)"></app-paging-controls>

    </section>
</main>