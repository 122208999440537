<div class="progress-bar-grid">
    <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
</div>
<main fxLayout="row wrap">

    <header *ngIf="this.roleCaption.get(this.role) === 'Captain'" fxFlex="100%" fxLayout="row wrap">
        <h2 fxFlex="100%">Reminders and Updates</h2>
        <p  fxFlex="100%">This information is displayed on the Scribe page.</p>
    </header>
       <p *ngIf="this.roleCaption.get(this.role) === 'Captain'">Important Reminders and Updates</p> 
    <form  *ngIf="this.roleCaption.get(this.role) === 'Captain'" [formGroup]="formGroup" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="start start">
        <mat-form-field fxFlex="1 1 100%" id="bulletin">
            <mat-label>Reminders and Updates</mat-label>
            <fate-material (paste)="onPaste($event)" [buttons]="['bold','italic','underline','strike','ordered','unordered','clean']" [formControl]="formGroup.controls['bulletin']"></fate-material>
          </mat-form-field>
          <footer fxFlex="100%"  fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="end center">
            <button  (click)="cancel()" mat-button color="primary"
            >Cancel</button>
          <button (click)="updateBulletin()"  [disabled]="isWaiting" mat-raised-button color="primary"
           >Save</button>
        </footer>
    </form>
    <section *ngIf="this.roleCaption.get(this.role) !== 'Captain'" fxFlex="100%" fxLayout="row wrap" >
        <strong fxFlex="100%"><b>Important Reminders and Updates</b></strong> 
        <div [innerHTML]="dto?.bulletin">
        </div>
    </section>
</main>
<!-- (paste)="onPaste($event)" -->