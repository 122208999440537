import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import {
  SecurityControllerService,
  CreateUserDTO,
  LoginDTO,
  UserDTO,
} from '@helplinesos-api-client';
import { ActivatedRoute } from '@angular/router';
import { AppValidators } from '../../app-validators';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  // encapsulation: ViewEncapsulation.None,
 // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent extends ComponentBase implements OnInit {
  registerForm: FormGroup;
  createUserDto: CreateUserDTO;
  email: string;
  isSubmitted = false;
  isWaiting = false;
  isPasswordControlFocused = false;
  timezone = '';
  timezoneText = 'Timezone';
  timezoneShowError = false;

  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private service: SecurityControllerService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private snackBarService: CustomSnackbarService
  ) { super(); }

  ngOnInit(): void {
    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));


    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[A-Za-z]/)]],
      lastName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[A-Za-z]/)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}'
          ),
        ],
      ],
      confirmPassword: [
        '',
        [Validators.required, AppValidators.isMatching('password')],
      ],
      termsAndConditions: ['', Validators.requiredTrue],
    });

    // https://stackoverflow.com/a/55929868
    this.registerForm.controls.password.valueChanges.subscribe(() => {
      this.registerForm.controls.confirmPassword.updateValueAndValidity();
    });

    this.email = this.route.snapshot.queryParamMap.get('email');

  }

  timezoneChange(element: any){
    this.timezone = element.nameValue;
    this.timezoneShowError = false;
  }

  openDialog() {
    // TODO: https://github.com/angular/components/issues/9823#issuecomment-528824405
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '684px', // IMPORTANT: otherwise it uses 80uw
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.registerForm.patchValue({
        termsAndConditions: true,
      });
    });
  }

  register() {
    this.isSubmitted = true;
    console.log(this.isSubmitted && this.registerForm.controls.termsAndConditions.errors?.required);

    if (this.timezone === ''){
      this.timezoneShowError = true;
    }

    if (this.registerForm.valid && this.timezone !== '') {
      this.isWaiting = true;
      const createUserDto: CreateUserDTO = {
        email: this.email,
        firstName: this.registerForm.value.firstName,
        lastName: this.registerForm.value.lastName,
        password: this.registerForm.value.password,
        phoneNumber: this.registerForm.value.phoneNumber.replace(/[&\/\\()-]/g, ''),
        userTimeZone: this.timezone,
        isoCountryCode: 'US'
      };
      this.service.registerUserUsingPOST(createUserDto).subscribe(
        (response) => {
         // this.isWaiting = false;
         // this.router.navigate(['/login']);
          const snackBar = this.snackBarService.openToastNotification('User Registered');
          const loginDto = {
            email: createUserDto.email,
            password: createUserDto.password,
            rememberMe: false
          };
          this.login(loginDto);
        },
      );

    }
  }

  onPasswordControlFocus() {
    this.isPasswordControlFocused = true;
  }

  onPasswordControlFocusOut() {
    this.isPasswordControlFocused = false;
  }

  login(loginDto: LoginDTO){
    this.service.loginUsingPOST(loginDto, 'response').subscribe(
      (response) => {
        localStorage.setItem('user', JSON.stringify(response.body)); // get using JSON.parse(localStorage.getItem('user'));
        localStorage.setItem('token', response.headers.get('authorization')); // get token from headers
        localStorage.setItem('rememberMe', String(loginDto.rememberMe));
        this.isWaiting = false;
        switch (response.body.role) {
          case UserDTO.RoleEnum.CAPTAIN:
            case UserDTO.RoleEnum.COORDINATOR: {
            const clinicId = response.body.clinicId;
            this.router.navigate(['/call-queue/' + clinicId]);
            break;
          }
          case UserDTO.RoleEnum.ADMIN: {
            this.router.navigate(['/clinics']);
            break;
          }
          case UserDTO.RoleEnum.VOLUNTEER: {
            const clinicId = response.body.clinicId;
            this.router.navigate(['/call-queue/' + clinicId]);
            break;
          }
          default:
            break;
        }
      });
  }
}
