<header fxLayout="row">
  <h2 fxFlex="100%"><b>Clinic Information</b></h2>
  <button *ngIf="this.roleCaption.get(role) !== 'Volunteer'" mat-button color="primary" (click)="goToEditClinic()">
    <mat-icon>edit</mat-icon>Edit
  </button>
</header>

<section>
  <h3><b>{{ dto?.name }}</b></h3>
  <!-- <p *ngIf="!(isPhoneNumberSet() || this.dto?.hotlineNumber)" > (phone number not set)</p>
  <p *ngIf="(!isPhoneNumberSet() && this.dto?.hotlineNumber)" >{{ getHotlineNumber() }}</p> -->
  <p>{{this.dto?.hotlineNumber? getHotlineNumber() : '(phone number not set)'}}</p>
  <!-- <p *ngFor="let item of this.dto?.phoneNumbers">{{ getPhoneNumber(item) }}</p> -->
  <p>{{ getAddress() }}</p>
</section>

<section>
  <h3><b>Hours of operations</b></h3>
   <div *ngFor="let item of getHoursOfOperations()">
    <p>
      {{item}}
    </p>
   </div>
   <div [innerHtml]="dto?.hoursOfOperationNotes ? dto?.hoursOfOperationNotes : ''">
  </div>
</section>

<section>
  <h3><b>Main Clinic Contacts</b></h3>
   <div *ngFor="let item of dto?.contacts">
     <p>{{item?.firstName}} {{item?.lastName}}</p>
     <p>{{item?.title}} {{getContactNumber(item?.phoneNumber)}}</p>
   </div>
</section>

<section>
  <h3><b>Clinic Notes</b></h3>
  <div [innerHTML]="dto?.notes || '(none)'"></div>
</section>

<section >
  <h3><b>Testing Requirements for your State</b></h3>
  <div [innerHTML]="dto?.testingRequirements || '(none)'"></div>
</section>
