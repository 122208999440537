import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { MemberControllerService, PageUserDTO, UserDTO } from '@helplinesos-api-client';

export class MemberTableRow {
  id: number;
  name: string;
  role: string;
  phone: string;
  email: string;
  status: UserDTO.OnlineStatusEnum;
  activated: boolean;
}

export class MemberTableQuery {
  search: string;
  status: [];
}

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private service: MemberControllerService) { }

  get(
    clinicId: number,
    request: PageRequest<MemberTableRow>,
    query: MemberTableQuery
  ): Observable<Page<MemberTableRow>> {

    // ...&sortBy=name.asc,address.desc&...
    return this.service.getTeamMembersUsingGET(
      clinicId,
      query.search,
      `${request.page}`,
      `${request.size}`,
      !request.sort.order ? '' : `${request.sort.property}.${request.sort.order}`,
      query.status.join(','),
      ).pipe(
      // https://angular.io/guide/rx-library
      map((response: PageUserDTO) => {
        const content = response?.content?.map((dto: UserDTO) => {
          return {
            id: dto?.id,
            activated: dto?.activated,
            name: [dto.firstName, dto.lastName].join(' '),
            role: dto.role,
            phone:
            dto.phoneNumber ?
            '(' +
            dto.phoneNumber.slice(0, 3) + ') ' +
            dto.phoneNumber.slice(3, 6) + '-' +
            dto.phoneNumber.slice(6) : 'Phone number not set',
            email: dto.email,
            status: dto.onlineStatus
          } as MemberTableRow;
        });
        return {
          totalElements: response?.totalElements ?? 0, // TODO: map from response
          size: response?.size ?? 0,
          number: (response?.number ?? 0) + 1, // IMPORTANT: UI uses 1-based, API uses 0-based
          content,
        } as Page<MemberTableRow>;
      })
    ); // .pipe(delay(1000));
  }
}
