/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressDTO } from './addressDTO';
import { ContactDTO } from './contactDTO';
import { HoursOfOperationDTO } from './hoursOfOperationDTO';
import { LocalTime } from './localTime';


export interface ClinicDTO { 
    address?: AddressDTO;
    averageCallVolume?: number;
    averageResponseTime?: LocalTime;
    callVolumeType?: ClinicDTO.CallVolumeTypeEnum;
    contacts?: Array<ContactDTO>;
    createdBy?: string;
    createdDate?: Date;
    hotlineNumber?: string;
    hoursOfOperation?: Array<HoursOfOperationDTO>;
    hoursOfOperationNotes?: string;
    id?: number;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    name?: string;
    notes?: string;
    status?: ClinicDTO.StatusEnum;
    testingRequirements?: string;
    unAnsweredCalls?: number;
}
export namespace ClinicDTO {
    export type CallVolumeTypeEnum = 'HIGH' | 'NORMAL' | 'LOW';
    export const CallVolumeTypeEnum = {
        HIGH: 'HIGH' as CallVolumeTypeEnum,
        NORMAL: 'NORMAL' as CallVolumeTypeEnum,
        LOW: 'LOW' as CallVolumeTypeEnum
    };
    export type StatusEnum = 'INCOMPLETE' | 'ACTIVE' | 'DEACTIVATED' | 'SUSPENDED';
    export const StatusEnum = {
        INCOMPLETE: 'INCOMPLETE' as StatusEnum,
        ACTIVE: 'ACTIVE' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
}
