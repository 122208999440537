import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { InformationData } from '../user-profile-editor.component';
import { UserDTO, UserControllerService } from '@helplinesos-api-client';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'confirm-email-changes',
  templateUrl: './confirm-email-changes.component.html',
  styleUrls: ['./confirm-email-changes.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class ConfirmEmailChangesComponent implements OnInit {
  userFormGroup: FormGroup;
  userDto: UserDTO;
  mask = {
    guide: false,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  constructor(
    private formBuilder: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<ConfirmEmailChangesComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<ConfirmEmailChangesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: InformationData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: InformationData,
  ) { }

  ngOnInit(): void {
    if (this.dialogData){
      this.userDto = this.dialogData.userDto;
      }
    else
    {
      this.userDto = this.bottomSheetData.userDto;
    }

    this.userFormGroup = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  closeDialog(){
    if (this.dialogRef){
      this.dialogRef.close();
    }
    else{
      this.bottomSheetRef.dismiss();
    }
  }
}
