import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PaginationService, PaginationControlsDirective, PaginationInstance } from 'ngx-pagination';
import { Page } from 'ngx-pagination/dist/pagination-controls.directive';

// https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-controls.component.ts
@Component({
  selector: 'app-paging-controls',
  templateUrl: './paging-controls.component.html',
  styleUrls: ['./paging-controls.component.scss'],
})
// https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-controls.directive.ts
export class PagingControlsComponent extends PaginationControlsDirective implements OnInit {
  // @Input() id: string;
  // @Input() maxSize = 5;

  @Input()
  get directionLinks(): boolean {
    return this._directionLinks;
  }
  set directionLinks(value: boolean) {
    this._directionLinks = this.coerceToBoolean(value);
  }
  @Input()
  get autoHide(): boolean {
    return this._autoHide;
  }
  set autoHide(value: boolean) {
    this._autoHide = this.coerceToBoolean(value);
  }
  @Input()
  get responsive(): boolean {
    return this._responsive;
  }
  set responsive(value: boolean) {
    this._responsive = this.coerceToBoolean(value);
  }
  @Input() previousLabel = 'Previous';
  @Input() nextLabel = 'Next';
  @Input() screenReaderPaginationLabel = 'Pagination';
  @Input() screenReaderPageLabel = 'page';
  @Input() screenReaderCurrentLabel = `You're on page`;

  // @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  // @Output() pageBoundsCorrection: EventEmitter<number> = new EventEmitter<number>();

  private _directionLinks = true;
  private _autoHide = false;
  private _responsive = false;

  private _service: PaginationService;

  constructor(
    // https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination.service.ts
    // https://github.com/michaelbromley/ngx-pagination/blob/master/src/paginate.pipe.ts
    service: PaginationService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(service, changeDetectorRef);
    this._service = service;
    if (!this.id) {
      this.id = service.defaultId(); // IMPORTANT: used for matching with the service events
    }
  }

  ngOnInit(): void {}

  coerceToBoolean(input: string | boolean): boolean {
    return !!input && input !== 'false';
  }

  public register(instance: PaginationInstance): boolean {
    const isNewOrUpdated = this._service.register(instance);
    // if (isNewOrUpdated) { ?????????
    //   this._service.change.emit(instance.id);
    // }
    this._service.change.emit(instance.id);
    return isNewOrUpdated;
  }
}
