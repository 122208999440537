import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CallControllerService, SecurityControllerService } from '@helplinesos-api-client';
import { TimerData } from '../scribe.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer-dialog',
  templateUrl: './timer-dialog.component.html',
  styleUrls: ['./timer-dialog.component.scss']
})
export class TimerDialogComponent implements OnInit {
  // clinicId: number;
  // callId: number;
  interval: NodeJS.Timeout;
  timeOut = 60;
  constructor(
    private router: Router,
    private securityControllerService: SecurityControllerService,
    private callControllerService: CallControllerService,
    @Optional() private dialogRef: MatDialogRef<TimerDialogComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<TimerDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: TimerData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: TimerData,
  ) {
    if (dialogRef){
      dialogRef.disableClose = true;
    }
    else{
      bottomSheetRef.disableClose = true;
    }

   }

  ngOnInit(): void {
    this.startCounting();
    // if (this.dialogData){
    //   this.callId = this.dialogData.callId;
    //   this.clinicId = this.dialogData.clinicId;
    // }
    // else{
    //   this.callId = this.bottomSheetData.callId;
    //   this.clinicId = this.bottomSheetData.clinicId;
    // }
  }

  startCounting(){
    this.interval = setInterval(() => {
      this.timeOut--;
      console.log(this.timeOut);
      if (this.timeOut < 1 ) {
        clearInterval(this.interval);
        this.logOut();
        // this.router.navigate(['call-queue', this.clinicId]);
        this.closeDialog();
      }
    }, 1000);

  }

  closeDialog(){
//    if (this.dialogRef){
      this.dialogRef.close();
    // }
    // else{
    //   this.bottomSheetRef.dismiss();
    // }
  }

  continueToScribe(){
    clearTimeout(this.interval);
    this.closeDialog();
  }

  unAssignCall(){
    // this.callControllerService.unAssignCallUsingPOST(18 ,this.callId).subscribe(response => {
    // });
  }

  logOut(){
    this.unAssignCall();
    this.securityControllerService.logoutUsingPOST().subscribe(response => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
      clearTimeout(this.interval);
      this.closeDialog();
    });
  }
}
