<main (click)="areDownloadButtonsShown = false">
    <div fxLayout="row wrap" fxLayoutAlign="start start">
        <header fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <!-- <nav>
                <button mat-icon-button>
                    <mat-icon *ngIf="this.role === 'ROLE_ADMIN'">arrow_back</mat-icon>
                </button>
            </nav> -->
            <h1>Call Queue</h1>
            <strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong>
            <span>
            <app-notifications></app-notifications>
            <app-user-profile></app-user-profile>
            </span>
        </header>
        <mat-button-toggle-group [value]="currentStatus">
            <mat-button-toggle class="call-queue-notification"  matBadgePosition="after"  matBadgeColor="warn" *ngFor="let status of statuses" [matBadge]='getNumberOfNotifications(status.value)' (click)="changeStatus(status.value)" (change)="dataSource.queryBy({status: status.value})" [value]="status.value" [matBadgeHidden]="!status.showNotification" >{{status.name}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <nav fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxFlex>
            <mat-button-toggle-group #toggleGroup  value="English">
                <mat-button-toggle  class="call-queue-notification" *ngFor="let language of languageOptions" matBadge='0' matBadgePosition="after"  matBadgeColor="warn" (click)="changeLanguage(language.value)" (change)="dataSource.queryBy({language: language.value})" [value]="language.value"  [matBadgeHidden]="!language.showNotification">{{language.value}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div fxLayout="column" *ngIf="areDownloadButtonsShown">
            <a mat-stroked-button (click)="callsDownload('CSV')" >Download as CSV</a>
            <a mat-stroked-button  (click)="callsDownload('XLSX')">Download as XLS</a>
        </div>
        <button mat-icon-button (click)="showButtons($event)"><mat-icon color="primary" >save_alt</mat-icon></button>
    </nav>

    <div class="progress-bar-grid">
        <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    </div>
    <section>
    <nav  fxLayout="row" fxLayoutAlign="space-between start">
        <a mat-stroked-button [matMenuTriggerFor]="menu">Categories<mat-icon>arrow_drop_down</mat-icon></a >
            <mat-menu #menu="matMenu" >
                        <nav class="categories-nav" fxLayoutAlign="space-between center">
                            <strong>Categories</strong>
                            <mat-checkbox (click)="stopPropagation($event)" (change)="setDefaultCategories($event)">Set as default</mat-checkbox>
                        </nav>
                        
                        <mat-list fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="400px" >
                            <mat-list-item  role="listitem" fxFlex="50%" *ngFor="let column of columns" >
                                <mat-checkbox  (click)="stopPropagation($event)"  (change)="changeColumns($event, column.value)" [checked]="isChecked(column.value)">{{column.display}}</mat-checkbox>
                            </mat-list-item>
                        </mat-list>
                    
              </mat-menu>
              <div fxLayoutGap="24px" fxLayout="row"  fxLayoutAlign="center center">
                 
                <app-paging-controls [style.visibility]="isOnePage ? 'hidden': 'visible'" (pageChange)="pageChange($event)">
                </app-paging-controls>
                <strong>{{paginator.getTotalItems()}}  results</strong>
                <strong>Show:</strong>
              <mat-select #se  [(value)]="this.dataPerPage" disableOptionCentering  (selectionChange)="changeNumOfDataPerPage(se.value)">
                  <mat-option *ngFor="let item of numOfDataPerPage" [value]="item">{{item}}</mat-option>
              </mat-select>
              </div>

    </nav>
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container  matColumnDef="flag">
            <mat-header-cell fxFlex="7%"  *matHeaderCellDef >Escalate</mat-header-cell>
            <mat-cell fxFlex="7%" *matCellDef="let element"> 
                <button 
                mat-icon-button 
                (click)="openFlagCall($event, element.callId)"  
                matTooltip="Escalate this call">
                    <mat-icon [ngClass]="element.flagged ? 'textDanger' : 'textDefault'">arrow_upward</mat-icon>
                </button>
                 </mat-cell>
        </ng-container>

        <ng-container  matColumnDef="callerId"> 
            <mat-header-cell fxFlex="8%" *matHeaderCellDef > Caller ID </mat-header-cell>
            <mat-cell fxFlex="8%" *matCellDef="let element"> {{element.callerId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timeStamp" >
            <mat-header-cell *matHeaderCellDef mat-sort-header> Time Stamp </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.timeStamp | date:'MM/dd/yy h:mm a'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="isComplex(element.type) ? 'textDanger' : 'textDefault'"> {{this.roleCaption.get(element.type)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="symptoms">
            <mat-header-cell *matHeaderCellDef >  Symptoms </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="isWarningColor(element.symptoms) ? 'textDanger' : 'textDefault'"> {{element.symptoms}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="chronicDisease">
            <mat-header-cell *matHeaderCellDef > Chronic Disease </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="isWarningColor(element.chronicDisease) ? 'textDanger' : 'textDefault'"> {{element.chronicDisease}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="volunteer">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Volunteer </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.volunteer}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="exposure">
            <mat-header-cell *matHeaderCellDef > Exposure </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="isWarningColor(element.exposure) ? 'textDanger' : 'textDefault'">{{element.exposure}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="getStatusClass(element.status)"> {{this.roleCaption.get(element.status)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="age">
            <mat-header-cell *matHeaderCellDef > Age </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="travel">
            <mat-header-cell *matHeaderCellDef > Travel </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="isWarningColor(element.travel) ? 'textDanger' : 'textDefault'"> {{element.travel}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef > Phone Number </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.phoneNumber}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="outcome">
            <mat-header-cell *matHeaderCellDef > Outcome </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.outcome}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="zip">
            <mat-header-cell *matHeaderCellDef > Zip </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.zip}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="previousCalls">
            <mat-header-cell *matHeaderCellDef > Previous Calls </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.previousCalls}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="clinicPatient">
            <mat-header-cell *matHeaderCellDef > Clinic Patient </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.clinicPatient}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef > Duration </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.duration}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastEdited">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Last Edit </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastEdited | date:'MM/dd/yy h:mm a'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef >Language </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.language}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="insurance">
            <mat-header-cell *matHeaderCellDef >Insurance </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.insurance}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="menu">
            <mat-header-cell fxFlex="5%" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxFlex="5%" *matCellDef="let element">
              <button *ngIf="element?.volunteer"  mat-icon-button (click)="stopPropagation($event)" [matMenuTriggerFor]="beforeMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button (click)="unassignCall(element.callId)" mat-menu-item>
                  <span>Unassign</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)='openDialog(row.callId)'></mat-row>
    </mat-table>
</section>
</main>