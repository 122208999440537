import { Observable, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    private static errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor() { }

    public pushErrorMessage(message: string){
    ErrorService.errorMessage.next(message);
    }

    public fetchErrorMessage(): Observable<string> {
       return ErrorService.errorMessage;
    }

    public clearErrorMessage() {
        ErrorService.errorMessage = new BehaviorSubject<string>('');
     }

 }
