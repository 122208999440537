<header fxLayout="row">
  <h2 fxFlex>Team Members</h2>
  <h4><mat-icon class="status-online">call</mat-icon><span>In Call</span></h4>
  <h4><mat-icon class="status-online">fiber_manual_record</mat-icon><span>Online</span></h4>
  <h4><mat-icon class="status-idle">fiber_manual_record</mat-icon><span>Idle</span></h4>
  <h4><mat-icon class="status-pending">fiber_manual_record</mat-icon><span>Pending</span></h4>
  <h4><mat-icon class="status-offline">fiber_manual_record</mat-icon><span>Offline</span></h4>
  <h4><mat-icon class="status-deactivated">highlight_off</mat-icon><span>Deactivated</span></h4>
</header>
<header fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="24px" >
  <button mat-raised-button (click)="inviteNewMemberDialog()" color="primary" *ngIf="role !== 'ROLE_VOLUNTEER'">
    Invite New Member
  </button>
      <mat-form-field fxFlex="13%" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select #se [(value)]="selected" disableOptionCentering panelClass="position-of-content" multiple (selectionChange)="dataSource.queryBy({ search:'', status: se.value})" >
                    <mat-option *ngFor="let item of activeAndIncomplete" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
  <app-paging-controls [style.visibility]="isOnePage ? 'hidden': 'visible'" fxFlex (pageChange)="pageChange($event)"></app-paging-controls>
</header>
<section>

  <!-- <div class="progress-bar-grid">
    <mat-progress-bar *ngIf="dataSource.loading$ | async" mode="indeterminate"></mat-progress-bar>
  </div> -->
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="member">
      <mat-header-cell *matHeaderCellDef> Team Member </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ roleCaption.get(element.role) }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> Phone Numbers </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.phone }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email Address </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <!-- <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell> -->
      <mat-cell *matCellDef="let element"
        ><mat-icon  [ngClass]='getStatusClass(element.status)'>{{ getStatusIcon(element.status)}}</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="menu" >
      <mat-header-cell width="5%" *matHeaderCellDef></mat-header-cell>
      <!-- <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell> -->
      <mat-cell width="5%" *matCellDef="let element">
        <button *ngIf="!(roleCaption.get(element?.role) === 'Captain' && roleCaption.get(role) === 'Coordinator')" mat-icon-button [matMenuTriggerFor]="beforeMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <button *ngIf="!(roleCaption.get(element?.status) === 'Pending' || roleCaption.get(element?.status) === 'Deactivated')" (click)="goToRoleEditor(element.id)" mat-menu-item>
            <span>Edit Role</span>
          </button>
          <mat-divider></mat-divider>
          <button *ngIf="roleCaption.get(element?.status) === 'Pending'"  (click)="resendInvite(element.email)" mat-menu-item>
            <span >Resend Invite</span>
          </button>
          <mat-divider></mat-divider>
          <button *ngIf="roleCaption.get(element?.status) !== 'Deactivated' 
                         && roleCaption.get(element?.status) !== 'Pending'"
                          (click)="deactivate(element.id)" mat-menu-item>
            <span>Deactivate</span>
          </button>
          <button *ngIf="roleCaption.get(element?.status) === 'Deactivated'" (click)="reactivate(element.id)" mat-menu-item>
            <span>Reactivate</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>
  
  <app-paging-controls [style.visibility]="isOnePage ? 'hidden': 'visible'" (pageChange)="pageChange($event)"></app-paging-controls>
</section>
