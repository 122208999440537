import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClinicsDashboardComponent } from './clinics-dashboard/clinics-dashboard.component';
import { ClinicEditorComponent } from './clinic-editor/clinic-editor.component';
import { MembersEditorComponent } from './members-editor/members-editor.component';
// import { CancelDialogComponent } from './clinic-editor/cancel-dialog/cancel-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClinicService } from './clinic.service';
import { ClinicInformationComponent } from './clinic-information/clinic-information.component';
import { PagingControlsComponent } from '../shared/paging-controls/paging-controls.component';
// import { MemberService } from './member.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
// import { DashboardVolunteerComponent } from '../dashboard-volunteer/dashboard-volunteer.component';
// import { DashboardManagerComponent } from '../dashboard-manager/dashboard-manager.component';
import { FateModule, FateMaterialModule  } from 'fate-editor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextMaskModule } from 'angular2-text-mask';
import { CancelDialogService } from '../shared/cancel-dialog.service';
import { CancelDialogComponent } from '../shared/cancel-dialog/cancel-dialog.component';
import {MatMenuModule, MatMenu} from '@angular/material/menu';
import { CustomSnackbarService } from '../shared/custom-snackbar.service';
import { ClinicInformationTabComponent } from './clinic-information/clinic-information-tab/clinic-information-tab.component';
import { MembersTabComponent } from './clinic-information/members-tab/members-tab.component';
import { MemberService } from './member.service';
import { DeactivateDialogComponent } from './clinic-information/deactivate-dialog/deactivate-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppErrorStateMatcher } from '../app-matcher';
import { MatStepperModule } from '@angular/material/stepper';
import { HistoryRoutingService } from '../shared/history-routing.service';
//import { UserProfileComponent } from '../shared/user-profile/user-profile.component';
import { SharedModule } from '../shared/shared.module';
// import { UserProfileComponent } from '../shared/user-profile/user-profile.component';
import * as SockJS from 'sockjs-client';
import { ClinicTeamComponent } from './clinic-team/clinic-team.component';
import { RoleEditorComponent } from './role-editor/role-editor.component';
import { ResourceBoardModule } from '../resource-board/resource-board.module';
import { AuthGuard } from '../auth.guard';
import { UserDTO } from '@helplinesos-api-client';


@NgModule({
  declarations: [
    // DashboardManagerComponent,
    // DashboardVolunteerComponent,
    ClinicsDashboardComponent,
    ClinicEditorComponent,
    CancelDialogComponent,
    MembersEditorComponent,
    ClinicInformationComponent,
   // PagingControlsComponent,
    ClinicInformationTabComponent,
    MembersTabComponent,
    DeactivateDialogComponent,
    ClinicTeamComponent,
    RoleEditorComponent
  ],
  imports: [
    ResourceBoardModule,
    SharedModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    FateMaterialModule,
    FateModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    CommonModule,
    MatProgressBarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDialogModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatToolbarModule,
    MatStepperModule,
    RouterModule.forChild([
      { path: 'clinics', component: ClinicsDashboardComponent,
      canActivate: [AuthGuard],
      data: {
        role: UserDTO.RoleEnum.ADMIN
      }
     },
      { path: 'clinics/:clinicId/information', component: ClinicInformationComponent,
      canActivate: [AuthGuard],
      data: {
        role: [UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.VOLUNTEER, UserDTO.RoleEnum.COORDINATOR]
      }
     },
      {
      path: 'clinics/:clinicId/edit', component: ClinicEditorComponent,
      canActivate: [AuthGuard],
      data: {
        role: [UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
      }
     },
      { path: 'clinics/add', component: ClinicEditorComponent,
      canActivate: [AuthGuard],
      data: {
        role: UserDTO.RoleEnum.ADMIN,
      } },
      { path: 'clinics/:clinicId/members', component: MembersEditorComponent,
      canActivate: [AuthGuard],
      data: {
        role: [UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
      }
    },
      { path: 'team/:clinicId', component: ClinicTeamComponent },
      { path: 'clinics/:clinicId/role/:userId', component: RoleEditorComponent,
      canActivate: [AuthGuard],
      data: {
        role: [UserDTO.RoleEnum.ADMIN, UserDTO.RoleEnum.CAPTAIN, UserDTO.RoleEnum.COORDINATOR]
      } },
    ]
    )
  ],
  providers: [
    HistoryRoutingService,
    MemberService,
    ClinicService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    { provide: CancelDialogService },
    { provide: CustomSnackbarService },
    { provide: ErrorStateMatcher, useClass: AppErrorStateMatcher },
  ]
})
export class ClinicsModule { }
