import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { UpdateHelplineResourceDTO } from 'projects/helplinesos-api-client/src/model/updateHelplineResourceDTO';
import { ActivatedRoute } from '@angular/router';
import { ClinicControllerService, UserDTO } from '@helplinesos-api-client';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { HelplineResourceDTO } from 'projects/helplinesos-api-client/src/model/helplineResourceDTO';
import { ComponentBase } from '../../../shared/models/componentBase';
import { ViewFlags } from '@angular/compiler/src/core';

@Component({
  selector: 'app-helpline-resource',
  templateUrl: './helpline-resource.component.html',
  styleUrls: ['./helpline-resource.component.scss']
})
export class HelplineResourceComponent extends ComponentBase implements OnInit {
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
  ]);
  isButtonDisabled = true;
  searchedTerm: '';
  role: any;
  @Input() private clinicId: number;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  helpfulInfoFormGroup: FormGroup;
  dto: HelplineResourceDTO = {
    faqDTO: [],
    informationDTO: []
  };
  get informationArray() {
    return this.helpfulInfoFormGroup.get('information') as FormArray;
  }

  get questionsArray() {
    return this.helpfulInfoFormGroup.get('questions') as FormArray;
  }
  constructor(
    private snackBarService: CustomSnackbarService,
    private route: ActivatedRoute,
    private clinicControllerService: ClinicControllerService,
    private formBuilder: FormBuilder
  ) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.helpfulInfoFormGroup = this.formBuilder.group({
      information: this.formBuilder.array([]),
      questions: this.formBuilder.array([])
    });

    const question = this.formBuilder.group({
      question: ['', Validators.pattern(/^[A-Za-z|0-9]/)],
      answer: ['', Validators.pattern(/^[A-Za-z|0-9]/)]
    });
    this.questionsArray.push(question);

    const info = this.formBuilder.group({
      title: ['', Validators.pattern(/^[A-Za-z|0-9]/)],
      file: [''],
      filename: [''],
      filetype: [''],
      url: ['', Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')],
      description: ['']
    });
    this.informationArray.push(info);

    this.loadData();
    this.onChanges();
  }

  onChanges(){
    this.helpfulInfoFormGroup.valueChanges.subscribe(res => {
      this.onFormGroupChange.emit(this.helpfulInfoFormGroup);
    });
  }

  addNewInformation() {
    const question = this.formBuilder.group({
      title: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      file: [''],
      filename: [''],
      filetype: [''],
      url: ['',  Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')],
      description: [''],
    });
    this.informationArray.push(question);
  }

  addNewQuestion() {
    const question = this.formBuilder.group({
      question: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      answer: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
    });
    this.questionsArray.push(question);
  }

  removeInformation(i: number) {
    this.informationArray.removeAt(i);
  }

  removeQuestion(i: number) {
    this.questionsArray.removeAt(i);
  }

  showDeleteQuestion(){
    if (this.questionsArray.length > 1){
      return true;
    }
    else {
      return false;
    }
  }

  showDeleteInformation(){
    if (this.informationArray.length > 1){
      return true;
    }
    else {
      return false;
    }
  }
  onFileSelect(e: any, i: number): void {
    try {
      const file = e.target.files[0];
      if (file.size === 0){
        this.snackBarService.openErrorNotification('This file has no content. Please upload file with content');
        return;
      }
      const fReader = new FileReader();
      const fileType = file.type;
      fReader.readAsDataURL(file);
      const blob = file as Blob;
      fReader.onloadend = (event: any) => {
        const filename = file.name;
        const base64File = event.target.result;
        const blob1 = new Blob([new Uint8Array(event.target.result)], { type: file.type });
        (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('file').setValue(base64File);
        (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('filename').setValue(filename);
        (this.helpfulInfoFormGroup.get('links') as FormArray).controls[i].get('fileType').setValue(fileType);
      }
    } catch (error) {
      console.log('no file was selected...');
    }
  }

  updateResource() {
    if (!this.helpfulInfoFormGroup.valid){
      this.snackBarService.openErrorNotification('Please fill all required fields in order to save Helpline Resource');
      return;
    }
    const dto: UpdateHelplineResourceDTO = {
      faqDTO: [],
      informationDTO: []
    };

    this.isWaiting = true;
    this.helpfulInfoFormGroup.controls.information.value.forEach((element: any) => {
      dto.informationDTO.push({
        title: element.title,
        url: element.url,
        description: element.description,
        fileData: element.file,
        fileName: element.filename
      });
    });

    this.helpfulInfoFormGroup.controls.questions.value.forEach((element: any) => {
      dto.faqDTO.push({
        question: element.question,
        answer: element.answer
      });
    });
    //    const x = this.dto;
    this.clinicControllerService.updateHelplineResourceUsingPUT(dto).subscribe(response => {
      this.isWaiting = false;
      this.snackBarService.openToastNotification('HelplineSOS Resource Updated');
      this.ngOnInit();
    },
      err => {
        this.isWaiting = false;
      });
  }

  loadData() {
    this.clinicControllerService.getHelplineResourceUsingGET().subscribe(response => {
      this.dto = response;
      this.dto.informationDTO.forEach(x => {
        x.fileType = x?.fileData.split(';')[0].split(':')[1];
      });
      if (response?.faqDTO.length > 0) {
        this.removeQuestion(0);
        response?.faqDTO.forEach(x => {
          const c = this.formBuilder.group({
            question: [x?.question, (x?.question ? [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)] : Validators.pattern(/^[A-Za-z|0-9]/) )],
            answer: [x?.answer, (x?.question ? [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)] : Validators.pattern(/^[A-Za-z|0-9]/))]
          });
          this.questionsArray.push(c);
        });
      }
      if (response?.informationDTO.length > 0) {
        this.removeInformation(0);
        response?.informationDTO.forEach(x => {
          const fileParts = x?.fileData.split(';')[0];
          const fileType = fileParts.split(':')[1];
          const c = this.formBuilder.group({
            description: [x?.description],
            title: [x?.title,
                (x?.title ? [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)] : Validators.pattern(/^[A-Za-z|0-9]/))],
            file: [x?.fileData],
            filename: [x?.fileName],
            filetype: [fileType],
            url: [x?.url, Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]
          });
          this.informationArray.push(c);
        });
      }
      this.isWaiting = false;
    },
      err => {
        this.isWaiting = false;
      });
  }

  getFileName(i: number) {
    return (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('filename').value;
  }

  getIndex(i: number) {
    return String(i);
  }

  getFileData(i: number) {
    return (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('file').value;
  }

  getFileType(i: number) {
    const x = (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('filetype').value;
    return x;
  }

  removeFile(i: number) {
    (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('filename').setValue('');
    (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('file').setValue('');
    (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('fileType').setValue('');
  }

  doesUrlExist(i: number){
    const url = (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('url').value;
    if (url){
      return true;
    }
    else{
      return false;
    }
  }

  isFileUploaded(i: number){
    const file = (this.helpfulInfoFormGroup.get('information') as FormArray).controls[i].get('file').value;
    if (file){
      return true;
    }
    else{
      return false;
    }
  }

  public base64ToBlob(b64Data: string, contentType: string, sliceSize: 512) {
    b64Data = b64Data.replace(/\s/g, '');
    const x = b64Data.split(',');
    const byteCharacters = atob(x[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(b64encodedString: string, fileType: string, fileName: string) {
    // if (b64encodedString) {
    //   const blob = this.base64ToBlob(b64encodedString, fileType, 512);
    //   saveAs(blob, fileName);
    // }
    this.openBase64InNewTab(b64encodedString, fileType);
  }

  openBase64InNewTab(data: any, mimeType: any) {
    data = data.replace(/\s/g, '');
    const x = data.split(',');
    const byteCharacters = atob(x[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: mimeType + ';base64' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}
  goToLink(url: string){
    window.open(url, '_blank');
}

  cancel(){
    this.ngOnInit();
  }
}
