import { NotificationDTO } from '@helplinesos-api-client';

export class CallQueueNotificationModel {
    notificationId: number;
    clinicId: number;
    num: number;
    type: NotificationDTO.SystemNotificationTypeEnum;

    constructor(){
        this.notificationId = 0;
        this.clinicId = 0;
        this.num = 0;
    }
  }
