import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { ClinicControllerService, ClinicDTO, UserDTO } from '../../../../../helplinesos-api-client/src';
import { delay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { DeactivateDialogComponent } from './deactivate-dialog/deactivate-dialog.component';
import { HistoryRoutingService } from '../../shared/history-routing.service';
import { Location } from '@angular/common';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';


@Component({
  selector: 'app-clinic-information',
  templateUrl: './clinic-information.component.html',
  styleUrls: ['./clinic-information.component.scss'],
})
export class ClinicInformationComponent extends ComponentBase implements OnInit {
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain'],
    [ClinicDTO.StatusEnum.DEACTIVATED, 'Deactivated']
  ]);
  isResourceBoardShown = false;
  role: any;
  openTab = 'information';
  clinicId: number;
  isInformationShown = true;
  isTeamShown = false;
  clinicDto: ClinicDTO;
  constructor(
    private errorService: ErrorService,
    private historyRoutingService: HistoryRoutingService,
    private clinicService: ClinicControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private snackBarService: CustomSnackbarService
  ) {
    super();
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isWaiting = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isWaiting = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    const x = this.historyRoutingService.getPreviousUrl() ;
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.errorService.clearErrorMessage();
    if (this.historyRoutingService.getPreviousUrl() === 'members' || this.historyRoutingService.getPreviousUrl() === 'role') {
      this.openTab = 'team';
      this.showTeam();
    }

    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.isWaiting = true;
    this.clinicService
      .getClinicUsingGET(this.clinicId)
      .subscribe(
        (response) => {
          this.clinicDto = response;
          this.isWaiting = false;
        }
      );

    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));
  }



  showInformation() {
    this.isResourceBoardShown = false;
    this.isTeamShown = false;
    this.isInformationShown = true;
  }

  showTeam() {
    this.isResourceBoardShown = false;
    this.isTeamShown = true;
    this.isInformationShown = false;
  }

  showResourceBoard(){
    this.isTeamShown = false;
    this.isInformationShown = false;
    this.isResourceBoardShown = true;
  }

  goToDashboard() {
    this.router.navigate(['clinics']);
  }

  deactivateClinic() {
    const dialogRef = this.dialog.open(DeactivateDialogComponent, {
      width: '684px',
      backdropClass: 'dialog-backdrop',
      data: { clinicId: this.clinicId }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response !== true) { // dialog after close returns true if there is no message
        this.errorMessage = response;
        this.isWaiting = false;
      }
    });
    // this.isWaiting = true;
    // this.clinicService.deActivateClinicUsingGET(this.clinicId).subscribe((response) => {
    //   this.router.navigate(['/clinics']);
    //   this.isWaiting = false;
    // }, err => {
    //   this.isWaiting = false;
    // });
    // this.snackBarService.open('Clinic Deactivated');
  }

  // getPhoneNumber() {
  //   // return this.clinicDto?.phoneNumbers ?? '(not set)';
  //   // const numbers: string[] = [];
  //   // this.clinicDto?.phoneNumbers.forEach(function (item) {
  //   //   numbers.push(item.type + ' (' + item.phoneNumber.slice(0, 3) + ')' + item.phoneNumber.slice(3, 6) + '-' + item.phoneNumber.slice(6));
  //   // });
  //   // return numbers ?? '(not set)';
  //   return 'N/A';
  // }

  getAddress() {
    if (this.clinicDto?.address) {
      return [
        this.clinicDto.address.streetAddress,
        this.clinicDto.address.city,
        this.clinicDto.address.zipCode
      ].join(', ');
    }

    return null;
  }

  goToClinicQueue(){
    this.router.navigate(['clinics/' + this.clinicId + '/call-queue']);
  }

  activateClinic(){
    this.clinicService.activateClinicUsingPUT(this.clinicId).subscribe(response => {
      this.snackBarService.openToastNotification('Clinic Activated');
      this.router.navigate(['clinics']);
    });
  }
}
