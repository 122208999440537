import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { ClinicsModule } from './clinics/clinics.module';
import { ShellModule } from './shell/shell.module';
import { ShellComponent } from './shell/shell.component';
import { CallQueueModule } from './call-queue/call-queue.module';
import { ResourceBoardModule } from './resource-board/resource-board.module';
import { UserProfileComponent } from './shared/user-profile/user-profile.component';
import { UserModule } from './user/user.module';
import { LandingModule } from './landing/landing.module';




const routes: Routes = [
  {
    path: '',
    loadChildren: () => LandingModule
  },
  {
    path: '',
    loadChildren: () => AuthModule
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        loadChildren: () => ClinicsModule
      },
      {
        path: '',
        loadChildren: () => CallQueueModule
      },
      {
        path: '',
        loadChildren: () => ResourceBoardModule
      },
      {
        path: '',
        loadChildren: () => UserModule
      },
    ]
  }
];

@NgModule({
  imports:[RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
