import { PagingControlsComponent } from '../../shared/paging-controls/paging-controls.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  ClinicService,
  ClinicTableRow,
  ClinicTableQuery,
} from '../clinic.service';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { PaginationService, PaginationInstance } from 'ngx-pagination';
import { SecurityControllerService, MemberControllerService, ClinicDTO, ClinicControllerService } from '@helplinesos-api-client';
import { HistoryRoutingService } from '../../shared/history-routing.service';
import { ErrorService } from '../../shared/error.service';
import { ComponentBase} from '../../shared/models/componentBase';
import { ExtendPaginationDataSource } from '../../shared/models/extendPaginationDataSource';
import { UserProfileComponent } from '../../shared/user-profile/user-profile.component';
import { WebSocketAPIService } from '../../shared/web-socket-api.service';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';

@Component({
  selector: 'app-clinics-dashboard',
  templateUrl: './clinics-dashboard.component.html',
  styleUrls: ['./clinics-dashboard.component.scss']
})
export class ClinicsDashboardComponent extends ComponentBase implements OnInit {
  displayedColumns: string[] = [
    'select',
    'name',
    'address',
    'phone',
    'status',
  ];
  clinicsSelected = 0;
  isClinicChecked = false;
  selected = [ClinicDTO.StatusEnum.ACTIVE , ClinicDTO.StatusEnum.INCOMPLETE];
  activeAndIncomplete: any = [{ name: 'Active', value: ClinicDTO.StatusEnum.ACTIVE },
                              { name: 'Incomplete', value: ClinicDTO.StatusEnum.INCOMPLETE } ,
                              { name: 'Deactivated', value: ClinicDTO.StatusEnum.DEACTIVATED },
                              { name: 'Suspended', value: ClinicDTO.StatusEnum.SUSPENDED }];
  clinicId: string;
  dataSource: ExtendPaginationDataSource<ClinicTableRow, ClinicTableQuery>;
  selectedCount: number;
  selection = new SelectionModel<ClinicTableRow>(true, []); // allowMultiSelect:true, initialSelection: []
  data: ClinicTableRow[];
  isOnePage = true;
  dataPerPage = 20;
  selectionMemory: number[];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(PagingControlsComponent, { static: true }) paginator: PagingControlsComponent;

  constructor(
    private customSnackBarService: CustomSnackbarService,
    private clinicsControllerService: ClinicControllerService,
    private historyRoutingService: HistoryRoutingService,
    private router: Router,
    private service: ClinicService,
    private location: Location,
    private securityControllerService: SecurityControllerService,
    private errorService: ErrorService,
    private webSocketAPIservice: WebSocketAPIService
  ) {
    super();
   // this.webSocketAPIservice = new WebSocketAPIService(this,['/topic/calls' , '/topic/clinics' , '/topic/teams' , '/topic/notifications' , '/topic/scribes']);
  }


  ngOnInit(): void {

    // this.webSocketAPIservice._connect(); comment socket for now
    // const _this = this;
    // this.webSocketAPIservice.isConnected.subscribe((isConnected => {

    //   if (!isConnected){
    //      return;
    //     }
    //   // tslint:disable-next-line: only-arrow-functions
    //   this.webSocketAPIservice.stompClient.subscribe('/topic/clinics', function(sdkEvent: any) {
    //     console.log('Handled:- ' + sdkEvent);
    //     _this.dataSource.fetch( _this.dataSource.currentPage - 1);
    //   });
    // }));


    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      this.isWaiting = false;
    }));

    this.dataSource = new ExtendPaginationDataSource<ClinicTableRow, ClinicTableQuery>(
      (request, query) => this.service.get(request, query),
      { property: 'name', order: 'asc' },
      { search: '', status: this.selected as [] },
      this.dataPerPage
     );

    this.dataSource.page$.subscribe((page) => {
      this.data = page.content;
      // https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-instance.ts
      const paginationInstance: PaginationInstance = {
        itemsPerPage: this.dataSource.pageSize,
        currentPage: page.number,
        totalItems: page.totalElements,
      };
      this.paginator.register(paginationInstance);
      this.handleRefreshSelection();
      this.isOnePage  = (paginationInstance.totalItems > this.dataPerPage ) ? false : true;
    });

    this.sort.sortChange.subscribe((sort: Sort) => {
      const sortSpecification = {
          property: sort.active,
          order: sort.direction
      } as unknown;
      this.dataSource.sortBy(sortSpecification);
    });

  }

  deactivateListOfClinics(){
    let errorMessage = '';
    const clinicIds = this.selection.selected.map(x => x.id);
    clinicIds.forEach( x => {
      this.clinicsControllerService.deActivateClinicUsingPUT(x).subscribe( response => {
      },
      err => {
        errorMessage = err.error.message;
      });
    });
    if (errorMessage){
    this.customSnackBarService.openErrorNotification(errorMessage);
    }
    else{
      this.selection.clear();
      this.isClinicChecked = false;
      this.clinicsSelected = 0;
      this.selectionMemory = [];
      this.customSnackBarService.openToastNotification('List of selected clinics deactivated');
    }

  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    this.clinicsSelected = numSelected;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? (this.selection.clear(), this.isClinicChecked = false, this.clinicsSelected = 0)
      : (this.data.forEach((row) => this.selection.select(row))
      , this.isClinicChecked = true
      , this.clinicsSelected = this.selection.selected.length)
      , this.selectionMemory = this.selection.selected.map(x => x.id);
  }

  toggle(element: any){
    this.selection.toggle(element);
    this.clinicsSelected = this.selection.selected.length;
    if ( this.clinicsSelected > 0){
      this.isClinicChecked = true;
    }
    else{
      this.isClinicChecked = false;
    }
    this.selectionMemory = this.selection.selected.map(x => x.id);
  }

  handleRefreshSelection(){
    this.selection.clear();
    this.isClinicChecked = false;
    if (this.selectionMemory){
      this.selectionMemory.forEach((id: number) => {
        this.selection.select(this.data.filter(x => x.id === id)[0]);
        this.isClinicChecked = true;
      });
    }
  }

  changeStatus(e: any, status: any){
    this.stopPropagation(e);
    this.dataSource.queryBy({status});
  }

  stopPropagation(e: any) {
    e.stopPropagation(); // stop turning off mat menu
  }
  addClinic() {
    this.router.navigate(['/clinics/add']);
  }

  goToClinic(id: string) {
    this.router.navigate(['clinics/' + id + '/information']);
  }

  pageChange(page: number){
    this.dataSource.fetch(page - 1); // IMPORTANT: dataSource is 0-based, while app-paging-controls is 1-based
  }

  logout(){
    this.securityControllerService.logoutUsingPOST().subscribe((response) =>
    {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }, err =>
    {

    });
  }

}
