import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { UserDTO, ClinicControllerService } from '@helplinesos-api-client';
import { UpdateFaqDTO } from 'projects/helplinesos-api-client/src/model/updateFaqDTO';
import { UpdateFaqsDTO } from 'projects/helplinesos-api-client/src/model/updateFaqsDTO';
import { ActivatedRoute } from '@angular/router';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { FaqDTO } from 'projects/helplinesos-api-client/src/model/faqDTO';
import { ComponentBase } from '../../../shared/models/componentBase';

@Component({
  selector: 'app-clinic-faqs',
  templateUrl: './clinic-faqs.component.html',
  styleUrls: ['./clinic-faqs.component.scss']
})
export class ClinicFaqsComponent extends ComponentBase  implements OnInit {
  searchedTerm = '';
  dto: FaqDTO[];
  role: any;
  helpfulInfoFormGroup: FormGroup;
  @Input() private clinicId: number;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Captain']
   ]);

  get questionsArray() {
    return this.helpfulInfoFormGroup.get('questions') as FormArray;
  }
  constructor(
    private snackBarService: CustomSnackbarService,
    private route: ActivatedRoute,
    private clinicControllerService: ClinicControllerService,
    private formBuilder: FormBuilder
  )
  { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    this.helpfulInfoFormGroup = this.formBuilder.group({
      questions: this.formBuilder.array([])
    });

    const question = this.formBuilder.group({
      question: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      answer: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
    });

    this.questionsArray.push(question);
    this.loadData();
    this.onChanges();
  }

  onChanges(){
    this.helpfulInfoFormGroup.valueChanges.subscribe(res => {
      this.onFormGroupChange.emit(this.helpfulInfoFormGroup);
    });
  }

  addNewQuestion() {
    const question = this.formBuilder.group({
      question: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      answer: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
    });
    this.questionsArray.push(question);
  }

  removeQuestion(i: number) {
    this.questionsArray.removeAt(i);
  }

  updateFaqs() {
    if (this.helpfulInfoFormGroup.valid){
      const dto: UpdateFaqsDTO = {
        faqDTO: []
      };
      this.isWaiting = true;
      this.helpfulInfoFormGroup.controls.questions.value.forEach((element: any) => {
        dto.faqDTO.push({
          question: element.question,
          answer: element.answer
        });
      });
      this.clinicControllerService.updateFaqsUsingPUT(this.clinicId, dto).subscribe(response => {
        this.isWaiting = false;
        this.snackBarService.openToastNotification('Clinic Faqs Updated');
        this.ngOnInit();
      },
      err => {
        this.isWaiting = false;
      });
    }
  }

  searchData(){
    this.isWaiting = true;
    this.clinicControllerService.getFaqsUsingGET(this.clinicId, this.searchedTerm).subscribe(response => {
      this.dto = response;
      this.isWaiting = false;
    },
    err => {
      this.isWaiting = false;
    });
  }

  loadData(){
    this.clinicControllerService.getFaqsUsingGET(this.clinicId, '').subscribe(response => {
      this.dto = response;
      if (response.length > 0) {
        this.removeQuestion(0);
        response.forEach(x => {
          const c = this.formBuilder.group({
            question: [x?.question, [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
            answer: [x?.answer, [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]]
          });
          this.questionsArray.push(c);
        });
      }
      this.isWaiting = false;
    },
    err => {
      this.isWaiting = false;
    });
  }

  cancel(){
    this.ngOnInit();
  }

  getIndex(i: number) {
    return String(i);
  }

  showDeleteFaq(){
    if (this.questionsArray.length > 1){
      return true;
    }
    else{
      return false;
    }
  }

}

