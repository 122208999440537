<main fxLayout="row wrap" fxLayoutAlign="center start">
    <nav fxFlex=100% fxLayout="column" fxLayoutAlign="center center">
        <mat-icon  color="warn">report_problem</mat-icon>

            <h2>Are you sure you want to unassign?</h2>
    </nav>
    <section fxFlex=100% fxFlex=100% fxLayout="column" fxLayoutAlign="start start">
        <strong>Warning! You will no longer be assigned to this call and someone else can assign themselves. Your work will be saved.</strong>
        <mat-checkbox ><strong>Don't show me this message again</strong></mat-checkbox>
    </section>

    <footer  fxFlex=100% fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
        <button  (click)="closeDialog(false)" mat-button color="primary" tabIndex="-1"
           mat-button>Cancel
        </button>
        <button  mat-button color="warn" tabIndex="-1" (click)="closeDialog(true)">Unassign
        </button>
      </footer>

</main>
