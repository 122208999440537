/**
 * HelplineSOS REST API Service - DEV
 * HelplineSOS REST API Service - Hosted on AWS ECS Cluster - DEV Environment Last Updated On Mon, 7 Sep 2020 17:23:26 EST
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateUserDTO } from '../model/createUserDTO';
import { InviteDTO } from '../model/inviteDTO';
import { LoginDTO } from '../model/loginDTO';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SecurityControllerService {

    protected basePath = 'https://api-dev.helplinesos.org';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get User&#39;s Invitation
     * Returns User&#39;s Invitation
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserInviteUsingGET(email: string, observe?: 'body', reportProgress?: boolean): Observable<InviteDTO>;
    public getUserInviteUsingGET(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InviteDTO>>;
    public getUserInviteUsingGET(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InviteDTO>>;
    public getUserInviteUsingGET(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUserInviteUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InviteDTO>(`${this.basePath}/api/auth/register/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Authenticate a User
     * Authenticates a User and Returns a JWT Token
     * @param loginDTO loginDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginUsingPOST(loginDTO: LoginDTO, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public loginUsingPOST(loginDTO: LoginDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public loginUsingPOST(loginDTO: LoginDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public loginUsingPOST(loginDTO: LoginDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginDTO === null || loginDTO === undefined) {
            throw new Error('Required parameter loginDTO was null or undefined when calling loginUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDTO>(`${this.basePath}/api/auth/login`,
            loginDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logout Authenticated User
     * Logout Authenticated User
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logoutUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logoutUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logoutUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/auth/logout`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a New User
     * Register a New User. Type: VOLUNTEER | MANAGER | ADMIN
     * @param createUserDTO createUserDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUserUsingPOST(createUserDTO: CreateUserDTO, observe?: 'body', reportProgress?: boolean): Observable<UserDTO>;
    public registerUserUsingPOST(createUserDTO: CreateUserDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDTO>>;
    public registerUserUsingPOST(createUserDTO: CreateUserDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDTO>>;
    public registerUserUsingPOST(createUserDTO: CreateUserDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createUserDTO === null || createUserDTO === undefined) {
            throw new Error('Required parameter createUserDTO was null or undefined when calling registerUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserDTO>(`${this.basePath}/api/auth/register`,
            createUserDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset User&#39;s Password
     * Reset User&#39;s Password
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordUsingPUT(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordUsingPUT(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordUsingPUT(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordUsingPUT(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling resetPasswordUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (AUTHORIZATION) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/auth/forgotPassword`,
            email,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
