import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomSnackbarService } from './custom-snackbar.service';
import {ErrorService} from './error.service';
import { Router } from '@angular/router';

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router, private errorService: ErrorService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem('token');
        const currentRoute = this.router.url.split('?')[0];

        if (currentRoute !== '/login' && currentRoute !== '/register' && token) {
            request = request.clone({ headers: request.headers.set('Authorization', token) });
        }


        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log('Handled', error);
                if ( error.status === 401){
                    this.router.navigate(['login']);
                }
                this.errorService.pushErrorMessage(error.error.message);
                return throwError(error);
            }));
    }
}
