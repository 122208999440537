import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CallBackDTO, CallBackQuestionDTO, ClinicControllerService, ScribeActionDTO } from '@helplinesos-api-client';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UpdateScribeActionDTO } from 'projects/helplinesos-api-client/src/model/updateScribeActionDTO';
import { UpdateScribeActionsDTO } from 'projects/helplinesos-api-client/src/model/updateScribeActionsDTO';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentBase } from '../../../shared/models/componentBase';
import { empty } from 'rxjs';

@Component({
  selector: 'app-scribe-actions',
  templateUrl: './scribe-actions.component.html',
  styleUrls: ['./scribe-actions.component.scss']
})
export class ScribeActionsComponent extends ComponentBase implements OnInit {
  @Input() private clinicId: number;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  isValidationShown = false;
  dto: UpdateScribeActionsDTO =
    {
      scribeActions: []
    };
    example = true;

  //clinicId: number;
  questionTypes: any = [
    { name: 'Checkboxes', value: UpdateScribeActionDTO.TypeEnum.CHECK },
    { name: 'Multiple Choice', value: UpdateScribeActionDTO.TypeEnum.RADIO }
  ];

  defaultOutcomes: any[] = [
    { value: 'information', display: 'Information', state: false },
    { value: 'sentToEr', display: 'Sent to ER', state: false },
    { value: 'test', display: 'Test', state: false },
    { value: 'leftVm', display: 'Left VM', state: false },
    { value: 'providerReferral', display: 'Provider/Referral', state: false },
    { value: 'unableToReach', display: 'Unable to Reach', state: false },
    { value: 'helpedElsewhere', display: 'Helped Elsewhere', state: false },
    { value: 'wrongNumber', display: 'Wrong Number', state: false },
  ];

  questionsFormGroup: FormGroup;

  get questionForms() {
    return this.questionsFormGroup.get('questions') as FormArray;
  }
  constructor(
    private snackBarService: CustomSnackbarService,
    private clinicControllerService: ClinicControllerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { super(); }

  ngOnInit(): void {
    this.isWaiting = true;
    this.questionsFormGroup = this.formBuilder.group({
      questions: this.formBuilder.array([])
    });
    this.loadDataTest();
    this.onChanges();
  }

  // ngOnDestroy(){
  //   this.loadDataTest();
  // }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questionsFormGroup.controls.questions.value, event.previousIndex, event.currentIndex);
    moveItemInArray(this.questionForms.controls as any, event.previousIndex, event.currentIndex);
  }


  onChanges(){
    this.questionsFormGroup.valueChanges.subscribe(res => {
      this.onFormGroupChange.emit(this.questionsFormGroup);
    });
  }
  addQuestionGroup(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      editable: [true],
      required: [false],
      sequence: [''],
      question: ['',  [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      title: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
      type: [{ name: 'Checkboxes', value: UpdateScribeActionDTO.TypeEnum.CHECK }],
      options: this.formBuilder.array([this.addOptionsGroup()])
    });
  }

  addNewQuestion(): void {
    this.questionForms.push(this.addQuestionGroup());
  }

  removeOption(index: number, i: number) {
    ((this.questionForms.controls[index] as FormGroup).controls.options as FormArray).removeAt(i);
  }

  removeQuestion(i: number) {
    this.questionForms.removeAt(i);
  }

  getIndex(i: number) {
    return String(i);
  }

  private addOptionsGroup(): FormGroup {
    return this.formBuilder.group({
      option: ['', [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
    });
  }

  addOption(index: number): void {
    ((this.questionForms.controls[index] as FormGroup).controls.options as FormArray).push(this.addOptionsGroup());
  }


  changeQuestionType(type: any, index: number) {
    (this.questionForms.controls[index] as FormGroup).controls.type.setValue(type);
  }

  getQuestionType(index: number) {
    return (this.questionForms.controls[index] as FormGroup).controls.type.value;
  }

  isEditable(index: number) {
    return (this.questionForms.controls[index] as FormGroup).controls.editable.value;
  }

  submitClick() {
    if (!this.questionsFormGroup.valid){
      this.isValidationShown = true;
      this.snackBarService.openErrorNotification('There are empty fields. Please fill all required fields in order to save');
      return;
    }

    // let emptyOptions = false;
    // this.questionsFormGroup.controls.questions.value.forEach((element: any, index: number) => {
    //     if (element.options.length === 0){
    //       emptyOptions = true;
    //       return;
    //     }
    // });

    // if (emptyOptions){
    //   this.snackBarService.openErrorNotification('Each question must have at least one option');
    //   return;
    // }

    const dto: UpdateScribeActionsDTO =
    {
      scribeActions: []
    };
    this.isWaiting = true;

    this.questionsFormGroup.controls.questions.value.forEach((element: any, index: number) => {
      dto.scribeActions.push({
        id: element.id,
        required: element.required,
        sequence: index + 1,
        question: element.question,
        title: element.title,
        type: element.type.value,
        values: element.options.map((x: any) => x.option).join(',')
      });
    });
    this.clinicControllerService.updateScribeActionsUsingPUT(this.clinicId, dto).subscribe(response => {
      const x = response;
      this.isWaiting = false;
      this.snackBarService.openToastNotification('Scribe Actions Updated');
      this.ngOnInit();
    //  this.loadDataTest();
    },
      err => {
        this.isWaiting = false;
      });
  }

  loadDataTest() {
    this.clinicControllerService.getScribeActionUsingGET(this.clinicId).subscribe(response => {
      response.forEach(x => {
        const question = this.formBuilder.group({
          editable: [x?.editable],
          question: [x?.question,  [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
          required: [x?.required],
          id: [x?.id],
          title: [x?.title,  [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]],
          sequence: [x?.sequence],
          type: [{ name: 'Checkboxes', value: x?.type }],
          options: this.formBuilder.array([])
        });

        const answers = x.values.split(',');
        answers.forEach(y => {
          (question.controls.options as FormArray).push(this.formBuilder.group({
            option: [y, [Validators.required, Validators.pattern(/^[A-Za-z|0-9]/)]]
          }));
        });
        this.questionForms.push(question);
      });
      this.isWaiting = false;
    },
      err => {
        this.isWaiting = false;
      });
  }


  showDeleteForOptions(index: number){
    const length = ((this.questionForms.controls[index] as FormGroup).controls.options as FormArray).length;
    if( length > 1){
      return true;
    }
    else{
      return false;
    }
  }

  revertToDefault(){
    this.isWaiting = true;
    this.clinicControllerService.setDefaultScribeActionsUsingPOST(this.clinicId).subscribe(response => {
      this.snackBarService.openToastNotification('Scribe actions reverted to default values');
      this.ngOnInit();
    },
    err => {
      this.isWaiting = false;
    });
  }

  cancel(){
    this.ngOnInit();
  }
}
