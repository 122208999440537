import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextMaskModule } from 'angular2-text-mask';
import {MatMenuModule, MatMenu} from '@angular/material/menu';
import { CustomSnackbarService } from '../shared/custom-snackbar.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppErrorStateMatcher } from '../app-matcher';
import { SharedModule } from '../shared/shared.module';
import { UserProfileEditorComponent } from './user-profile-editor/user-profile-editor.component';
import { EditEmailComponent } from './user-profile-editor/edit-email/edit-email.component';
import { EditPasswordComponent } from './user-profile-editor/edit-password/edit-password.component';
import { UserDialogService } from './user-dialog.service';
import { DialogService } from '../call-queue/dialog.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { CancelDialogService } from '../shared/cancel-dialog.service';
import { ConfirmEmailChangesComponent } from './user-profile-editor/confirm-email-changes/confirm-email-changes.component';

@NgModule({
  declarations: [UserProfileEditorComponent, ConfirmEmailChangesComponent, EditEmailComponent, EditPasswordComponent],
  imports: [
    MatPasswordStrengthModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    CommonModule,
    SharedModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatButtonToggleModule,
    CommonModule,
    MatProgressBarModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MomentTimezonePickerModule,
    RouterModule.forChild([
      { path: 'user-profile-editor', component: UserProfileEditorComponent }
    ]
    )
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    { provide: CancelDialogService },
    { provide: CustomSnackbarService },
    { provide: ErrorStateMatcher, useClass: AppErrorStateMatcher },
    { provide: UserDialogService },
  ]
})
export class UserModule { }
