import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationControllerService, NotificationDTO, CallDTO } from '@helplinesos-api-client';
import { Router } from '@angular/router';
import { ErrorService } from '../error.service';
import { CustomSnackbarService } from '../custom-snackbar.service';
import { CallQueueNotificationModel } from '../models/CallQueueNotificationModel';
import { NotificationService } from '../notifications.service';
import { MatDialog } from '@angular/material/dialog';
import { TimerDialogComponent } from '../../call-queue/scribe/timer-dialog/timer-dialog.component';

export type NotificationStateTypeEnum = 'NEW' | 'OLD' | 'ALL';
export const NotificationStateTypeEnum = {
  NEW: 'NEW' as NotificationStateTypeEnum,
  OLD: 'OLD' as NotificationStateTypeEnum,
  ALL: 'ALL' as NotificationStateTypeEnum,
};

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  areOlderNotificationsShown = false;
  newNotifications: NotificationDTO[] = [];
  oldNotifications: NotificationDTO[] = [];
  successNotifications: NotificationDTO[] = [];
  interval: any;
  clinicId: number;
  isRead = false;

  constructor(
    private dialog: MatDialog,
    private customSnackBarService: CustomSnackbarService,
    private router: Router,
    private notificationControllerService: NotificationControllerService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.isRead = NotificationService.isRead;

    this.clinicId = JSON.parse(localStorage.getItem('user'))?.clinicId;
    this.getNotifications(NotificationStateTypeEnum.NEW);

    this.interval = setInterval(() => {
      this.getNotifications(NotificationStateTypeEnum.NEW);
    }, 10000);

    const test = this.newNotifications;
    const test1 = this.oldNotifications;
    const test2 = this.successNotifications;

    // this.notificationControllerService.getNotificationsUsingGET(NotificationStateTypeEnum.OLD).subscribe(response => {
    //   this.oldNotifications = response;
    // });
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  actionButtonName(notification: NotificationDTO){
    if (notification.notificationType === NotificationDTO.NotificationTypeEnum.ALERT){
      return 'Show alert';
    }
    return 'Go to call queue';
  }

  pushCallQueueNotification(notification: NotificationDTO){
    const callQueueNotificationModel: CallQueueNotificationModel = {
      notificationId: notification.id,
      num: notification.counter,
      clinicId: notification.resourceId,
      type: notification.systemNotificationType
    };
    switch (notification.systemNotificationType) {
      case NotificationDTO.SystemNotificationTypeEnum.NEWCALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.ENGLISHCALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.FOLLOWUPCALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.INCOMPLETECALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.SPANISHCALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.COMPLETECALL: {
        this.notificationService.pushCallQueueNotification(callQueueNotificationModel);
        break;
      }
      default:
        break;
    }
  }

  actions(notification: NotificationDTO) {
    switch (notification.systemNotificationType) {
      case NotificationDTO.SystemNotificationTypeEnum.NEWCALL: {
        this.router.navigate(['call-queue/' + notification.resourceId]);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.FLAGGEDCALL: {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { callId: notification.resourceId }}); //optional param in call-queue
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.CALLVOLUMESPIKE: {
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.DEACTIVATEDCLINIC: {
        this.router.navigate(['clinics/' + notification.resourceId + '/information']);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.ENGLISHCALL: {
        this.router.navigate(['call-queue/' + notification.resourceId]);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.EROUTCOME: {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { callId: notification.resourceId }}); //optional param in call-queue
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.FOLLOWUPCALL: {
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { status: CallDTO.StatusEnum.FOLLOWUP }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.INCOMPLETECALL: {
        this.router.navigate(['call-queue/' + notification.resourceId]);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.INCOMPLETECLINIC: {
        this.router.navigate(['clinics/' + notification.resourceId + '/information']);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.LONGCALLBACK: {
         // tslint:disable-next-line: max-line-length
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { callId: notification.resourceId }}); //optional param in call-queue
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.NEWCLINIC: {
        this.router.navigate(['clinics/' + notification.resourceId + '/information']);
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.SCRIBEACTIONUPDATE: {
        this.router.navigate(['resource-dashboard/' + notification.resourceId], { queryParams: { tab: 'scribeActions' }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.HELPFULINFORMATIONUPDATE: {
        this.router.navigate(['resource-dashboard/' + notification.resourceId], { queryParams: { tab: 'helpfulInformation' }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.BULLETINUPDATE: {
        this.router.navigate(['resource-dashboard/' + notification.resourceId], { queryParams: { tab: 'bulletin' }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.FAQUPDATE: {
        this.router.navigate(['resource-dashboard/' + notification.resourceId], { queryParams: { tab: 'clinicFAQs' }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.SPANISHCALL: {
        this.router.navigate(['call-queue/' + this.clinicId], { queryParams: { language: 'Spanish' }});
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.UPDATEDCLINIC: {
        this.router.navigate(['clinics/' + notification.resourceId + '/information']);
        break;
      }
      default:
        break;
    }
  }

  clearNotification(id: number, e: any) {
    this.stopPropagation(e);
    NotificationService.notificationsCount = NotificationService.notificationsCount - 1;
    this.notificationControllerService.clearNotificationUsingPOST(id, 'response').subscribe(
      (response) => {
        if (response.status === 200) {
          this.newNotifications = this.newNotifications.filter(x => x.id !== id);
          this.oldNotifications = this.oldNotifications.filter(x => x.id !== id);
        }
      }
    );
  }

  clearNotification2(id: number) {
    NotificationService.notificationsCount = NotificationService.notificationsCount - 1;
    this.notificationControllerService.clearNotificationUsingPOST(id, 'response').subscribe(
      (response) => {
        if (response.status === 200) {
          this.newNotifications = this.newNotifications.filter(x => x.id !== id);
          this.oldNotifications = this.oldNotifications.filter(x => x.id !== id);
        }
      }
    );
  }

  clearAllNotifications(e: any) {
    NotificationService.notificationsCount = 0;
    this.stopPropagation(e);
    this.notificationControllerService.clearNotificationsUsingPOST('response').subscribe(
      (response) => {
        if (response.status === 200) {
          this.newNotifications = [];
          this.oldNotifications = [];
        }
      }
    );
  }

  getNotifications(state: NotificationStateTypeEnum) {
    this.successNotifications = [];
    this.notificationControllerService.getNotificationsUsingGET(state, 'response').subscribe(
      (response) => {
        switch (state) {
          case NotificationStateTypeEnum.NEW: {
            this.newNotifications = [];
            if (NotificationService.notificationsCount < response.body.length + this.oldNotifications.length ){
              NotificationService.notificationsCount = response.body.length + this.oldNotifications.length;
              NotificationService.isRead = false;
              this.isRead = false;
            }
            break;
          }
          case NotificationStateTypeEnum.OLD: {
            this.oldNotifications = [];
            break;
          }
          default:
            break;
        }
        for (const notification of response.body) {
          this.pushNotification(notification, state);
        }
      }
    );
  }


  pushNotification(notification: NotificationDTO, state: NotificationStateTypeEnum) {
    switch (state) {
      case NotificationStateTypeEnum.NEW: {
        if (notification.notificationType === NotificationDTO.NotificationTypeEnum.SUCCESS) {
          this.successNotifications.push(notification);
          this.customSnackBarService.openSuccessNotification(notification?.message);
          this.clearNotification2(notification?.id);
          return;
        }
        // else if (notification.notificationType === NotificationDTO.SystemNotificationTypeEnum.){
        //   this.dialog.open(TimerDialogComponent, {
        //     maxWidth: '560px',
        //     minWidth: '560px',
        //     backdropClass: 'dialog-backdrop'
        //   });
        //   this.clearNotification2(notification?.id);
        // }
        this.newNotifications.push(notification);
        this.pushCallQueueNotification(notification);
        break;
      }
      case NotificationStateTypeEnum.OLD: {
        if (notification.notificationType === NotificationDTO.NotificationTypeEnum.SUCCESS) {
          return;
        }
        this.oldNotifications.push(notification);
        break;
      }
      default:
        break;
    }

  }

  getNotificationType(type: any) {
    switch (type) {
      case NotificationDTO.NotificationTypeEnum.INFORMATION: {
        return 'info';
      }
      case NotificationDTO.NotificationTypeEnum.ALERT: {
        return 'warning';
      }
      default:
        return 'warning';
    }
  }

  getIconColor(type: any) {
    switch (type) {
      case NotificationDTO.NotificationTypeEnum.INFORMATION: {
        return 'primary';
      }
      case NotificationDTO.NotificationTypeEnum.ALERT: {
        return 'warn';
      }
      default:
        return 'warn';
    }
  }

  setAsRead(){
    NotificationService.isRead = true;
    this.isRead = true;
  }

  showOlderNotifications(e: any){
    this.stopPropagation(e);
    this.areOlderNotificationsShown = !this.areOlderNotificationsShown;
  }

  stopPropagation(e: any){
    e.stopPropagation(); // stop turning off mat menu
}
}
