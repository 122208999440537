import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CallControllerService, CallDTO, CallFlagNoteDTO, ClinicDTO, ClinicControllerService, UserDTO, NotificationDTO, CallBackControllerService, UserControllerService, CallBackDTO } from '@helplinesos-api-client';
import { CallQueueService, CallQueueTableRow, CallQueueTableQuery } from '../call-queue.service';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { PagingControlsComponent } from '../../shared/paging-controls/paging-controls.component';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ExtendPaginationDataSource } from '../../shared/models/extendPaginationDataSource';
import { CallInformationComponent } from '../call-information/call-information.component';
import { DialogService } from '../dialog.service';
import { MatSort, Sort } from '@angular/material/sort';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomSnackbarComponent } from '../../shared/custom-snackbar/custom-snackbar.component';
import { CustomSnackbarService } from '../../shared/custom-snackbar.service';
import { ComponentBase } from '../../shared/models/componentBase';
import { ErrorService } from '../../shared/error.service';
import { NotificationService } from '../../shared/notifications.service';
import { CallQueueNotificationModel } from '../../shared/models/CallQueueNotificationModel';
import { DefaultColumnsDTO } from 'projects/helplinesos-api-client/src/model/defaultColumnsDTO';

export interface CallData {
  callFlagDto: CallFlagNoteDTO;
  callDto: CallDTO;
  callBackDto: CallBackDTO;
}

export interface FlagData {
  callFlagDto: CallFlagNoteDTO;
  callId: number;
}

@Component({
  selector: 'app-call-queue-dashboard',
  templateUrl: './call-queue-dashboard.component.html',
  styleUrls: ['./call-queue-dashboard.component.scss']

})
export class CallQueueDashboardComponent extends ComponentBase implements OnInit {

  constructor(
    private userControllerService: UserControllerService,
    private snackBarService: CustomSnackbarService,
    private clinicControllerService: ClinicControllerService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private service: CallControllerService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private callControllerService: CallControllerService,
    private callQueueService: CallQueueService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private callBackController: CallBackControllerService,) { super(); }
  areDownloadButtonsShown = false;
  roleCaption = new Map([
    [CallDTO.StatusEnum.NEWCALL, 'New Call'],
    [CallDTO.StatusEnum.INPROGRESS, 'In Progress'],
    [CallDTO.StatusEnum.COMPLETE, 'Complete'],
    [CallDTO.StatusEnum.INCOMPLETE, 'Incomplete'],
    [CallDTO.StatusEnum.FOLLOWUP, 'Follow up'],
    [CallDTO.CallTypeEnum.COMPLEX, 'Complex'],
    [CallDTO.CallTypeEnum.INFORMATION, 'Information'],
    [CallDTO.CallTypeEnum.SIMPLE, 'Simple'],
    [CallDTO.CallTypeEnum.TESTING, 'Testing']
  ]);

  areCategoriesSet = false;
  numOfDataPerPage: number[] = [10, 20, 30, 40, 50];
  isPopUpClosed: any;
  role: string;
  categories: string[] = [];
  columns: any[] = [
    { value: 'callerId', display: 'Caller Id', state: true },
    { value: 'timeStamp', display: 'Time Stamp', state: true },
    { value: 'type', display: 'Type', state: true },
    { value: 'symptoms', display: 'Symptoms', state: true },
    { value: 'chronicDisease', display: 'Chronic Disease', state: true },
    { value: 'exposure', display: 'Exposure', state: true },
    { value: 'volunteer', display: 'Volunteer', state: true },
    { value: 'status', display: 'Status', state: true },
    { value: 'age', display: 'Age', state: false },
    { value: 'travel', display: 'Travel', state: false },
    { value: 'phoneNumber', display: 'Phone Number', state: false },
    { value: 'outcome', display: 'Outcome', state: false },
    { value: 'zip', display: 'Zip', state: false },
    { value: 'previousCalls', display: 'Previous Calls', state: false },
    { value: 'lastEdited', display: 'Last Edit', state: false },
    { value: 'duration', display: 'Duration', state: false },
    { value: 'language', display: 'Language', state: false },
    { value: 'insurance', display: 'Insurance', state: false },
  ];
  currentStatus = CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE;
  statuses = [
    {
      name: 'New Calls', value: CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS +
        ',' + CallDTO.StatusEnum.INCOMPLETE, showNotification: false
    },
    { name: 'Follow Up', value: CallDTO.StatusEnum.FOLLOWUP, showNotification: false },
    { name: 'Complete', value: CallDTO.StatusEnum.COMPLETE, showNotification: false }
  ];
  clinicDto: ClinicDTO;
  callFlagDto: CallFlagNoteDTO;
  clinicId: number;
  dataSource: ExtendPaginationDataSource<CallQueueTableRow, CallQueueTableQuery>;
  dataPerPage = 20;
  data: CallQueueTableRow[];
  isOnePage = true;
  languageOptions = [
    { value: 'English', showNotification: false },
    { value: 'Spanish', showNotification: false },
    { value: 'All', showNotification: false }
  ];
  selectedLanguage = 'English';
  callId: string;
  public destroyed = new Subject<any>();
  @ViewChild(PagingControlsComponent, { static: true }) paginator: PagingControlsComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pendingNotification: any = { notificationId: 0, showNotification: false, num: 0 };
  completeNotification: any = { notificationId: 0, showNotification: false, num: 0 };
  followUpNotification: any = { notificationId: 0, showNotification: false, num: 0 };
  englishNotification: any = { notificationId: 0, showNotification: false, num: 0 };
  spanishNotification: any = { notificationId: 0, showNotification: false, num: 0 };

  ngOnInit(): void {
    this.isWaiting = false;
    this.errorService.clearErrorMessage();
    this.errorService.fetchErrorMessage().subscribe((errorMessage => {
      this.errorMessage = errorMessage;
      if (errorMessage !== '') {
        this.isWaiting = false;
      }
    }));

    this.getDefaultCategories();

    this.notificationService.fetchCallQueueNotification().subscribe((notification => {
      this.setNotification(notification);
    }));

    this.clinicId = Number(this.route.snapshot.paramMap.get('clinicId'));
    this.role = JSON.parse(localStorage.getItem('user')).role;
    this.handleWrongClinicId();

    this.clinicControllerService.getClinicUsingGET(this.clinicId).subscribe(response => {
      this.clinicDto = response;
    });


    this.dataSource = new ExtendPaginationDataSource<CallQueueTableRow, CallQueueTableQuery>(
      (request, query) => this.callQueueService.get(this.clinicId, request, query),
      { property: undefined, order: undefined },
      { search: '', language: 'English', status: this.statuses[0].value },
      this.dataPerPage
    );

    this.dataSource.page$.subscribe((page) => {
      this.data = page.content;
      const paginationInstance: PaginationInstance = {
        itemsPerPage: this.dataSource.pageSize,
        currentPage: page.number,
        totalItems: page.totalElements,
      };
      this.paginator.register(paginationInstance);

      this.isOnePage = (paginationInstance.totalItems > this.dataPerPage) ? false : true;

    });
    this.sort.sortChange.subscribe((sort: Sort) => {
      const sortSpecification = {
        property: sort.active,
        order: sort.direction
      } as unknown;
      this.dataSource.sortBy(sortSpecification);
    });

    this.isNotificationView();
  }


  getDefaultCategories() {
    this.userControllerService.getDefaultColumnsUsingGET().subscribe(response => {
      if (response?.columnNames) {
        this.areCategoriesSet = true;
        const categories = response?.columnNames.split(',');
        categories.forEach(x => {
          this.categories.push(x);
        });
      }
      else {
        this.categories = [
          'callerId',
          'timeStamp',
          'type',
          'symptoms',
          'chronicDisease',
          'exposure',
          'volunteer',
          'status',
        ];
      }
    });

  }


  setNotification(notification: CallQueueNotificationModel) {
    switch (notification.type) {
      case NotificationDTO.SystemNotificationTypeEnum.NEWCALL: {
        if (this.currentStatus === CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE) {
          this.notificationService.clearNotification(notification.notificationId);
          return;
        }
        this.pendingNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
        this.statuses[0].showNotification = true;
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.ENGLISHCALL: {
        this.englishNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
        this.languageOptions[0].showNotification = true;
        this.languageOptions[2].showNotification = true;
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.FOLLOWUPCALL: {
        if (this.currentStatus === CallDTO.StatusEnum.FOLLOWUP) {
          this.notificationService.clearNotification(notification.notificationId);
          return;
        }
        this.followUpNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
        this.statuses[1].showNotification = true;
        break;
      }
      case NotificationDTO.SystemNotificationTypeEnum.COMPLETECALL: {
        if (this.currentStatus === CallDTO.StatusEnum.COMPLETE) {
          this.notificationService.clearNotification(notification.notificationId);
          return;
        }
        this.completeNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
        this.statuses[2].showNotification = true;
        break;
      }
      // case NotificationDTO.SystemNotificationTypeEnum.INCOMPLETECALL: {
      //   if (this.currentStatus === CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE){
      //     return;
      //   }
      //   this.pendingNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
      //   this.statuses[0].showNotification = true;
      //   break;
      // }
      case NotificationDTO.SystemNotificationTypeEnum.SPANISHCALL: {
        this.spanishNotification = { notificationId: notification.notificationId, showNotification: true, num: notification.num };
        this.languageOptions[1].showNotification = true;
        this.languageOptions[2].showNotification = true;
        break;
      }
      default:
        break;
    }
  }

  handleWrongClinicId() {
    const membersClinicId = Number(JSON.parse(localStorage.getItem('user'))?.clinicId);
    if (this.role !== UserDTO.RoleEnum.ADMIN) {
      if (membersClinicId !== this.clinicId) {
        this.clinicId = membersClinicId;
      }
    }
  }

  isNotificationView() {
    this.router.events.pipe( // only if u click notification to redirect you on the same page
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.callId = this.route.snapshot.queryParamMap.get('callId');
      if (this.callId) {
        // tslint:disable-next-line: radix
        this.openDialog(Number.parseInt(this.callId));
      }
    });

    this.callId = this.route.snapshot.queryParamMap.get('callId');
    if (this.callId) {
      // tslint:disable-next-line: radix
      this.openDialog(Number.parseInt(this.callId));
    }

    const language = this.route.snapshot.queryParamMap.get('language');
    if (language) {
      this.selectedLanguage = language;
    }

    const status = this.route.snapshot.queryParamMap.get('status');
    if (status) {
      this.currentStatus = status;
      this.dataSource.queryBy({ status });
    }
  }


  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.notificationService.clear();
  }

  openDialog(callId: number) {
    this.callControllerService.getCallFlagNotesUsingGET(callId).subscribe(response => {
      this.callFlagDto = response[0];
    });
    this.service.getCallUsingGET(callId, 'response').subscribe(
      (response) => {
        const call = response.body;
        if (call.status === CallDTO.StatusEnum.COMPLETE) {
          this.router.navigate(['/scribe-complete/' + this.clinicId + '/' + call.id]);
          return;
        }
        if (call.status === CallDTO.StatusEnum.INPROGRESS) {
          this.isWaiting = true;
          this.callBackController.getCallBacksUsingGET(callId, 'response').subscribe(
            (response1) => {
              const callBacksDto = response1.body
                .sort(function (a, b) {
                  return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
                });

              const userId = JSON.parse(localStorage.getItem('user'))?.id;
              // const myCallback = callBacksDto.
              //   find(x =>
              //     (x.status === CallDTO.StatusEnum.INPROGRESS || x.status === CallDTO.StatusEnum.NEWCALL) && x.createdById === userId);
              this.isWaiting = false;
              const myCallback = callBacksDto[0];
              if (myCallback?.createdById === userId && myCallback?.status === CallDTO.StatusEnum.INPROGRESS) {
                this.router.navigate(['/scribe/' + this.clinicId + '/' + call.id + '/' + myCallback.id]);
                return;
              }
              else {
                this.dialogService.openCallInformation(call, this.callFlagDto, myCallback);
                return;
              }
            }
          );
          return;
        }
        if (call.status === CallDTO.StatusEnum.FOLLOWUP) {
          this.isWaiting = true;
          this.callBackController.getCallBacksUsingGET(callId, 'response').subscribe(
            (response1) => {
              const callBacksDto = response1.body
                .sort(function (a, b) {
                  return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
                });
              const userId = JSON.parse(localStorage.getItem('user'))?.id;
              this.isWaiting = false;
              const myCallback = callBacksDto[0];
              if (myCallback?.createdById === userId && myCallback?.status === CallDTO.StatusEnum.INPROGRESS) {
                this.router.navigate(['/scribe/' + this.clinicId + '/' + call.id + '/' + myCallback.id]);
                return;
              }
              else {
                this.dialogService.openCallInformation(call, this.callFlagDto, myCallback);
                return;
              }
            }
          );
          return;
        }
        this.dialogService.openCallInformation(call, this.callFlagDto);
      }
    );
  }

  pageChange(page: number) {
    this.dataSource.fetch(page - 1);
  }

  changeNumOfDataPerPage(numOfDataPerPage: number) {
    this.dataSource.currentPage = 1; //refresh pagination to first page
    this.dataSource.pageSize = numOfDataPerPage;
    this.dataPerPage = numOfDataPerPage;
    this.dataSource.fetch(this.dataSource.currentPage - 1);
  }

  goToCallInformation(clinicId: number, callId: number) {
    this.router.navigate(['call-queue/' + clinicId + '/' + callId]);
  }

  isComplex(type: string) {
    if (type === CallDTO.CallTypeEnum.COMPLEX || type === CallDTO.CallTypeEnum.SIMPLE) {
      return true;
    }
    return false;
  }

  isWarningColor(prop: string) {
    if (prop === 'Yes') {
      return true;
    }
    return false;
  }

  stopPropagation(e: any) {
    e.stopPropagation(); // stop turning off mat menu
  }
  showButtons(e: any) {
    this.stopPropagation(e);
    this.areDownloadButtonsShown = !this.areDownloadButtonsShown;
  }


  getDisplayedColumns(): string[] {
    const columnsForDisplay: string[] = [];
    columnsForDisplay.push('flag');
    this.categories.forEach((x) => {
      columnsForDisplay.push(x);
    });
    if ((this.role === UserDTO.RoleEnum.CAPTAIN || this.role === UserDTO.RoleEnum.COORDINATOR)
    && this.currentStatus !== CallDTO.StatusEnum.COMPLETE) {
      columnsForDisplay.push('menu');
    }
    return columnsForDisplay;
  }

  setDefaultCategories(e: any) {
    if (e.checked) {
      // this.changeStatus(this.currentStatus);
      // const columns: string[] = [];
      // this.columns.filter(x => x.state).map(c => c.value).forEach((column) => {
      //   columns.push(column);
      // });
      const defaultCategories: DefaultColumnsDTO = {
        columnNames: this.categories.join(',')
      };
      this.userControllerService.setDefaultColumnsUsingPUT(defaultCategories).subscribe(response => {
        this.areCategoriesSet = true;
        this.snackBarService.openToastNotification('Categories set as default');
      });
    }
  }

  getNumberOfNotifications(status: any) {
    switch (this.currentStatus) {
      case CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE: {
        return this.pendingNotification.num;
      }
      case CallDTO.StatusEnum.COMPLETE: {
        return this.completeNotification.num;
      }
      case CallDTO.StatusEnum.FOLLOWUP: {
        return this.followUpNotification.num;
      }
      default:
        return 0;
    }
  }

  changeStatus(status: any) {
    this.currentStatus = status;
    switch (this.currentStatus) {
      case CallDTO.StatusEnum.NEWCALL + ',' + CallDTO.StatusEnum.INPROGRESS + ',' + CallDTO.StatusEnum.INCOMPLETE: {
        if (!this.areCategoriesSet) {
          this.categories = [
            'callerId',
            'timeStamp',
            'type',
            'symptoms',
            'chronicDisease',
            'exposure',
            'volunteer',
            'status',
          ];
        }
        this.notificationService.clearNotification(this.pendingNotification.notificationId);
        this.statuses[0].showNotification = false;
        break;
      }
      case CallDTO.StatusEnum.COMPLETE: {
        if (!this.areCategoriesSet) {
          this.categories = [
            'callerId',
            'timeStamp',
            'type',
            'symptoms',
            'chronicDisease',
            'exposure',
            'volunteer',
            'status',
            'lastEdited'
          ];
        }
        this.notificationService.clearNotification(this.completeNotification.notificationId);
        this.statuses[2].showNotification = false;
        break;
      }
      case CallDTO.StatusEnum.FOLLOWUP: {
        if (!this.areCategoriesSet) {
          this.categories = [
            'callerId',
            'timeStamp',
            'type',
            'symptoms',
            'chronicDisease',
            'exposure',
            'volunteer',
            'status',
            'lastEdited'
          ];
        }
        this.statuses[1].showNotification = false;
        this.notificationService.clearNotification(this.followUpNotification.notificationId);
        break;
      }
      default:
        break;
    }
  }

  changeLanguage(language: string) {
    switch (language) {
      case 'English': {
        this.notificationService.clearNotification(this.englishNotification.notificationId);
        this.languageOptions[0].showNotification = false;
        this.languageOptions[2].showNotification = false;
        break;
      }
      case 'Spanish': {
        this.notificationService.clearNotification(this.spanishNotification.notificationId);
        this.languageOptions[1].showNotification = false;
        this.languageOptions[2].showNotification = false;
        break;
      }
      case 'All': {
        this.languageOptions[2].showNotification = false;
        this.languageOptions[0].showNotification = false;
        this.languageOptions[1].showNotification = false;
        this.notificationService.clearNotification(this.spanishNotification.notificationId);
        this.notificationService.clearNotification(this.spanishNotification.notificationId);
        break;
      }
      default:
        break;
    }
  }

  changeColumns(e: any, value: string) {
    // this.columns.filter(x => x.value === value)[0].state = e.checked;
    if (e.checked) {
      this.categories.push(value);
    }
    else {
      const index = this.categories.indexOf(value, 0);
      if (index > -1) {
        this.categories.splice(index, 1);
      }
    }
    this.getDisplayedColumns();
  }

  isChecked(value: string) {
    return this.categories.includes(value);
  }

  openFlagCall(event: any, callId: number) {
    this.stopPropagation(event);
    this.callControllerService.getCallFlagNotesUsingGET(callId).subscribe(response => {
      this.callFlagDto = response[0];
      const ref = this.dialogService.openFlagCall(this.callFlagDto, callId);
      ref.afterClosed().subscribe(response => {
        this.dataSource.queryBy(undefined);
      });
    });

    // if (ref){
    //   this.dataSource.queryBy(undefined);
    // }
  }

  getNotesInfo(callId: number) {
    return 'Call Flag Information';
  }

  getStatusClass(status: CallDTO.StatusEnum) {
    let statusClass = '';
    switch (status) {
      case CallDTO.StatusEnum.NEWCALL: {
        statusClass = 'new-call';
        break;
      }
      case CallDTO.StatusEnum.INPROGRESS: {
        statusClass = 'in-progress';
        break;
      }
      default:
        break;
    }
    return statusClass;
  }



  callsDownload(extension: any) {
    this.isWaiting = true;
    this.callControllerService.downloadCallsAsResourceUsingGET(this.clinicId, extension, 'response').subscribe((response) => {
      const test = response;
      // let blob = new Blob([response.file], { type: 'text/csv' });
      // var url = window.URL.createObjectURL(blob);
      //  window.open(url);
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      const url = window.URL.createObjectURL(response.body);
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
      this.isWaiting = false;
    });
  }

  unassignCall(callId: number) {
    // this.service.unAssignCallUsingPOST(18, callId).subscribe(response => {
    //   this.snackBarService.openToastNotification('User Unassigned');
    //   this.changeNumOfDataPerPage(this.dataPerPage);
    // });
    this.callBackController.getCallBacksUsingGET(callId, 'response').subscribe(
      (response1) => {
        const callBacksDto = response1.body
          .sort(function (a, b) {
            return (new Date(b.createdDate) as any) - (new Date(a.createdDate) as any);
          });
        const callBackId = callBacksDto[0]?.id;
        this.service.unAssignCallUsingPOST(callBackId, callId).subscribe(response => {
          this.snackBarService.openToastNotification('User Unassigned');
          this.changeNumOfDataPerPage(this.dataPerPage);
        });
      });
  }
}
