<main fxLayout="column" fxLayoutAlign="start stretch">
    <mat-toolbar  fxLayout="row" fxLayoutGap="64px" class="mat-elevation-z4">
        <button (click)="cancel()" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h1 fxFlex="100%">Edit Role</h1>
    </mat-toolbar>
    <strong *ngIf="errorMessage">{{errorMessage}}</strong>
    <section  fxLayout="row wrap" class="mat-elevation-z1" fxLayoutAlign="center center">
        <div fxFlex="100%" class="progress-bar-grid">
            <mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
        </div>
        <form  fxFlex="576px" fxLayout="row wrap" fxLayoutAlign="start start" id="formGroup" [formGroup]="formGroup">
            <h2>Update your team member's role.</h2>
            <div  fxLayout="column" fxLayoutGap="32px">            
                <strong>Select a role from the dropdown and check the box to send them an email notifying them of this change. </strong>
                <!-- <strong fxFlex="100%"><b>{{this.userDto?.firstName userDto?.lastName}}</b> {{userDto?.email}} (132) 132-1312</strong> -->
                <strong fxFlex="100%"><b>{{this.userDto?.firstName}} {{userDto?.lastName}}</b></strong>
            </div>

            <mat-form-field fxFlex="242px" >
                <mat-label>Role</mat-label>
                <mat-select disableOptionCentering panelClass="position-of-content" formControlName="role">
                  <mat-option *ngFor="let role of roles" [value]="role">
                    {{ this.roleCaption.get(this.role) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-checkbox fxFlex="100%" (change)="isNotesFieldShown = !isNotesFieldShown">Send an email to update your team member of this change</mat-checkbox>
              <mat-form-field fxFlex="" *ngIf="isNotesFieldShown">

                <mat-label>Notes - optional</mat-label>
                <fate-material  [buttons]="['bold','italic','underline','strike','ordered','unordered','clean']"  [formControl]="formGroup.controls['notes']"></fate-material>
              </mat-form-field>
        </form>
    </section>
    <nav  class="mat-elevation-z1">
        <button mat-button color="primary" class="cancel" (click)="cancel()">
            Cancel
        </button>
        <button type="submit" form="formGroup" (click)="updateUserRole()" [disabled]="isWaiting" mat-raised-button color="primary" class="save">
            Save
        </button>
    </nav>
</main>