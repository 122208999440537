<div class="wrapper">
  <div fxLayout="row" fxLayoutAlign="center center">

    <main fxFlex="100%" fxFlex.gt-xs="80%" fxFlex.gt-sm="684px">
      <div><mat-progress-bar *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar></div>
      <div fxLayout="row" fxLayoutAlign="center center">

        <div></div>
        <div fxFlex="100%" fxFlex.xs="90%" fxFlex.gt-xs="80%" fxFlex.gt-sm="60%">

          <header>
            <!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
            <div><img class="logo" src="../assets/images/HelplineLogo_01_FinalColor.svg" /></div>
            <h2 class="header-item" *ngIf="email">
              Welcome <span class="primary">{{email}}</span>, Thanks for joining us!
            </h2>
            <div><strong class="error" *ngIf="errorMessage">{{errorMessage}}</strong></div>
            <div><strong class="error" *ngIf="!email">Registration requires an invitation</strong></div>
          </header>

          <form fxLayout="column" *ngIf="email" #form [formGroup]="registerForm">

            <div fxLayout="column" fxLayoutGap="0px" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="24px">

              <mat-form-field fxFlex>
                <mat-label>First Name</mat-label>
                <input matInput [formControl]="registerForm.controls.firstName" maxlength="50">
                <mat-error *ngIf="registerForm.controls.firstName.errors?.required">First name is required</mat-error>
                <mat-error *ngIf="registerForm.controls.firstName.errors?.maxlength">First name is too long</mat-error>
                <mat-error *ngIf="registerForm.controls.firstName.errors?.pattern">First name must start with character</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Last Name</mat-label>
                <input matInput [formControl]="registerForm.controls.lastName" maxlength="50">
                <mat-error *ngIf="registerForm.controls.lastName.errors?.required">Last name is required</mat-error>
                <mat-error *ngIf="registerForm.controls.lastName.errors?.maxlength">Last name is too long</mat-error>
                <mat-error *ngIf="registerForm.controls.lastName.errors?.pattern">Last name must start with character</mat-error>
              </mat-form-field>

            </div>

            <mat-form-field>
              <mat-label>Password (Use at least 8 characters)</mat-label>
              <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
              <input autocomplete="new-password" [type]="toggle.type" matInput [formControl]="registerForm.controls.password" maxlength="20" (focusout)="onPasswordControlFocusOut()" (focus)="onPasswordControlFocus()"> <!-- [type]="isPasswordHidden ? 'password' : 'text'" -->
              <mat-error *ngIf="registerForm.controls.password.errors?.required">Password is required</mat-error>
              <mat-error *ngIf="registerForm.controls.password.errors?.pattern">Password complexity criteria not met</mat-error>
            </mat-form-field>
            
            <!-- https://github.com/angular-material-extensions/password-strength -->
            <mat-password-strength 
              #passwordComponent 
              [hidden]="(!isPasswordControlFocused)"
              [password]="registerForm.value.password">
            </mat-password-strength><!-- (onStrengthChanged)="onStrengthChanged($event)" -->
            <mat-password-strength-info 
              [hidden]="(!isPasswordControlFocused)"
              [passwordComponent]="passwordComponent"
              lowerCaseCriteriaMsg="at least 1 lower case"
              upperCaseCriteriaMsg="at least 1 upper case"
              digitsCriteriaMsg="at least 1 digit"
              specialCharsCriteriaMsg="at least 1 special character"
              minCharsCriteriaMsg="at least 8 characters"
            ></mat-password-strength-info>

            <mat-form-field>
              <mat-label>Confirm Password</mat-label>
              <input [type]="toggle.type" matInput [formControl]="registerForm.controls.confirmPassword" maxlength="20">
              <mat-error *ngIf="registerForm.controls.confirmPassword.errors?.required">Confirm password is required</mat-error>
              <mat-error *ngIf="!registerForm.controls.confirmPassword.errors?.isMatching">Passwords do not match</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Phone Number</mat-label>
              <input matInput [textMask]="mask" [formControl]="registerForm.controls.phoneNumber">
              <mat-error *ngIf="registerForm.controls.phoneNumber.errors?.required">Phone number is required</mat-error>
              <mat-error *ngIf="registerForm.controls.phoneNumber.errors?.pattern">Phone number must have 10 digits</mat-error>
            </mat-form-field>

            <mat-error [ngClass]="'timezone-error'" *ngIf="timezoneShowError" >Timezone is required</mat-error>
            <ng-moment-timezone-picker (onselect)="timezoneChange($event)"
              [customPlaceholderText]="this.timezoneText" [getUserZone]='true'>
            </ng-moment-timezone-picker>

            <div class="terms" fxLayout="row wrap" fxLayoutAlign="start start">
              <mat-checkbox [formControl]="registerForm.controls.termsAndConditions" required>I have read and agree to the
              <a (click)="openDialog()">Terms and Conditions</a></mat-checkbox>
              <mat-error *ngIf="isSubmitted && registerForm.controls.termsAndConditions.errors?.required">Please accept Terms and Conditions</mat-error>
            </div>

            <button type="submit" mat-raised-button (click)="register()" [disabled]="isWaiting" color="primary">Sign up</button>
          </form>
          <footer>
            <h4>
              <span>Already a User? <a href="/login">Login here</a></span>
            </h4>           
          </footer>
        </div>
      </div>
    </main>
  </div>
</div>
