import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { MemberTableRow, MemberTableQuery, MemberService } from '../../member.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PaginationInstance } from 'ngx-pagination';
import { MatSort, Sort } from '@angular/material/sort';
import { PagingControlsComponent } from '../../../shared/paging-controls/paging-controls.component';
import { MatDialog } from '@angular/material/dialog';
import { MembersEditorComponent } from '../../members-editor/members-editor.component';
import { Router } from '@angular/router';
import { MemberControllerService, UserDTO } from '@helplinesos-api-client';
import { CustomSnackbarService } from '../../../shared/custom-snackbar.service';
import { ExtendPaginationDataSource } from '../../../shared/models/extendPaginationDataSource';

@Component({
  selector: 'app-members-tab',
  templateUrl: './members-tab.component.html',
  styleUrls: ['./members-tab.component.scss']
})
export class MembersTabComponent implements OnInit {
  @Input() private clinicId: number;
  isWaiting = true;
  displayedColumns: string[] = ['member', 'role', 'phone', 'email', 'status', 'menu'];

  selected = [ `${UserDTO.OnlineStatusEnum.INCALL},${UserDTO.OnlineStatusEnum.ONLINE},${UserDTO.OnlineStatusEnum.IDLE},${UserDTO.OnlineStatusEnum.OFFLINE}`
               , UserDTO.OnlineStatusEnum.PENDING, UserDTO.OnlineStatusEnum.DEACTIVATED  ];

  activeAndIncomplete: any = [
    { name: 'Active', value: `${UserDTO.OnlineStatusEnum.INCALL},${UserDTO.OnlineStatusEnum.ONLINE},${UserDTO.OnlineStatusEnum.IDLE},${UserDTO.OnlineStatusEnum.OFFLINE}`},
    { name: 'Pending', value: UserDTO.OnlineStatusEnum.PENDING },
    { name: 'Deactivated', value: UserDTO.OnlineStatusEnum.DEACTIVATED}
  ];
  dataSource: ExtendPaginationDataSource<MemberTableRow, MemberTableQuery>;
  selection = new SelectionModel<MemberTableRow>(true, []); // allowMultiSelect:true, initialSelection: []
  data: MemberTableRow[];
  isOnePage = true;
  dataPerPage = 20;
  role: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(PagingControlsComponent, { static: true }) paginator: PagingControlsComponent;

  roleCaption = new Map([
    [UserDTO.RoleEnum.ADMIN, 'Admin'],
    [UserDTO.RoleEnum.VOLUNTEER, 'Volunteer'],
    [UserDTO.RoleEnum.CAPTAIN, 'Captain'],
    [UserDTO.RoleEnum.COORDINATOR, 'Coordinator'],
    [UserDTO.OnlineStatusEnum.DEACTIVATED, 'Deactivated'],
    [UserDTO.OnlineStatusEnum.PENDING, 'Pending']
   ]);

  constructor(
    private memberControllerService: MemberControllerService,
    private service: MemberService,
    private dialog: MatDialog,
    private router: Router,
    private snackBarService: CustomSnackbarService) {
    }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('user'))?.role;
    if (this.role === 'ROLE_VOLUNTEER'){
      this.displayedColumns = ['member', 'role', 'phone', 'email', 'status'];
      this.activeAndIncomplete = [
        { name: 'Active', value: `${UserDTO.OnlineStatusEnum.INCALL},${UserDTO.OnlineStatusEnum.ONLINE},${UserDTO.OnlineStatusEnum.IDLE},${UserDTO.OnlineStatusEnum.OFFLINE}`},
      ];
      this.selected = [ `${UserDTO.OnlineStatusEnum.INCALL},${UserDTO.OnlineStatusEnum.ONLINE},${UserDTO.OnlineStatusEnum.IDLE},${UserDTO.OnlineStatusEnum.OFFLINE}`];
    }
    this.dataSource = new ExtendPaginationDataSource<MemberTableRow, MemberTableQuery>(
      (request, query) => this.service.get(this.clinicId, request, query),
      { property: 'name', order: 'asc' },
      { search: '' , status: this.selected as []},
      this.dataPerPage
    );
    this.dataSource.page$.subscribe((page) => {
      this.data = page.content;
      // https://github.com/michaelbromley/ngx-pagination/blob/master/src/pagination-instance.ts
      const paginationInstance: PaginationInstance = {
        itemsPerPage: this.dataSource.pageSize,
        currentPage: page.number,
        totalItems: page.totalElements,
      };
      this.paginator.register(paginationInstance);

      this.isOnePage = (paginationInstance.totalItems > this.dataPerPage) ? false : true ;

    });

    this.sort.sortChange.subscribe((sort: Sort) => {
      const sortSpecification = {
          property: sort.active,
          order: sort.direction
      } as unknown;
      this.dataSource.sortBy(sortSpecification);
    });
  }

  pageChange(page: number){
    this.dataSource.fetch(page - 1); // IMPORTANT: dataSource is 0-based, while app-paging-controls is 1-based
  }

  inviteNewMemberDialog() {
    this.router.navigate(['clinics/' + this.clinicId + '/members']);
  }

  resendInvite(email: string){
    this.memberControllerService.resendInviteUsingPOST(this.clinicId, email).subscribe((response) =>
    {
      this.snackBarService.openToastNotification('Invite Sent');
  });
}

  getStatusClass(status: UserDTO.OnlineStatusEnum){
     let className = '';
     switch (status) {
      case UserDTO.OnlineStatusEnum.INCALL: {
        className = 'status-online';
        break;
      }
      case UserDTO.OnlineStatusEnum.ONLINE: {
        className = 'status-online';
        break;
      }
      case UserDTO.OnlineStatusEnum.IDLE: {
        className = 'status-idle';
        break;
      }
      case UserDTO.OnlineStatusEnum.DEACTIVATED: {
        className = 'status-deactivated';
        break;
      }
      case UserDTO.OnlineStatusEnum.OFFLINE: {
        className = 'status-offline';
        break;
      }
      case UserDTO.OnlineStatusEnum.PENDING: {
        className = 'status-pending';
        break;
      }
      default:
        break;
    }
     return className;
  }

  getStatusIcon(status: UserDTO.OnlineStatusEnum){
    let statusIcon = 'fiber_manual_record';
    switch (status) {
     case UserDTO.OnlineStatusEnum.INCALL: {
      statusIcon = 'call';
      break;
     }
     case UserDTO.OnlineStatusEnum.ONLINE: {
      statusIcon = 'fiber_manual_record';
      break;
     }
     case UserDTO.OnlineStatusEnum.IDLE: {
      statusIcon = 'fiber_manual_record';
      break;
     }
     case UserDTO.OnlineStatusEnum.DEACTIVATED: {
      statusIcon = 'highlight_off';
      break;
     }
     case UserDTO.OnlineStatusEnum.OFFLINE: {
      statusIcon = 'fiber_manual_record';
      break;
     }
     case UserDTO.OnlineStatusEnum.PENDING: {
      statusIcon = 'fiber_manual_record';
      break;
     }
     default:
       break;
   }
    return statusIcon;
 }


  deactivate(memberId: number){
    this.memberControllerService.deactivateUsingPOST(this.clinicId, memberId).subscribe((response) => {
      this.snackBarService.openToastNotification('Member Deactivated');
      this.dataSource.queryBy({ search: ''});
    });
  }
  goToRoleEditor(id: number){
    this.router.navigate(['clinics/' + this.clinicId + '/role/' + id]);
  }

  reactivate(id: number){
    this.memberControllerService.reactivateUsingPOST(this.clinicId, id).subscribe(response => {
      this.snackBarService.openToastNotification('Member reactivated');
      this.dataSource.queryBy({ search: ''});
    });
  }
}
