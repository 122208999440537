import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { ClinicControllerService, PageClinicDTO, ClinicDTO } from '@helplinesos-api-client';

export class ClinicTableRow {
  id: number;
  name: string;
  address: string;
  phone: string;
  status: string;
}

export class ClinicTableQuery {
  search: string;
  status: [];
}

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  constructor(private service: ClinicControllerService) {}

  get(
    request: PageRequest<ClinicTableRow>,
    query: ClinicTableQuery
  ): Observable<Page<ClinicTableRow>> {

    // ...&sortBy=name.asc,address.desc&...
    return this.service.getClinicsUsingGET(
      query.search,
      `${request.page}`,
      `${request.size}`,
      !request.sort.order ? '' : `${request.sort.property}.${request.sort.order}`,
      query.status.join(',')
      ).pipe(
      // https://angular.io/guide/rx-library
      map((response: PageClinicDTO) => {
        const content = response.content.map((dto: ClinicDTO) => {
          return {
            id: dto.id,
            name: dto.name,
            address: [
              dto.address.apt,
              dto.address.streetAddress,
              dto.address.city,
              dto.address.zipCode,
              dto.address.state,
            ].join(' '),
            phone:
            dto.hotlineNumber ?
              '(' +
              dto.hotlineNumber.slice(0, 3) + ') ' +
              dto.hotlineNumber.slice(3, 6) + '-' +
              dto.hotlineNumber.slice(6) : 'Phone number not set',
              status: dto.status,
          } as ClinicTableRow;
        });
        return {
          totalElements: response.totalElements, // TODO: map from response
          size: response.size,
          number: response.number + 1, // IMPORTANT: UI uses 1-based, API uses 0-based
          content,
        } as Page<ClinicTableRow>;
      })
    ); // .pipe(delay(1000));
  }
}
