<main  fxLayout="column">
<header id="header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="center center">
    <img fxFlex="268px" fxFlex.xs="150px" src="../assets/images/HelplineLogo_03_FinalColor.svg" />
    <div fxLayout.xs="column" fxLayoutAlign.xs="center center">
        <a href="#who_we_are" mat-button color="primary">Who we are</a>
        <a href="#what_we_do" mat-button >What we do</a>
        <a href="#how_it_works" mat-button >How it works</a>
        <a href="#contact_us"  mat-button >Contact Us</a>
        <button mat-stroked-button color="primary" class="stroked-button" (click)="redirectToLogin()">Login</button>
    </div>
</header>
<section  fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.s="start center" fxLayoutAlign.xs="start center">
    <div fxFlex="536px" fxFlex.xl="30%" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign.xs="start center" fxLayoutAlign="start start">
        <h4>Decentralized Telephonic Triage Platform</h4>
        <h1>Effectively Answer Every Call for Help</h1>
        <strong>Organized call intake to reduce headaches, increase efficiencies and save lives.</strong>
        <a href="#contact_us"  mat-stroked-button color="warn" ngClass="stroked-button-warn margin-top64" ngClass.xs="stroked-button-warn" fxLayoutAlign="center center">Contact Us</a>
    </div>
    <div fxHide.xs fxFlex="50%" fxLayoutAlign="space-around center">
        <img fxFlex="716px" src="../assets/images/Helpline_Triage_System.png" />
    </div>
    <div class="margin-top64" fxFlex="100%" fxLayoutAlign="center center">
        <a href="#who_we_are" mat-icon-button  ><mat-icon  id="who_we_are" class="arrow-down">arrow_downward</mat-icon></a>
    </div>

</section>
<section fxLayout="column" class="white-section">
    <div fxLayoutAlign="center center">
        <span class="circle"></span>
    </div>
    <div  fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
        <div fxHide.xs>
            <img class="virus-image" fxFlex="253px" src="../assets/images/Virus_Blue.png" />
            <img class="virus-image-small"  src="../assets/images/Virus_Blue.png" />
        </div>
        

    </div>
    <div  fxLayout="row" fxLayoutAlign="center center" ngClass.xl="reduce-spacing" ngClass.lg="reduce-spacing" ngClass.md="reduce-spacing">
        <strong  fxFlex="50%" fxFlex.xs="100%" ngClass="text-align-center">Our mission is to strengthen every health clinic's ability to answer calls for help from patients and the community-at-large.</strong>
    </div>

    <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center center">
        

        <div class="section-line"></div>
        <h1 ngClass.xs="text-align-center">Who We Are</h1>
        <strong>We Help Clinics Work Smarter and Achieve More</strong>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="24px" fxLayoutGap.xs="0px" fxLayoutGap.xl="48px" fxLayoutAlign="center center">
        <mat-card class="mat-elevation-z3">
            <mat-card-content>
                <mat-icon color="primary">public</mat-icon>
                <mat-card-title>Our History</mat-card-title>
                <!-- <mat-icon color="primary">minimize</mat-icon> -->
                <div class="card-line"></div>
              <p>
                HelplineSOS was founded during COVID-19 to assist clinics that were unable to answer their phones due to high call volumes and closure of offices.
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
                <mat-icon color="primary">pan_tool</mat-icon>
                <mat-card-title>Experience</mat-card-title>
                <!-- <mat-icon color="primary">minimize</mat-icon> -->
                <div class="card-line"></div>
              <p>
                We are a team of cross-functional experts working across the United States with experience in communications, technology and public health.
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
                <mat-icon color="primary">local_hospital</mat-icon>
                <mat-card-title>Support</mat-card-title>
                <!-- <mat-icon color="primary">minimize</mat-icon> -->
                <div class="card-line"></div>
              <p>
                We view our work as a mission to help those that are serving others by making their job easier.
              </p>
              <p>
                  We provide programmatic, technical and fundraising support.
              </p>
            </mat-card-content>
          </mat-card>
    </div>
    <div fxFlex="100%" fxLayoutAlign="center center">
        <a mat-icon-button href="#what_we_do"><mat-icon class="arrow-down" id="what_we_do">arrow_downward</mat-icon></a>
    </div>
</section>
<section fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100%" fxLayoutAlign="center center">
        <span class="white-circle"></span>
    </div>
    <div fxFlex="605px" fxFlex.xs="100%" fxLayout="column"  fxLayoutGap="16px" >
        <div class="section-line"></div>
        <h1 >What We Do</h1>
        <p>We help clinics improve their ability to respond to incoming calls faster and smarter.</p>
        <p>Community clinics face huge demands on a daily basis. In a world turned upside-down due to coronavirus clinics are facing new challenges filled with intensity and uncertainty.</p>
        <p>Our driving motivation is to save clinics time, reduce their headaches, and help them serve more people during the pandemic and beyond. </p>
    </div>
    <div fxFlex.xl="100px">

    </div>
    <div fxFlex="494px" fxHide.xs  fxLayoutAlign="center end">
        <img fxFlex="494px" src="../assets/images/Clinic_Line1.png" />
    </div>
    <div class="margin-top64" fxFlex="100%" fxLayoutAlign="center center">
        <a href="#how_it_works" mat-icon-button><mat-icon  id="how_it_works" class="arrow-down">arrow_downward</mat-icon></a>
    </div>
</section>
<section  fxLayout="column" fxLayoutAlign="center center"  class="white-section">
    <div fxFlex="100%" fxLayoutAlign="center center">
        <span class="circle"></span>
    </div>
    <div class="section-line"></div>
    <h1>How It Works</h1>
    <div fxFlexFill fxLayout="row wrap"  fxLayoutAlign="center start" fxLayoutGap.xl="48px">
        <div fxFlex="367px" fxFlex.xs="300px"  class="padding-8px" fxLayout="row wrap" fxLayoutGap="24px" >
            <img  class="caller-image" fxFlex.xs="250px" src="../assets/images/Caller.png" />
            <p  class="primary-color"><b>Person Calls Clinic</b></p>
            <p fxFlex="100%">Based on the type of assistance the caller is seeking, they get routed into the HelplineSOS triage system.</p>
        </div>
        <!-- <mat-icon color="primary">keyboard_arrow_right</mat-icon> -->
        <div fxFlex="367px" fxFlex.xs="300px" class="padding-8px" fxLayout="row wrap" fxLayoutGap="24px">
            <img  class="phone-system-image" fxFlex.xs="250px"  src="../assets/images/IVR_Phone_System.png" />
            <p  class="primary-color"><b>HelplineSOS Intakes Call</b></p>
            <p fxFlex="100%">The caller enters HelplineSOS phone triage and is prompted with a series of questions and answers are loaded real-time into an online prioritization callback list.</p>
        </div>
        <!-- <mat-icon color="primary">keyboard_arrow_right</mat-icon> -->
        <div fxFlex="367px" fxFlex.xs="300px" class="padding-8px" fxLayout="row wrap" fxLayoutGap="24px">
            <img class="helpline-support-image" fxFlex.xs="250px" src="../assets/images/Helpline_Support.png" />
            <p  class="primary-color"><b>Clinic Staff Make Callback</b></p>
            <p fxFlex="100%">Staff or volunteers working at the clinic or from home access the callback list and use the HelplineSOS provided caller information and customized script to make callbacks.</p>
        </div>
    </div>
    <div fxFlex="100%" fxLayoutAlign="center center" class="margin-top64">
        <a href="#comments" mat-icon-button  ><mat-icon id="comments" class="arrow-down">arrow_downward</mat-icon></a>
    </div>
</section>
<section fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center"  fxLayoutAlign.xs="start center" >
    <div fxFlex="100%" fxLayoutAlign="center center">
        <span class="white-circle"></span>
    </div>
    <div fxLayout="column" fxFlex=456px fxFlex.xs=30% fxLayoutAlign="center center"  class="margin-top64">
        <!-- <mat-icon color="primary" class="face-icon">face</mat-icon> -->
        <span  class="face-icon">
            <img src="../assets/images/nurse-icon.png" />
        </span>
        <mat-icon fxFlexAlign="start" class="quotes">format_quote</mat-icon>
        <p>My patients depend on me and I want to provide them the guidance, reassurance, and direction they need during this crisis. We were operating at full capacity before COVID-19 and now more capacity is needed. HelplineSOS enables us to respond to COVID-19, while still providing for the chronic health needs of our patients.</p>
          <p class="primary-color " fxFlexAlign="end"><b>—Clinic Chief Operating Officer</b></p>
    </div>
    <div  fxHide.xs fxFlex="100px" fxFlex.xl="200px" ></div>
    <div fxLayout="column" fxFlex.xs=30% fxFlex=456px  fxLayoutAlign="center center" class="margin-top64">
        <span  class="face-icon-patient">
            <img src="../assets/images/caller-icon.png" />
        </span>
        <mat-icon fxFlexAlign="start" class="quotes">format_quote</mat-icon>
        <p>I called multiple different hotlines to try and get a test and doctor’s note because I work at an elder care facility. I was put on long term hold and directed back to my provider. HelplineSOS quickly got me connected to an actual person who got me routed to telehealth, drive through testing and provided a doctor’s note for my employers.</p>
          <p class="primary-color " fxFlexAlign="end"><b>—Patient Caller</b></p>
    </div>
    <div fxFlex="100%" fxLayoutAlign="center center" class="margin-top64">
        <a mat-icon-button href="#contact_us"><mat-icon class="arrow-down">arrow_downward</mat-icon></a>
    </div>
</section>
<section class="contact-us-section" fxFlex="100%" id="contact_us"  fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex="100%" fxLayoutAlign="center center">
        <span class="circle"></span>
    </div>
<div fxFlex="400px" fxFlex.xs="100%" fxLayout="row wrap" >
    <div class="section-line-classic"></div>
    <h1 class="margin-top32" fxFlex="100%">Contact Us</h1>
    <img fxFlex="215px" class="receiver-image" src="../assets/images/Receiver.png" />
    <p fxFlex="100%"><b>Contact information</b></p>
    <p fxFlex="100%">helpline@sostento.org</p>
</div>
<form [formGroup]="formGroup" fxFlex="654px" fxFlex.xs="100%" fxLayout="column"  >
    <strong><b>Interested in receiving a demo and orientation of HelplineSOS?</b>  </strong>
    <mat-form-field class="margin-top32">
        <mat-label>Full Name</mat-label>
        <input formControlName="fullName" matInput />
      </mat-form-field>
      <mat-form-field >
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput />
      </mat-form-field>
      <mat-form-field >
        <mat-label>Phone Number</mat-label>
        <input matInput [textMask]="mask" formControlName="phoneNumber"  />
      </mat-form-field>
      <mat-form-field >
        <mat-label>Message</mat-label>
        <textarea formControlName="message" matInput ></textarea>
      </mat-form-field>
      <button fxFlexAlign="center" (click)="submit()" mat-raised-button color="primary">SUBMIT</button>
    </form>
</section>
<footer fxLayout="row wrap" fxLayoutAlign="center center">
    <img fxFlex="268px" src="../assets/images/HelplineLogo_03_FinalColor.svg" />
    <div fxFlex="100%"  fxLayout="column">
        <p fxFlexAlign="center">HelplineSOS initiative, Sostento Inc. All Rights Reserved</p>
    </div>
</footer>
</main>
