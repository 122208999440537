import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  isChecked = true;

  constructor(public dialogRef: MatDialogRef<TermsAndConditionsComponent>) { }

  ngOnInit(): void {
  }

  iAgree() {
    this.dialogRef.close();
  }
}
